const setRate = (rateObj) => {
  return {
    type: "SET_Rate",
    payload: rateObj,
  };
};

const delRate = () => {
  return {
    type: "del_Rate",
  };
};

export default {
  setRate,
  delRate,
};
