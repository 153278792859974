import React, { useState, useEffect } from "react";
import GeneralHeader from "components/Headers/GeneralHeader";
import Select from "react-select";
import axios from "axios";
import Swal from "sweetalert2";
import NumberFormat from "react-number-format";
import Moment from "react-moment";
import {
  Button,
  Card,
  CardHeader,
  CardFooter,
  CardBody,
  Form,
  Table,
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  Badge,
  Label,
} from "reactstrap";
import { useSelector } from "react-redux";
const path = require("../../externals/path.json").path;

export default function Index() {
  const rate = useSelector((state) => state.rateData.rate);
  const user = useSelector((state) => state.isLogged.user);
  const [activityList, setActivityList] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [activityId, setActivityId] = useState();
  const [isDone, setIsDone] = useState();
  const [username, setUsername] = useState();
  const [listType, setListType] = useState("all");
  const [activityTo, setActivityTo] = useState();
  const [type, setType] = useState();
  const [details, setDetails] = useState();
  const [showAddModal, setshowAddModal] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  function onBindActivitySectionData() {
    axios
      .post(
        path + "/activitysection/list",
        {
          userId: user.id,
          leaderId: user.leaderId,
          role: user.role,
          type: listType !== "all" ? listType : null,
        },
        {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        }
      )
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setActivityList(result.data.data);
        }
      });
  }

  useEffect(() => {
    onBindActivitySectionData();
  }, [listType]);

  function onBindUsersData() {
    axios
      .get(path + "/users", {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setUsersList(result.data.data);
        }
      });
  }
  function toggle() {
    setshowAddModal(!showAddModal);
  }

  function usersItemsRender() {
    let items = [];
    usersList.map((item) =>
      items.push(
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      )
    );
    return items;
  }

  function onAddNewDataHandler() {
    setType();
    setUsername(user.id);
    setDetails();
    setActivityTo();
    setIsDone("0");
    setIsUpdate(false);
    onBindUsersData();
    toggle();
  }

  function onSaveDataHandler() {
    const activitysection = {
      type: type !== "0" ? type : null,
      details: details,
      username: username,
      activityTo: Number(activityTo),
      isDone: isDone,
    };
    if (isUpdate === true) {
      axios
        .put(path + "/activitysection/" + activityId, activitysection, {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        })
        .then((result) => {
          if (result.data.success === true) {
            onBindActivitySectionData();
            setshowAddModal(false);
            setIsUpdate(false);
            Swal.fire({
              title: "THANKS",
              text: "the activitysection updated",
              icon: "success",
            });
          }
          // if (result.data.token !== null)
          //   this.props.user.user.token = result.data.token;
        })
        .catch((error) => {
          Swal.fire({
            title: "warning",
            text: "activity did not updated please contact administrator",
            icon: "warning",
          });
        });
    } else {
      axios
        .post(path + "/activitysection", activitysection, {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        })
        .then((result) => {
          if (result.data.success === true) {
            onBindActivitySectionData();
            setshowAddModal(false);
            Swal.fire({
              title: "THANKS",
              text: "new activity Added",
              icon: "success",
            });
          }
          // if (result.data.token !== null)
          //   this.props.user.user.token = result.data.token;
        })
        .catch((error) => {
          Swal.fire({
            title: "warning",
            text: "activity did not added please contact administrator",
            icon: "warning",
          });
        });
    }
  }

  function EditDataHandler(activityId, Index) {
    // let item = itemList.find((o) => o.id === item.itemId);
    axios
      .get(path + "/activitysection/" + activityId, {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          onBindUsersData();
          setIsUpdate(true);
          setshowAddModal(true);
          setType(result.data.data.type);
          setIsDone(result.data.data.isDone);
          setActivityId(activityId);
          setDetails(result.data.data.details);
          setActivityTo(result.data.data.activityTo);
          setUsername(result.data.data.username);
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "warning",
          text: `there is some error while get Activity with  number ${Index} data please contact administrator`,
          icon: "warning",
        });
      });
  }

  function onDeleteHandler(id, index) {
    Swal.fire({
      title: 'Do you want to delete Activity  "' + index + '" ?',
      showCancelButton: true,
      confirmButtonText: "Save",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.value) {
        axios
          .delete(path + "/activitysection/" + id, {
            // headers: {
            //   "auth-token": this.props.user.user.token,
            // },
          })
          .then((result) => {
            if (result.data.statusCode === 200) {
              onBindActivitySectionData();
              Swal.fire("activity Deleted!", "", "success");
            }
            // if (result.data.token !== null)
            //   this.props.user.user.token = result.data.token;
          })
          .catch((error) => {
            Swal.fire({
              title: "warning",
              text: "the activity did not deleted please contact administrator",
              icon: "warning",
            });
          });
      }
    });
  }

  function isDoneHandler(id, index) {
    Swal.fire({
      title: 'Do you want to Done  Activity  "' + index + '" ?',
      showCancelButton: true,
      confirmButtonText: "Save",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.value) {
        axios
          .put(path + "/activitysection/isDone/" + id, {
            // headers: {
            //   "auth-token": this.props.user.user.token,
            // },
          })
          .then((result) => {
            if (result.data.statusCode === 200) {
              onBindActivitySectionData();
              Swal.fire("activity Done!", "", "success");
            }
            // if (result.data.token !== null)
            //   this.props.user.user.token = result.data.token;
          })
          .catch((error) => {
            Swal.fire({
              title: "warning",
              text: "the activity did not Done please contact administrator",
              icon: "warning",
            });
          });
      }
    });
  }
  return (
    <>
      <GeneralHeader />

      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col xs="4">
                    <h2 className="mb-0">Activity List</h2>
                  </Col>
                  <Col xs="4">
                    <FormGroup row className="mb-0">
                      <Label
                        for="exampleEmail"
                        style={{
                          fontSize: 18,
                          fontWeight: "bold",
                        }}
                      >
                        List Type
                      </Label>
                      <Col style={{}}>
                        <Input
                          className="form-control-alternative"
                          value={listType}
                          id="offerFor"
                          placeholder="offerFor"
                          type="select"
                          onChange={(e) => {
                            setListType(e.target.value);
                          }}
                        >
                          <option value={"all"}>All</option>
                          <option value={"0"}>not Done</option>
                          <option value={"1"}>is Done</option>
                        </Input>
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      onClick={(e) => onAddNewDataHandler()}
                      size="md"
                    >
                      New Activity
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <div style={{ height: "76vh", overflow: "scroll" }}>
                <Table
                  className=" align-items-center table-flush"
                  style={{ fontWeight: "bold", color: "black" }}
                >
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">type</th>
                      <th scope="col">from</th>
                      <th scope="col">Details</th>
                      <th scope="col">Activity For</th>
                      <th scope="col">is Done?</th>
                      <th scope="col">Date</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {activityList.map(function (item, index) {
                      return (
                        <tr>
                          <th scope="row">{index + 1}</th>
                          <td>{item.type}</td>
                          <td>{item.fromName}</td>
                          <td>{item.details}</td>
                          <td>{item.toName}</td>
                          <td>
                            {item.isDone === "1" ? (
                              <Badge color="success">YES</Badge>
                            ) : (
                              <Badge color="danger">No</Badge>
                            )}
                          </td>
                          <td>
                            {" "}
                            <Moment format="DD-MM-YYYY">{item.date}</Moment>
                          </td>

                          <td>
                            <div>
                              {user.role === "admin" ||
                              user.role === "accountant" ? (
                                <>
                                  <Button
                                    size="sm"
                                    color="primary"
                                    onClick={() =>
                                      isDoneHandler(item.id, index + 1)
                                    }
                                    disabled={
                                      item.isDone === "1" ? true : false
                                    }
                                  >
                                    Done ?
                                  </Button>{" "}
                                  <Button
                                    size="sm"
                                    color="warning"
                                    onClick={() =>
                                      EditDataHandler(item.id, index + 1)
                                    }
                                  >
                                    Edit
                                  </Button>{" "}
                                  <Button
                                    size="sm"
                                    color="danger"
                                    onClick={() =>
                                      onDeleteHandler(item.id, index + 1)
                                    }
                                  >
                                    Delete
                                  </Button>{" "}
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem className="disabled">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem className="active">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        1
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        isOpen={showAddModal}
        toggle={toggle}
        className="modal-lg"
        style={{ maxWidth: "1500px", width: "100%" }}
      >
        <ModalHeader toggle={toggle}>New Activity</ModalHeader>
        <ModalBody>
          <Col className="order-xl-1">
            <Card className="shadow" style={{ backgroundColor: "#F0F8FF" }}>
              <CardBody>
                <Form autoComplete="off" id="ff">
                  <div className="pl-lg-2">
                    <Row></Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            value={type}
                            id="type"
                            placeholder="cost (IQD)"
                            type="select"
                            onChange={(e) => setType(e.target.value)}
                          >
                            <option value={0}>---Type---</option>
                            <option value={"تایبەت"}>تایبەت</option>
                            <option value={"پێشنیار"}>پێشنیار</option>
                            <option value={"چالاکی داهاتوو"}>
                              چالاکی داهاتوو
                            </option>
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            value={details}
                            id="details"
                            placeholder="details"
                            type="textarea"
                            onChange={(e) => setDetails(e.target.value)}
                          >
                            {" "}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            value={activityTo}
                            id="activityTo"
                            placeholder="activityTo"
                            type="select"
                            onChange={(e) => setActivityTo(e.target.value)}
                          >
                            <option>---Activity For---</option>
                            {usersItemsRender()}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => onSaveDataHandler()}>
            Save
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
