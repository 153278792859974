// const loggedReducer = (state = false, action) => {
//   switch (action.type) {
//     case "SIGN_IN":
//       return !state;
//     default:
//       return state;
//   }
// };

// export default loggedReducer;
const Rates = (state = {}, action) => {
  switch (action.type) {
    case "SET_Rate":
      return {
        ...state,
        rate: action.payload,
      };
    case "del_Rate":
      return {
        ...state,
        rate: {},
      };
    default:
      return state;
  }
};

export default Rates;
