import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  FormGroup,
  Input,
  Table,
  Form,
  ModalFooter,
} from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import allActions from "actions/allActions";
import NumberFormat from "react-number-format";
import Moment from "react-moment";
import Swal from "sweetalert2";

const path = require("../../externals/path.json").path;
const MandwbHeader = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.isLogged.user);
  const rate = useSelector((state) => state.rateData.rate);

  const [pendingCount, setPendingCount] = useState(0);
  const [showAddModal, setshowAddModal] = useState(false);
  const [rateList, setrateList] = useState([]);
  const [username, setusername] = useState();
  const [rateCost, setRateCost] = useState();

  function onPendingSellData() {
    axios
      .get(path + "/sell/pending", {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setPendingCount(result.data.data.count);
        }
      });
  }
  function onBindRateData() {
    axios
      .get(path + "/rates/list", {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setrateList(result.data.data);
        }
      });
  }
  useEffect(() => {
    onPendingSellData();
  }, []);

  function toggle() {
    setshowAddModal(!showAddModal);
  }

  function onAddNewDataHandler() {
    setusername(user.id);
    setRateCost();
    onBindRateData();
    toggle();
  }
  function onsaveRateHandler() {
    let rateData = {
      rate: rateCost,
      username: username,
    };

    axios
      .post(path + "/rates", rateData, {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        if (result.data.success === true) {
          GetLastRate();
          toggle();

          Swal.fire({
            title: "THANKS",
            text: "new Rate Added",
            icon: "success",
          });
        }
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
      })
      .catch((error) => {
        Swal.fire({
          title: "warning",
          text: "rate did not added please contact administrator",
          icon: "warning",
        });
      });
  }

  function GetLastRate() {
    axios
      .get(path + "/rates", {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          let rate = {
            id: result.data.data.id,
            rate: result.data.data.rate,
          };
          dispatch(allActions.RatesAction.setRate(rate));
        }
      });
  }
  return (
    <>
      <div className="header bg-gradient-info pb-6 pt-5 pt-md-6">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center">
              <Col className="text-center mb-3">
                <Button
                  color="secondary"
                  onClick={(e) => onAddNewDataHandler()}
                  size="md"
                >
                  Change Rate
                </Button>
              </Col>
            </Row>
            {/* Card stats */}
            <Row>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-2">
                  <Link to="/admin/sales">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h4"
                            className="text-uppercase text-muted mb-0"
                          >
                            New Invoice
                          </CardTitle>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                            <i class="fas fa-file-invoice"></i>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Link>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-2">
                  <Link to="/admin/sales?status=0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h4"
                            className="text-uppercase text-muted mb-0"
                          >
                            Pending Sales
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {pendingCount}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-green  text-white rounded-circle shadow">
                            <i class="fas fa-pause-circle"></i>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Link>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-2">
                  <Link to="/admin/items">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h4"
                            className="text-uppercase text-muted mb-0"
                          >
                            Manage items
                          </CardTitle>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-green  text-white rounded-circle shadow">
                            <i class="fas fa-pencil-alt"></i>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Link>
                </Card>
              </Col>

              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-2">
                  <Link to="/admin/Inventory">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h4"
                            className="text-uppercase text-muted mb-0"
                          >
                            Inventory
                          </CardTitle>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-purple text-white rounded-circle shadow">
                            <i class="fas fa-warehouse"></i>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Link>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-2">
                  <Link to="/admin/customer/create">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h4"
                            className="text-uppercase text-muted mb-0"
                          >
                            Customers
                          </CardTitle>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-pink text-white rounded-circle shadow">
                            <i class="fas fa-user-tie"></i>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Link>
                </Card>
              </Col>

              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-2">
                  <Link to="/admin/Doctors">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h4"
                            className="text-uppercase text-muted mb-0"
                          >
                            Doctors
                          </CardTitle>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-pink text-white rounded-circle shadow">
                            <i class="fas fa-user-nurse"></i>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Link>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-2">
                  <Link to="/admin/Hospitals">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h4"
                            className="text-uppercase text-muted mb-0"
                          >
                            Hospitals
                          </CardTitle>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                            <i class="fas fa-hospital-user"></i>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Link>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-2">
                  <Link to="/admin/debts">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h4"
                            className="text-uppercase text-muted mb-0"
                          >
                            Debits
                          </CardTitle>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-purple text-white rounded-circle shadow">
                            <i class="far fa-credit-card"></i>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Link>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
        <Modal isOpen={showAddModal} toggle={toggle} className="modal-lg">
          <ModalHeader toggle={toggle}>user's page permission</ModalHeader>
          <ModalBody>
            <Col className="order-xl-1">
              <Card className="shadow" style={{ backgroundColor: "#F0F8FF" }}>
                <CardBody>
                  <Form autoComplete="off" id="ff">
                    <div className="pl-lg-2">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <Input
                              className="form-control-alternative"
                              value={rateCost}
                              id="rate"
                              placeholder="Rate (IQD)"
                              type="text"
                              onChange={(e) => setRateCost(e.target.value)}
                            ></Input>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Table bordered responsive>
                          <thead className="thead-light">
                            <tr>
                              <th scope="col" className="text-center">
                                #
                              </th>
                              <th scope="col" className="text-center">
                                Rate
                              </th>
                              <th scope="col" className="text-center">
                                User Name
                              </th>
                              <th scope="col" className="text-center">
                                Entry Date
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {rateList.map(function (item, index) {
                              return (
                                <tr>
                                  <th scope="row">{item.id}</th>
                                  <td className="text-center">
                                    <NumberFormat
                                      value={item.rate}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={"IQD "}
                                    />
                                  </td>
                                  <td className="text-center">
                                    {item.username}
                                  </td>
                                  <td className="text-center">
                                    <Moment format="DD-MM-YYYY">
                                      {item.timestamp}
                                    </Moment>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={onsaveRateHandler}>
              Save
            </Button>{" "}
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
};

export default MandwbHeader;
