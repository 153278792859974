import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";

import {
  Button,
  Card,
  CardHeader,
  CardFooter,
  CardBody,
  Form,
  Table,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
} from "reactstrap";

const path = require("../../../externals/path.json").path;

export default function Neighbours() {
  const [zoneList, setZoneList] = useState([]);
  const [neighboursList, setNeighboursList] = useState([]);
  const [CitiesList, setCitiesList] = useState([]);
  const [cityId, setCityId] = useState();
  const [zoneId, setZoneId] = useState();
  const [neighbourId, setNeighbourId] = useState();
  const [neighbourName, setNeighbourName] = useState();
  const [showModal, setShowModal] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [searchcityId, setSearchCityId] = useState();
  const [searchzoneId, setSearchZoneId] = useState();
  const Toggle = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    onBindTableData();
    onBindCityList();
  }, []);
  useEffect(() => {
    onBindTableData();
  }, [searchzoneId]);
  const onBindTableData = () => {
    axios
      .get(path + `/neighbourslist/${searchzoneId}`, {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setNeighboursList(result.data.data);
        }
      });
  };
  const onBindCityList = () => {
    axios
      .get(path + "/cities", {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setCitiesList(result.data.data);
        }
      });
  };
  function onBindZoneDataHandler(cityId) {
    axios
      .get(path + "/zone/" + Number(cityId), {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) setZoneList(result.data.data);
      });
  }

  function cityItemsRender() {
    let items = [];
    CitiesList.map((item) =>
      items.push(
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      )
    );
    return items;
  }
  function zoneItemsRender() {
    let items = [];
    zoneList.map((item) =>
      items.push(
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      )
    );
    return items;
  }

  function onChangeCityHandler(event) {
    if (event !== "---City---") {
      setCityId(event);
      onBindZoneDataHandler(event);
    }
  }
  function onChangeSearchCityHandler(event) {
    if (event !== "---City---") {
      setSearchCityId(event);
      onBindZoneDataHandler(event);
    }
  }

  function onAddNewHandler() {
    Toggle();
    setIsUpdate(false);
    setNeighbourName();
    setZoneId();
    setCityId();
    onBindCityList();
    setZoneList([]);
  }

  function onSaveDataHandler() {
    const Data = {
      name: neighbourName,
      cityId: cityId,
      zoneId: zoneId,
    };
    if (isUpdate === true) {
      axios
        .put(path + "/neighbours/" + neighbourId, Data, {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        })
        .then((result) => {
          if (result.data.success === true) {
            onBindTableData();
            setShowModal(false);
            setIsUpdate(false);
            Swal.fire({
              title: "THANKS",
              text: "the neighbours updated",
              icon: "success",
            });
          }
          // if (result.data.token !== null)
          //   this.props.user.user.token = result.data.token;
        })
        .catch((error) => {
          Swal.fire({
            title: "warning",
            text: "neighbours did not updated please contact administrator",
            icon: "warning",
          });
        });
    } else {
      axios
        .post(path + "/neighbours", Data, {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        })
        .then((result) => {
          if (result.data.success === true) {
            onBindTableData();
            setShowModal(false);
            Swal.fire({
              title: "THANKS",
              text: "new neighbours Added",
              icon: "success",
            });
          }
          // if (result.data.token !== null)
          //   this.props.user.user.token = result.data.token;
        })
        .catch((error) => {
          Swal.fire({
            title: "warning",
            text: "neighbours did not added please contact administrator",
            icon: "warning",
          });
        });
    }
  }
  function EditDataHandler(neighbourId, Index) {
    // let item = itemList.find((o) => o.id === item.itemId);
    axios
      .get(path + "/neighbours/id/" + neighbourId, {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setIsUpdate(true);
          setShowModal(true);
          setNeighbourName(result.data.data.name);
          setCityId(result.data.data.cityId);
          setZoneId(result.data.data.zoneId);
          setNeighbourId(neighbourId);
          onBindCityList();
          onBindZoneDataHandler(result.data.data.cityId);
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "warning",
          text: `there is some error while get neighbour with  number ${Index} data please contact administrator`,
          icon: "warning",
        });
      });
  }
  function onDeleteHandler(id, index) {
    Swal.fire({
      title: 'Do you want to delete neighbour  "' + index + '" ?',
      showCancelButton: true,
      confirmButtonText: "Save",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.value) {
        axios
          .delete(path + "/neighbours/" + id, {
            // headers: {
            //   "auth-token": this.props.user.user.token,
            // },
          })
          .then((result) => {
            if (result.data.statusCode === 200) {
              onBindTableData();
              Swal.fire("neighbours Deleted!", "", "success");
            }
            // if (result.data.token !== null)
            //   this.props.user.user.token = result.data.token;
          })
          .catch((error) => {
            Swal.fire({
              title: "warning",
              text: "the neighbours did not deleted please contact administrator",
              icon: "warning",
            });
          });
      }
    });
  }

  return (
    <>
      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col xs="3">
                    <h2 className="mb-0">Neighbours list</h2>
                  </Col>
                  <Col lg="3">
                    <FormGroup>
                      <Input
                        className="form-control-alternative"
                        value={searchcityId}
                        id="searchcityId"
                        placeholder="city"
                        type="select"
                        onChange={(e) =>
                          onChangeSearchCityHandler(e.target.value)
                        }
                      >
                        <option>---City---</option>
                        {cityItemsRender()}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col lg="3">
                    <FormGroup>
                      <Input
                        className="form-control-alternative"
                        value={searchzoneId}
                        id="searchzoneId"
                        placeholder="zone"
                        type="select"
                        onChange={(e) => setSearchZoneId(e.target.value)}
                      >
                        <option value={-1}>---Zone---</option>
                        {zoneItemsRender()}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col className="text-right" xs="3">
                    <Button
                      color="primary"
                      onClick={(e) => onAddNewHandler()}
                      size="md"
                    >
                      New neighbour
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <div style={{ height: "70vh", overflow: "scroll" }}>
                <Table
                  className=" align-items-center table-flush"
                  style={{ fontWeight: "bold", color: "black" }}
                >
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col"> name</th>
                      <th scope="col"> City</th>
                      <th scope="col"> Zone</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {neighboursList.map(function (item, index) {
                      return (
                        <tr>
                          <th scope="row">{index + 1}</th>
                          <td>{item.name}</td>
                          <td>{item.cityName}</td>
                          <td>{item.zoneName}</td>
                          <td className="">
                            <div>
                              <Button
                                size="sm"
                                color="warning"
                                onClick={() =>
                                  EditDataHandler(item.id, index + 1)
                                }
                              >
                                Edit
                              </Button>
                              <Button
                                size="sm"
                                color="danger"
                                onClick={() =>
                                  onDeleteHandler(item.id, index + 1)
                                }
                              >
                                Delete
                              </Button>{" "}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        isOpen={showModal}
        toggle={Toggle}
        className="modal-lg"
        style={{ maxWidth: "1500px", width: "100%" }}
      >
        <ModalHeader toggle={Toggle}>New User</ModalHeader>
        <ModalBody>
          <Col className="order-xl-1">
            <Card className="shadow" style={{ backgroundColor: "#F0F8FF" }}>
              <CardBody>
                <Form autoComplete="off" id="ff">
                  <h6 className="heading-small text-muted mb-2">
                    City information
                  </h6>
                  <div className="pl-lg-2">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            value={cityId}
                            id="city"
                            placeholder="city"
                            type="select"
                            onChange={(e) =>
                              onChangeCityHandler(e.target.value)
                            }
                          >
                            <option>---City---</option>
                            {cityItemsRender()}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            value={zoneId}
                            id="zoneId"
                            placeholder="zoneId"
                            type="select"
                            onChange={(e) => setZoneId(e.target.value)}
                          >
                            <option>---Zone---</option>
                            {zoneItemsRender()}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            value={neighbourName}
                            id="name"
                            placeholder="Zone Name .."
                            type="text"
                            onChange={(e) => setNeighbourName(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => onSaveDataHandler()}>
            Save
          </Button>{" "}
          <Button color="secondary" onClick={Toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
