import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import NumberFormat from "react-number-format";
import Moment from "react-moment";
import ReactToPrint from "react-to-print";

import {
  Button,
  Table,
  Row,
  Col,
  Input,
  FormGroup,
  Label,
  Badge,
} from "reactstrap";

const path = require("../../../externals/path.json").path;

export default function DoctorVisitations() {
  let invoiceRef = useRef();

  const [reportData, setReportData] = useState([]);
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [userName, setUserName] = useState();
  const [usersList, setUsersList] = useState([]);

  useEffect(() => {
    onBindUsersData();
  }, []);

  function onBindtableData() {
    axios
      .post(
        path + "/doctorsreports/getDoctorVisitations",
        {
          userName: userName !== "0" ? userName : null,
          from: from,
          to: to,
        },
        {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        }
      )
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setReportData(result.data.data);
        }
      });
  }

  function onBindUsersData() {
    axios
      .get(path + "/users", {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setUsersList(result.data.data);
        }
      });
  }

  function usersItemsRender() {
    let items = [];
    usersList.map((item) =>
      items.push(
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      )
    );
    return items;
  }
  return (
    <>
      <Row>
        <Col>
          <Input
            className="form-control-alternative"
            value={userName}
            id="userName"
            placeholder="userName"
            type="select"
            onChange={(e) => {
              setUserName(e.target.value);
            }}
            style={{ fontSize: 16, color: "black", height: 45 }}
          >
            <option value={0}>---User---</option>
            {usersItemsRender()}
          </Input>
        </Col>
        <Col>
          <Input
            className="form-control-alternative"
            value={from}
            id="from"
            placeholder="from"
            type="date"
            onChange={(e) => setFrom(e.target.value)}
            style={{ fontSize: 16, color: "black", height: 45 }}
          />
        </Col>
        <Col>
          <Input
            className="form-control-alternative"
            value={to}
            id="to"
            placeholder="to"
            type="date"
            onChange={(e) => setTo(e.target.value)}
            style={{ fontSize: 16, color: "black", height: 45 }}
          />
        </Col>
        <Col className="text-right">
          <Button color="primary" onClick={(e) => onBindtableData()} size="md">
            Get
          </Button>
          <ReactToPrint
            trigger={() => <Button color="primary">print</Button>}
            content={() => invoiceRef}
          />
        </Col>
      </Row>

      <br />
      <div ref={(el) => (invoiceRef = el)}>
        <br />
        <br />

        <div>
          <Table
            className=" align-items-center table-flush"
            bordered
            style={{ fontWeight: "bold", color: "black" }}
            responsive
          >
            <thead className="thead-light">
              <tr>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  #
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  Description
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  {" "}
                  Doctor Name
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  User Name
                </th>

                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  Date
                </th>
              </tr>
            </thead>
            <tbody>
              {reportData.map(function (item, index) {
                return (
                  <tr>
                    <th
                      scope="row"
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      {index + 1}
                    </th>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      {item.discription}
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      {item.doctorName}
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      {item.username}
                    </td>

                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      <Moment format="YYYY/MM/DD">{item.date}</Moment>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
}
