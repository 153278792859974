import React, { useState, useEffect } from "react";
import GeneralHeader from "components/Headers/GeneralHeader";
import axios from "axios";
import Swal from "sweetalert2";
import NumberFormat from "react-number-format";
import Moment from "react-moment";
import moment from "moment";

import {
  Button,
  Card,
  CardHeader,
  CardFooter,
  CardBody,
  Form,
  Table,
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  Badge,
  Label,
} from "reactstrap";
import { useSelector } from "react-redux";
const path = require("../../externals/path.json").path;

export default function Index() {
  const rate = useSelector((state) => state.rateData.rate);
  const user = useSelector((state) => state.isLogged.user);
  const [forCompanyList, setForCompanyList] = useState([]);
  const [forcompanyId, setForCompanyId] = useState();
  const [amountIQD, setAmountIQD] = useState();
  const [username, setusername] = useState(user.id);
  const [note, setNote] = useState();
  const [showAddModal, setshowAddModal] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [rateId, setRateId] = useState();
  const [date, setDate] = useState();
  const [status, setStatus] = useState(1);
  const [searchStatus, setsearchStatus] = useState("Active");
  const [searchFromDate, setsearchFromDate] = useState();
  const [searchToDate, setsearchToDate] = useState();
  const [totalAmountIQD, setTotalAmountIQD] = useState(0);
  const [totalAmountDLR, setTotalAmountDLR] = useState(0);

  function onBindForCompanyData() {
    axios
      .post(
        path + "/forcompany/list",
        {
          userId: user.id,
          leaderId: user.leaderId,
          role: user.role,
          searchStatus: searchStatus,
          searchFromDate: searchFromDate,
          searchToDate: searchToDate,
        },
        {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        }
      )
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setForCompanyList(result.data.data);
        }
      });

    axios
      .post(
        path + "/forcompany/total",
        {
          userId: user.id,
          leaderId: user.leaderId,
          role: user.role,
          searchStatus: searchStatus,
          searchFromDate: searchFromDate,
          searchToDate: searchToDate,
        },
        {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        }
      )
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setTotalAmountIQD(result.data.data.totalAmountIQD);
          setTotalAmountDLR(result.data.data.totalAmountDLR);
        }
      });
  }

  useEffect(() => {
    onBindForCompanyData();
  }, [searchFromDate, searchToDate, searchStatus]);
  // useEffect(() => {
  //   onBindForCompanyData();
  // }, []);

  function toggle() {
    setshowAddModal(!showAddModal);
  }

  function onAddNewDataHandler() {
    setRateId(rate.id);
    setAmountIQD();
    setusername(user.id);
    setNote();
    setIsUpdate(false);
    setStatus(1);
    toggle();
    setDate();
  }

  function onCHangeamountIQDHandler(value) {
    setAmountIQD(value);
  }

  function onSaveDataHandler() {
    const forcompany = {
      amountIQD: amountIQD,
      note: note,
      rateId: rateId,
      status: status,
      date: date,
      username: username,
    };
    if (isUpdate === true) {
      axios
        .put(path + "/forcompany/" + forcompanyId, forcompany, {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        })
        .then((result) => {
          if (result.data.success === true) {
            onBindForCompanyData();
            setshowAddModal(false);
            setIsUpdate(false);
            Swal.fire({
              title: "THANKS",
              text: "the data updated",
              icon: "success",
            });
          }
          // if (result.data.token !== null)
          //   this.props.user.user.token = result.data.token;
        })
        .catch((error) => {
          Swal.fire({
            title: "warning",
            text: "data did not updated please contact administrator",
            icon: "warning",
          });
        });
    } else {
      axios
        .post(path + "/forcompany", forcompany, {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        })
        .then((result) => {
          if (result.data.success === true) {
            onBindForCompanyData();
            setshowAddModal(false);
            Swal.fire({
              title: "THANKS",
              text: "new data Added",
              icon: "success",
            });
          }
          // if (result.data.token !== null)
          //   this.props.user.user.token = result.data.token;
        })
        .catch((error) => {
          Swal.fire({
            title: "warning",
            text: "data did not added please contact administrator",
            icon: "warning",
          });
        });
    }
  }

  function EditDataHandler(forcompanyId, Index) {
    // let item = itemList.find((o) => o.id === item.itemId);
    axios
      .get(path + "/forcompany/" + forcompanyId, {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setIsUpdate(true);
          setshowAddModal(true);
          setRateId(result.data.data.rateId);
          setDate(moment(result.data.data.date).format("YYYY-MM-DD"));
          setAmountIQD(result.data.data.amountIQD);
          setForCompanyId(forcompanyId);
          setNote(result.data.data.note);
          setStatus(result.data.data.status);
          setusername(result.data.data.username);
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "warning",
          text: `there is some error while get offer with  number ${Index} data please contact administrator`,
          icon: "warning",
        });
      });
  }

  function onDeleteHandler(id, index) {
    Swal.fire({
      title: 'Do you want to delete data  "' + index + '" ?',
      showCancelButton: true,
      confirmButtonText: "Save",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.value) {
        axios
          .delete(path + "/forcompany/" + id, {
            // headers: {
            //   "auth-token": this.props.user.user.token,
            // },
          })
          .then((result) => {
            if (result.data.statusCode === 200) {
              onBindForCompanyData();
              Swal.fire("data Deleted!", "", "success");
            }
            // if (result.data.token !== null)
            //   this.props.user.user.token = result.data.token;
          })
          .catch((error) => {
            Swal.fire({
              title: "warning",
              text: "the data did not deleted please contact administrator",
              icon: "warning",
            });
          });
      }
    });
  }

  function onActiveCustomerHandler(id, index) {
    Swal.fire({
      title: 'Do you want to Active data  "' + index + '" ?',
      showCancelButton: true,
      confirmButtonText: "Active",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.value) {
        axios
          .put(path + "/activeforcompany/" + id, {
            // headers: {
            //   "auth-token": this.props.user.user.token,
            // },
          })
          .then((result) => {
            if (result.data.statusCode === 200) {
              onBindForCompanyData();
              Swal.fire("data Actived!", "", "success");
            }
            // if (result.data.token !== null)
            //   this.props.user.user.token = result.data.token;
          })
          .catch((error) => {
            Swal.fire({
              title: "warning",
              text: "the data did not deleted please contact administrator",
              icon: "warning",
            });
          });
      }
    });
  }
  return (
    <>
      <GeneralHeader />

      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col>
                    <h2 className="mb-0">Money Back to Company</h2>
                  </Col>

                  <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      onClick={(e) => onAddNewDataHandler()}
                      size="md"
                    >
                      New Amount
                    </Button>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col col="4">
                    <FormGroup row className="mb-0">
                      <Label
                        for="exampleEmail"
                        style={{
                          fontSize: 14,
                          fontWeight: "bold",
                        }}
                      >
                        Status
                      </Label>
                      <Col style={{}}>
                        <Input
                          className="form-control-alternative"
                          value={searchStatus}
                          id="searchStatus"
                          placeholder="searchStatus"
                          type="select"
                          onChange={(e) => setsearchStatus(e.target.value)}
                        >
                          <option value="Active">Active</option>
                          <option value="Archive">Archive</option>
                        </Input>
                      </Col>
                    </FormGroup>
                  </Col>

                  <Col col="4">
                    <FormGroup row className="mb-0">
                      <Label
                        for="exampleEmail"
                        style={{
                          fontSize: 14,
                          fontWeight: "bold",
                        }}
                      >
                        From
                      </Label>
                      <Col style={{}}>
                        <Input
                          className="form-control-alternative"
                          value={searchFromDate}
                          id="searchFromDate"
                          placeholder="searchFromDate"
                          type="date"
                          onChange={(e) => {
                            setsearchFromDate(e.target.value);
                          }}
                        />
                      </Col>
                    </FormGroup>
                  </Col>

                  <Col col="4">
                    <FormGroup row className="mb-0">
                      <Label
                        for="exampleEmail"
                        style={{
                          fontSize: 14,
                          fontWeight: "bold",
                        }}
                      >
                        To
                      </Label>
                      <Col style={{}}>
                        <Input
                          className="form-control-alternative"
                          value={searchToDate}
                          id="searchToDate"
                          placeholder="searchToDate"
                          type="date"
                          onChange={(e) => {
                            setsearchToDate(e.target.value);
                          }}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col col="4">
                    <FormGroup row className="mb-0">
                      <Label
                        for="exampleEmail"
                        style={{
                          fontSize: 14,
                          fontWeight: "bold",
                        }}
                      >
                        Total(IQD)
                      </Label>
                      <Col style={{}}>
                        <NumberFormat
                          value={totalAmountIQD ? totalAmountIQD.toFixed(0) : 0}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"IQD "}
                        />
                      </Col>
                    </FormGroup>
                  </Col>

                  <Col col="4">
                    <FormGroup row className="mb-0">
                      <Label
                        for="exampleEmail"
                        style={{
                          fontSize: 14,
                          fontWeight: "bold",
                        }}
                      >
                        Total(DLR)
                      </Label>
                      <Col style={{}}>
                        <NumberFormat
                          value={totalAmountDLR ? totalAmountDLR.toFixed(0) : 0}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$ "}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>
              </CardHeader>
              <div style={{ height: "76vh", overflow: "scroll" }}>
                <Table
                  className=" align-items-center table-flush"
                  style={{ fontWeight: "bold", color: "black" }}
                >
                  <thead className="thead-light">
                    <tr>
                      <th
                        scope="col"
                        style={{
                          fontSize: 15,
                        }}
                      >
                        #
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontSize: 15,
                        }}
                      >
                        Amount IQD
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontSize: 15,
                        }}
                      >
                        Amount $
                      </th>

                      <th
                        scope="col"
                        style={{
                          fontSize: 15,
                        }}
                      >
                        note
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontSize: 15,
                        }}
                      >
                        Date
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontSize: 15,
                        }}
                      >
                        user
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontSize: 15,
                        }}
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {forCompanyList.map(function (item, index) {
                      return (
                        <tr>
                          <th
                            scope="row"
                            style={{
                              fontSize: 15,
                            }}
                          >
                            {index + 1}
                          </th>
                          <td
                            style={{
                              fontSize: 15,
                            }}
                          >
                            {" "}
                            <NumberFormat
                              value={item.amountIQD.toFixed(0)}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"IQD "}
                            />
                          </td>
                          <td
                            style={{
                              fontSize: 15,
                            }}
                          >
                            <NumberFormat
                              value={item.amountDLR.toFixed(0)}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$ "}
                            />
                          </td>
                          <td
                            style={{
                              fontSize: 15,
                            }}
                          >
                            {item.note}
                          </td>
                          <td
                            style={{
                              fontSize: 15,
                            }}
                          >
                            {" "}
                            <Moment format="DD-MM-YYYY">{item.date}</Moment>
                          </td>
                          <td
                            style={{
                              fontSize: 15,
                            }}
                          >
                            {item.username}
                          </td>
                          <td className="text-right">
                            <div>
                              {user.role === "admin" ||
                              user.role === "accountant" ? (
                                <>
                                  {item.status === 0 ? (
                                    <>
                                      <Button
                                        size="sm"
                                        color="success"
                                        onClick={() =>
                                          onActiveCustomerHandler(
                                            item.id,
                                            index
                                          )
                                        }
                                      >
                                        Active
                                      </Button>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  <Button
                                    size="sm"
                                    color="warning"
                                    onClick={() =>
                                      EditDataHandler(item.id, index + 1)
                                    }
                                  >
                                    Edit
                                  </Button>{" "}
                                  <Button
                                    size="sm"
                                    color="danger"
                                    onClick={() =>
                                      onDeleteHandler(item.id, index + 1)
                                    }
                                  >
                                    Delete
                                  </Button>{" "}
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem className="disabled">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem className="active">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        1
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        isOpen={showAddModal}
        toggle={toggle}
        className="modal-lg"
        style={{ maxWidth: "1500px", width: "100%" }}
      >
        <ModalHeader toggle={toggle}>New Amount</ModalHeader>
        <ModalBody>
          <Col className="order-xl-1">
            <Card className="shadow" style={{ backgroundColor: "#F0F8FF" }}>
              <CardBody>
                <Form autoComplete="off" id="ff">
                  <div className="pl-lg-2">
                    <Row></Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            htmlFor="deliveredBy"
                            style={{
                              fontSize: 16,
                              fontWeight: "bold",
                            }}
                          >
                            Amount (IQD)
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={amountIQD}
                            id="amountIQD"
                            placeholder="Amount (IQD)"
                            type="number"
                            onChange={(e) =>
                              onCHangeamountIQDHandler(e.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            htmlFor="deliveredBy"
                            style={{
                              fontSize: 16,
                              fontWeight: "bold",
                            }}
                          >
                            Note (Reason)
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={note}
                            id="note"
                            placeholder="note"
                            type="textarea"
                            onChange={(e) => setNote(e.target.value)}
                          >
                            {" "}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            htmlFor="deliveredBy"
                            style={{
                              fontSize: 16,
                              fontWeight: "bold",
                            }}
                          >
                            Date
                          </label>{" "}
                          <Input
                            className="form-control-alternative"
                            value={date}
                            id="date"
                            placeholder="date"
                            type="date"
                            onChange={(e) => setDate(e.target.value)}
                          ></Input>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => onSaveDataHandler()}>
            Save
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
