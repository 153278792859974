import React, { useState, useRef, useEffect } from "react";
import ReactToPrint from "react-to-print";
import axios from "axios";
import Swal from "sweetalert2";
import NumberFormat from "react-number-format";
import Moment from "react-moment";
import { useSelector } from "react-redux";

import { Button, Table, Row, Col, Input, FormGroup, Label } from "reactstrap";

const path = require("../../../externals/path.json").path;

export default function MonthlyReport() {
  let invoiceRef = useRef();
  let reportHistoryRef = useRef();
  const user = useSelector((state) => state.isLogged.user);

  const [reportData, setReportData] = useState({
    totalExpensesDLR: 0,
    totalExpensesIQD: 0,
    totalSellDLR: 0,
    totalSellIQD: 0,
    totalUserShareDLR: 0,
    totalUserShareIQD: 0,
    bankDLR: 0,
    bankIQD: 0,
    forCompanyDLR: 0,
    forCompanyIQD: 0,
    keepInBankPer: 5,
  });
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [resultDLR, setResultDLR] = useState(0);
  const [resultIQD, setResultIQD] = useState(0);
  const [bankDLR, setbankDLR] = useState(0);
  const [bankIQD, setbankIQD] = useState(0);
  const [forCompanyDLR, setforCompanyDLR] = useState(0);
  const [forCompanyIQD, setforCompanyIQD] = useState(0);
  const [keepInBankPer, setKeepInBankPer] = useState(5);
  const [reportHistory, setReportHistory] = useState([]);

  useEffect(() => {
    onBindReportHistory();
  }, []);
  function onBindtableData() {
    if (from && to)
      axios
        .post(
          path + "/sellreports/getMonthlyReport",
          {
            from: from,
            to: to,
          },
          {
            // headers: {
            //   "auth-token": this.props.user.user.token,
            // },
          }
        )
        .then((result) => {
          // if (result.data.token !== null)
          //   this.props.user.user.token = result.data.token;
          if (result.data.success === true) {
            setReportData({
              totalExpensesDLR: result.data.data.totalExpensesDLR
                ? result.data.data.totalExpensesDLR
                : 0,
              totalExpensesIQD: result.data.data.totalExpensesIQD
                ? result.data.data.totalExpensesIQD
                : 0,
              totalSellDLR: result.data.data.totalSellDLR
                ? result.data.data.totalSellDLR
                : 0,
              totalSellIQD: result.data.data.totalSellIQD
                ? result.data.data.totalSellIQD
                : 0,
              totalUserShareDLR: result.data.data.totalUserShareDLR
                ? result.data.data.totalUserShareDLR
                : 0,
              totalUserShareIQD: result.data.data.totalUserShareIQD
                ? result.data.data.totalUserShareIQD
                : 0,
            });
            let resDLR =
              result.data.data.totalSellDLR -
              result.data.data.totalExpensesDLR -
              // result.data.data.totalUserShareDLR -
              result.data.data.totalForCompanyDLR;

            setResultDLR(resDLR);
            let resIQD =
              result.data.data.totalSellIQD -
              result.data.data.totalExpensesIQD -
              // result.data.data.totalUserShareIQD -
              result.data.data.totalForCompanyIQD;
            setResultIQD(resIQD);

            // let stayDLR = (resDLR * keepInBankPer) / 100;
            // let stayIQD = (resIQD * keepInBankPer) / 100;
            setbankDLR(resDLR);
            setbankIQD(resIQD);
            setforCompanyDLR(result.data.data.totalForCompanyDLR);
            setforCompanyIQD(result.data.data.totalForCompanyIQD);
          }
        });
    else
      Swal.fire({
        title: "warning",
        text: "Please select date",
        icon: "warning",
      });
  }

  function onBindReportHistory() {
    axios
      .get(
        path + "/monthlyreport",

        {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        }
      )
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setReportHistory(result.data.data);
        }
      });
  }

  function onSaveReportData() {
    Swal.fire({
      title: "Do you want save report ?",
      showCancelButton: true,
      confirmButtonText: "Save",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.value) {
        axios
          .post(
            path + "/monthlyreport",
            {
              totalSellIQD: reportData.totalSellIQD,
              totalSellDLR: reportData.totalSellIQD,
              totalExpensesIQD: reportData.totalExpensesIQD,
              totalExpensesDLR: reportData.totalExpensesDLR,
              totalSalaryIQD: reportData.totalUserShareIQD,
              totalSalaryDLR: reportData.totalUserShareDLR,
              forCompanyIQD: forCompanyIQD,
              forCompanyDLR: forCompanyDLR,
              inBankIQD: bankIQD,
              inBankDLR: bankDLR,
              keepInBankPer: keepInBankPer,
              fromDate: from,
              toDate: to,

              username: user.id,
            },
            {
              // headers: {
              //   "auth-token": this.props.user.user.token,
              // },
            }
          )
          .then((result) => {
            if (result.data.statusCode === 200) {
              Swal.fire("Report saved successfuly", "success");
              onBindReportHistory();
            }
            // if (result.data.token !== null)
            //   this.props.user.user.token = result.data.token;
          })
          .catch((error) => {
            Swal.fire({
              title: "warning",
              text: " Report can not save  please contact administrator",
              icon: "warning",
            });
          });
      }
    });
  }

  return (
    <>
      <Row>
        <Col lg="4">
          <FormGroup row>
            <Label
              for="exampleEmail"
              style={{
                fontSize: 16,
                fontWeight: "bold",
                marginTop: 10,
              }}
            >
              from
            </Label>
            <Col>
              <Input
                className="form-control-alternative"
                value={from}
                id="from"
                placeholder="from"
                type="date"
                onChange={(e) => setFrom(e.target.value)}
                style={{ fontSize: 16, color: "black", height: 45 }}
              />
            </Col>
          </FormGroup>
        </Col>
        <Col lg="4">
          <FormGroup row>
            <Label
              for="exampleEmail"
              style={{
                fontSize: 16,
                fontWeight: "bold",
                marginTop: 10,
              }}
            >
              to
            </Label>
            <Col>
              <Input
                className="form-control-alternative"
                value={to}
                id="to"
                placeholder="to"
                type="date"
                onChange={(e) => setTo(e.target.value)}
                style={{ fontSize: 16, color: "black", height: 45 }}
              />
            </Col>
          </FormGroup>
        </Col>
        {/* <Col lg="3">
          <FormGroup row>
            <Label
              for="exampleEmail"
              style={{
                fontSize: 16,
                fontWeight: "bold",
                marginTop: 10,
              }}
            >
              Keep in Bank(%):
            </Label>
            <Col>
              <Input
                className="form-control-alternative"
                value={keepInBankPer}
                id="keepInBankPer"
                placeholder="keepInBankPer"
                type="number"
                onChange={(e) => setKeepInBankPer(e.target.value)}
                style={{ fontSize: 16, color: "black", height: 45 }}
              />
            </Col>
          </FormGroup>
        </Col> */}

        <Col className="text-right">
          <Button color="primary" onClick={(e) => onBindtableData()} size="md">
            Get
          </Button>
          <Button color="success" onClick={(e) => onSaveReportData()} size="md">
            Save
          </Button>
          <ReactToPrint
            trigger={() => <Button color="warning">print</Button>}
            content={() => invoiceRef}
          />
        </Col>
      </Row>

      <br />
      <div ref={(el) => (invoiceRef = el)}>
        <br />
        <br />
        <h2 style={{ textAlign: "center" }}>
          ڕاپۆرتی گشتی مانگانە بۆ بەرواری{" "}
          {<Moment format="YYYY/MM/DD">{from}</Moment>} بۆ {"    "}
          {<Moment format="YYYY/MM/DD">{to}</Moment>}
        </h2>
        <br />
        <br />{" "}
        <div>
          <Table
            className=" align-items-center table-flush"
            bordered
            style={{ fontWeight: "bold", color: "black", direction: "rtl" }}
            responsive
          >
            <thead className="thead-light">
              <tr>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                ></th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  بڕی پارە ($)
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  بڕی پارە (IQD)
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  style={{
                    color: "black",
                    fontSize: 15,
                    textAlign: "center",
                  }}
                >
                  کۆی پارەی وەرگیراوی فرۆشتن
                </td>
                <td
                  style={{
                    color: "black",
                    fontSize: 15,
                    textAlign: "center",
                  }}
                >
                  <NumberFormat
                    value={
                      reportData.totalSellDLR
                        ? reportData.totalSellDLR.toFixed(0)
                        : 0
                    }
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$ "}
                  />
                </td>
                <td
                  style={{
                    color: "black",
                    fontSize: 15,
                    textAlign: "center",
                  }}
                >
                  <NumberFormat
                    value={
                      reportData.totalSellIQD
                        ? reportData.totalSellIQD.toFixed(0)
                        : 0
                    }
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"IQD "}
                  />
                </td>
              </tr>
              {/* <tr>
                <td
                  style={{
                    color: "black",
                    fontSize: 15,
                    textAlign: "center",
                  }}
                >
                  کۆی پارەی ڕۆشتوو بۆ ئۆردەرکردن
                </td>
                <td
                  style={{
                    color: "black",
                    fontSize: 15,
                    textAlign: "center",
                  }}
                >
                  <NumberFormat
                    value={
                      reportData.totalPurchaseDLR
                        ? reportData.totalPurchaseDLR.toFixed(0)
                        : 0
                    }
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$ "}
                  />
                </td>
                <td
                  style={{
                    color: "black",
                    fontSize: 15,
                    textAlign: "center",
                  }}
                >
                  <NumberFormat
                    value={
                      reportData.totalPurchaseIQD
                        ? reportData.totalPurchaseIQD.toFixed(0)
                        : 0
                    }
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"IQD "}
                  />
                </td>
              </tr> */}
              <tr>
                <td
                  style={{
                    color: "black",
                    fontSize: 15,
                    textAlign: "center",
                  }}
                >
                  کۆی گشتی مەصروفات
                </td>
                <td
                  style={{
                    color: "black",
                    fontSize: 15,
                    textAlign: "center",
                  }}
                >
                  <NumberFormat
                    value={
                      reportData.totalExpensesDLR
                        ? reportData.totalExpensesDLR.toFixed(0)
                        : 0
                    }
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$ "}
                  />
                </td>
                <td
                  style={{
                    color: "black",
                    fontSize: 15,
                    textAlign: "center",
                  }}
                >
                  <NumberFormat
                    value={
                      reportData.totalExpensesIQD
                        ? reportData.totalExpensesIQD.toFixed(0)
                        : 0
                    }
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"IQD "}
                  />
                </td>
              </tr>
              {/* <tr>
                <td
                  style={{
                    color: "black",
                    fontSize: 15,
                    textAlign: "center",
                  }}
                >
                  کۆی گشتی ڕۆشتوو بۆ موچە و نسبە
                </td>
                <td
                  style={{
                    color: "black",
                    fontSize: 15,
                    textAlign: "center",
                  }}
                >
                  <NumberFormat
                    value={
                      reportData.totalUserShareDLR
                        ? reportData.totalUserShareDLR.toFixed(0)
                        : 0
                    }
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$ "}
                  />
                </td>
                <td
                  style={{
                    color: "black",
                    fontSize: 15,
                    textAlign: "center",
                  }}
                >
                  <NumberFormat
                    value={
                      reportData.totalUserShareIQD
                        ? reportData.totalUserShareIQD.toFixed(0)
                        : 0
                    }
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"IQD "}
                  />
                </td>
              </tr> */}
              <tr>
                <td
                  style={{
                    color: "black",
                    fontSize: 15,
                    textAlign: "center",
                  }}
                >
                  كۆى گشتى پارەى نقدي بۆ شريكە{" "}
                </td>
                <td
                  style={{
                    color: "black",
                    fontSize: 15,
                    textAlign: "center",
                  }}
                >
                  <NumberFormat
                    value={forCompanyDLR ? forCompanyDLR.toFixed(0) : 0}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$ "}
                  />
                </td>
                <td
                  style={{
                    color: "black",
                    fontSize: 15,
                    textAlign: "center",
                  }}
                >
                  <NumberFormat
                    value={forCompanyIQD ? forCompanyIQD.toFixed(0) : 0}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"IQD "}
                  />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    color: "black",
                    fontSize: 15,
                    textAlign: "center",
                  }}
                >
                  باقياتى پارەى ماوەى ناو صندوق
                </td>
                <td
                  style={{
                    color: "black",
                    fontSize: 15,
                    textAlign: "center",
                  }}
                >
                  <NumberFormat
                    value={bankDLR ? bankDLR.toFixed(0) : 0}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$ "}
                  />
                </td>
                <td
                  style={{
                    color: "black",
                    fontSize: 15,
                    textAlign: "center",
                  }}
                >
                  <NumberFormat
                    value={bankIQD ? bankIQD.toFixed(0) : 0}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"IQD "}
                  />
                </td>
              </tr>
              {/* <tr style={{ background: resultDLR < 0 ? "red" : "green" }}>
                <td
                  style={{
                    color: "black",
                    fontSize: 15,
                    textAlign: "center",
                  }}
                >
                  کۆی گشتی
                </td>
                <td
                  style={{
                    color: "black",
                    fontSize: 15,
                    textAlign: "center",
                  }}
                >
                  <NumberFormat
                    value={resultDLR ? resultDLR.toFixed(0) : 0}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$ "}
                  />
                </td>
                <td
                  style={{
                    color: "black",
                    fontSize: 15,
                    textAlign: "center",
                  }}
                >
                  <NumberFormat
                    value={resultIQD ? resultIQD.toFixed(0) : 0}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"IQD "}
                  />
                </td>
              </tr> */}
            </tbody>
          </Table>
        </div>
      </div>
      <br />
      <br />
      <hr />
      <Row>
        <Col className="text-right">
          <ReactToPrint
            trigger={() => <Button color="warning">print</Button>}
            content={() => reportHistoryRef}
          />
        </Col>
      </Row>

      <br />
      <div ref={(el) => (reportHistoryRef = el)}>
        <h2 style={{ textAlign: "center" }}>ڕاپۆرتی گشتی مانگانە</h2>
        <br />
        <br />{" "}
        <div dir="rtl">
          <Table
            className=" align-items-center table-flush"
            bordered
            style={{ fontWeight: "bold", color: "black" }}
            responsive
          >
            <thead className="thead-light">
              <tr>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  #
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  پارەی وەرگیراوی فرۆشتن
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  مەصروفات
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  بڕی ڕۆشتوو بۆ موچە و نسبە
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  پارەى نقدي بۆ شريكە
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  پارەى ماوەى ناو صندوق
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  بەروار
                </th>
              </tr>
            </thead>
            <tbody>
              {reportHistory.map(function (item, index) {
                return (
                  <tr>
                    <th
                      scope="row"
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      {index + 1}
                    </th>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      <NumberFormat
                        value={item.totalSellIQD.toFixed(0)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"IQD "}
                      />
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      <NumberFormat
                        value={item.totalExpensesIQD.toFixed(0)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"IQD "}
                      />
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      <NumberFormat
                        value={item.totalSalaryIQD.toFixed(0)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"IQD "}
                      />
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      <NumberFormat
                        value={item.forCompanyIQD.toFixed(0)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"IQD "}
                      />
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      <NumberFormat
                        value={item.inBankIQD.toFixed(0)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"IQD "}
                      />
                    </td>{" "}
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      {<Moment format="YYYY/MM/DD">{item.from}</Moment>} بۆ{" "}
                      {"    "}
                      {<Moment format="YYYY/MM/DD">{item.to}</Moment>}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
}
