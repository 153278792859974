import React, { useState, useEffect, useRef } from "react";
import ReactToPrint from "react-to-print";
import axios from "axios";
import Swal from "sweetalert2";
import NumberFormat from "react-number-format";
import Moment from "react-moment";

import {
  Button,
  Table,
  Row,
  Col,
  Input,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap";
import Select from "react-select";
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "variables/charts.js";
import { Line, Bar } from "react-chartjs-2";

const path = require("../../../externals/path.json").path;

export default function SellPerDateAndCustomer() {
  let invoiceRef = useRef();
  let chartRef = useRef();
  const [reportData, setReportData] = useState([]);
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [customerList, setCustomerList] = useState([]);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [customerOptionIndex, setCustomerOptionIndex] = useState();
  const [customerId, setCustomerId] = useState();
  const [totalPaid, setTotalPaid] = useState(0);
  const [totalPaidQuantity, setTotalPaidQuantity] = useState(0);

  const [totalIQD, setTotalIQD] = useState(0);
  const [totalDLR, setTotalDLR] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [showChartAddModal, setshowChartAddModal] = useState(false);
  const [ChartOverView, setchartOverView] = useState(chartExample1);
  const [chartExample1Data, setChartExample1Data] = useState("data1");
  var colors = {
    gray: {
      100: "#f6f9fc",
      200: "#e9ecef",
      300: "#dee2e6",
      400: "#ced4da",
      500: "#adb5bd",
      600: "#8898aa",
      700: "#525f7f",
      800: "#32325d",
      900: "#212529",
    },
    theme: {
      default: "#172b4d",
      primary: "#5e72e4",
      secondary: "#f4f5f7",
      info: "#11cdef",
      success: "#2dce89",
      danger: "#f5365c",
      warning: "#fb6340",
    },
    black: "#12263F",
    white: "#FFFFFF",
    transparent: "transparent",
  };

  useEffect(() => {
    onBindCustomerData();
  }, []);
  function chartToggle() {
    setshowChartAddModal(!showChartAddModal);
    onBindChartData();
  }
  function onBindChartData() {
    axios
      .post(
        path + "/sellreports/getSellByDateAndCustomerChart",
        {
          customerId: customerId,
          from: from,
          to: to,
        },
        {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        }
      )
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          let month = [];
          let total = [];
          result.data.data.forEach((element) => {
            month.push(element.month);
            total.push(element.total);
          });

          setchartOverView({
            options: {
              scales: {
                yAxes: [
                  {
                    gridLines: {
                      color: colors.gray[900],
                      zeroLineColor: colors.gray[900],
                    },
                    ticks: {
                      callback: function (value) {
                        if (!(value % 10)) {
                          return "IQD " + value + "k";
                        }
                      },
                    },
                  },
                ],
              },
              tooltips: {
                callbacks: {
                  label: function (item, data) {
                    var label = data.datasets[item.datasetIndex].label || "";
                    var yLabel = item.yLabel;
                    var content = "";

                    if (data.datasets.length > 1) {
                      content += label;
                    }

                    content += "IQD" + yLabel + "k";
                    return content;
                  },
                },
              },
            },
            data1: (canvas) => {
              return {
                labels: month,
                datasets: [
                  {
                    label: "Performance",
                    data: total,
                  },
                ],
              };
            },
            data2: (canvas) => {
              return {
                labels: [
                  "May",
                  "Jun",
                  "Jul",
                  "Aug",
                  "Sep",
                  "Oct",
                  "Nov",
                  "Dec",
                ],
                datasets: [
                  {
                    label: "Performance",
                    data: [0, 20, 5, 25, 10, 30, 15, 40, 40],
                  },
                ],
              };
            },
          });
        }
      });
  }

  function onBindtableData() {
    axios
      .post(
        path + "/sellreports/getSellByDateAndCustomer",
        {
          customerId: customerId,
          from: from,
          to: to,
        },
        {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        }
      )
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setReportData(result.data.data);
          setTotalPaid(
            result.data.data.reduce((sum, invoice) => {
              if (invoice.paidStatus === "2") {
                return sum + invoice.paid;
              }
              return sum;
            }, 0)
          );
          setTotalPaidQuantity(
            result.data.data.reduce((sum, invoice) => {
              if (invoice.paidStatus === "2") {
                return sum + invoice.quantity;
              }
              return sum;
            }, 0)
          );
        }
      });
    axios
      .post(
        path + "/sellreports/getTotalSellByDateAndCustomer",
        {
          customerId: customerId,
          from: from,
          to: to,
        },
        {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        }
      )
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          // setTotalPaid(result.data.data.paid);
          setTotalIQD(result.data.data.priceIQD);
          setTotalDLR(result.data.data.priceDLR);
          setTotalQuantity(result.data.data.quantity);
        }
      });
  }

  function onBindCustomerData() {
    axios
      .post(path + "/customers/sell", {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setCustomerList(result.data.data);
          let options = [{ value: -1, label: "----Customer List ----" }];

          result.data.data.forEach((item, index) => {
            options.push({ value: index, label: item.name });
          });
          setCustomerOptions(options);
        }
      });
  }
  function onCustomerChangeHandler(e) {
    if (e.value !== -1) {
      setCustomerOptionIndex({
        value: e.value,
        label: customerList[e.value].name,
      });
      setCustomerId(customerList[e.value].id);
    } else {
      setCustomerOptionIndex();
    }
  }

  return (
    <>
      <Row>
        <Col>
          <Select
            value={customerOptionIndex}
            onChange={(e) => onCustomerChangeHandler(e)}
            options={customerOptions}
            style={{ fontSize: 16, color: "black", height: 45 }}
          />
        </Col>
        <Col>
          <Input
            className="form-control-alternative"
            value={from}
            id="from"
            placeholder="from"
            type="date"
            onChange={(e) => setFrom(e.target.value)}
            style={{ fontSize: 16, color: "black", height: 45 }}
          />
        </Col>
        <Col>
          <Input
            className="form-control-alternative"
            value={to}
            id="to"
            placeholder="to"
            type="date"
            onChange={(e) => setTo(e.target.value)}
            style={{ fontSize: 16, color: "black", height: 45 }}
          />
        </Col>
        <Col className="text-right">
          <Button color="primary" onClick={(e) => onBindtableData()} size="md">
            Get
          </Button>
          <ReactToPrint
            trigger={() => <Button color="success">print</Button>}
            content={() => invoiceRef}
          />{" "}
          <Button color="warning" onClick={chartToggle} size="md">
            Chart
          </Button>
        </Col>
      </Row>

      <br />
      <div ref={(el) => (invoiceRef = el)}>
        <br />
        <br />
        <Row>
          <Col>
            <FormGroup row>
              <Label
                for="exampleEmail"
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  marginTop: 10,
                }}
              >
                Total Quantity
              </Label>
              <Col
                style={{
                  marginTop: 10,
                }}
              >
                {totalQuantity}
              </Col>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup row>
              <Label
                for="exampleEmail"
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  marginTop: 10,
                }}
              >
                Total Paid Quantity
              </Label>
              <Col
                style={{
                  marginTop: 10,
                }}
              >
                {totalPaidQuantity ? totalPaidQuantity.toFixed(0) : 0}
              </Col>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup row>
              <Label
                for="exampleEmail"
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  marginTop: 10,
                }}
              >
                Total Price (IQD)
              </Label>
              <Col
                style={{
                  marginTop: 10,
                }}
              >
                <NumberFormat
                  value={totalIQD ? totalIQD.toFixed(0) : 0}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"IQD "}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup row>
              <Label
                for="exampleEmail"
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  marginTop: 10,
                }}
              >
                Total Price ($)
              </Label>
              <Col
                style={{
                  marginTop: 10,
                }}
              >
                <NumberFormat
                  value={totalDLR ? totalDLR.toFixed(0) : 0}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$ "}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup row>
              <Label
                for="exampleEmail"
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  marginTop: 10,
                }}
              >
                Total Paid (Account)
              </Label>
              <Col
                style={{
                  marginTop: 10,
                }}
              >
                <NumberFormat
                  value={totalPaid ? totalPaid.toFixed(0) : 0}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"IQD "}
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>

        <div>
          <Table
            className=" align-items-center table-flush"
            bordered
            style={{ fontWeight: "bold", color: "black" }}
            responsive
          >
            <thead className="thead-light">
              <tr>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  Manual Invoice Number
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  Quantity
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  {" "}
                  total ($)
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  total (IQD)
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  PAID
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  Date
                </th>
              </tr>
            </thead>
            <tbody>
              {reportData.map(function (item, index) {
                return (
                  <tr>
                    <th
                      scope="row"
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      {item.manualInvoiceNumber}
                    </th>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      {item.quantity}
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      <NumberFormat
                        value={item.priceDLR.toFixed(0)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$ "}
                      />
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      <NumberFormat
                        value={item.priceIQD.toFixed(0)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"IQD "}
                      />
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      <NumberFormat
                        value={item.paid.toFixed(0)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"IQD "}
                      />
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      <Moment format="YYYY/MM/DD">{item.date}</Moment>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
      <Modal
        isOpen={showChartAddModal}
        toggle={chartToggle}
        size="lg"
        style={{ maxWidth: "1500px", width: "100%" }}
      >
        <ModalBody>
          <div ref={(el) => (chartRef = el)}>
            <br />
            <br />
            <br />
            <br />
            <Card className="bg-gradient-default shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-light ls-1 mb-1">
                      Chart
                    </h6>
                    <h2 className="text-white mb-0">Sales value</h2>
                  </div>
                  <div className="col"></div>
                </Row>
              </CardHeader>
              <CardBody>
                {/* Chart */}
                <div className="chart">
                  <Line
                    data={ChartOverView[chartExample1Data]}
                    options={chartExample1.options}
                    getDatasetAtEvent={(e) => console.log(e)}
                  />
                </div>
              </CardBody>
            </Card>
          </div>
        </ModalBody>
        <ModalFooter>
          <ReactToPrint
            trigger={() => <Button color="primary">print</Button>}
            content={() => chartRef}
          />

          <Button color="secondary" onClick={chartToggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
