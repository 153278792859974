import { Container } from "reactstrap";
import CitiesCard from "./LinkCards/CitiesCard";

function CitiesNav() {
  if (window.location.pathname === "/admin/cities") {
    return <CitiesCard />;
  } else return "";
}

const UserHeader = () => {
  return (
    <>
      <div
        className="header pb-5 pt-5 pt-lg-5 d-flex align-items-center"
        style={{
          minHeight: "50px",
          // backgroundImage:
          //   "url(" +
          //   require("../../assets/img/theme/profile-cover.jpg").default +
          //   ")",
          // backgroundSize: "cover",
          // backgroundPosition: "center top",
        }}
      >
        <span className="mask bg-gradient-info opacity-8" />
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            {CitiesNav()}
          </div>
        </Container>
      </div>
    </>
  );
};

export default UserHeader;
