import React, { useState, useEffect } from "react";
import GeneralHeader from "components/Headers/GeneralHeader";
import Select from "react-select";
import axios from "axios";
import Swal from "sweetalert2";
import NumberFormat from "react-number-format";
import Moment from "react-moment";
import {
  Button,
  Card,
  CardHeader,
  CardFooter,
  CardBody,
  Form,
  Table,
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  Badge,
  Label,
} from "reactstrap";
import { useSelector } from "react-redux";
const path = require("../../externals/path.json").path;

export default function Index() {
  const rate = useSelector((state) => state.rateData.rate);
  const user = useSelector((state) => state.isLogged.user);
  const [expensesList, setExpensesList] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [expenseId, setExpenseId] = useState();
  const [expenseInvoiceNumber, setExpenseInvoiceNumber] = useState();
  const [costDlR, setCostDlR] = useState();
  const [costIQD, setCostIQD] = useState();
  const [paidBack, setPaidBack] = useState();
  const [username, setusername] = useState(user.id);
  const [listType, setListType] = useState("all");
  const [expenseFor, setExpenseFor] = useState();
  const [reason, setReason] = useState();
  const [showAddModal, setshowAddModal] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [rateId, setRateId] = useState();
  const [rateValue, setRateValue] = useState();
  const [userNameSearch, setUserNameSearch] = useState();
  const [expenseType, setExpenseType] = useState("");
  const [expensesTypeList, setExpensesTypeList] = useState([]);
  const [searchcityId, setsearchcityId] = useState("0");
  const [searchzoneId, setSearchZoneId] = useState("0");
  const [searchexpenseType, setsearchExpenseType] = useState("");
  const [SearchInvoiceNumber, setSearchInvoiceNumber] = useState();

  const [cityId, setcityId] = useState("0");
  const [zoneId, setZoneId] = useState("0");
  const [cityList, setCityList] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  function onBindExpensesData() {
    axios
      .post(
        path + "/expenses/list",
        {
          userId: user.id,
          leaderId: user.leaderId,
          role: user.role,
          type: listType,
          userNameSearch: userNameSearch,
          SearchInvoiceNumber: SearchInvoiceNumber,
          expenseType: searchexpenseType !== "0" ? searchexpenseType : null,
          cityId: searchcityId !== "0" ? searchcityId : null,
          zoneId: searchzoneId !== "0" ? searchzoneId : null,
          from: from,
          to: to,
        },
        {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        }
      )
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setExpensesList(result.data.data);
        }
      });
  }

  useEffect(() => {
    onBindExpensesData();
  }, [
    listType,
    userNameSearch,
    SearchInvoiceNumber,
    searchcityId,
    searchexpenseType,
    searchzoneId,
    from,
    to,
  ]);
  useEffect(() => {
    onBindUsersData();
    onBindExpensesTypeData();
    onBindcityData();
  }, []);

  function onBindcityData() {
    axios
      .get(path + "/cities", {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setCityList(result.data.data);
        }
      });
  }

  function onBindZoneDataHandler(cityId) {
    axios
      .get(path + "/zone/" + Number(cityId), {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.data !== null) setZoneList(result.data.data);
      });
  }

  function cityItemsRender() {
    let items = [];
    cityList.map((item) =>
      items.push(
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      )
    );
    return items;
  }
  function zoneItemsRender() {
    let items = [];
    zoneList.map((item) =>
      items.push(
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      )
    );
    return items;
  }
  function onChangeCityHandler(event) {
    setcityId(event.target.value);
    setZoneId("0");
    onBindZoneDataHandler(event.target.value);
  }

  function onChangeSearchCityHandler(event) {
    setsearchcityId(event.target.value);
    setSearchZoneId("0");
    onBindZoneDataHandler(event.target.value);
  }

  function onBindUsersData() {
    axios
      .get(path + "/users", {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setUsersList(result.data.data);
        }
      });
  }
  function onBindExpensesTypeData() {
    axios
      .get(path + "/expensestype", {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setExpensesTypeList(result.data.data);
        }
      });
  }
  function toggle() {
    setshowAddModal(!showAddModal);
  }

  function usersItemsRender() {
    let items = [];
    usersList.map((item) =>
      items.push(
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      )
    );
    return items;
  }

  function expensesTypeItemsRender() {
    let items = [];
    expensesTypeList.map((item) =>
      items.push(
        <option key={item.id} value={item.id}>
          {item.typeName}
        </option>
      )
    );
    return items;
  }

  function onAddNewDataHandler() {
    setRateValue(rate.rate);
    setRateId(rate.id);
    setExpenseInvoiceNumber();
    setCostDlR();
    setCostIQD();
    setusername(user.id);
    setReason();
    setExpenseType();
    setcityId();
    setZoneId();
    setExpenseFor();
    setPaidBack();
    setIsUpdate(false);
    onBindUsersData();
    toggle();
  }

  function onCHangeCostIQDHandler(value, rate) {
    setCostIQD(value);
    setCostDlR(value / rate);
  }
  function onCHangeCostDLRHandler(value, rate) {
    setCostIQD(value * rate);
    setCostDlR(value);
  }

  function onSaveDataHandler() {
    const expense = {
      invoiceNumber: expenseInvoiceNumber,
      amount: costDlR ? costDlR : 0,
      amountIQD: costIQD ? costIQD : 0,
      expenseType: expenseType,
      cityId: cityId,
      zoneId: zoneId,
      reason: reason,
      expenseFor: expenseFor,
      paidBack: paidBack,
      rateId: rateId,
      username: username,
    };
    if (isUpdate === true) {
      axios
        .put(path + "/expenses/" + expenseId, expense, {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        })
        .then((result) => {
          if (result.data.success === true) {
            onBindExpensesData();
            setshowAddModal(false);
            setIsUpdate(false);
            Swal.fire({
              title: "THANKS",
              text: "the expense updated",
              icon: "success",
            });
          }
          // if (result.data.token !== null)
          //   this.props.user.user.token = result.data.token;
        })
        .catch((error) => {
          Swal.fire({
            title: "warning",
            text: "expense did not updated please contact administrator",
            icon: "warning",
          });
        });
    } else {
      axios
        .post(path + "/expenses", expense, {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        })
        .then((result) => {
          if (result.data.success === true) {
            onBindExpensesData();
            setshowAddModal(false);
            Swal.fire({
              title: "THANKS",
              text: "new expense Added",
              icon: "success",
            });
          }
          // if (result.data.token !== null)
          //   this.props.user.user.token = result.data.token;
        })
        .catch((error) => {
          Swal.fire({
            title: "warning",
            text: "expense did not added please contact administrator",
            icon: "warning",
          });
        });
    }
  }

  function EditDataHandler(expenseId, Index) {
    // let item = itemList.find((o) => o.id === item.itemId);
    axios
      .get(path + "/expenses/" + expenseId, {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          axios
            .get(path + "/rates/" + result.data.data.rateId, {
              // headers: {
              //   "auth-token": this.props.user.user.token,
              // },
            })
            .then((res) => {
              onBindUsersData();
              setIsUpdate(true);
              setshowAddModal(true);
              setRateValue(res.data.data.rate);
              setRateId(res.data.data.id);
              onCHangeCostDLRHandler(
                result.data.data.amount,
                res.data.data.rate
              );
              onCHangeCostIQDHandler(
                result.data.data.amountIQD,
                res.data.data.rate
              );
              setPaidBack(result.data.data.paidBack);
              setExpenseInvoiceNumber(result.data.data.invoiceNumber);
              setExpenseType(result.data.data.expenseType);
              setcityId(result.data.data.cityId);
              setZoneId(result.data.data.zoneId);
              setExpenseId(expenseId);
              setReason(result.data.data.reason);
              setExpenseFor(result.data.data.expenseFor);
              setusername(result.data.data.username);
            })
            .catch((error) => {
              Swal.fire({
                title: "warning",
                text: `there is some error while get rate with rate id ${result.data.data.rateId} data please contact administrator`,
                icon: "warning",
              });
            });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "warning",
          text: `there is some error while get offer with  number ${Index} data please contact administrator`,
          icon: "warning",
        });
      });
  }

  function onDeleteHandler(id, index) {
    Swal.fire({
      title: 'Do you want to delete expense  "' + index + '" ?',
      showCancelButton: true,
      confirmButtonText: "Save",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.value) {
        axios
          .delete(path + "/expenses/" + id, {
            // headers: {
            //   "auth-token": this.props.user.user.token,
            // },
          })
          .then((result) => {
            if (result.data.statusCode === 200) {
              onBindExpensesData();
              Swal.fire("expense Deleted!", "", "success");
            }
            // if (result.data.token !== null)
            //   this.props.user.user.token = result.data.token;
          })
          .catch((error) => {
            Swal.fire({
              title: "warning",
              text: "the expense did not deleted please contact administrator",
              icon: "warning",
            });
          });
      }
    });
  }
  return (
    <>
      <GeneralHeader />

      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col>
                    <Input
                      className="form-control-alternative"
                      value={userNameSearch}
                      id="userName"
                      placeholder="userName"
                      type="select"
                      onChange={(e) => {
                        if (e.target.value !== "0")
                          setUserNameSearch(e.target.value);
                        else setUserNameSearch();
                      }}
                      style={{ fontSize: 16, color: "black", height: 45 }}
                    >
                      <option value={0}>---User---</option>
                      {usersItemsRender()}
                    </Input>
                  </Col>
                  <Col>
                    <FormGroup row className="mb-0">
                      <Col style={{}}>
                        <Input
                          className="form-control-alternative"
                          value={listType}
                          id="offerFor"
                          placeholder="offerFor"
                          type="select"
                          onChange={(e) => {
                            setListType(e.target.value);
                          }}
                        >
                          <option value={"all"}>All</option>
                          <option value={"0"}>Not paid back</option>
                          <option value={"1"}>Paid Back</option>
                        </Input>
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col>
                    <Input
                      className="form-control-alternative"
                      value={from}
                      id="from"
                      placeholder="from"
                      type="date"
                      onChange={(e) => setFrom(e.target.value)}
                      style={{ fontSize: 16, color: "black", height: 45 }}
                    />
                  </Col>
                  <Col>
                    <Input
                      className="form-control-alternative"
                      value={to}
                      id="to"
                      placeholder="to"
                      type="date"
                      onChange={(e) => setTo(e.target.value)}
                      style={{ fontSize: 16, color: "black", height: 45 }}
                    />
                  </Col>
                  <Col className="text-right" xs="٢">
                    <Button
                      color="primary"
                      onClick={(e) => onAddNewDataHandler()}
                      size="md"
                    >
                      New Expense
                    </Button>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col>
                    <Input
                      className="form-control-alternative"
                      value={SearchInvoiceNumber}
                      id="SearchInvoiceNumber"
                      placeholder="Invoice Number"
                      type="text"
                      onChange={(e) => setSearchInvoiceNumber(e.target.value)}
                    ></Input>
                  </Col>
                  <Col>
                    <Input
                      className="form-control-alternative"
                      value={searchexpenseType}
                      id="expenseType"
                      placeholder="expenseType"
                      type="select"
                      onChange={(e) => setsearchExpenseType(e.target.value)}
                    >
                      <option key={0} value={0}>
                        ---Expense Type---
                      </option>
                      {expensesTypeItemsRender()}
                    </Input>
                  </Col>
                  <Col>
                    <Input
                      className="form-control-alternative"
                      value={searchcityId}
                      id="searchcityId"
                      placeholder="cityId"
                      type="select"
                      onChange={(e) => onChangeSearchCityHandler(e)}
                      style={{ fontSize: 16, color: "black", height: 45 }}
                    >
                      <option key={0} value={0}>
                        ---city---
                      </option>
                      {cityItemsRender()}
                    </Input>
                  </Col>
                  <Col>
                    <Input
                      className="form-control-alternative"
                      value={searchzoneId}
                      id="searchzoneId"
                      placeholder="searchzoneId"
                      type="select"
                      onChange={(e) => setSearchZoneId(e.target.value)}
                      style={{ fontSize: 16, color: "black", height: 45 }}
                    >
                      <option key={0} value={0}>
                        ---zone---
                      </option>
                      {zoneItemsRender()}
                    </Input>
                  </Col>
                </Row>
              </CardHeader>
              <div style={{ height: "76vh", overflow: "scroll" }}>
                <Table
                  className=" align-items-center table-flush"
                  style={{
                    fontWeight: "bold",
                    color: "black",
                    textAlign: "center",
                  }}
                >
                  <thead className="thead-light">
                    <tr>
                      <th
                        scope="col"
                        style={{
                          fontSize: 15,
                        }}
                      >
                        #
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontSize: 15,
                        }}
                      >
                        invoice Number
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontSize: 15,
                        }}
                      >
                        Amount IQD
                      </th>
                      {/* <th
                        scope="col"
                        style={{
                          fontSize: 15,
                        }}
                      >
                        Amount $
                      </th> */}
                      <th
                        scope="col"
                        style={{
                          fontSize: 15,
                        }}
                      >
                        Type
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontSize: 15,
                        }}
                      >
                        Reason
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontSize: 15,
                        }}
                      >
                        Expense For
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontSize: 15,
                        }}
                      >
                        Paid Back ?
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontSize: 15,
                        }}
                      >
                        Date
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontSize: 15,
                        }}
                      >
                        user
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontSize: 15,
                        }}
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {expensesList.map(function (item, index) {
                      return (
                        <tr>
                          <th
                            scope="row"
                            style={{
                              fontSize: 15,
                            }}
                          >
                            {index + 1}
                          </th>
                          <th
                            scope="row"
                            style={{
                              fontSize: 15,
                            }}
                          >
                            {item.invoiceNumber}
                          </th>
                          <td
                            style={{
                              fontSize: 15,
                            }}
                          >
                            {" "}
                            <NumberFormat
                              value={item.amountIQD.toFixed(0)}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"IQD "}
                            />
                          </td>
                          {/* <td
                            style={{
                              fontSize: 15,
                            }}
                          >
                            <NumberFormat
                              value={item.amount.toFixed(0)}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$ "}
                            />
                          </td> */}
                          <td
                            style={{
                              fontSize: 15,
                            }}
                          >
                            {item.expenseType}
                          </td>
                          <td
                            style={{
                              fontSize: 15,
                            }}
                          >
                            {item.reason}
                          </td>
                          <td
                            style={{
                              fontSize: 15,
                            }}
                          >
                            {item.expenseForName}
                          </td>
                          <td
                            style={{
                              fontSize: 15,
                            }}
                          >
                            {item.paidBack === "1" ? (
                              <Badge color="success">YES</Badge>
                            ) : (
                              <Badge color="danger">No</Badge>
                            )}
                          </td>
                          <td
                            style={{
                              fontSize: 15,
                            }}
                          >
                            {" "}
                            <Moment format="DD-MM-YYYY">{item.date}</Moment>
                          </td>
                          <td
                            style={{
                              fontSize: 15,
                            }}
                          >
                            {item.username}
                          </td>
                          <td className="text-right">
                            <div>
                              {user.role === "admin" ||
                              user.role === "accountant" ? (
                                <>
                                  <Button
                                    size="sm"
                                    color="warning"
                                    onClick={() =>
                                      EditDataHandler(item.id, index + 1)
                                    }
                                  >
                                    Edit
                                  </Button>{" "}
                                  <Button
                                    size="sm"
                                    color="danger"
                                    onClick={() =>
                                      onDeleteHandler(item.id, index + 1)
                                    }
                                  >
                                    Delete
                                  </Button>{" "}
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem className="disabled">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem className="active">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        1
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        isOpen={showAddModal}
        toggle={toggle}
        className="modal-lg"
        style={{ maxWidth: "1500px", width: "100%" }}
      >
        <ModalHeader toggle={toggle}>New Expense</ModalHeader>
        <ModalBody>
          <Col className="order-xl-1">
            <Card className="shadow" style={{ backgroundColor: "#F0F8FF" }}>
              <CardBody>
                <Form autoComplete="off" id="ff">
                  <div className="pl-lg-2">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            value={expenseInvoiceNumber}
                            id="expenseInvoiceNumber"
                            placeholder="Expense Invoice Number"
                            type="number"
                            onChange={(e) =>
                              setExpenseInvoiceNumber(e.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            value={costIQD}
                            id="costIQD"
                            placeholder="cost (IQD)"
                            type="number"
                            onChange={(e) =>
                              onCHangeCostIQDHandler(e.target.value, rateValue)
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            value={costDlR}
                            id="costDlR"
                            placeholder="cost ($)"
                            type="number"
                            onChange={(e) =>
                              onCHangeCostDLRHandler(e.target.value, rateValue)
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            value={expenseFor}
                            id="deliveredBy"
                            placeholder="deliveredBy"
                            type="select"
                            onChange={(e) => setExpenseFor(e.target.value)}
                          >
                            <option>---Expense For---</option>
                            {usersItemsRender()}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            value={paidBack}
                            id="offerFor"
                            placeholder="offerFor"
                            type="select"
                            onChange={(e) => setPaidBack(e.target.value)}
                          >
                            <option>---Paid Back ?---</option>
                            <option key={"0"} value={"0"}>
                              No
                            </option>
                            <option key={"1"} value={"1"}>
                              Yes
                            </option>
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            value={cityId}
                            id="cityId"
                            placeholder="cityId"
                            type="select"
                            onChange={(e) => onChangeCityHandler(e)}
                          >
                            <option key={0} value={0}>
                              ---city---
                            </option>
                            {cityItemsRender()}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            value={zoneId}
                            id="zoneId"
                            placeholder="zoneId"
                            type="select"
                            onChange={(e) => setZoneId(e.target.value)}
                          >
                            <option key={0} value={0}>
                              ---zone---
                            </option>
                            {zoneItemsRender()}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            value={expenseType}
                            id="expenseType"
                            placeholder="expenseType"
                            type="select"
                            onChange={(e) => setExpenseType(e.target.value)}
                          >
                            <option>---Expense Type---</option>
                            {expensesTypeItemsRender()}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            value={reason}
                            id="reason"
                            placeholder="reason"
                            type="textarea"
                            onChange={(e) => setReason(e.target.value)}
                          >
                            {" "}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => onSaveDataHandler()}>
            Save
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
