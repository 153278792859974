import GeneralHeader from "components/Headers/GeneralHeader";
import {
  Button,
  Card,
  CardHeader,
  CardFooter,
  CardBody,
  Form,
  Table,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
} from "reactstrap";
import React, { useState, useEffect } from "react";
import SellPerDateAndUsers from "./SellPerDateAndUsers";
import SellPerDateAndCustomer from "./SellPerDateAndCustomer";
import SellPerDateAndItem from "./SellPerDateAndItem";
import SellPerDateAndZone from "./SellPerDateAndZone";
import ExpensesPerDateAndUsers from "./ExpensesPerDateAndUsers";
import PurchaseandStockRemains from "./PurchaseandStockRemains";
import SellUserShare from "./SellUserShare";
import SellDebtReport from "./SellDebt";
import MonthlyReport from "./MonthlyReport";
import SellUserCompareByDate from "./SellUserCompareByDate";
import SellZoneCompareByDate from "./SellZoneCompareByDate";
import SellCityCompareByDate from "./SellCityCompareByDate";
import SellNeighbourCompareByDate from "./SellNeighbourCompareByDate";
import SellCustomerCompareByDate from "./SellCustomerCompareByDate";
import EmployeesAccounting from "./EmployeesAccounting";
import DoctorVisitations from "./DoctorVisitations";
import SellPaidByMandwb from "./SellPaidByMandwb";
import MonthlyProfitOrLoss from "./MonthlyProfitOrLoss";
import PayParnerProfit from "./PayParnerProfit";
import SellPaidByAccountant from "./SellPaidByAccountant";
import ExpensesCompareByType from "./ExpensesCompareByType";
import ExpensesCompareByCities from "./ExpensesCompareByCities";
import ExpensesCompareByZones from "./ExpensesCompareByZones";
import ExpensesCompareByUsers from "./ExpensesCompareByUsers";
import ItemSummary from "./ItemSummary";

export default function Index() {
  const [reportType, setReportType] = useState();

  function renderPage() {
    if (reportType === "sellByDateAndUser") return <SellPerDateAndUsers />;
    else if (reportType === "sellByDateAndCustomer")
      return <SellPerDateAndCustomer />;
    else if (reportType === "sellByDateAndItem") return <SellPerDateAndItem />;
    else if (reportType === "sellByDateAndZone") return <SellPerDateAndZone />;
    else if (reportType === "expensesByDateAndUser")
      return <ExpensesPerDateAndUsers />;
    else if (reportType === "expensesCompareByType")
      return <ExpensesCompareByType />;
    else if (reportType === "expensesCompareByCities")
      return <ExpensesCompareByCities />;
    else if (reportType === "expensesCompareByZones")
      return <ExpensesCompareByZones />;
    else if (reportType === "expensesCompareByUsers")
      return <ExpensesCompareByUsers />;
    else if (reportType === "PurchaseandStockRemains")
      return <PurchaseandStockRemains />;
    else if (reportType === "SellUserShare") return <SellUserShare />;
    else if (reportType === "SellDebt") return <SellDebtReport />;
    else if (reportType === "MonthlyReport") return <MonthlyReport />;
    else if (reportType === "UserCompareByDate")
      return <SellUserCompareByDate />;
    else if (reportType === "CustomerCompareByDate")
      return <SellCustomerCompareByDate />;
    else if (reportType === "CityCompareByDate")
      return <SellCityCompareByDate />;
    else if (reportType === "ZoneCompareByDate")
      return <SellZoneCompareByDate />;
    else if (reportType === "NeighbourCompareByDate")
      return <SellNeighbourCompareByDate />;
    else if (reportType === "EmployeesAccounting")
      return <EmployeesAccounting />;
    else if (reportType === "DoctorVisitations") return <DoctorVisitations />;
    else if (reportType === "SellPaidByMandwb") return <SellPaidByMandwb />;
    else if (reportType === "SellPaidByAccountant")
      return <SellPaidByAccountant />;
    else if (reportType === "MonthlyProfitOrLoss")
      return <MonthlyProfitOrLoss />;
    else if (reportType === "PayParnerProfit") return <PayParnerProfit />;
    else if (reportType === "ItemSummary") return <ItemSummary />;
  }
  return (
    <>
      <GeneralHeader />
      <Container className="mt--7" fluid style={{ color: "black" }}>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col xs="4">
                    <span style={{ fontSize: 16 }}>Reports</span>
                  </Col>
                  <Col xs="8">
                    <Input
                      className="form-control-alternative"
                      value={reportType}
                      id="reportType"
                      placeholder="reportType"
                      type="select"
                      onChange={(e) => setReportType(e.target.value)}
                      style={{ fontSize: 16, color: "black", height: 45 }}
                    >
                      <option value="">---Report Type---</option>
                      <option value="sellByDateAndUser">
                        Sell By Date And User
                      </option>
                      <option value="sellByDateAndCustomer">
                        Sell By Date And Customer
                      </option>
                      <option value="sellByDateAndItem">
                        Sell By Date And Item
                      </option>
                      <option value="sellByDateAndZone">
                        Sell By Date And (City or Zone)
                      </option>
                      <option value="expensesByDateAndUser">
                        Expenses By Date And User
                      </option>
                      <option value="expensesCompareByType">
                        Expenses Compare By Type
                      </option>
                      <option value="expensesCompareByCities">
                        Expenses Compare By Cities
                      </option>
                      <option value="expensesCompareByZones">
                        Expenses Compare By Zones
                      </option>
                      <option value="expensesCompareByUsers">
                        Expenses Compare By Users
                      </option>
                      <option value="PurchaseandStockRemains">
                        Purchase and Stock Remains
                      </option>
                      <option value="SellUserShare">User Share</option>
                      <option value="SellDebt">Sell Debt</option>
                      <option value="MonthlyReport">Monthly Report</option>
                      <option value="UserCompareByDate">
                        User Compare By Date
                      </option>
                      <option value="CityCompareByDate">
                        City Compare By Date
                      </option>
                      <option value="ZoneCompareByDate">
                        Zone Compare By Date
                      </option>{" "}
                      <option value="NeighbourCompareByDate">
                        Neighbour Compare By Date
                      </option>
                      <option value="CustomerCompareByDate">
                        Customer Compare By Date
                      </option>
                      <option value="EmployeesAccounting">
                        Employees Accounting
                      </option>
                      <option value="DoctorVisitations">
                        Doctor Visitations
                      </option>
                      <option value="SellPaidByMandwb">
                        Sell Paid By Mandwb
                      </option>{" "}
                      <option value="SellPaidByAccountant">
                        Sell Paid By Accountant
                      </option>
                      <option value="MonthlyProfitOrLoss">
                        Monthly Profit Or Loss
                      </option>
                      <option value="PayParnerProfit">
                        Pay Partner Profit
                      </option>
                      <option value="ItemSummary">Item Summary</option>
                    </Input>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>{renderPage()}</CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
