import React, { useState, useEffect, useRef } from "react";
import ReactToPrint from "react-to-print";
import axios from "axios";
import Swal from "sweetalert2";
import NumberFormat from "react-number-format";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import Select from "react-select";

import {
  Button,
  Table,
  Row,
  Col,
  Input,
  FormGroup,
  Label,
  Badge,
} from "reactstrap";

const path = require("../../../externals/path.json").path;

export default function SellUserShare() {
  const rate = useSelector((state) => state.rateData.rate);
  const user = useSelector((state) => state.isLogged.user);
  let invoiceRef = useRef();

  const [reportData, setReportData] = useState([]);
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [userName, setUserName] = useState();
  const [userRole, setUserRole] = useState();
  const [fullName, setFullName] = useState();
  const [cityList, setCityList] = useState([]);
  const [zoneList, setZoneList] = useState([]);

  const [userPersentage, setUserPersentage] = useState();
  const [userRepaymentType, setUserRepaymentType] = useState();

  const [totalBonus, setTotalBunus] = useState();
  const [totalPersentage, setTotalPersentage] = useState();
  const [usersList, setUsersList] = useState([]);
  const [totalPaid, setTotalPaid] = useState(0);
  const [totalIQD, setTotalIQD] = useState(0);
  const [totalDLR, setTotalDLR] = useState(0);
  const [ReadytoPay, setReadytoPay] = useState(true);

  const [totalQuantity, setTotalQuantity] = useState(0);

  const [cityOptions, setCityOptions] = useState([]);
  const [cityOptionIndex, setCityOptionIndex] = useState();
  const [cityQuery, setCityQuery] = useState("");
  const [zoneQuery, setZoneQuery] = useState("");

  const [zoneOptions, setZoneOptions] = useState([]);
  const [zoneOptionIndex, setZoneOptionIndex] = useState();

  useEffect(() => {
    onBindUsersData();
    onBindCityList();
  }, []);

  function OnCityChangeHandler(data) {
    setCityOptionIndex(data);
    let query = "";

    if (data.length > 0) {
      onBindZoneDataHandler(data);
      for (let i = 0; i < data.length; i++) {
        if (i !== 0 && i < data.length) {
          query = query + ",";
        }
        query = query + cityList[data[i].value].id;
      }
      setCityQuery(query);
    } else {
      setZoneList([]);
      setZoneOptions([]);
      setZoneOptionIndex();
      OnZoneChangeHandler(null);
      setCityQuery("");
    }
  }
  function OnZoneChangeHandler(data) {
    setZoneOptionIndex(data);
    let query = "";
    if (data && data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        if (i !== 0 && i < data.length) {
          query = query + ",";
        }
        query = query + zoneList[data[i].value].id;
      }
      setZoneQuery(query);
    } else setZoneQuery("");
  }

  const onBindCityList = () => {
    axios
      .get(path + "/cities", {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setCityList(result.data.data);
          let options = [];

          result.data.data.forEach((item, index) => {
            options.push({ value: index, label: item.name });
          });

          setCityOptions(options);
        }
      });
  };
  function onBindZoneDataHandler(data) {
    let list = [];
    data.forEach((item) => {
      axios
        .get(path + "/zone/" + Number(cityList[item.value].id), {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        })
        .then((result) => {
          // if (result.data.token !== null)
          //   this.props.user.user.token = result.data.token;
          if (result.data.success === true) {
            list.push(...result.data.data);
            setZoneList(list);
            let options = [];

            list.forEach((item, index) => {
              options.push({
                value: index,
                label: item.name + ` (${item.cityName})`,
              });
            });

            setZoneOptions(options);
          }
        });
    });
  }

  function onBindtableData() {
    let index = usersList.findIndex(
      (element) => element.id === Number(userName)
    );
    axios
      .post(
        path + "/sellreports/getSellUserShare",
        {
          userName: userName !== "0" ? userName : null,
          from: from,
          to: to,
          role: index !== -1 ? usersList[index].role : null,
          repaymentType: userRepaymentType,
          persentage: userPersentage,
          cityQuery: userRepaymentType === "notseller" ? cityQuery : null,
          zoneQuery: userRepaymentType === "notseller" ? zoneQuery : null,
          // paidType: paidType !== "0" ? paidType : null,
        },
        {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        }
      )
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setReportData(result.data.data);
          let Tquantity = 0;
          let TpriceIQD = 0;
          let TpriceDLR = 0;
          let Tpaid = 0;
          let TPersentage = 0;
          let TBonus = 0;

          result.data.data.forEach((item) => {
            Tquantity = Tquantity + item.quantity;
            TpriceDLR = TpriceDLR + item.priceDLR;
            TpriceIQD = TpriceIQD + item.priceIQD;
            Tpaid = Tpaid + item.paid;
            TPersentage = TPersentage + item.persentagePrice;
            TBonus = TBonus + item.bonusPrice;
          });
          setReportData(result.data.data);
          setTotalQuantity(Tquantity);
          setTotalIQD(TpriceIQD);
          setTotalDLR(TpriceDLR);
          setTotalPaid(Tpaid);
          setTotalBunus(TBonus);
          setTotalPersentage(TPersentage);
          setReadytoPay(false);
        }
      });
    // axios
    //   .post(
    //     path + "/sellreports/getSellTotaUserShare",
    //     {
    //       userName: userName !== "0" ? userName : null,
    //       from: from,
    //       to: to,
    //       role: index !== -1 ? usersList[index].role : null,
    //       repaymentType: userRepaymentType,
    //       persentage: userPersentage,
    //       cityQuery: userRepaymentType === "notseller" ? cityQuery : null,
    //       zoneQuery: userRepaymentType === "notseller" ? zoneQuery : null,
    //       // paidType: paidType !== "0" ? paidType : null,
    //     },
    //     {
    //       // headers: {
    //       //   "auth-token": this.props.user.user.token,
    //       // },
    //     }
    //   )
    //   .then((result) => {
    //     // if (result.data.token !== null)
    //     //   this.props.user.user.token = result.data.token;
    //     if (result.data.success === true) {
    //       setTotalPaid(result.data.data.paid);
    //       setTotalIQD(result.data.data.priceIQD);
    //       setTotalDLR(result.data.data.priceDLR);
    //       setTotalQuantity(result.data.data.quantity);
    //       setTotalBunus(result.data.data.bonusPrice);
    //       setTotalPersentage(result.data.data.persentagePrice);
    //     }
    //   });
  }

  function onBindUsersData() {
    axios
      .get(path + "/users", {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setUsersList(result.data.data);
        }
      });
  }
  function onChangeUserHandler(e) {
    let index = usersList.findIndex(
      (element) => element.id === Number(e.target.value)
    );
    setUserName(e.target.value);
    setUserPersentage(usersList[index].persentage);
    setUserRole(usersList[index].role);
    setUserRepaymentType(usersList[index].repaymentType);
    setReadytoPay(true);
    setReportData([]);
    setTotalDLR();
    setTotalIQD();
    setTotalQuantity();
    setTotalPaid();
    setFullName(usersList[index].name);
    setCityQuery();
    setZoneQuery();
    setCityOptionIndex();
    setZoneList([]);
    setZoneOptions([]);
    setZoneOptionIndex();
    OnZoneChangeHandler(null);
  }

  function usersItemsRender() {
    let items = [];
    usersList.map((item) =>
      items.push(
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      )
    );
    return items;
  }

  function onPaidToUserHandler(id) {
    let index = usersList.findIndex(
      (element) => element.id === Number(userName)
    );
    if (index) {
      Swal.fire({
        title:
          'Do you want to Pay persentage to user User  "' +
          usersList[index].name +
          '" ?',
        showCancelButton: true,
        confirmButtonText: "Save",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.value) {
          axios
            .post(
              path + "/userhare",
              {
                userId: userName,
                fullName: fullName,
                numberofInvoice: reportData.length,
                amountIQD: totalPersentage,
                repaymentType: userRepaymentType,
                bonusAmount: totalBonus,
                rateId: rate.id,
                from: from,
                to: to,
                username: user.id,
              },
              {
                // headers: {
                //   "auth-token": this.props.user.user.token,
                // },
              }
            )
            .then((result) => {
              if (result.data.statusCode === 200) {
                if (
                  userRepaymentType !== "notseller" ||
                  usersList[index].role !== "leader"
                ) {
                  axios
                    .put(
                      path + "/usercreditpaid",
                      {
                        userName: userName !== "0" ? userName : null,
                        from: from,
                        to: to,
                        repaymentType: userRepaymentType,
                        role: userRole,
                        // paidType: paidType !== "0" ? paidType : null,
                      },
                      {
                        // headers: {
                        //   "auth-token": this.props.user.user.token,
                        // },
                      }
                    )
                    .then((result) => {
                      if (result.data.statusCode === 200) {
                        onBindtableData();
                        setReadytoPay(false);
                        setReportData([]);
                        setTotalDLR();
                        setTotalIQD();
                        setTotalQuantity();
                        setTotalPaid();
                        Swal.fire(
                          "Paid Successful to user !" + usersList[index].name,
                          "",
                          "success"
                        );
                      }
                      // if (result.data.token !== null)
                      //   this.props.user.user.token = result.data.token;
                    })
                    .catch((error) => {
                      Swal.fire({
                        title: "warning",
                        text: " paying did not suscess please contact administrator",
                        icon: "warning",
                      });
                    });
                } else {
                  onBindtableData();
                  setReadytoPay(false);
                  setReportData([]);
                  setTotalDLR();
                  setTotalIQD();
                  setTotalQuantity();
                  setTotalPaid();
                  Swal.fire(
                    "Paid Successful to user !" + usersList[index].name,
                    "",
                    "success"
                  );
                }
              }
              // if (result.data.token !== null)
              //   this.props.user.user.token = result.data.token;
            })
            .catch((error) => {
              Swal.fire({
                title: "warning",
                text: " paying did not suscess please contact administrator",
                icon: "warning",
              });
            });
        }
      });
    } else
      Swal.fire({
        title: "warning",
        text: " Please select user",
        icon: "warning",
      });
  }
  return (
    <>
      <Row>
        <Col>
          <Input
            className="form-control-alternative"
            value={userName}
            id="userName"
            placeholder="userName"
            type="select"
            onChange={(e) => onChangeUserHandler(e)}
            style={{ fontSize: 16, color: "black", height: 45 }}
          >
            <option value={0}>---User---</option>
            {usersItemsRender()}
          </Input>
        </Col>
        <Col>
          <Input
            className="form-control-alternative"
            value={from}
            id="from"
            placeholder="from"
            type="date"
            onChange={(e) => {
              setFrom(e.target.value);
              setReadytoPay(true);
              setReportData([]);
              setTotalDLR();
              setTotalIQD();
              setTotalQuantity();
              setTotalPaid();
            }}
            style={{ fontSize: 16, color: "black", height: 45 }}
          />
        </Col>
        <Col>
          <Input
            className="form-control-alternative"
            value={to}
            id="to"
            placeholder="to"
            type="date"
            onChange={(e) => {
              setTo(e.target.value);
              setReadytoPay(true);
              setReportData([]);
              setTotalDLR();
              setTotalIQD();
              setTotalQuantity();
              setTotalPaid();
            }}
            style={{ fontSize: 16, color: "black", height: 45 }}
          />
        </Col>
        {/* <Col>
          <Input
            className="form-control-alternative"
            value={paidType}
            id="paidType"
            placeholder="paidType"
            type="select"
            onChange={(e) => setPaidType(e.target.value)}
            style={{ fontSize: 16, color: "black", height: 45 }}
          >
            <option value={0}>---all---</option>
            <option value={"paid"}>Paid</option>
            <option value={"unpaid"}>UnPaid</option>
          </Input>
        </Col> */}
        <Col className="text-right">
          <Button color="primary" onClick={(e) => onBindtableData()} size="md">
            Get
          </Button>
          <ReactToPrint
            trigger={() => <Button color="success">print</Button>}
            content={() => invoiceRef}
          />
          <Button
            color="warning"
            onClick={(e) => onPaidToUserHandler()}
            size="md"
            disabled={ReadytoPay}
          >
            Pay to user
          </Button>
        </Col>
      </Row>
      <br />
      {userRepaymentType === "notseller" ? (
        <Row>
          <Col>
            <Select
              options={cityOptions}
              placeholder="Select City"
              value={cityOptionIndex}
              onChange={OnCityChangeHandler}
              isSearchable={true}
              isMulti
            />
          </Col>
          <Col>
            <Select
              options={zoneOptions}
              placeholder="Select Zone"
              value={zoneOptionIndex}
              onChange={OnZoneChangeHandler}
              isSearchable={true}
              isMulti
            />
          </Col>
        </Row>
      ) : (
        ""
      )}

      <br />
      <div ref={(el) => (invoiceRef = el)}>
        <br />
        <br />
        <Row>
          <Col>
            <FormGroup row>
              <Label
                for="exampleEmail"
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  marginTop: 10,
                }}
              >
                Total Quantity
              </Label>
              <Col
                style={{
                  marginTop: 10,
                }}
              >
                {totalQuantity}
              </Col>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup row>
              <Label
                for="exampleEmail"
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  marginTop: 10,
                }}
              >
                Total Price (IQD)
              </Label>
              <Col
                style={{
                  marginTop: 10,
                }}
              >
                <NumberFormat
                  value={totalIQD ? totalIQD.toFixed(0) : 0}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"IQD "}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup row>
              <Label
                for="exampleEmail"
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  marginTop: 10,
                }}
              >
                Total Price ($)
              </Label>
              <Col
                style={{
                  marginTop: 10,
                }}
              >
                <NumberFormat
                  value={totalDLR ? totalDLR.toFixed(0) : 0}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$ "}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup row>
              <Label
                for="exampleEmail"
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  marginTop: 10,
                }}
              >
                Total Paid (IQD)
              </Label>
              <Col
                style={{
                  marginTop: 10,
                }}
              >
                <NumberFormat
                  value={totalPaid ? totalPaid.toFixed(0) : 0}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"IQD "}
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup row>
              <Label
                for="exampleEmail"
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  marginTop: 10,
                }}
              >
                user persentage
              </Label>
              <Col
                style={{
                  marginTop: 10,
                }}
              >
                {userRepaymentType === "perquantity" ? (
                  <>IQD {userPersentage}</>
                ) : (
                  <>% {userPersentage}</>
                )}
              </Col>
            </FormGroup>
          </Col>

          <Col>
            <FormGroup row>
              <Label
                for="exampleEmail"
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  marginTop: 10,
                }}
              >
                Total Persentage (IQD)
              </Label>
              <Col
                style={{
                  marginTop: 10,
                }}
              >
                <NumberFormat
                  value={totalPersentage ? totalPersentage.toFixed(0) : 0}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"IQD "}
                />
              </Col>
            </FormGroup>
          </Col>

          <Col>
            <FormGroup row>
              <Label
                for="exampleEmail"
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  marginTop: 10,
                }}
              >
                Total Bonus (IQD)
              </Label>
              <Col
                style={{
                  marginTop: 10,
                }}
              >
                <NumberFormat
                  value={totalBonus ? totalBonus.toFixed(0) : 0}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"IQD "}
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <div>
          <Table
            className=" align-items-center table-flush"
            bordered
            style={{ fontWeight: "bold", color: "black" }}
            responsive
          >
            <thead className="thead-light">
              <tr>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  Manual Invoice Number
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  Quantity
                </th>
                {/* <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  {" "}
                  total ($)
                </th> */}
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  total (IQD)
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  PAID
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  P.Status
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  persentage Price
                </th>{" "}
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  Bonus Price
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  User name
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  Date
                </th>
              </tr>
            </thead>
            <tbody>
              {reportData.map(function (item, index) {
                return (
                  <tr>
                    <th
                      scope="row"
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      {item.manualInvoiceNumber}
                    </th>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      {item.quantity}
                    </td>
                    {/* <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      <NumberFormat
                        value={item.priceDLR.toFixed(0)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$ "}
                      />
                    </td> */}
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      <NumberFormat
                        value={item.priceIQD.toFixed(0)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"IQD "}
                      />
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      <NumberFormat
                        value={item.paid.toFixed(0)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"IQD "}
                      />
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      {item.paidStatus === "0" ? (
                        <Badge
                          color="danger"
                          style={{
                            fontSize: 11,
                            color: "black",
                          }}
                        >
                          NOt Paid
                        </Badge>
                      ) : item.paidStatus === "1" ? (
                        <Badge
                          color="warning"
                          style={{
                            fontSize: 11,
                            color: "black",
                          }}
                        >
                          Paid to Mandwb
                        </Badge>
                      ) : (
                        <Badge
                          color="success"
                          style={{
                            fontSize: 11,
                            color: "black",
                          }}
                        >
                          Paid to Accountant
                        </Badge>
                      )}
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      <NumberFormat
                        value={item.persentagePrice.toFixed(0)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"IQD "}
                      />
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      <NumberFormat
                        value={item.bonusPrice.toFixed(0)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"IQD "}
                      />
                    </td>{" "}
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      {item.username}
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      <Moment format="YYYY/MM/DD">{item.date}</Moment>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
}
