import GeneralHeader from "components/Headers/GeneralHeader";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

import Cities from "./Cities";
import Neighbours from "./Neighbours";
import Zones from "./Zones";

export default function Index() {
  const { search } = useLocation();
  const { status } = queryString.parse(search);

  if (status === "city") {
    return (
      <>
        <GeneralHeader />
        <Cities />
      </>
    );
  } else if (status === "zone") {
    return (
      <>
        <GeneralHeader />

        <Zones />
      </>
    );
  } else if (status === "neighbour") {
    return (
      <>
        <GeneralHeader />

        <Neighbours />
      </>
    );
  } else {
    return (
      <>
        <GeneralHeader />
      </>
    );
  }
}
