import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
} from "reactstrap";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import allActions from "actions/allActions";
import Swal from "sweetalert2";
import axios from "axios";
const path = require("../../../externals/path.json").path;

const Login = () => {
  const dispatch = useDispatch();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");

  const onLoginHandler = (e) => {
    e.preventDefault();
    let data = { username: userName, password: password };
    axios({
      method: "post",
      url: path + "/login",
      headers: {},
      data: data,
    })
      // axios
      //   .post(path + "/users", data)
      .then(async (result) => {
        if (result.data.success === true) {
          const user = {
            id: result.data.data.id,
            username: userName,
            name: result.data.data.name,
            role: result.data.data.role,
            repaymentType: result.data.data.repaymentType,
            token: result.data.data.token,
            zones: result.data.data.zones,
            leaderId: result.data.data.leaderId,
            pages: result.data.data.pages,
            isPartner: result.data.data.isPartner,
          };

          dispatch(allActions.AuthAction.logIn(user));
        }
      })
      .catch(function (error) {
        if (error.response) {
          if (
            error.response.data.success === false &&
            error.response.data.statusCode === 404
          ) {
            Swal.fire({
              title: "Warning",
              text: "User name incorrect",
              icon: "warning",
            });
          } else if (
            error.response.data.success === false &&
            error.response.data.statusCode === 400
          ) {
            Swal.fire({
              title: "Warning",
              text: "password incorrect",
              icon: "warning",
            });
          } else {
            Swal.fire({
              title: "warning",
              text: "there is an error please contact administrator",
              icon: "warning",
            });
          }
        }
      });
  };
  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Login</small>
            </div>
            <Form role="form" onSubmit={onLoginHandler}>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i class="fas fa-user"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    onChange={(e) => {
                      setUserName(e.target.value);
                    }}
                    placeholder="User Name"
                    type="text"
                    autoFocus
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    placeholder="Password"
                    type="password"
                  />
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                <Button
                  // onClick={onLoginHandler}
                  className="my-4"
                  color="primary"
                  type="submit"
                >
                  Sign in
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Login;
