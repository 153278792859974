import React, { useState, useEffect, useRef } from "react";
import ReactToPrint from "react-to-print";
import axios from "axios";
import Swal from "sweetalert2";
import NumberFormat from "react-number-format";
import Moment from "react-moment";

import {
  Button,
  Table,
  Row,
  Col,
  Input,
  FormGroup,
  Label,
  Badge,
} from "reactstrap";
import Select from "react-select";

const path = require("../../../externals/path.json").path;

export default function SellDebtReport() {
  let invoiceRef = useRef();
  const [reportData, setReportData] = useState([]);
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [customerList, setCustomerList] = useState([]);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [customerOptionIndex, setCustomerOptionIndex] = useState();
  const [customerId, setCustomerId] = useState();
  const [totalPaid, setTotalPaid] = useState(0);
  const [totalIQD, setTotalIQD] = useState(0);
  const [totalDebt, setTotalDebt] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [userName, setUserName] = useState();
  const [usersList, setUsersList] = useState([]);
  const [cityId, setCityId] = useState("0");
  const [zoneId, setZoneId] = useState("0");
  const [cityList, setCityList] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [neighbourList, setNeighbourList] = useState([]);
  const [neighbourId, setNeighbourId] = useState("0");

  useEffect(() => {
    onBindCustomerData();
    onBindUsersData();
    onBindcityData();
  }, []);
  function onBindUsersData() {
    axios
      .get(path + "/users", {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setUsersList(result.data.data);
        }
      });
  }

  function usersItemsRender() {
    let items = [];
    usersList.map((item) =>
      items.push(
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      )
    );
    return items;
  }
  function onBindcityData() {
    axios
      .get(path + "/cities", {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setCityList(result.data.data);
        }
      });
  }

  function onBindZoneDataHandler(cityId) {
    axios
      .get(path + "/zone/" + Number(cityId), {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.data !== null) setZoneList(result.data.data);
      });
  }
  function onBindNeighboursDataHandler(zoneId) {
    axios
      .get(path + "/neighbours/" + Number(zoneId), {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) setNeighbourList(result.data.data);
        else
          this.setState({
            neighbour: null,
            neighbourList: [],
          });
      });
  }
  function neighbourItemsRender() {
    let items = [];
    neighbourList.map((item) =>
      items.push(
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      )
    );
    return items;
  }

  function cityItemsRender() {
    let items = [];
    cityList.map((item) =>
      items.push(
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      )
    );
    return items;
  }
  function zoneItemsRender() {
    let items = [];
    zoneList.map((item) =>
      items.push(
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      )
    );
    return items;
  }
  function onChangeCityHandler(event) {
    setCityId(event.target.value);
    // setUserName("0");
    setCustomerOptionIndex({ value: -1, label: "----Customer List ----" });
    setCustomerId();
    onBindZoneDataHandler(event.target.value);
    setNeighbourId("0");
  }

  function onChangeZoneHandler(event) {
    setZoneId(event.target.value);
    // setUserName("0");
    setNeighbourId("0");
    setCustomerOptionIndex({ value: -1, label: "----Customer List ----" });
    setCustomerId();
    onBindNeighboursDataHandler(event.target.value);
  }

  function onBindtableData() {
    let index = usersList.findIndex(
      (element) => element.id === Number(userName)
    );
    axios
      .post(
        path + "/sellreports/getSellDebt",
        {
          customerId: customerId,
          cityId: cityId !== "0" ? cityId : null,
          zoneId: zoneId !== "0" ? zoneId : null,
          neighbourId: neighbourId !== "0" ? neighbourId : null,
          userName: userName !== "0" ? Number(userName) : null,
          role: index !== -1 ? usersList[index].role : null,
          from: from,
          to: to,
        },
        {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        }
      )
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setReportData(result.data.data);
          setTotalPaid(
            result.data.data.reduce((sum, invoice) => {
              return sum + invoice.totalPaid;
            }, 0)
          );
          setTotalIQD(
            result.data.data.reduce((sum, invoice) => {
              return sum + invoice.totalIvoices;
            }, 0)
          );
          setTotalDebt(
            result.data.data.reduce((sum, invoice) => {
              return sum + invoice.totalDebt;
            }, 0)
          );
          setTotalQuantity(
            result.data.data.reduce((sum, invoice) => {
              return sum + invoice.quantity;
            }, 0)
          );
        }
      });
    // axios
    //   .post(
    //     path + "/sellreports/getTotalSellDebt",
    //     {
    //       customerId: customerId,
    //       cityId: zoneId !== "0" && cityId !== "0" ? cityId : null,
    //       zoneId: neighbourId !== "0" && zoneId !== "0" ? zoneId : null,
    //       neighbourId: neighbourId !== "0" ? neighbourId : null,
    //       userName: userName !== "0" ? Number(userName) : null,
    //       role: index !== -1 ? usersList[index].role : null,
    //       from: from,
    //       to: to,
    //     },
    //     {
    //       // headers: {
    //       //   "auth-token": this.props.user.user.token,
    //       // },
    //     }
    //   )
    //   .then((result) => {
    //     // if (result.data.token !== null)
    //     //   this.props.user.user.token = result.data.token;
    //     if (result.data.success === true) {
    //       setTotalPaid(result.data.data.totalPaid);
    //       setTotalIQD(result.data.data.totalIvoices);
    //       setTotalDebt(result.data.data.totalDebt);
    //       setTotalQuantity(result.data.data.quantity);
    //     }
    //   });
  }

  function onBindCustomerData() {
    axios
      .post(path + "/customers/sell", {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setCustomerList(result.data.data);
          let options = [{ value: -1, label: "----Customer List ----" }];

          result.data.data.forEach((item, index) => {
            options.push({ value: index, label: item.name });
          });
          setCustomerOptions(options);
        }
      });
  }
  function onCustomerChangeHandler(e) {
    setUserName("0");
    setZoneId("0");
    setCityId("0");

    if (e.value !== -1) {
      setCustomerOptionIndex({
        value: e.value,
        label: customerList[e.value].name,
      });
      setCustomerId(customerList[e.value].id);
    } else {
      setCustomerOptionIndex();
    }
  }

  return (
    <>
      <Row>
        <Col>
          <Select
            value={customerOptionIndex}
            onChange={(e) => onCustomerChangeHandler(e)}
            options={customerOptions}
            style={{ fontSize: 16, color: "black", height: 45 }}
          />
        </Col>
        <Col>
          <Input
            className="form-control-alternative"
            value={userName}
            id="userName"
            placeholder="userName"
            type="select"
            onChange={(e) => {
              setUserName(e.target.value);
              setCustomerOptionIndex({
                value: -1,
                label: "----Customer List ----",
              });
              setCustomerId();
              // setZoneId("0");
              // setCityId("0");
            }}
            style={{ fontSize: 16, color: "black", height: 45 }}
          >
            <option value={0}>---User---</option>
            {usersItemsRender()}
          </Input>
        </Col>
        <Col>
          <Input
            className="form-control-alternative"
            value={cityId}
            id="cityId"
            placeholder="cityId"
            type="select"
            onChange={(e) => onChangeCityHandler(e)}
            style={{ fontSize: 16, color: "black", height: 45 }}
          >
            <option key={0} value={0}>
              ---city---
            </option>
            {cityItemsRender()}
          </Input>
        </Col>
        <Col>
          <Input
            className="form-control-alternative"
            value={zoneId}
            id="zoneId"
            placeholder="zoneId"
            type="select"
            onChange={(e) => {
              onChangeZoneHandler(e);
            }}
            style={{ fontSize: 16, color: "black", height: 45 }}
          >
            <option key={0} value={0}>
              ---zone---
            </option>
            {zoneItemsRender()}
          </Input>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <Input
            className="form-control-alternative"
            value={neighbourId}
            id="neighbourId"
            placeholder="neighbourId"
            type="select"
            onChange={(e) => {
              // setUserName("0");
              setCustomerOptionIndex({
                value: -1,
                label: "----Customer List ----",
              });
              setCustomerId();
              setNeighbourId(e.target.value);
              // setCityId("0");
              // setZoneId("0");
            }}
            style={{ fontSize: 16, color: "black", height: 45 }}
          >
            <option key={0} value={0}>
              ---neighbour---
            </option>
            {neighbourItemsRender()}
          </Input>
        </Col>
        <Col>
          <Input
            className="form-control-alternative"
            value={from}
            id="from"
            placeholder="from"
            type="date"
            onChange={(e) => setFrom(e.target.value)}
            style={{ fontSize: 16, color: "black", height: 45 }}
          />
        </Col>
        <Col>
          <Input
            className="form-control-alternative"
            value={to}
            id="to"
            placeholder="to"
            type="date"
            onChange={(e) => setTo(e.target.value)}
            style={{ fontSize: 16, color: "black", height: 45 }}
          />
        </Col>
        <Col className="text-right">
          <Button color="primary" onClick={(e) => onBindtableData()} size="md">
            Get
          </Button>
          <ReactToPrint
            trigger={() => <Button color="success">print</Button>}
            content={() => invoiceRef}
          />{" "}
        </Col>
      </Row>

      <br />
      <div ref={(el) => (invoiceRef = el)}>
        <br />
        <br />
        <Row>
          <Col>
            <FormGroup row>
              <Label
                for="exampleEmail"
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  marginTop: 10,
                }}
              >
                Total Quantity
              </Label>
              <Col
                style={{
                  marginTop: 10,
                }}
              >
                {totalQuantity}
              </Col>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup row>
              <Label
                for="exampleEmail"
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  marginTop: 10,
                }}
              >
                Total Price (IQD)
              </Label>
              <Col
                style={{
                  marginTop: 10,
                }}
              >
                <NumberFormat
                  value={totalIQD ? totalIQD.toFixed(0) : 0}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"IQD "}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup row>
              <Label
                for="exampleEmail"
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  marginTop: 10,
                }}
              >
                Total Debt (IQD)
              </Label>
              <Col
                style={{
                  marginTop: 10,
                }}
              >
                <NumberFormat
                  value={totalDebt ? totalDebt.toFixed(0) : 0}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$ "}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup row>
              <Label
                for="exampleEmail"
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  marginTop: 10,
                }}
              >
                Total Paid (IQD)
              </Label>
              <Col
                style={{
                  marginTop: 10,
                }}
              >
                <NumberFormat
                  value={totalPaid ? totalPaid.toFixed(0) : 0}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"IQD "}
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>

        <div>
          <Table
            className=" align-items-center table-flush"
            style={{ fontWeight: "bold", color: "black" }}
          >
            <thead className="thead-light">
              <tr>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  #
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  Customer Name
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  Total Invoices
                </th>{" "}
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  quantity
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  Total paid on invoice
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  Current Debt
                </th>
              </tr>
            </thead>
            <tbody>
              {reportData.map(function (item, index) {
                return (
                  <tr>
                    <th
                      scope="row"
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      {index + 1}
                    </th>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      {item.name}
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      <Badge
                        color={"info"}
                        style={{
                          color: "black",
                          fontSize: 15,
                          textAlign: "center",
                        }}
                      >
                        <NumberFormat
                          value={
                            item.totalIvoices ? item.totalIvoices.toFixed(0) : 0
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"IQD "}
                        />
                      </Badge>
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      <Badge
                        color={"warning"}
                        style={{
                          color: "black",
                          fontSize: 15,
                          textAlign: "center",
                        }}
                      >
                        {item.quantity ? item.quantity.toFixed(2) : 0}
                      </Badge>
                    </td>

                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      <Badge
                        color={"success"}
                        style={{
                          color: "black",
                          fontSize: 15,
                          textAlign: "center",
                        }}
                      >
                        <NumberFormat
                          value={item.totalPaid ? item.totalPaid.toFixed(0) : 0}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"IQD "}
                        />
                      </Badge>
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      <Badge
                        color={"danger"}
                        style={{
                          color: "black",
                          fontSize: 15,
                          textAlign: "center",
                        }}
                      >
                        <NumberFormat
                          value={item.totalDebt ? item.totalDebt.toFixed(0) : 0}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"IQD "}
                        />
                      </Badge>
                    </td>

                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    ></td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
}
