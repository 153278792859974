import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
// import { connect } from "react-redux";
import GeneralHeader from "components/Headers/GeneralHeader.js";
// import NumberFormat from "react-number-format";
// import Moment from "react-moment";
// import moment from "moment";
import axios from "axios";
import {
  Card,
  CardHeader,
  CardBody,
  Form,
  // CardFooter,
  Table,
  Container,
  Row,
  Col,
  // DropdownMenu,
  // DropdownItem,
  // UncontrolledDropdown,
  // DropdownToggle,
  // Pagination,
  // PaginationItem,
  // PaginationLink,
  Button,
  // Badge,
  // ButtonGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
} from "reactstrap";
import { useSelector } from "react-redux";

const path = require("../../externals/path.json").path;

export default function Index() {
  const rate = useSelector((state) => state.rateData.rate);
  const user = useSelector((state) => state.isLogged.user);
  const [showPriceModal, setShowPriceModal] = useState(false);
  const [showItemeModal, setShowItemeModal] = useState(false);
  const [itemList, setitemList] = useState([]);
  const [itemPriceList, setItemPriceList] = useState([]);
  const [isUpdatePrice, setisUpdatePrice] = useState(false);
  const [isUpdateItem, setisUpdateItem] = useState(false);
  const [cityList, setCityList] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [priceRecordId, setpriceRecordId] = useState(null);
  const [itemId, setItemId] = useState(null);
  const [itemName, setItemName] = useState(null);
  const [barcode, setBarcode] = useState(null);
  const [nameEng, setNameEng] = useState(null);
  const [nameKrd, setNameKrd] = useState(null);
  const [nameAr, setNameAr] = useState(null);
  const [quantityPerBox, setQuantityPerBox] = useState(null);
  const [weight, setWeight] = useState(null);
  const [note, setNote] = useState(null);
  const [status, setStatus] = useState(null);
  const [city, setCity] = useState(null);
  const [zone, setZone] = useState(null);
  const [price, setPrice] = useState(null);
  const [minPrice, setMinPrice] = useState(null);

  useEffect(() => {
    onBindTableData();
  }, []);

  const priceToggle = () => {
    setShowPriceModal(!showPriceModal);
  };
  const itemToggle = () => {
    setShowItemeModal(!showItemeModal);
  };
  const onBindTableData = () => {
    axios
      .get(path + "/items", {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setitemList(result.data.data);
        }
      });
  };
  const onBindCityList = () => {
    axios
      .get(path + "/cities", {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setCityList(result.data.data);
        }
      });
  };
  function onBindZoneDataHandler(cityId) {
    axios
      .get(path + "/zone/" + Number(cityId), {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) setZoneList(result.data.data);
      });
  }

  function onChangeCityHandler(event) {
    setCity(event.target.value);

    if (event.target.value !== "---City---")
      onBindZoneDataHandler(event.target.value);
  }

  function cityItemsRender() {
    let items = [];
    cityList.map((item) =>
      items.push(
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      )
    );
    return items;
  }
  function zoneItemsRender() {
    let items = [];
    zoneList.map((item) =>
      items.push(
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      )
    );
    return items;
  }
  function EditDataHandler(id) {
    axios
      .get(path + "/items/" + id, {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setBarcode(result.data.data.barcode);
          setNameAr(result.data.data.nameAr);
          setNameEng(result.data.data.nameEng);
          setNameKrd(result.data.data.nameKrd);
          setQuantityPerBox(result.data.data.quantityPerBox);
          setWeight(result.data.data.weight);
          setNote(result.data.data.note);
          setStatus(result.data.data.status);
          setItemId(id);
          setisUpdateItem(true);
          setShowItemeModal(true);
        }
      });
  }
  function onBindItemPriceData(name, itemId) {
    axios
      .get(path + "/pricePerZone/ItemPriceList/" + itemId, {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setItemPriceList(result.data.data);
          setItemId(itemId);
          setItemName(name);
          onBindCityList();
          setpriceRecordId(null);
          setCity(null);
          onBindZoneDataHandler([]);
          setZone(null);
          setPrice(null);
          setMinPrice(null);
          setisUpdatePrice(false);
          priceToggle();
        }
      });
  }

  function onAddNewHandler() {
    itemToggle();
    setBarcode(null);
    setNameAr(null);
    setNameEng(null);
    setNameKrd(null);
    setQuantityPerBox(null);
    setNote(null);
    setWeight(null);
    setisUpdateItem(false);
    setStatus(1);
  }

  function onSaveItemHandler() {
    let item = {
      barcode: barcode,
      nameEng: nameEng,
      nameAr: nameAr,
      nameKrd: nameKrd,
      quantityPerBox: quantityPerBox,
      weight: weight,
      note: note,
      status: status,
      username: user.id,
    };
    if (!isUpdateItem) {
      axios
        .post(path + "/items", item, {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        })
        .then((result) => {
          if (result.data.success === true) {
            onBindTableData();
            setBarcode(null);
            setNameAr(null);
            setNameEng(null);
            setNameKrd(null);
            setQuantityPerBox(null);
            setWeight(null);
            setNote(null);
            setStatus();
            itemToggle();
            Swal.fire({
              title: "THANKS",
              text: "new item  added",
              icon: "success",
            });
          }
          // if (result.data.token !== null)
          //   this.props.user.user.token = result.data.token;
        })
        .catch((error) => {
          Swal.fire({
            title: "warning",
            text: " item  did not add please contact administrator",
            icon: "warning",
          });
        });
    } else {
      axios
        .put(path + "/items/" + itemId, item, {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        })
        .then((result) => {
          if (result.data.success === true) {
            // setShowPriceModal(false);
            onBindTableData();
            setBarcode(null);
            setNameAr(null);
            setNameEng(null);
            setNameKrd(null);
            setQuantityPerBox(null);
            setWeight(null);
            setNote(null);
            setisUpdateItem(false);
            setItemId(null);
            itemToggle();
            setStatus();

            Swal.fire({
              title: "THANKS",
              text: "new item  upadted",
              icon: "success",
            });
          }
          // if (result.data.token !== null)
          //   this.props.user.user.token = result.data.token;
        })
        .catch((error) => {
          Swal.fire({
            title: "warning",
            text: " item  did not update please contact administrator",
            icon: "warning",
          });
        });
    }
  }

  function onDeleteItemHandler(id, index) {
    Swal.fire({
      title: 'Do you want to delete item  with id "' + index + '" ?',
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.value) {
        axios
          .delete(path + "/items/" + id, {
            // headers: {
            //   "auth-token": this.props.user.user.token,
            // },
          })
          .then((result) => {
            if (result.data.statusCode === 200) {
              onBindTableData();
              Swal.fire("item Deleted!", "success", "success");
            }
            // if (result.data.token !== null)
            //   this.props.user.user.token = result.data.token;
          })
          .catch((error) => {
            Swal.fire({
              title: "warning",
              text: "the Item did not deleted please contact administrator",
              icon: "warning",
            });
          });
      }
    });
  }

  const onSaveItemPriceHandler = () => {
    let pricePerZone = {
      itemId: itemId,
      zoneId: zone,
      priceDLR: price,
      priceIQD: minPrice,
      username: user.id,
    };

    if (minPrice && zone && price) {
      if (!isUpdatePrice) {
        axios
          .post(path + "/pricePerZone", pricePerZone, {
            // headers: {
            //   "auth-token": this.props.user.user.token,
            // },
          })
          .then((result) => {
            if (result.data.success === true) {
              // setShowPriceModal(false);

              setCity(null);
              setZone(null);
              setPrice(null);
              setMinPrice(null);
              priceToggle();

              Swal.fire({
                title: "THANKS",
                text: "new item price added",
                icon: "success",
              });
            }
            // if (result.data.token !== null)
            //   this.props.user.user.token = result.data.token;
          })
          .catch((error) => {
            Swal.fire({
              title: "warning",
              text: " item price did not add please contact administrator",
              icon: "warning",
            });
          });
      } else {
        axios
          .put(path + "/pricePerZone/" + priceRecordId, pricePerZone, {
            // headers: {
            //   "auth-token": this.props.user.user.token,
            // },
          })
          .then((result) => {
            if (result.data.success === true) {
              // setShowPriceModal(false);

              setCity(null);
              setZone(null);
              setPrice(null);
              setMinPrice(null);
              setisUpdatePrice(false);
              priceToggle();

              Swal.fire({
                title: "THANKS",
                text: "new item price upadted",
                icon: "success",
              });
            }
            // if (result.data.token !== null)
            //   this.props.user.user.token = result.data.token;
          })
          .catch((error) => {
            Swal.fire({
              title: "warning",
              text: " item price did not update please contact administrator",
              icon: "warning",
            });
          });
      }
    } else {
      Swal.fire({
        title: "WARNING",
        text: "please Fill All fields correctly ",
        icon: "warning",
      });
    }
  };

  const onDeletePriceHandler = (id, index) => {
    Swal.fire({
      title: 'Do you want to delete item price with id "' + index + '" ?',
      showCancelButton: true,
      confirmButtonText: "Save",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.value) {
        axios
          .delete(path + "/pricePerZone/" + id, {
            // headers: {
            //   "auth-token": this.props.user.user.token,
            // },
          })
          .then((result) => {
            if (result.data.statusCode === 200) {
              onBindItemPriceData(itemName, itemId);
              Swal.fire("price Deleted!", "success", "success");
            }
            // if (result.data.token !== null)
            //   this.props.user.user.token = result.data.token;
          })
          .catch((error) => {
            Swal.fire({
              title: "warning",
              text: "the price did not deleted please contact administrator",
              icon: "warning",
            });
          });
      }
    });
  };
  return (
    <>
      <GeneralHeader />
      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h2 className="mb-0">Item List</h2>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      onClick={(e) => onAddNewHandler()}
                      size="md"
                    >
                      New Item
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <div style={{ height: "76vh", overflow: "scroll" }}>
                <Table bordered style={{ fontWeight: "bold", color: "black" }}>
                  <thead className="thead-light">
                    <tr>
                      <th
                        scope="col"
                        style={{
                          fontSize: 15,
                        }}
                        className="text-center"
                      >
                        #
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontSize: 15,
                        }}
                        className="text-center"
                      >
                        Item Name (kurdish)
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontSize: 15,
                        }}
                        className="text-center"
                      >
                        Item Name (English)
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontSize: 15,
                        }}
                        className="text-center"
                      >
                        Item Name (Arabic)
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontSize: 15,
                        }}
                        className="text-center"
                      >
                        quantity Per Box
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontSize: 15,
                        }}
                        className="text-center"
                      >
                        Weight
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontSize: 15,
                        }}
                        className="text-center"
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {itemList.map(function (item, index) {
                      return (
                        <tr>
                          <th scope="row">{index + 1}</th>
                          <td
                            style={{
                              fontSize: 15,
                            }}
                            className="text-center"
                          >
                            {item.nameKrd}
                          </td>
                          <td
                            style={{
                              fontSize: 15,
                            }}
                            className="text-center"
                          >
                            {item.nameEng}
                          </td>
                          <td
                            style={{
                              fontSize: 15,
                            }}
                            className="text-center"
                          >
                            {item.nameAr}
                          </td>

                          <td
                            style={{
                              fontSize: 15,
                            }}
                            className="text-center"
                          >
                            {item.quantityPerBox}
                          </td>
                          <td
                            style={{
                              fontSize: 15,
                            }}
                            className="text-center"
                          >
                            {item.weight}{" "}
                          </td>
                          <td
                            style={{
                              fontSize: 15,
                            }}
                            className="text-center"
                          >
                            <div>
                              {user.role === "admin" ||
                              user.role === "accountant" ? (
                                <>
                                  <Button
                                    size="sm"
                                    color="info"
                                    onClick={() =>
                                      onBindItemPriceData(item.nameKrd, item.id)
                                    }
                                  >
                                    Item Price
                                  </Button>
                                  <Button
                                    size="sm"
                                    color="warning"
                                    onClick={() => EditDataHandler(item.id)}
                                  >
                                    Edit
                                  </Button>
                                  <Button
                                    size="sm"
                                    color="danger"
                                    onClick={() =>
                                      onDeleteItemHandler(item.id, index + 1)
                                    }
                                  >
                                    Delete
                                  </Button>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
              {/* <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem className="disabled">
                        <PaginationLink
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                          tabIndex="-1"
                        >
                          <i className="fas fa-angle-left" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem className="active">
                        <PaginationLink
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          1
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter> */}
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        isOpen={showItemeModal}
        toggle={itemToggle}
        size="lg"
        style={{ maxWidth: "1200px", width: "100%" }}
      >
        <ModalHeader toggle={itemToggle}>New Item</ModalHeader>
        <ModalBody>
          <Col className="order-xl-1">
            <Card className="shadow" style={{ backgroundColor: "#F0F8FF" }}>
              <CardBody>
                <Form autoComplete="off" id="ff">
                  <h6 className="heading-small text-muted mb-2">Item</h6>
                  <div className="pl-lg-2">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            value={barcode}
                            id="barcode"
                            placeholder="barcode"
                            type="text"
                            onChange={(e) => setBarcode(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            value={nameKrd}
                            id="nameKrd"
                            placeholder="Item Name kurdish"
                            type="text"
                            onChange={(e) => setNameKrd(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            value={nameAr}
                            id="nameAr"
                            placeholder="Item Name Arabic"
                            type="text"
                            onChange={(e) => setNameAr(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            value={nameEng}
                            id="nameEng"
                            placeholder="Item Name English"
                            type="text"
                            onChange={(e) => setNameEng(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            value={quantityPerBox}
                            id="quantityPerBox"
                            placeholder="quantity Per Box"
                            type="number"
                            onChange={(e) => setQuantityPerBox(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            value={weight}
                            id="weight"
                            placeholder="Item weight"
                            type="number"
                            onChange={(e) => setWeight(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="8">
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            value={note}
                            id="note"
                            placeholder="Note"
                            type="textarea"
                            onChange={(e) => setNote(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => onSaveItemHandler()}>
            Save
          </Button>{" "}
          <Button color="secondary" onClick={itemToggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={showPriceModal} toggle={priceToggle} className="modal-lg">
        <ModalHeader toggle={priceToggle}>
          Item Price (<span className="h4"> {itemName} </span>)
        </ModalHeader>
        <ModalBody>
          <Col className="order-xl-1">
            <Card className="shadow" style={{ backgroundColor: "#F0F8FF" }}>
              <CardBody>
                <Form autoComplete="off" id="ff">
                  <h6 className="heading-small text-muted mb-2">Purchase</h6>
                  <div className="pl-lg-2">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            value={city}
                            id="city"
                            placeholder="city"
                            type="select"
                            onChange={onChangeCityHandler}
                          >
                            <option>---City---</option>
                            {cityItemsRender()}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            value={zone}
                            id="zone"
                            placeholder="zone"
                            type="select"
                            onChange={(e) => setZone(e.target.value)}
                          >
                            <option value="">---Zone---</option>
                            {zoneItemsRender()}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            value={price}
                            id="price"
                            placeholder="price ( DOLAR )"
                            type="number"
                            onChange={(e) => {
                              let change = e.target.value * rate.rate;
                              setMinPrice(change);
                              setPrice(e.target.value);
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            value={minPrice}
                            id="minPrice"
                            placeholder="Price ( DINAR )"
                            type="number"
                            onChange={(e) => {
                              let change = e.target.value / rate.rate;
                              setPrice(change);
                              setMinPrice(e.target.value);
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Table bordered responsive>
                        <thead className="thead-light">
                          <tr>
                            <th
                              scope="col"
                              style={{
                                fontSize: 15,
                              }}
                              className="text-center"
                            >
                              #
                            </th>
                            <th
                              scope="col"
                              style={{
                                fontSize: 15,
                              }}
                              className="text-center"
                            >
                              city
                            </th>
                            <th
                              scope="col"
                              style={{
                                fontSize: 15,
                              }}
                              className="text-center"
                            >
                              zone
                            </th>
                            <th
                              scope="col"
                              style={{
                                fontSize: 15,
                              }}
                              className="text-center"
                            >
                              price (Dolar)
                            </th>
                            <th
                              scope="col"
                              style={{
                                fontSize: 15,
                              }}
                              className="text-center"
                            >
                              price (Dinar)
                            </th>

                            <th
                              scope="col"
                              style={{
                                fontSize: 15,
                              }}
                              className="text-center"
                            >
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {itemPriceList.map(function (item, index) {
                            return (
                              <tr>
                                <th scope="row">{index + 1}</th>
                                <td
                                  style={{
                                    fontSize: 15,
                                  }}
                                  className="text-center"
                                >
                                  {item.city}
                                </td>
                                <td
                                  style={{
                                    fontSize: 15,
                                  }}
                                  className="text-center"
                                >
                                  {item.zone}
                                </td>
                                <td
                                  style={{
                                    fontSize: 15,
                                  }}
                                  className="text-center"
                                >
                                  {item.priceDLR.toFixed(0)}
                                </td>
                                <td
                                  style={{
                                    fontSize: 15,
                                  }}
                                  className="text-center"
                                >
                                  {item.priceIQD.toFixed(0)}
                                </td>

                                <td
                                  style={{
                                    fontSize: 15,
                                  }}
                                  className="text-center"
                                >
                                  <div>
                                    <Button
                                      size="sm"
                                      color="warning"
                                      onClick={() => {
                                        setpriceRecordId(item.pid);
                                        setCity(item.cityId);
                                        onBindZoneDataHandler(item.cityId);
                                        setZone(item.zoneId);
                                        setPrice(item.priceDLR);
                                        setMinPrice(item.priceIQD);
                                        setisUpdatePrice(true);
                                      }}
                                    >
                                      Edit
                                    </Button>
                                    <Button
                                      size="sm"
                                      color="danger"
                                      onClick={() =>
                                        onDeletePriceHandler(
                                          item.pid,
                                          index + 1
                                        )
                                      }
                                    >
                                      Delete
                                    </Button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={onSaveItemPriceHandler}>
            Save
          </Button>{" "}
          <Button color="secondary" onClick={priceToggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
