import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import GeneralHeader from "components/Headers/GeneralHeader.js";
import axios from "axios";
import NumberFormat from "react-number-format";
import moment from "moment";

import {
  Card,
  CardHeader,
  CardBody,
  Form,
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  Label,
} from "reactstrap";
import { useSelector } from "react-redux";
import Moment from "react-moment";

const path = require("../../externals/path.json").path;

export default function Returning() {
  const user = useSelector((state) => state.isLogged.user);

  const [itemPriceAllList, setItemPriceAllList] = useState([]);
  const [showItemeModal, setShowItemeModal] = useState(false);
  const [itemList, setItemList] = useState([]);
  const [invoiceData, setInvoiceData] = useState([]);
  const [itemToReturn, setItemToReturn] = useState(null);
  const [itemToChange, setItemToChange] = useState(null);
  const [itemExpiryToChangeIndex, setItemExpiryToChangeIndex] = useState(null);
  const [itemTypetoReturn, setItemTypetoReturn] = useState("box");
  const [quantityToReturn, setquantityToReturn] = useState(null);
  const [itemTypetoChange, setItemTypetoChange] = useState("box");
  const [quantityToChange, setquantityToChange] = useState(null);
  const [noteToChange, setNoteToChange] = useState(null);

  const [manualInvoiceNumber, setManualInvoiceNumber] = useState(null);
  const [type, setType] = useState("0");

  useEffect(() => {
    // onBindInvoiceData();
  }, []);

  const itemToggle = () => {
    setShowItemeModal(!showItemeModal);
  };

  const onBindInvoiceData = () => {
    if (manualInvoiceNumber)
      axios
        .get(path + "/sell/getSellToReturning/" + manualInvoiceNumber, {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        })
        .then((result) => {
          // if (result.data.token !== null)
          //   this.props.user.user.token = result.data.token;
          if (result.data.success === true) {
            setInvoiceData(result.data.data);
            setNoteToChange(invoiceData[0].note);
          }
        });
  };

  function onBindItemsData() {
    axios
      .get(path + "/items", {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setItemList(result.data.data);
        }
      });
  }
  function onItemChangeHandler(e) {
    const { id, value } = e.target;
    // const list = [...inputList];
    // list[index][id] = value;

    if (invoiceData[0].zoneId) {
      axios
        .get(
          path +
            `/items/priceToReturnChange/${value}/${invoiceData[0].zoneId}/${invoiceData[0].inventoryLocationId}`,

          {
            // headers: {
            //   "auth-token": this.props.user.user.token,
            // },
          }
        )
        .then((result) => {
          // if (result.data.token !== null)
          //   this.props.user.user.token = result.data.token;

          if (result.data.success === true) {
            // let itempricelistTemp = itemPriceAllList;
            // itempricelistTemp[index] = result.data.data;
            setItemPriceAllList(result.data.data);

            setItemToChange(value);
          }
        })
        .catch((err) => {
          setItemPriceAllList([]);

          Swal.fire({
            title: "warning",
            text: "There is no price for this Item in this zone please entter price first or Check the stock to available Item",
            icon: "warning",
          });
        });
    } else {
      // const { id, value } = e.target;
      // const list = [...inputList];
      // list[index][id] = 0;
      // setInputList(list);
      Swal.fire({
        title: "warning",
        text: "please select Customer first",
        icon: "warning",
      });
    }
  }

  function ItemsRender() {
    let items = [];
    itemList.map((item) =>
      items.push(
        <option key={item.id} value={item.id}>
          {item.nameKrd}
        </option>
      )
    );
    return items;
  }

  function StockRender(i) {
    let items = [];

    if (itemPriceAllList)
      itemPriceAllList.map((item) =>
        items.push(
          <option
            key={item.inventoryId}
            value={item.inventoryId}
            style={{
              fontSize: 15,
              fontWeight: "bold",
              color: "black",
            }}
          >
            {moment(item.expiry).format("YYYY-MM-DD").toString()} ({" "}
            {item.stock.toFixed(2)} )
          </option>
        )
      );
    return items;
  }

  function onChangeOrReturnItemHandler() {
    if (invoiceData.length) {
      setItemToReturn("-1");
      setItemTypetoReturn("box");
      setquantityToReturn(0);
      setShowItemeModal(true);
      onBindItemsData();
      setItemToChange("0");
      setItemPriceAllList([]);
      setItemExpiryToChangeIndex("0");
      setquantityToChange(0);
      setItemTypetoChange("box");
    } else {
      Swal.fire({
        title: "warning",
        text: "تکایە ژمارەی وەسڵی دەستی بنوسە و گەڕان بکە ",
        icon: "warning",
      });
    }
  }

  function onSaveItemHandler() {
    let isCorrect = true;
    let warningMessage = "";
    let quanToReturn = 0;
    let index = itemToReturn;
    let totalPaid = 0;
    let totalIndex = invoiceData.length;
    let rejectedQuantity = [];
    if (invoiceData[index])
      if (itemToReturn !== "-1") {
        if (itemTypetoReturn === "box") {
          quanToReturn =
            (1 / invoiceData[index].quantityPerBox) * Number(quantityToReturn);
        } else {
          quanToReturn = Number(quantityToReturn);
        }
      }

    if (itemToReturn === "-1") {
      warningMessage = `تکایە جۆری ئایتم بۆ گەڕانەوە دیاری بکە`;
      isCorrect = false;
    }
    if (invoiceData[index] && quanToReturn > invoiceData[index].quantity) {
      warningMessage = `بڕی دیاری کراوی گەڕاوە زیاترە لە بڕی وەسڵەکە `;
      isCorrect = false;
    }

    if (!quantityToReturn || quantityToReturn <= 0) {
      warningMessage = `تکایە بڕی گەڕاوە دیاری بکە`;
      isCorrect = false;
    }

    if (isCorrect) {
      rejectedQuantity.push({
        id: invoiceData[index].inventoryId,
        quantity: Number(quanToReturn),
        note: noteToChange,
        status: invoiceData[index].status,
      });

      if (invoiceData[index].paidStatus !== "0") {
        invoiceData.forEach((item) => {
          if (item.itemId === invoiceData[index].itemId) {
            totalPaid = totalPaid + invoiceData[index].priceIQD * quanToReturn;
          } else {
            totalPaid =
              totalPaid +
              invoiceData[index].priceIQD * invoiceData[index].quantity;
          }
        });
      }

      if (type === "change") {
        let quanToChange = 0;
        let expiryIndex = itemPriceAllList.findIndex(
          (obj) => obj.inventoryId === Number(itemExpiryToChangeIndex)
        );
        if (itemTypetoChange === "box") {
          quanToChange =
            (1 / itemPriceAllList[expiryIndex].quantityPerBox) *
            quantityToChange;
        } else {
          quanToChange = quantityToChange;
        }
        let dis = 0;
        if (invoiceData[index].discountType === "Box") {
          let totalQuantityPerBox = invoiceData[index].quantityPerBox;
          dis = totalQuantityPerBox * invoiceData[index].discount;
        } else if (invoiceData[index].discountType === "Carton") {
          dis = invoiceData[index].discount;
        } else if (invoiceData[index].discountType === "Cash") {
          dis =
            (invoiceData[index].actualPriceIQD * invoiceData[index].discount) /
            100;
        }

        totalIndex = totalIndex + 1;
        if (invoiceData[index].paidStatus !== "0")
          totalPaid =
            totalPaid +
            (invoiceData[index].actualPriceIQD - dis) * quanToChange;

        let sell = [];
        sell.push({
          invoiceNumber: invoiceData[index].invoiceNumber,
          manualInvoiceNumber: invoiceData[index].manualInvoiceNumber,
          itemId: itemToChange,
          priceIQD: invoiceData[index].priceIQD,
          actualPriceIQD: invoiceData[index].actualPriceIQD,
          priceDLR: invoiceData[index].priceDLR.toFixed(0),
          actualPriceDLR: invoiceData[index].actualPriceDLR,
          quantity: quanToChange ? quanToChange : 0,
          rateId: invoiceData[index].rateId,
          inventoryId: itemPriceAllList[expiryIndex].inventoryId,
          expiry: moment(itemPriceAllList[expiryIndex].expiry)
            .format("YYYY-MM-DD")
            .toString(),
          zoneId: invoiceData[index].zoneId,
          offer: invoiceData[index].offer ? invoiceData[index].offer : 0,
          paid: totalPaid / totalIndex,
          note: noteToChange,
          deleteStatus: invoiceData[index].deleteStatus,
          discount: invoiceData[index].discount,
          quantityPerBox: itemPriceAllList[expiryIndex].quantityPerBox,
          discountType: invoiceData[index].discountType,
          customerId: invoiceData[index].customerId,
          status: invoiceData[index].status,
          paidStatus: invoiceData[index].paidStatus,
          username: invoiceData[index].username,
          date: moment(invoiceData[index].date).format("YYYY-MM-DD").toString(),
          paidInvoiceNumber: invoiceData[index].paidInvoiceNumber,
          paidBy: invoiceData[index].paidBy,
          paidDate: invoiceData[index].paidDate
            ? moment(invoiceData[index].paidDate)
                .format("YYYY-MM-DD")
                .toString()
            : null,
          id: undefined,
        });
        let AddedQuantity = [];
        AddedQuantity.push({
          id: itemPriceAllList[expiryIndex].inventoryId,
          quantity: quanToChange,
          status: invoiceData[index].status,
        });

        axios
          .put(path + "/sell", sell, {
            // headers: {
            //   "auth-token": this.props.user.user.token,
            // },
          })
          .then((result) => {
            if (result.data.success === true) {
              axios
                .put(path + "/inventory/sell", AddedQuantity, {
                  // headers: {
                  //   "auth-token": this.props.user.user.token,
                  // },
                })
                .then((result) => {
                  if (result.data.success === true) {
                    onBindInvoiceData();
                    setItemPriceAllList([]);
                    setShowItemeModal(false);
                    setManualInvoiceNumber("");
                    setType("0");
                    setInvoiceData([]);
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    title: "warning",
                    text: `there is some error while delivery with invoice No ${invoiceData[index].invoiceNumber}`,
                    icon: "warning",
                  });
                });

              Swal.fire({
                title: "THANKS",
                text: "کرداری گۆڕین سەرکەوتو بوو",
                icon: "success",
              });
            }
            // if (result.data.token !== null)
            //   this.props.user.user.token = result.data.token;
          })
          .catch((error) => {
            Swal.fire({
              title: "warning",
              text: "there is some error while saving sell data please contact administrator",
              icon: "warning",
            });
          });
      } else {
        // Swal.fire({
        //   title: "warning",
        //   text: "ژمارەی وەسڵی دەستی دوبارەیە",
        //   icon: "warning",
        // });
      }
      axios
        .put(path + "/inventory/reject", rejectedQuantity, {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        })
        .then((result) => {
          if (result.data.success === true) {
            axios
              .put(
                path + "/sell/updateSellFromReturning",
                {
                  id: invoiceData[index].id,
                  quantity: invoiceData[index].quantity - quanToReturn,
                },
                {
                  // headers: {
                  //   "auth-token": this.props.user.user.token,
                  // },
                }
              )
              .then((result) => {
                if (result.data.success === true) {
                  axios
                    .put(
                      path + "/sell/updatePaidSellFromReturning",
                      {
                        manualInvoiceNumber: manualInvoiceNumber,
                        paid: totalPaid / totalIndex,
                        note: noteToChange,
                      },
                      {
                        // headers: {
                        //   "auth-token": this.props.user.user.token,
                        // },
                      }
                    )
                    .then((result) => {
                      if (result.data.success === true) {
                        onBindInvoiceData();

                        if (type === "returning") {
                          Swal.fire({
                            title: "THANKS",
                            text: "کرداری گەڕانەوە سەرکەوتو بوو",
                            icon: "success",
                          });
                          setShowItemeModal(false);
                          setManualInvoiceNumber("");
                          setType("0");
                          setInvoiceData([]);
                        }
                      }
                      Swal.fire({
                        title: "success",
                        text: "کارەکە سەرکەوتو بوو",
                        icon: "success",
                      });
                    })
                    .catch((error) => {
                      Swal.fire({
                        title: "warning",
                        text: "there is some error while saving sell data please contact administrator",
                        icon: "warning",
                      });
                    });
                }
              })
              .catch((error) => {
                Swal.fire({
                  title: "warning",
                  text: "there is some error while saving sell data please contact administrator",
                  icon: "warning",
                });
              });
          }
          // if (result.data.token !== null)
          //   this.props.user.user.token = result.data.token;
        })
        .catch((error) => {
          Swal.fire({
            title: "warning",
            text: "there is some error while saving sell data please contact administrator",
            icon: "warning",
          });
        });
    } else {
      Swal.fire({
        title: "warning",
        text: warningMessage,
        icon: "warning",
      });
    }
  }

  function ItemsToReturnRender() {
    let items = [];
    invoiceData.map((item, index) =>
      items.push(
        <option key={index} value={index}>
          {item.nameKrd}
        </option>
      )
    );
    return items;
  }

  return (
    <>
      <GeneralHeader />
      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-right">
                  <Col className="text-right">
                    <h2 className="mb-0 ">گەڕانەوە یان گۆڕین</h2>
                  </Col>
                </Row>
              </CardHeader>

              <FormGroup row style={{ margin: 5, padding: 10 }} dir="rtl">
                <Label
                  for="exampleEmail"
                  style={{ marginLeft: 20, marginTop: 10, fontSize: 18 }}
                >
                  ژمارەی وەسڵی دەستی :
                </Label>
                <Col lg={4}>
                  <Input
                    className="form-control-alternative"
                    value={manualInvoiceNumber}
                    id="manualInvoiceNumber"
                    placeholder="Manual Invoice Number"
                    type="text"
                    onChange={(e) => {
                      setManualInvoiceNumber(e.target.value);
                      setInvoiceData([]);
                    }}
                  />
                </Col>

                <Label
                  for="exampleEmail"
                  style={{ marginLeft: 20, marginTop: 10, fontSize: 18 }}
                >
                  جۆر :
                </Label>
                <Col lg={4}>
                  <Input
                    className="form-control-alternative"
                    id="from"
                    placeholder="inventory Location"
                    type="select"
                    value={type}
                    onChange={(e) => {
                      setType(e.target.value);
                    }}
                  >
                    <option id={0} value={0}>
                      ----- جۆر -----
                    </option>
                    <option id={"returning"} value={"returning"}>
                      گەڕانەوە
                    </option>
                    <option id={"change"} value={"change"}>
                      گۆڕین
                    </option>
                  </Input>
                </Col>
                <Col className="text-left">
                  <Button
                    color="primary"
                    onClick={(e) => onBindInvoiceData()}
                    size="md"
                  >
                    گەڕان
                  </Button>
                </Col>
              </FormGroup>
              <hr />

              <div className=" text-right" dir="rtl">
                <Row style={{ margin: 5, padding: 10 }}>
                  <Col>
                    <Label
                      for="exampleEmail"
                      style={{
                        marginTop: 10,
                        fontSize: 18,
                      }}
                    >
                      کڕیار:
                    </Label>

                    <Label
                      for="exampleEmail"
                      style={{
                        marginTop: 10,
                        fontSize: 16,
                      }}
                    >
                      {invoiceData.length ? invoiceData[0].customerName : ""}
                    </Label>
                  </Col>
                  <Col>
                    <Label
                      for="exampleEmail"
                      style={{
                        marginTop: 10,
                        fontSize: 18,
                      }}
                    >
                      ژمارەی پسوڵە:
                    </Label>

                    <Label
                      for="exampleEmail"
                      style={{
                        marginTop: 10,
                        fontSize: 16,
                      }}
                    >
                      {invoiceData.length ? invoiceData[0].invoiceNumber : ""}
                    </Label>
                  </Col>
                  <Col>
                    <Label
                      for="exampleEmail"
                      style={{
                        marginTop: 10,
                        fontSize: 18,
                      }}
                    >
                      ژمارەی پسوڵەی دەستی:
                    </Label>

                    <Label
                      for="exampleEmail"
                      style={{
                        marginTop: 10,
                        fontSize: 16,
                      }}
                    >
                      {invoiceData.length
                        ? invoiceData[0].manualInvoiceNumber
                        : ""}
                    </Label>
                  </Col>
                  <Col>
                    <Label
                      for="exampleEmail"
                      style={{
                        marginTop: 10,
                        fontSize: 18,
                      }}
                    >
                      بەروار:
                    </Label>

                    <Label
                      for="exampleEmail"
                      style={{
                        marginTop: 10,
                        fontSize: 16,
                      }}
                    >
                      <Moment format="YYYY/MM/DD">
                        {invoiceData.length ? invoiceData[0].date : ""}
                      </Moment>
                    </Label>
                  </Col>
                </Row>
                <hr />
                <div className="invoice-sell">
                  <table className="invoice-table-main">
                    <thead className="thead-light text-center">
                      <tr>
                        <th
                          scope="col"
                          style={{ fontSize: 14, fontWeight: "bold" }}
                        >
                          #
                        </th>
                        <th
                          scope="col"
                          style={{ fontSize: 14, fontWeight: "bold" }}
                        >
                          Items
                        </th>
                        <th
                          scope="col"
                          style={{ fontSize: 14, fontWeight: "bold" }}
                        >
                          Quantity
                        </th>
                        <th
                          scope="col"
                          style={{ fontSize: 14, fontWeight: "bold" }}
                        >
                          price
                        </th>

                        <th
                          scope="col"
                          style={{ fontSize: 14, fontWeight: "bold" }}
                          colSpan={2}
                        >
                          Expiry
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-center">
                      {invoiceData.map(function (i, ind) {
                        return (
                          <tr>
                            <th scope="row">{ind + 1}</th>
                            <td style={{ fontSize: 14, fontWeight: "bold" }}>
                              {i.nameKrd}
                            </td>
                            <td style={{ fontSize: 14, fontWeight: "bold" }}>
                              {i.quantity}
                            </td>

                            <td style={{ fontSize: 14, fontWeight: "bold" }}>
                              <NumberFormat
                                value={i.priceIQD}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"IQD "}
                              />
                            </td>

                            <td style={{ fontSize: 14, fontWeight: "bold" }}>
                              <Moment format="DD-MM-YYYY">{i.expiry}</Moment>
                            </td>
                            <td className="text-right">
                              <div> </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 10,
                    }}
                  >
                    <Button
                      size="sm"
                      color="info"
                      onClick={() => onChangeOrReturnItemHandler()}
                      disabled={type === "0" ? true : false}
                    >
                      {type === "change" ? "گۆڕین" : "گەڕانەوە"}
                    </Button>
                  </div>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        isOpen={showItemeModal}
        toggle={itemToggle}
        size="lg"
        style={{ maxWidth: "1200px", width: "100%", dir: "rtl" }}
      >
        <ModalHeader toggle={itemToggle}>
          {type === "change" ? "گۆڕین" : "گەڕانەوە"}
        </ModalHeader>
        <ModalBody>
          <Col className="order-xl-1">
            <Card className="shadow" style={{ backgroundColor: "#F0F8FF" }}>
              <CardBody>
                <Form autoComplete="off" id="ff">
                  {/* <h6 className="heading-small text-muted mb-2">Item</h6> */}
                  <div className="pl-lg-2">
                    <FormGroup row dir="rtl">
                      <Label> جۆری ئایتم بۆ گەڕانەوە :</Label>
                      <Col lg="3">
                        <Input
                          className="form-control-alternative"
                          value={itemToReturn}
                          id="itemToReturn"
                          placeholder="itemToReturn"
                          type="select"
                          onChange={(e) => setItemToReturn(e.target.value)}
                        >
                          <option id="-1" value="-1">
                            ----- جۆر -----
                          </option>
                          {ItemsToReturnRender()}
                        </Input>
                      </Col>
                      <Label> جۆری گەڕاوە :</Label>
                      <Col lg="3">
                        <Input
                          className="form-control-alternative"
                          value={itemTypetoReturn}
                          id="itemTypetoReturn"
                          placeholder="itemTypetoReturn"
                          type="select"
                          onChange={(e) => setItemTypetoReturn(e.target.value)}
                        >
                          <option id="box" value="box">
                            قتوو
                          </option>
                          <option id="carton" value="carton">
                            کارتۆن
                          </option>
                        </Input>
                      </Col>

                      <Label> بری گەڕانەوە :</Label>
                      <Col lg="2">
                        <Input
                          className="form-control-alternative"
                          value={quantityToReturn}
                          id="quantityToReturn"
                          placeholder="quantity To Return"
                          type="text"
                          onChange={(e) => setquantityToReturn(e.target.value)}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row dir="rtl">
                      <Label> تێبینی:</Label>
                      <Col lg="4">
                        <Input
                          className="form-control-alternative"
                          value={noteToChange}
                          id="noteToChange"
                          placeholder="Note"
                          type="textarea"
                          onChange={(e) => setNoteToChange(e.target.value)}
                        />
                      </Col>
                    </FormGroup>
                    <hr />
                    {type === "change" ? (
                      <>
                        <FormGroup row dir="rtl">
                          <Label> جۆری ئایتم بۆ گۆڕین :</Label>
                          <Col lg="2">
                            <Input
                              className="form-control-alternative"
                              value={itemToChange}
                              id="itemToChange"
                              placeholder="itemToChange"
                              type="select"
                              onChange={(e) => onItemChangeHandler(e)}
                            >
                              <option id="-1" value="-1">
                                ----- جۆر -----
                              </option>
                              {ItemsRender()}
                            </Input>
                          </Col>
                          <Label> ئێکسپایەر:</Label>
                          <Col lg="2">
                            <Input
                              className="form-control-alternative"
                              value={itemExpiryToChangeIndex}
                              id="itemExpiryToChangeIndex"
                              placeholder="itemExpiryToChangeIndex"
                              type="select"
                              onChange={(e) =>
                                setItemExpiryToChangeIndex(e.target.value)
                              }
                            >
                              <option id="-1" value="-1">
                                ----- ئێکسپایەر -----
                              </option>
                              {StockRender()}
                            </Input>
                          </Col>
                          <Label> جۆری گۆڕین :</Label>
                          <Col lg="2">
                            <Input
                              className="form-control-alternative"
                              value={itemTypetoChange}
                              id="itemTypetoChange"
                              placeholder="itemTypetoChange"
                              type="select"
                              onChange={(e) =>
                                setItemTypetoChange(e.target.value)
                              }
                            >
                              <option id="box" value="box">
                                قتوو
                              </option>
                              <option id="carton" value="carton">
                                کارتۆن
                              </option>
                            </Input>
                          </Col>

                          <Label> بری گۆڕین :</Label>
                          <Col lg="2">
                            <Input
                              className="form-control-alternative"
                              value={quantityToChange}
                              id="quantityToChange"
                              placeholder="quantity To Return"
                              type="text"
                              onChange={(e) =>
                                setquantityToChange(e.target.value)
                              }
                            />
                          </Col>
                        </FormGroup>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => onSaveItemHandler()}>
            {type === "change" ? "گۆڕین" : "گەڕانەوە"}
          </Button>{" "}
          <Button color="secondary" onClick={itemToggle}>
            پەشیمانبونەوە
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
