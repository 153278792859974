// const loggedReducer = (state = false, action) => {
//   switch (action.type) {
//     case "SIGN_IN":
//       return !state;
//     default:
//       return state;
//   }
// };

// export default loggedReducer;
const currentUser = (state = {}, action) => {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        user: action.payload,
        loggedIn: true,
      };
    case "LOG_OUT":
      return {
        ...state,
        user: {},
        loggedIn: false,
      };
    default:
      return state;
  }
};

export default currentUser;
