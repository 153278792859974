/* eslint-disable jsx-a11y/heading-has-content */
import GeneralHeader from "components/Headers/GeneralHeader";
import React, { Component } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import {
  Button,
  Card,
  CardHeader,
  CardFooter,
  CardBody,
  Form,
  Table,
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
} from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

const path = require("../../externals/path.json").path;
export class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      doctorlist: [],
      cityList: [],
      zoneList: [],
      neighbourList: [],
      hospitaldoctorslist: [],
      doctorId: null,
      name: null,
      type: null,
      cityId: null,
      zoneId: null,
      neighbourId: null,
      address: null,
      email: null,
      phone: null,
      pharmacy: null,
      support: null,
      class: null,
      status: null,
      username: this.props.user.id,
      showAddModal: false,
      showHospitalDoctorModal: false,
      isUpdate: false,
      searchName: null,
      searchCity: null,
      searchZone: null,
      searchStatus: "Active",
    };
    this.onBindDoctorsHandler();
    this.onBindCityHandler();
  }

  onBindDoctorsHandler() {
    axios
      .post(
        path + "/doctors/list",
        { role: this.props.user.role, zones: this.props.user.zones },
        {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        }
      )
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          this.setState({
            doctorlist: result.data.data,
          });
        }
      });
  }

  onBindSearchData(e) {
    const { id, value } = e.target;
    if (value !== "---City---" || value !== "---Zone---")
      this.setState({
        [id]: value,
      });
    else {
      this.setState({
        [id]: null,
      });
    }

    let searchInputList;
    if (id === "searchName")
      searchInputList = {
        name: e.target.value,
        cityId: this.state.searchCity,
        zoneId: this.state.searchZone,
        searchStatus: this.state.searchStatus,
      };
    if (id === "searchStatus")
      searchInputList = {
        name: this.state.searchName,
        cityId: this.state.searchCity,
        zoneId: this.state.searchZone,
        neighbourId: this.state.searchNeighbour,
        searchStatus: e.target.value,
      };
    if (id === "searchCity") {
      let val = value;
      if (value === "---City---") val = null;
      searchInputList = {
        name: this.state.searchName,
        cityId: val,
        zoneId: null,
        searchStatus: this.state.searchStatus,
      };
      this.onBindZoneDataHandler(val);
    }
    if (id === "searchZone") {
      let val = value;
      if (value === "---Zone---") val = null;
      searchInputList = {
        name: this.state.searchName,
        cityId: null,
        zoneId: val,
        searchStatus: this.state.searchStatus,
      };
    }

    axios
      .post(path + "/doctors/search", searchInputList, {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          this.setState({
            doctorlist: result.data.data,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "warning",
          text: `there is some error while searching data please contact administrator`,
          icon: "warning",
        });
      });
  }

  onBindCityHandler() {
    axios
      .get(path + "/cities", {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.data !== null)
          this.setState({
            cityList: result.data.data,
          });
      });
  }

  onBindZoneDataHandler(cityId) {
    axios
      .get(path + "/zone/" + Number(cityId), {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.data !== null)
          this.setState({
            zoneList: result.data.data,
          });
        else
          this.setState({
            zone: null,
            neighbour: null,
            zoneList: [],
            neighbourList: [],
          });
      });
  }
  onBindNeighboursDataHandler(zoneId) {
    axios
      .get(path + "/neighbours/" + Number(zoneId), {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.data !== null)
          this.setState({
            neighbourList: result.data.data,
          });
        else
          this.setState({
            neighbour: null,
            neighbourList: [],
          });
      });
  }

  cityItemsRender() {
    let items = [];
    this.state.cityList.map((item) =>
      items.push(
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      )
    );
    return items;
  }
  zoneItemsRender() {
    let items = [];
    this.state.zoneList.map((item) =>
      items.push(
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      )
    );
    return items;
  }
  neighbourItemsRender() {
    let items = [];
    this.state.neighbourList.map((item) =>
      items.push(
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      )
    );
    return items;
  }
  onChangeHandler = (event) => {
    // if (event.target.id === "offerType") {
    //   if (event.target.value === "cash")
    //     this.setState({
    //       offer: null,
    //     });
    //   else
    //     this.setState({
    //       discount: null,
    //     });
    // }
    const key = event.target.id;
    const value = event.target.value;
    this.setState({
      [key]: value,
    });
  };

  toggle = () => {
    this.setState({
      showAddModal: !this.state.showAddModal,
    });
  };

  onAddNewHandler = () => {
    this.setState({
      doctorId: null,
      name: null,
      type: null,
      cityId: null,
      zoneId: null,
      neighbourId: null,
      address: null,
      email: null,
      phone: null,
      pharmacy: null,
      support: null,
      class: null,
      status: 1,
      username: this.props.user.id,
      showAddModal: true,
      isUpdate: false,
    });
    this.onBindCityHandler();
  };
  onChangeCityHandler = (value) => {
    this.setState({
      cityId: value,
    });

    this.onBindZoneDataHandler(value);
  };
  onChangeZoneHandler = (value) => {
    this.setState({
      zoneId: value,
    });
    this.onBindNeighboursDataHandler(value);
  };

  onSaveDataHandler = (e) => {
    e.preventDefault();

    const doctor = {
      name: this.state.name,
      type: this.state.type,
      cityId: Number(this.state.cityId),
      zoneId: Number(this.state.zoneId),
      neighbourId: Number(this.state.neighbourId),
      address: this.state.address,
      email: this.state.email,
      phone: this.state.phone,
      pharmacy: this.state.pharmacy,
      support: this.state.support,
      class: this.state.class,
      status: this.state.status,
      username: this.state.username,
    };

    if (this.state.isUpdate === true) {
      axios
        .put(path + "/doctors/" + this.state.doctorId, doctor, {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        })
        .then((result) => {
          if (result.data.success === true) {
            this.onBindDoctorsHandler();

            this.setState({
              showAddModal: false,
            });

            Swal.fire({
              title: "THANKS",
              text: "the doctor updated",
              icon: "success",
            });
          }
          // if (result.data.token !== null)
          //   this.props.user.user.token = result.data.token;
        })
        .catch((error) => {
          Swal.fire({
            title: "warning",
            text: "doctor did not updated please contact administrator",
            icon: "warning",
          });
        });
    } else {
      axios
        .post(path + "/doctors", doctor, {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        })
        .then((result) => {
          if (result.data.success === true) {
            this.setState({
              showAddModal: false,
            });

            this.onBindDoctorsHandler();
            Swal.fire({
              title: "THANKS",
              text: "new doctor Added",
              icon: "success",
            });
          }
          // if (result.data.token !== null)
          //   this.props.user.user.token = result.data.token;
        })
        .catch((error) => {
          Swal.fire({
            title: "warning",
            text: "doctor did not added please contact administrator",
            icon: "warning",
          });
        });
    }
  };

  EditDataHandler = (docId, index) => {
    axios
      .get(path + "/doctors/" + docId, {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          this.onChangeCityHandler(result.data.data.cityId.toString());
          this.onChangeZoneHandler(result.data.data.zoneId.toString());
          this.setState({
            doctorId: result.data.data.id,
            name: result.data.data.name,
            type: result.data.data.type,
            cityId: result.data.data.cityId,
            zoneId: result.data.data.zoneId,
            neighbourId: result.data.data.neighbourId,
            address: result.data.data.address,
            email: result.data.data.email,
            phone: result.data.data.phone,
            pharmacy: result.data.data.pharmacy,
            support: result.data.data.support,
            class: result.data.data.class,
            status: result.data.data.status,
            username: result.data.data.username,
            isUpdate: true,
            showAddModal: true,
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "warning",
          text: `Can not get doctor data with id ${index} `,
          icon: "warning",
        });
      });
  };

  onDeleteHandler = (id, doctorName) => {
    Swal.fire({
      title: 'Do you want to delete doctor  "' + doctorName + '" ?',
      showCancelButton: true,
      confirmButtonText: "DELETE",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.value) {
        axios
          .delete(path + "/doctors/" + id, {
            // headers: {
            //   "auth-token": this.props.user.user.token,
            // },
          })
          .then((result) => {
            if (result.data.statusCode === 200) {
              this.onBindDoctorsHandler();
              Swal.fire("doctor Deleted!", "", "success");
            }
            // if (result.data.token !== null)
            //   this.props.user.user.token = result.data.token;
          })
          .catch((error) => {
            Swal.fire({
              title: "warning",
              text: "the doctor did not deleted please contact administrator",
              icon: "warning",
            });
          });
      }
    });
  };

  onActiveDoctorHandler = (id, doctorName) => {
    Swal.fire({
      title: 'Do you want to Active doctor  "' + doctorName + '" ?',
      showCancelButton: true,
      confirmButtonText: "Active",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.value) {
        axios
          .put(path + "/activedoctors/" + id, {
            // headers: {
            //   "auth-token": this.props.user.user.token,
            // },
          })
          .then((result) => {
            if (result.data.statusCode === 200) {
              this.onBindDoctorsHandler();
              Swal.fire("doctor Actived!", "", "success");
            }
            // if (result.data.token !== null)
            //   this.props.user.user.token = result.data.token;
          })
          .catch((error) => {
            Swal.fire({
              title: "warning",
              text: "the doctor did not deleted please contact administrator",
              icon: "warning",
            });
          });
      }
    });
  };

  HospitalDoctortoggle = () => {
    this.setState({
      showHospitalDoctorModal: !this.state.showHospitalDoctorModal,
    });
  };

  onBindHospitalDoctorssHandler(doctorId) {
    axios
      .get(
        path + "/getDoctorsHospitalList/" + doctorId,

        {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        }
      )
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          this.setState({
            hospitaldoctorslist: result.data.data,
          });
        }
      });
  }
  render() {
    let _this = this;
    return (
      <>
        <GeneralHeader />

        <Container className="mt--7" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <Col xs="6">
                      <Input
                        className="form-control-alternative"
                        value={this.state.searchName}
                        id="searchName"
                        placeholder="Customer Name"
                        type="text"
                        onChange={(e) => {
                          this.onBindSearchData(e);
                        }}
                      />
                    </Col>
                    <Col>
                      <Input
                        className="form-control-alternative"
                        value={this.state.searchStatus}
                        id="searchStatus"
                        placeholder="searchStatus"
                        type="select"
                        onChange={(e) => this.onBindSearchData(e)}
                      >
                        <option value="Active">Active</option>
                        <option value="Archive">Archive</option>
                      </Input>
                    </Col>
                    <Col className="text-right" xs="2">
                      <Button
                        color="primary"
                        onClick={(e) => this.onAddNewHandler()}
                        size="md"
                      >
                        New Doctor
                      </Button>
                    </Col>
                  </Row>
                  <br />
                  <Row className="align-items-center">
                    <Col>
                      <Input
                        className="form-control-alternative"
                        value={this.state.searchCity}
                        id="searchCity"
                        placeholder="searchCity"
                        type="select"
                        onChange={(e) => this.onBindSearchData(e)}
                      >
                        <option value="">---City---</option>
                        {this.cityItemsRender()}
                      </Input>
                    </Col>
                    <Col>
                      <Input
                        className="form-control-alternative"
                        value={this.state.searchZone}
                        id="searchZone"
                        placeholder="searchZone"
                        type="select"
                        onChange={(e) => this.onBindSearchData(e)}
                      >
                        <option value="">---Zone---</option>
                        {this.zoneItemsRender()}
                      </Input>
                    </Col>
                  </Row>
                </CardHeader>
                <div style={{ height: "76vh", overflow: "scroll" }}>
                  <Table
                    className=" align-items-center table-flush"
                    style={{ fontWeight: "bold", color: "black" }}
                  >
                    <thead className="thead-light">
                      <tr>
                        <th
                          scope="col"
                          style={{
                            fontSize: 15,
                          }}
                        >
                          #
                        </th>
                        <th
                          scope="col"
                          style={{
                            fontSize: 15,
                          }}
                        >
                          NAME
                        </th>
                        <th
                          scope="col"
                          style={{
                            fontSize: 15,
                          }}
                        >
                          Type
                        </th>
                        <th
                          scope="col"
                          style={{
                            fontSize: 15,
                          }}
                        >
                          Class
                        </th>
                        <th
                          scope="col"
                          style={{
                            fontSize: 15,
                          }}
                        >
                          Support
                        </th>
                        <th
                          scope="col"
                          style={{
                            fontSize: 15,
                          }}
                        >
                          City
                        </th>
                        <th
                          scope="col"
                          style={{
                            fontSize: 15,
                          }}
                        >
                          Zone
                        </th>
                        <th
                          scope="col"
                          style={{
                            fontSize: 15,
                          }}
                        >
                          Neighbour
                        </th>
                        <th
                          scope="col"
                          style={{
                            fontSize: 15,
                          }}
                        >
                          Address
                        </th>
                        <th
                          scope="col"
                          style={{
                            fontSize: 15,
                          }}
                        >
                          Email
                        </th>
                        <th
                          scope="col"
                          style={{
                            fontSize: 15,
                          }}
                        >
                          Phone
                        </th>
                        <th
                          scope="col"
                          style={{
                            fontSize: 15,
                          }}
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.doctorlist.map(function (item, index) {
                        return (
                          <tr>
                            <th scope="row">{index + 1}</th>
                            <td
                              style={{
                                fontSize: 15,
                              }}
                            >
                              <Link to={"/admin/visitation?did=" + item.id}>
                                {item.name}
                              </Link>
                            </td>
                            <td
                              style={{
                                fontSize: 15,
                              }}
                            >
                              {item.type}
                            </td>
                            <td
                              style={{
                                fontSize: 15,
                              }}
                            >
                              {item.class}
                            </td>
                            <td
                              style={{
                                fontSize: 15,
                              }}
                            >
                              {item.support}
                            </td>
                            <td
                              style={{
                                fontSize: 15,
                              }}
                            >
                              {item.city}
                            </td>
                            <td
                              style={{
                                fontSize: 15,
                              }}
                            >
                              {item.zone}
                            </td>
                            <td
                              style={{
                                fontSize: 15,
                              }}
                            >
                              {item.neighbour}
                            </td>
                            <td
                              style={{
                                fontSize: 15,
                              }}
                            >
                              {item.address}
                            </td>
                            <td
                              style={{
                                fontSize: 15,
                              }}
                            >
                              {item.email}
                            </td>
                            <td
                              style={{
                                fontSize: 15,
                              }}
                            >
                              {item.phone}
                            </td>
                            <td className="text-right">
                              <div>
                                {_this.props.user.role === "admin" ||
                                _this.props.user.role === "accountant" ? (
                                  <>
                                    {item.status === 0 ? (
                                      <>
                                        <Button
                                          size="sm"
                                          color="success"
                                          onClick={() =>
                                            _this.onActiveDoctorHandler(
                                              item.id,
                                              item.name
                                            )
                                          }
                                        >
                                          Active
                                        </Button>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    <Button
                                      size="sm"
                                      color="primary"
                                      onClick={() => {
                                        _this.onBindHospitalDoctorssHandler(
                                          item.id
                                        );
                                        _this.HospitalDoctortoggle();
                                      }}
                                    >
                                      H.list
                                    </Button>{" "}
                                    <Button
                                      size="sm"
                                      color="warning"
                                      onClick={() =>
                                        _this.EditDataHandler(
                                          item.id,
                                          index + 1
                                        )
                                      }
                                    >
                                      Edit
                                    </Button>
                                    <Button
                                      size="sm"
                                      color="danger"
                                      onClick={() =>
                                        _this.onDeleteHandler(
                                          item.id,
                                          item.name
                                        )
                                      }
                                    >
                                      Delete
                                    </Button>{" "}
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem className="disabled">
                        <PaginationLink
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                          tabIndex="-1"
                        >
                          <i className="fas fa-angle-left" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem className="active">
                        <PaginationLink
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          1
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
        <Modal
          isOpen={this.state.showAddModal}
          toggle={this.toggle}
          className="modal-lg"
          style={{ maxWidth: "1500px", width: "100%" }}
        >
          <ModalHeader toggle={this.toggle}>New Doctor</ModalHeader>
          <ModalBody>
            <Col className="order-xl-1">
              <Card className="shadow" style={{ backgroundColor: "#F0F8FF" }}>
                <CardBody>
                  <Form autoComplete="off" id="ff">
                    <h6 className="heading-small text-muted mb-2">
                      Doctor information
                    </h6>
                    <div className="pl-lg-2">
                      <Row>
                        <Col lg="8">
                          <FormGroup>
                            <Input
                              className="form-control-alternative"
                              value={this.state.name}
                              id="name"
                              placeholder="Doctor Name"
                              type="text"
                              onChange={this.onChangeHandler}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <Input
                              className="form-control-alternative"
                              value={this.state.type}
                              id="type"
                              placeholder="type"
                              type="select"
                              onChange={this.onChangeHandler}
                            >
                              <option>---Type---</option>
                              <option>منداڵان</option>
                              <option>گشتی</option>
                              <option>مقیم اقدم</option>
                              <option>ژنان</option>
                              <option>منداڵبوون</option>
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <Input
                              className="form-control-alternative"
                              value={this.state.phone}
                              id="phone"
                              placeholder="Phone Number"
                              type="number"
                              onChange={this.onChangeHandler}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <Input
                              className="form-control-alternative"
                              value={this.state.pharmacy}
                              id="pharmacy"
                              placeholder="pharmacy"
                              type="text"
                              onChange={this.onChangeHandler}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <Input
                              className="form-control-alternative"
                              value={this.state.email}
                              id="email"
                              placeholder="Email"
                              type="email"
                              onChange={this.onChangeHandler}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <Input
                              className="form-control-alternative"
                              value={this.state.cityId}
                              id="cityId"
                              placeholder="city"
                              type="select"
                              onChange={(e) =>
                                this.onChangeCityHandler(e.target.value)
                              }
                            >
                              <option>---City---</option>
                              {this.cityItemsRender()}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <Input
                              className="form-control-alternative"
                              value={this.state.zoneId}
                              id="zoneId"
                              placeholder="zone"
                              type="select"
                              onChange={(e) =>
                                this.onChangeZoneHandler(e.target.value)
                              }
                            >
                              <option>---Zone---</option>
                              {this.zoneItemsRender()}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <Input
                              className="form-control-alternative"
                              value={this.state.neighbourId}
                              id="neighbourId"
                              placeholder="neighbour"
                              type="select"
                              onChange={this.onChangeHandler}
                            >
                              <option>---neighbour---</option>
                              {this.neighbourItemsRender()}
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <Input
                              className="form-control-alternative"
                              value={this.state.address}
                              id="address"
                              placeholder="address"
                              type="text"
                              onChange={this.onChangeHandler}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <Input
                              className="form-control-alternative"
                              value={this.state.class}
                              id="class"
                              placeholder="class"
                              type="select"
                              onChange={this.onChangeHandler}
                            >
                              <option>---Class---</option>
                              <option>A</option>
                              <option>B</option>
                              <option>C</option>
                              <option>D</option>
                              <option>E</option>
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <Input
                              className="form-control-alternative"
                              value={this.state.support}
                              id="support"
                              placeholder="support"
                              type="select"
                              onChange={this.onChangeHandler}
                            >
                              <option>---Support---</option>
                              <option>A</option>
                              <option>B</option>
                              <option>C</option>
                              <option>D</option>
                              <option>E</option>
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={(e) => this.onSaveDataHandler(e)}>
              Save
            </Button>{" "}
            <Button color="secondary" onClick={this.toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.showHospitalDoctorModal}
          toggle={this.HospitalDoctortoggle}
          className="modal-lg"
        >
          <ModalHeader toggle={this.HospitalDoctortoggle}>
            Hospital ({this.state.hospitalName})
          </ModalHeader>
          <ModalBody>
            <Col className="order-xl-1">
              <Card className="shadow" style={{ backgroundColor: "#F0F8FF" }}>
                <CardBody>
                  <Form autoComplete="off" id="ff">
                    <div className="pl-lg-2">
                      <Row>
                        <Table
                          bordered
                          responsive
                          style={{ fontWeight: "bold", color: "black" }}
                        >
                          <thead className="thead-light">
                            <tr>
                              <th
                                scope="col"
                                style={{
                                  fontSize: 15,
                                }}
                                className="text-center"
                              >
                                #
                              </th>
                              {/* <th scope="col"  style={{
                            fontSize: 15,
                          }} className="text-center">
                                Hospital
                              </th> */}
                              <th
                                scope="col"
                                style={{
                                  fontSize: 15,
                                }}
                                className="text-center"
                              >
                                Hospital Name
                              </th>{" "}
                              {/* <th
                                scope="col"
                                style={{
                                  fontSize: 15,
                                }}
                                className="text-center"
                              >
                                Actions
                              </th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.hospitaldoctorslist.map(function (
                              item,
                              index
                            ) {
                              return (
                                <tr>
                                  <th
                                    scope="row"
                                    style={{
                                      fontSize: 15,
                                    }}
                                  >
                                    {index + 1}
                                  </th>
                                  {/* <td className="text-center">
                                    {item.hospitalName}
                                  </td> */}
                                  <td
                                    className="text-center"
                                    style={{
                                      fontSize: 15,
                                    }}
                                  >
                                    {item.hospitalName}
                                  </td>
                                  <td
                                    className="text-center"
                                    style={{
                                      fontSize: 15,
                                    }}
                                  >
                                    {/* <div>
                                      <Button
                                        size="sm"
                                        color="danger"
                                        onClick={(e) =>
                                          _this.onDeleteHispitalDoctorHandler(
                                            item.id,
                                            item.hospitalName
                                          )
                                        }
                                      >
                                        Delete
                                      </Button>
                                    </div> */}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </ModalBody>
          <ModalFooter>
            {/* <Button
              color="primary"
              onClick={(e) => this.onSaveHospitalDoctorHandler(e)}
            >
              Save
            </Button>{" "} */}
            <Button color="secondary" onClick={this.HospitalDoctortoggle}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
const userState = (state) => ({
  user: state.isLogged.user,
});

export default connect(userState, null)(Index);
