import React, { Component } from "react";
import Moment from "react-moment";
import axios from "axios";
import NumberFormat from "react-number-format";

const path = require("../../externals/path.json").path;
export default class SellInvoice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invoiceData: [],
      total: 0,
      rate: 0,
      username: null,
    };
    this.onBindInvoiceData();
  }

  onBindInvoiceData() {
    axios
      .get(path + "/sell/printinvoice/" + this.props.invoiceNo, {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          let sum = 0;
          result.data.data.map((item) => {
            sum += item.priceIQD * item.quantity;
          });
          if (this.props.currency === "dolar")
            sum = sum / result.data.data[0].rate;
          this.setState({
            invoiceData: result.data.data,
            total: sum,
            rate: result.data.data[0].rate,
            username: result.data.data[0].username,
          });
        }
      });
  }

  render() {
    const { invoiceData, total, rate, username } = this.state;
    return (
      <>
        <div className="invoice-box" dir="rtl">
          <table>
            <tbody>
              <tr className="top">
                <td colSpan={7}>
                  <table>
                    <tbody>
                      <tr>
                        <td className="title" style={{ textAlign: "right" }}>
                          <img
                            src={
                              require("../../assets/img/brand/logo-english.png")
                                .default
                            }
                            alt="Company logo"
                            style={{ width: "100%", maxWidth: 200 }}
                          />
                        </td>
                        <td
                          style={{ fontSize: 25, textAlign: "center" }}
                          dir="ltr"
                        >
                          <div>0770 938 8785</div>
                          <br />
                          <div> 0771 697 4440</div>
                        </td>
                        <td className="title" style={{ textAlign: "left" }}>
                          <img
                            src={
                              require("../../assets/img/brand/logo-english.png")
                                .default
                            }
                            alt="Company logo"
                            style={{ width: "100%", maxWidth: 200 }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <br />
                        <td style={{ fontSize: 25, textAlign: "center" }}>
                          {this.props.language === "kurdish" ? (
                            <div> بریکاری سەرەکی براندی بالاکتانی ئیسپانی</div>
                          ) : (
                            <div> وکیل رئیسی براند بلاکتان اسبانی</div>
                          )}
                          <br />
                          {this.props.language === "kurdish" ? (
                            <div>
                              سلێمانی - شەقامی ١٠٠ مەتری - ابراهیم احمەد - بینای
                              ژمارە 104
                            </div>
                          ) : (
                            <div>
                              سلیمانیة - شارع ١٠٠ م - ابراهیم احمەد - بناء رقم
                              104
                            </div>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr className="information">
                <td colSpan={7}>
                  <table>
                    <tbody>
                      <tr style={{ fontSize: 22 }}>
                        <td>
                          <div>
                            {" "}
                            {this.props.language === "kurdish" ? (
                              <span>بەڕێز / </span>
                            ) : (
                              <span>سید / </span>
                            )}
                            {invoiceData.length
                              ? invoiceData[0].customerName
                              : ""}
                          </div>
                          <br />
                          <div>
                            {this.props.language === "kurdish" ? (
                              <span>بەروار : </span>
                            ) : (
                              <span>تاریخ : </span>
                            )}
                            <Moment format="DD-MM-YYYY">
                              {invoiceData.length ? invoiceData[0].date : ""}
                            </Moment>
                          </div>
                        </td>
                        <td>
                          <div>
                            Invoice No :{" "}
                            {invoiceData.length
                              ? invoiceData[0].invoiceNumber
                              : ""}
                          </div>
                          <br />
                          <div>
                            Mob :{" "}
                            {invoiceData.length ? invoiceData[0].phone : ""}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              {/* <tr className="heading">
                  <td>Payment Method</td>
                  <td>Check #</td>
                </tr>
                <tr className="details">
                  <td>Check</td>
                  <td>1000</td>
                </tr> */}
              <tr className="heading" style={{ textAlign: "center" }}>
                <td style={{ textAlign: "center", width: "30px" }}>#</td>
                {this.props.language === "kurdish" ? (
                  <td style={{ textAlign: "center" }}>جۆر</td>
                ) : (
                  <td style={{ textAlign: "center" }}>مادە</td>
                )}
                {this.props.language === "kurdish" ? (
                  <td style={{ textAlign: "center" }}>ژمارە</td>
                ) : (
                  <td style={{ textAlign: "center" }}>عدد</td>
                )}{" "}
                {this.props.language === "kurdish" ? (
                  <td style={{ textAlign: "center" }}>نرخ</td>
                ) : (
                  <td style={{ textAlign: "center" }}>سعر</td>
                )}{" "}
                {this.props.language === "kurdish" ? (
                  <td style={{ textAlign: "center" }}>کۆ</td>
                ) : (
                  <td style={{ textAlign: "center" }}>مجموع</td>
                )}
                {this.props.language === "kurdish" ? (
                  <td style={{ textAlign: "center" }}>تێبینی</td>
                ) : (
                  <td style={{ textAlign: "center" }}>ملاحضە</td>
                )}
              </tr>
              {invoiceData.map((item, index) => {
                return (
                  <tr className="item">
                    <td style={{ textAlign: "center" }}>{index + 1}</td>
                    {this.props.language === "kurdish" ? (
                      <td style={{ textAlign: "center" }}>{item.nameKrd}</td>
                    ) : (
                      <td style={{ textAlign: "center" }}>{item.nameAr}</td>
                    )}
                    <td style={{ textAlign: "center" }}>{item.quantity}</td>
                    {this.props.currency === "dolar" ? (
                      <td style={{ textAlign: "center" }}>
                        <NumberFormat
                          value={(item.priceIQD / item.rate).toFixed(0)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$ "}
                        />
                      </td>
                    ) : (
                      <td style={{ textAlign: "center" }}>
                        <NumberFormat
                          value={item.priceIQD.toFixed(0)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"IQD "}
                        />
                      </td>
                    )}
                    {this.props.currency === "dolar" ? (
                      <td style={{ textAlign: "center" }}>
                        <NumberFormat
                          value={(
                            (item.priceIQD * item.quantity) /
                            item.rate
                          ).toFixed(0)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$ "}
                        />
                      </td>
                    ) : (
                      <td style={{ textAlign: "center" }}>
                        <NumberFormat
                          value={(item.priceIQD * item.quantity).toFixed(0)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"IQD "}
                        />
                      </td>
                    )}
                    {index === 0 ? (
                      <td
                        style={{ textAlign: "center" }}
                        rowSpan={invoiceData.length}
                      >
                        {item.note}
                      </td>
                    ) : (
                      ""
                    )}
                  </tr>
                );
              })}
              <tr className="total">
                {this.props.language === "kurdish" ? (
                  <td colSpan={2}>کۆی گشتی</td>
                ) : (
                  <td colSpan={2}>مجموع</td>
                )}
                <td colSpan={5} style={{ textAlign: "right" }}>
                  {this.props.currency === "dolar" ? (
                    <NumberFormat
                      value={(total / rate).toFixed(0)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$ "}
                    />
                  ) : (
                    <NumberFormat
                      value={total.toFixed()}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"IQD "}
                    />
                  )}
                </td>
              </tr>
              <br />
              <br /> <br />
              <br />
              <tr className="seller">
                <td colSpan={1}></td>
                {this.props.language === "kurdish" ? (
                  <td colSpan={4}> فروۆشیار</td>
                ) : (
                  <td colSpan={4}> مندوب</td>
                )}
              </tr>
              <tr className="seller">
                <td colSpan={1}></td>
                <td colSpan={4}> {username}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    );
  }
}
