import React, { useState, useEffect, useRef } from "react";
import ReactToPrint from "react-to-print";
import axios from "axios";
import NumberFormat from "react-number-format";
import Moment from "react-moment";

import { Button, Table, Row, Col, Input, FormGroup, Label } from "reactstrap";

const path = require("../../../externals/path.json").path;

export default function SellPaidByMandwb() {
  let invoiceRef = useRef();

  const [reportData, setReportData] = useState([]);
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [userName, setUserName] = useState();
  const [usersList, setUsersList] = useState([]);

  const [totalPaid, settotalPaid] = useState(0);

  function onBindUsersData() {
    axios
      .get(path + "/users", {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setUsersList(result.data.data);
        }
      });
  }

  useEffect(() => {
    onBindUsersData();
  }, []);

  function onBindtableData() {
    let index = usersList.findIndex(
      (element) => element.id === Number(userName)
    );
    axios
      .post(
        path + "/sellreports/getSellPaidByMandwb",
        {
          userName: index !== -1 ? usersList[index].name : null,

          from: from,
          to: to,
          role: index !== -1 ? usersList[index].role : null,
        },
        {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        }
      )
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          let total = 0;
          result.data.data.forEach((element) => {
            total = total + element.paid;
          });
          settotalPaid(total);
          setReportData(result.data.data);
        }
      });
  }

  function usersItemsRender() {
    let items = [];
    usersList.map((item) =>
      items.push(
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      )
    );
    return items;
  }

  return (
    <>
      <Row>
        <Col>
          <Input
            className="form-control-alternative"
            value={userName}
            id="userName"
            placeholder="userName"
            type="select"
            onChange={(e) => setUserName(e.target.value)}
            style={{ fontSize: 16, color: "black", height: 45 }}
          >
            <option value={0}>---User---</option>
            {usersItemsRender()}
          </Input>
        </Col>
        <Col>
          <Input
            className="form-control-alternative"
            value={from}
            id="from"
            placeholder="from"
            type="date"
            onChange={(e) => setFrom(e.target.value)}
            style={{ fontSize: 16, color: "black", height: 45 }}
          />
        </Col>
        <Col>
          <Input
            className="form-control-alternative"
            value={to}
            id="to"
            placeholder="to"
            type="date"
            onChange={(e) => setTo(e.target.value)}
            style={{ fontSize: 16, color: "black", height: 45 }}
          />
        </Col>
        <Col className="text-right">
          <Button color="primary" onClick={(e) => onBindtableData()} size="md">
            Get
          </Button>
          <ReactToPrint
            trigger={() => <Button color="success">print</Button>}
            content={() => invoiceRef}
          />
        </Col>
      </Row>
      <br />
      {/* <Row>
        <Col>
          <Input
            className="form-control-alternative"
            value={cityId}
            id="cityId"
            placeholder="cityId"
            type="select"
            onChange={(e) => onChangeCityHandler(e)}
            style={{ fontSize: 16, color: "black", height: 45 }}
          >
            <option key={0} value={0}>
              ---city---
            </option>
            {cityItemsRender()}
          </Input>
        </Col>
        <Col>
          <Input
            className="form-control-alternative"
            value={zoneId}
            id="zoneId"
            placeholder="zoneId"
            type="select"
            onChange={(e) => setZoneId(e.target.value)}
            style={{ fontSize: 16, color: "black", height: 45 }}
          >
            <option key={0} value={0}>
              ---zone---
            </option>
            {zoneItemsRender()}
          </Input>
        </Col>
      </Row> */}

      <br />
      <div ref={(el) => (invoiceRef = el)}>
        <br />
        <Row>
          <Col>
            <FormGroup row>
              <Label
                for="exampleEmail"
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  marginTop: 10,
                }}
              >
                Total Paid (IQD)
              </Label>
              <Col
                style={{
                  marginTop: 10,
                }}
              >
                <NumberFormat
                  value={totalPaid ? totalPaid.toFixed(0) : 0}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"IQD "}
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <div>
          <Table
            className=" align-items-center table-flush"
            bordered
            style={{ fontWeight: "bold", color: "black" }}
            responsive
          >
            <thead className="thead-light">
              <tr>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  Invoice No#
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  Quantity
                </th>
                {/* <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  {" "}
                  total (IQD)
                </th> */}
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  Paid (IQD)
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  Customer Name
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  Paid By
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  PaidDate
                </th>
              </tr>
            </thead>
            <tbody>
              {reportData.map(function (item, index) {
                return (
                  <tr>
                    <th
                      scope="row"
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      {item.manualInvoiceNumber}
                    </th>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      {item.quantity}
                    </td>
                    {/* <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      <NumberFormat
                        value={item.priceIQD ? item.priceIQD.toFixed(0) : 0}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"IQD "}
                      />
                    </td> */}
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      <NumberFormat
                        value={item.paid ? item.paid.toFixed(0) : 0}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"IQD "}
                      />
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      {item.customerName}
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      {item.paidBy}
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      <Moment format="YYYY/MM/DD">{item.paidDate}</Moment>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
}
