import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import NumberFormat from "react-number-format";
import Moment from "react-moment";
import {
  Button,
  Table,
  Row,
  Col,
  Input,
  FormGroup,
  Badge,
  Label,
} from "reactstrap";
import { useSelector } from "react-redux";
const path = require("../../../externals/path.json").path;

export default function PayParnerProfit() {
  const user = useSelector((state) => state.isLogged.user);
  const [offersList, setOffersList] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [listType, setListType] = useState("all");

  const [userNameSearch, setUserNameSearch] = useState();

  function onBindPayToPartnerData() {
    axios
      .post(
        path + "/getAllPaytoPartner",
        {
          paidBack: listType,
          userName: userNameSearch,
        },
        {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        }
      )
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setOffersList(result.data.data);
        }
      });
  }

  useEffect(() => {
    onBindPayToPartnerData();
    onBindUsersData();
  }, [listType, userNameSearch]);
  useEffect(() => {
    onBindUsersData();
  }, []);

  function onBindUsersData() {
    axios
      .get(path + "/users", {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          const partners = result.data.data.filter(
            (item) => item.isPartner === "1"
          );
          setUsersList(partners);
        }
      });
  }

  function usersItemsRender() {
    let items = [];
    usersList.map((item) =>
      items.push(
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      )
    );
    return items;
  }

  function onPayToPartnerHandler(id, amount, name) {
    Swal.fire({
      title: `Do you want to pay amount '${amount}' to '${name}' ?`,
      showCancelButton: true,
      confirmButtonText: "Save",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.value) {
        axios
          .put(path + "/monthlyprofitUpdatePayStatus/" + id, {
            // headers: {
            //   "auth-token": this.props.user.user.token,
            // },
          })
          .then((result) => {
            if (result.data.statusCode === 200) {
              onBindPayToPartnerData();
              Swal.fire("Profit Paid!", "", "success");
            }
            // if (result.data.token !== null)
            //   this.props.user.user.token = result.data.token;
          })
          .catch((error) => {
            Swal.fire({
              title: "warning",
              text: "the profit did not pay please contact administrator",
              icon: "warning",
            });
          });
      }
    });
  }
  return (
    <>
      <Row className="align-items-center">
        <Col>
          <h2 className="mb-0">Offers List</h2>
        </Col>
        <Col>
          <Input
            className="form-control-alternative"
            value={userNameSearch}
            id="userName"
            placeholder="userName"
            type="select"
            onChange={(e) => {
              if (e.target.value !== "0") setUserNameSearch(e.target.value);
              else setUserNameSearch();
            }}
            style={{ fontSize: 16, color: "black", height: 45 }}
          >
            <option value={0}>---Partner Name---</option>
            {usersItemsRender()}
          </Input>
        </Col>

        <Col>
          <FormGroup row className="mb-0">
            <Label
              for="exampleEmail"
              style={{
                fontSize: 18,
                fontWeight: "bold",
              }}
            >
              List Type
            </Label>
            <Col style={{}}>
              <Input
                className="form-control-alternative"
                value={listType}
                id="offerFor"
                placeholder="offerFor"
                type="select"
                onChange={(e) => {
                  setListType(e.target.value);
                }}
              >
                <option value={"all"}>All</option>
                <option value={0}>Not Paid</option>
                <option value={1}>Paid Back</option>
              </Input>
            </Col>
          </FormGroup>
        </Col>
      </Row>
      <br />

      <div style={{ height: "76vh", overflow: "scroll" }}>
        <Table
          className=" align-items-center table-flush"
          style={{ fontWeight: "bold", color: "black" }}
        >
          <thead className="thead-light">
            <tr>
              <th
                scope="col"
                style={{
                  fontSize: 15,
                  textAlign: "center",
                }}
              >
                #
              </th>
              <th
                scope="col"
                style={{
                  fontSize: 15,
                  textAlign: "center",
                }}
              >
                Name
              </th>
              <th
                scope="col"
                style={{
                  fontSize: 15,
                  textAlign: "center",
                }}
              >
                Partner Per.
              </th>
              <th
                scope="col"
                style={{
                  fontSize: 15,
                  textAlign: "center",
                }}
              >
                Amount (IQD)
              </th>
              <th
                scope="col"
                style={{
                  fontSize: 15,
                  textAlign: "center",
                }}
              >
                Amount (DLR)
              </th>
              <th
                scope="col"
                style={{
                  fontSize: 15,
                  textAlign: "center",
                }}
              >
                Date
              </th>
              <th
                scope="col"
                style={{
                  fontSize: 15,
                  textAlign: "center",
                }}
              >
                Paid Back ?
              </th>
              <th
                scope="col"
                style={{
                  fontSize: 15,
                  textAlign: "center",
                }}
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {offersList.map(function (item, index) {
              return (
                <tr>
                  <th scope="row">{index + 1}</th>
                  <td
                    style={{
                      fontSize: 15,
                      textAlign: "center",
                    }}
                  >
                    {item.partnerName}
                  </td>
                  <td
                    style={{
                      fontSize: 15,
                      textAlign: "center",
                    }}
                  >
                    {item.partnerPersentage}
                  </td>

                  <td
                    style={{
                      fontSize: 15,
                      textAlign: "center",
                    }}
                  >
                    {" "}
                    <NumberFormat
                      value={
                        item.partnerAmountIQD
                          ? item.partnerAmountIQD.toFixed(0)
                          : 0
                      }
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"IQD "}
                    />
                  </td>
                  <td
                    style={{
                      fontSize: 15,
                      textAlign: "center",
                    }}
                  >
                    <NumberFormat
                      value={
                        item.partnerAmountDLR
                          ? item.partnerAmountDLR.toFixed(0)
                          : 0
                      }
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$ "}
                    />
                  </td>

                  <td
                    style={{
                      fontSize: 15,
                      textAlign: "center",
                    }}
                  >
                    {" "}
                    <Moment format="DD-MM-YYYY">{item.fromDate}</Moment> to{" "}
                    <Moment format="DD-MM-YYYY">{item.toDate}</Moment>
                  </td>

                  <td className="text-center">
                    {item.paidToParnter === 1 ? (
                      <Badge color="success">YES</Badge>
                    ) : (
                      <Badge color="danger">No</Badge>
                    )}
                  </td>

                  <td className="text-right">
                    <div>
                      {user.role === "admin" || user.role === "accountant" ? (
                        <>
                          <Button
                            size="sm"
                            color="warning"
                            onClick={() =>
                              onPayToPartnerHandler(
                                item.id,
                                item.partnerAmountIQD.toFixed(0),
                                item.partnerName
                              )
                            }
                            disabled={item.paidToParnter === 1 ? true : false}
                          >
                            Pay to Partner
                          </Button>{" "}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </>
  );
}
