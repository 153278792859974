import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import Swal from "sweetalert2";
import GeneralHeader from "components/Headers/GeneralHeader";
import NumberFormat from "react-number-format";
import Moment from "react-moment";
import moment from "moment";
import axios from "axios";
import { useSelector } from "react-redux";
import SellInvoice from "./SellInvoice";
import ReactToPrint from "react-to-print";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

import {
  Card,
  CardHeader,
  CardBody,
  Form,
  CardFooter,
  // Table,
  Container,
  Row,
  Col,
  // DropdownMenu,
  // DropdownItem,
  // UncontrolledDropdown,
  // DropdownToggle,
  Pagination,
  PaginationItem,
  PaginationLink,
  Button,
  // ButtonGroup,
  Modal,
  // ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  Label,
  Badge,
} from "reactstrap";

const path = require("../../externals/path.json").path;
export default function Index() {
  const rate = useSelector((state) => state.rateData.rate);
  const user = useSelector((state) => state.isLogged.user);
  const language = useSelector((state) => state.Language.language);

  const { search } = useLocation();
  const { status, cid, nid } = queryString.parse(search);
  let invoiceRef = useRef();

  const [itemList, setItemList] = useState([]);

  const [itemPriceAllList, setItemPriceAllList] = useState([]);
  const [sellList, setSellList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [customerOptionIndex, setCustomerOptionIndex] = useState();
  const [customerId, setCustomerId] = useState();
  const [customerName, setCustomerName] = useState();
  const [customerType, setCustomerType] = useState();
  const [customerDebit, setCustomerDebit] = useState(0);
  const [customerLimit, setCustomerLimit] = useState(0);
  const [customerCity, setCustomerCity] = useState();
  const [customerZone, setCustomerZone] = useState();
  const [inventoryLocationId, setInventoryLocationId] = useState();
  const [customerAddress, setCustomerAddress] = useState();
  const [zoneId, setZoneId] = useState();
  const [cityId, setCityId] = useState();
  const [showAddModal, setshowAddModal] = useState(false);
  const [showInvoiceAddModal, setshowInvoiceAddModal] = useState(false);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [subTotalDLR, setSubTotalDLR] = useState(0);
  const [subTotalIQD, setSubTotalIQD] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [totalInDinar, setTotalInDinar] = useState(0);
  const [totalPaid, setTotalPaid] = useState(0);
  const [totalInDolar, setTotalInDolar] = useState(0);
  const [manualInvoiceNumber, setManualInvoiceNumber] = useState();
  const [invoiceNo, setInvoiceNo] = useState();
  const [note, setNote] = useState();
  const [username, setUserName] = useState(user.id);
  const [rateId, setRateId] = useState();
  const [userRepaymentType, setuserRepaymentType] = useState(
    user.repaymentType
  );

  const [date, setDate] = useState();
  const [deliveredDate, setdeliveredDate] = useState();
  const [rateValue, setRateValue] = useState();
  const [invoiceLanguage, setInvoiceLanguage] = useState();
  const [discountType, setDiscountType] = useState(null);
  const [quantityPerBox, setQuantityPerBox] = useState(null);

  const [paidStatus, setPaidStatus] = useState("0");
  const [deleteStatus, setDeleteStatus] = useState(1);

  const [invoiceCurrency, setInvoiceCurrency] = useState();

  const initialState = {
    invoiceNumber: null,
    manualInvoiceNumber: null,
    itemId: null,
    priceDLR: null,
    actualPriceDLR: 0,
    priceIQD: null,
    actualPriceIQD: 0,
    quantity: null,
    rateId: rateId,
    inventoryId: null,
    zoneId: zoneId,
    offer: null,
    paid: 0,
    note: null,
    discount: 0,
    customerId: null,
    status: "0",
    stock: 0,
    expiry: null,
    paidStatus: "0",
    username: username,
    deleteStatus: deleteStatus,
    date: new Date(),
    deliveredDate: "",
    paidInvoiceNumber: null,
    paidBy: null,
    paidDate: null,
    quantityPerBox: null,
  };
  const [PageNumber, setPageNumber] = useState(1);
  const [iterator, setiterator] = useState(1);
  const [endLink, setendLink] = useState(1);
  const [numofPages, setnumofPages] = useState(1);
  const [usersList, setUsersList] = useState([]);
  const [inputList, setInputList] = useState([initialState]);
  const [oldQuantityList, setOldQuantityList] = useState([]);
  const [sellBindData, setSellBindData] = useState({
    userId: user.id,
    leaderId: user.leaderId,
    Role: user.role,
    status: status,
    cid: cid,
    nid: nid,
    page: PageNumber,
    invoiceNumber: null,
    manualInvoiceNumber: null,
    user: null,
    invoiceStatus: null,
    from: null,
    to: null,
  });

  function onBindUsersData() {
    axios
      .get(path + "/users", {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setUsersList(result.data.data);
        }
      });
  }

  function usersItemsRender() {
    let items = [];
    usersList.map((item) =>
      items.push(
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      )
    );
    return items;
  }
  function callPagination(page) {
    setPageNumber(page);
    onBindSellData(page);
  }

  function paginationItemsRender() {
    let items = [];
    for (let i = iterator; i <= endLink; i++) {
      items.push(
        <PaginationItem>
          <PaginationLink
            // style={{
            //   background: (i = PageNumber ? "#5E72E4" : "#FFFFFF"),
            //   color: "#FFFFFF",
            // }}
            onClick={(e) => {
              callPagination(i);
            }}
          >
            {i}
          </PaginationLink>
        </PaginationItem>
      );
    }
    return items;
  }
  function onBindItemsData() {
    axios
      .get(path + "/items", {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setItemList(result.data.data);
        }
      });
  }

  function onBindSellData(pageNo) {
    axios
      .post(
        path + "/sell",
        {
          userId: sellBindData.userId,
          leaderId: sellBindData.leaderId,
          role: sellBindData.Role,
          status: sellBindData.status,
          cid: sellBindData.cid,
          nid: sellBindData.nid,
          invoiceNumber: sellBindData.invoiceNumber,
          manualInvoiceNumber: sellBindData.manualInvoiceNumber,
          user: sellBindData.user,
          invoiceStatus: sellBindData.invoiceStatus,
          from: sellBindData.from,
          to: sellBindData.to,
          page: pageNo ? pageNo : 1,
        },
        {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        }
      )
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setPageNumber(result.data.page);
          setiterator(result.data.iterator);
          setendLink(result.data.endLink);
          setnumofPages(result.data.numofPages);
          setSellList(result.data.data);
          setShowSearchModal(false);
        }
      });
  }

  function onBindCustomerData() {
    axios
      .post(
        path + "/customers/sell",
        { role: user.role, zones: user.zones, userId: user.id },
        {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        }
      )
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setCustomerList(result.data.data);
          let options = [{ value: -1, label: "----Customer List ----" }];

          result.data.data.forEach((item, index) => {
            options.push({ value: index, label: item.name });
          });
          setCustomerOptions(options);
        }
      });
  }

  function EditDataHandler(invoiceNo) {
    // let item = itemList.find((o) => o.id === item.itemId);
    axios
      .get(path + "/sell/" + invoiceNo, {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          const index = customerList.findIndex(
            (item) => item.id === result.data.data[0].customerId
          );
          let paid = 0;
          result.data.data.forEach((item) => {
            paid = paid + item.paid;
          });

          axios
            .get(path + "/rates/" + result.data.data[0].rateId, {
              // headers: {
              //   "auth-token": this.props.user.user.token,
              // },
            })
            .then(async (res) => {
              let pricelist = [];
              for (var i = 0; i < result.data.data.length; i++) {
                let priceData = await axios.get(
                  path +
                    `/items/price/true/${result.data.data[i].itemId}/${result.data.data[i].zoneId}/${customerList[index].city}/${customerList[index].inventoryLocationId}`,

                  {
                    // headers: {
                    //   "auth-token": this.props.user.user.token,
                    // },
                  }
                );
                if (priceData.data.success === true)
                  pricelist.push(priceData.data.data);
              }
              setItemPriceAllList(pricelist);
              setIsUpdate(true);
              setRateValue(res.data.data.rate);
              setRateId(res.data.data.id);
              setManualInvoiceNumber(result.data.data[0].manualInvoiceNumber);
              setInvoiceNo(result.data.data[0].invoiceNumber);
              setNote(result.data.data[0].note);
              setDeleteStatus(result.data.data[0].deleteStatus);
              setPaidStatus(result.data.data[0].paidStatus);
              setTotalPaid(paid);
              setZoneId(result.data.data[0].zoneId);
              setDate(moment(result.data.data[0].date).format("YYYY-MM-DD"));
              setdeliveredDate(
                result.data.data[0].deliveredDate
                  ? moment(result.data.data[0].deliveredDate).format(
                      "YYYY-MM-DD"
                    )
                  : null
              );
              onCustomerChangeHandler({
                value: index,
                label: customerList[index].name,
              });
              setInputList(result.data.data);
              setshowAddModal(true);
              setDiscount(result.data.data[0].discount);
              setDiscountType(result.data.data[0].discountType);
              setQuantityPerBox(result.data.data[0].quantityPerBox);
              setUserName(result.data.data[0].username);
              let oldQuantity = [];
              result.data.data.map((item) => {
                oldQuantity.push({
                  inventoryId: item.inventoryId,
                  quantity: item.quantity,
                  status: item.status,
                });
              });
              setOldQuantityList(oldQuantity);
            })
            .catch((error) => {
              Swal.fire({
                title: "warning",
                text: `there is some error while get rate with rate id ${result.data.data[0].rateId} data please contact administrator`,
                icon: "warning",
              });
            });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "warning",
          text: `there is some error while get invoice with invoice number ${invoiceNo} data please contact administrator`,
          icon: "warning",
        });
      });
  }

  function onCustomerChangeHandler(e) {
    if (e.value !== -1) {
      setCustomerOptionIndex({
        value: e.value,
        label: customerList[e.value].name,
      });

      setCustomerName(customerList[e.value].name);
      setDiscount(customerList[e.value].discount);
      setCustomerLimit(customerList[e.value].debitLimit);
      setCustomerType(customerList[e.value].type);
      setCustomerDebit(customerList[e.value].debit);
      setZoneId(customerList[e.value].zone);
      setCityId(customerList[e.value].city);
      setInventoryLocationId(customerList[e.value].inventoryLocationId);
      setCustomerId(customerList[e.value].id);
      setCustomerCity(customerList[e.value].cityName);
      setCustomerZone(customerList[e.value].zoneName);
      setCustomerAddress(customerList[e.value].address);
      setDiscountType(customerList[e.value].discountType);
    }
  }

  useEffect(() => {
    onBindItemsData();
    onBindCustomerData();
    onBindSellData();

    onBindUsersData();
  }, []);

  useEffect(() => {
    SummationHandler();
  }, [SummationHandler]);

  function ItemsRender() {
    let items = [];
    itemList.map((item) =>
      items.push(
        <option key={item.id} value={item.id}>
          {item.nameKrd}
        </option>
      )
    );
    return items;
  }
  function StockRender(i) {
    let items = [];

    if (itemPriceAllList[i])
      itemPriceAllList[i].map((item) =>
        items.push(
          <option
            key={item.inventoryId}
            value={item.inventoryId}
            style={{
              fontSize: 15,
              fontWeight: "bold",
              color: "black",
            }}
          >
            {moment(item.expiry).format("YYYY-MM-DD").toString()} ( {item.stock}{" "}
            )
          </option>
        )
      );
    return items;
  }

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, initialState]);
  };

  function toggle() {
    setshowAddModal(!showAddModal);
  }

  function invoiceToggle() {
    setshowInvoiceAddModal(!showInvoiceAddModal);
  }

  function SearchToggle() {
    setSellBindData({
      userId: user.id,
      leaderId: user.leaderId,
      Role: user.role,
      status: status,
      cid: cid,
      nid: nid,
      page: PageNumber,
      invoiceNumber: null,
      manualInvoiceNumber: null,
      user: null,
      invoiceStatus: null,
      from: null,
      to: null,
    });
    setShowSearchModal(!showSearchModal);
  }

  function onPrintHandler(number) {
    setInvoiceNo(number);
    setshowInvoiceAddModal(!showInvoiceAddModal);
    setInvoiceCurrency("dinar");
    setInvoiceLanguage("kurdish");
  }

  function onAddNewHandler() {
    setDeleteStatus(1);
    setRateValue(rate.rate);
    setRateId(rate.id);
    setshowAddModal(true);
    setIsUpdate(false);
    setCustomerId();
    setManualInvoiceNumber();
    setCustomerName();
    setCustomerType();
    setCustomerCity();
    setCustomerZone();
    setCustomerAddress();
    setZoneId();
    setDate();
    setdeliveredDate();
    setCustomerDebit(0);
    setCustomerLimit(0);
    setSubTotalDLR(0);
    setSubTotalIQD(0);
    setTotalInDinar(0);
    setTotalInDolar(0);
    setTotalPaid(0);
    setDiscount(0);
    setCustomerOptionIndex();
    setNote();
    setItemPriceAllList([]);
    setUserName(user.id);
    setuserRepaymentType(user.repaymentType);
    setInputList([initialState]);
    setOldQuantityList([]);
    setPaidStatus("0");
  }

  function onSubmitCredit(repaymentType, data) {
    if (!isUpdate) {
      axios
        .post(
          path + "/usercredit",
          {
            invoiceNumber: data.invoiceNumber,
            totalIvoiceIQD: data.totalIQD,
            creditType: repaymentType,
            userId: data.userId,
          },
          {
            // headers: {
            //   "auth-token": this.props.user.user.token,
            // },
          }
        )
        .then((result) => {
          if (result.data.success === true) {
          }
        })
        .catch((error) => {
          Swal.fire({
            title: "warning",
            text: `there is some error while delivery with invoice No ${invoiceNo}`,
            icon: "warning",
          });
        });
    } else {
      axios
        .put(
          path + "/usercreditdata",
          {
            invoiceNumber: data.invoiceNumber,
            totalIvoiceIQD: data.totalIQD,
            creditType: repaymentType,
            quantity: data.quantity,
          },
          {
            // headers: {
            //   "auth-token": this.props.user.user.token,
            // },
          }
        )
        .then((result) => {
          if (result.data.success === true) {
          }
        })
        .catch((error) => {
          Swal.fire({
            title: "warning",
            text: `there is some error while delivery with invoice No ${invoiceNo}`,
            icon: "warning",
          });
        });
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function SummationHandler() {
    let totIQD = 0;
    let totDLR = 0;
    let totalDiscountIQD = 0;
    let totalDiscountDLR = 0;
    inputList.forEach((item) => {
      let price = item.actualPriceIQD * Number(item.quantity);
      totDLR = price / rateValue;
      totIQD = totIQD + price;
      if (discountType === "Box") {
        let totalQuantityPerBox = Number(item.quantity) * item.quantityPerBox;
        let disI = totalQuantityPerBox * discount;
        totalDiscountIQD = totalDiscountIQD + disI;
        totalDiscountDLR = totalDiscountIQD / rateValue;
      } else if (discountType === "Carton") {
        let disI = Number(item.quantity) * discount;
        totalDiscountIQD = totalDiscountIQD + disI;
        totalDiscountDLR = totalDiscountIQD / rateValue;
      } else if (discountType === "Cash") {
        let disI =
          (item.actualPriceIQD * Number(item.quantity) * discount) / 100;
        totalDiscountIQD = totalDiscountIQD + disI;
        totalDiscountDLR = totalDiscountIQD / rateValue;
      }
    });

    setSubTotalIQD(totIQD);
    setSubTotalDLR(totDLR);
    // let disDLR = (totDLR * discount) / 100;
    // let disIQD = (totIQD * discount) / 100;
    if (discount !== 0) {
      setTotalInDinar(totIQD - totalDiscountIQD);
      setTotalInDolar(totDLR - totalDiscountDLR);
    } else {
      setTotalInDinar(totIQD);
      setTotalInDolar(totDLR);
    }
  }

  function discountHandler(discount) {
    let totIQD = 0;
    let totDLR = 0;
    let totalDiscountIQD = 0;
    let totalDiscountDLR = 0;
    inputList.forEach((item) => {
      let price = item.actualPriceIQD * Number(item.quantity);
      totDLR = price / rateValue;
      totIQD = totIQD + price;
      if (discountType === "Box") {
        let totalQuantityPerBox = Number(item.quantity) * item.quantityPerBox;
        let disI = totalQuantityPerBox * discount;
        totalDiscountIQD = totalDiscountIQD + disI;
        totalDiscountDLR = totalDiscountIQD / rateValue;
      } else if (discountType === "Carton") {
        let disI = Number(item.quantity) * discount;
        totalDiscountIQD = totalDiscountIQD + disI;
        totalDiscountDLR = totalDiscountIQD / rateValue;
      } else if (discountType === "Cash") {
        let disI =
          (item.actualPriceIQD * Number(item.quantity) * discount) / 100;
        totalDiscountIQD = totalDiscountIQD + disI;
        totalDiscountDLR = totalDiscountIQD / rateValue;
      }
    });

    setSubTotalIQD(totIQD);
    setSubTotalDLR(totDLR);
    // let disDLR = (totDLR * discount) / 100;
    // let disIQD = (totIQD * discount) / 100;

    if (discount !== 0) {
      setTotalInDinar(totIQD - totalDiscountIQD);
      setTotalInDolar(totDLR - totalDiscountDLR);
    } else {
      setTotalInDinar(totIQD);
      setTotalInDolar(totDLR);
    }
  }
  function onchangeDiscountHandler(e) {
    setDiscount(e.target.value);
    discountHandler(e.target.value);
  }

  function onChangeSearchInputHandler(e) {
    let data = sellBindData;
    const { id, value } = e.target;
    data[id] = value;
    setSellBindData(data);
  }

  function onItemChangeHandler(e, index) {
    const { id, value } = e.target;
    const list = [...inputList];
    list[index][id] = value;

    if (zoneId) {
      axios
        .get(
          path +
            `/items/price/${isUpdate}/${value}/${zoneId}/${cityId}/${inventoryLocationId}`,

          {
            // headers: {
            //   "auth-token": this.props.user.user.token,
            // },
          }
        )
        .then((result) => {
          // if (result.data.token !== null)
          //   this.props.user.user.token = result.data.token;

          if (result.data.success === true) {
            let itempricelistTemp = itemPriceAllList;
            itempricelistTemp[index] = result.data.data;
            setItemPriceAllList(itempricelistTemp);

            if (!isUpdate || list[index]["actualPriceIQD"] <= 0) {
              list[index]["priceDLR"] = result.data.data[0].priceDLR;
              list[index]["actualPriceDLR"] = result.data.data[0].priceDLR;
              list[index]["priceIQD"] = result.data.data[0].priceIQD;
              list[index]["actualPriceIQD"] = result.data.data[0].priceIQD;
            }
            list[index]["inventoryId"] = null;
            list[index]["stock"] = null;
            list[index]["quantityPerBox"] = null;
            list[index]["expiry"] = null;
            setInputList(list);
            SummationHandler();
          }
        })
        .catch((err) => {
          setItemPriceAllList([]);

          Swal.fire({
            title: "warning",
            text: "There is no price for this Item in this zone please entter price first or Check the stock to available Item",
            icon: "warning",
          });
        });
    } else {
      const { id, value } = e.target;
      const list = [...inputList];
      list[index][id] = 0;
      setInputList(list);
      Swal.fire({
        title: "warning",
        text: "please select Customer first",
        icon: "warning",
      });
    }
  }
  function onStockChangeHandler(e, index) {
    const { id, value } = e.target;
    const list = [...inputList];
    list[index][id] = value;
    let arrayIndex = itemPriceAllList[index].findIndex(
      (obj) => obj.inventoryId === Number(value)
    );

    list[index]["inventoryId"] =
      itemPriceAllList[index][arrayIndex].inventoryId;
    list[index]["stock"] = itemPriceAllList[index][arrayIndex].stock;
    list[index]["expiry"] = itemPriceAllList[index][arrayIndex].expiry;
    list[index]["quantityPerBox"] =
      itemPriceAllList[index][arrayIndex].quantityPerBox;

    setInputList(list);
    // SummationHandler();
  }

  const handleInputChange = (e, index) => {
    const { id, value } = e.target;
    const list = [...inputList];
    list[index][id] = value;

    setInputList(list);
    SummationHandler();
  };

  async function onsaveDataHandler() {
    let checkmanualNumberResult;
    if (
      manualInvoiceNumber !== undefined &&
      manualInvoiceNumber !== null &&
      manualInvoiceNumber !== ""
    ) {
      checkmanualNumberResult = await OnCheckManualInvoiceNumberExsists(
        manualInvoiceNumber
      );
    }
    let checkmanualNumber =
      checkmanualNumberResult !== undefined
        ? checkmanualNumberResult.data.data
        : undefined;

    if (checkmanualNumber !== true || isUpdate) {
      axios
        .get(path + "/sell/invoicenumber", {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        })
        .then((result) => {
          if (result.data.success === true) {
            let sell = [];
            let invNO;
            let paidStat;
            if (!isUpdate) {
              invNO = result.data.data.invoiceNumber + 1;
              if (totalPaid >= totalInDinar) paidStat = "1";
              else paidStat = "0";
            } else {
              invNO = invoiceNo;
              if (totalPaid < totalInDinar) paidStat = "0";
              else paidStat = paidStatus;
            }
            let isCorrect = true;
            let isCompleteData = true;
            let isMissLimitDebt = false;
            let rejectedQuantity = [];
            let AddedQuantity = [];
            let isRejecting = false;
            let isAdding = false;
            let totalQuantityForCredit = 0;

            const isDelivered = inputList.some((item) => item.status === "1");
            if (
              !isUpdate &&
              Math.abs(customerDebit + totalInDinar) > customerLimit &&
              totalPaid < totalInDinar
            )
              isMissLimitDebt = true;
            inputList.forEach((item, index) => {
              totalQuantityForCredit =
                totalQuantityForCredit + Number(item.quantity);
              let dis = (item.actualPriceIQD * discount) / 100;
              if (discountType === "Box") {
                let totalQuantityPerBox = item.quantityPerBox;
                dis = totalQuantityPerBox * discount;
              } else if (discountType === "Carton") {
                dis = discount;
              } else if (discountType === "Cash") {
                dis = (item.actualPriceIQD * discount) / 100;
              }
              if (Number(item.quantity) > item.stock) isCorrect = false;
              if (!isUpdate && (!item.expiry || item.quantity <= 0 || !date))
                isCompleteData = false;
              sell.push({
                invoiceNumber: invNO,
                manualInvoiceNumber: manualInvoiceNumber,
                itemId: item.itemId,
                priceIQD: item.actualPriceIQD - dis,
                actualPriceIQD: item.actualPriceIQD,
                priceDLR: ((item.actualPriceIQD - dis) / rateValue).toFixed(0),
                actualPriceDLR: item.actualPriceDLR,
                quantity: item.quantity ? item.quantity : 0,
                rateId: rate.id,
                inventoryId: item.inventoryId,
                expiry: moment(item.expiry).format("YYYY-MM-DD").toString(),
                zoneId: zoneId,
                offer: item.offer ? item.offer : 0,
                paid: totalPaid / inputList.length,
                note: note,
                deleteStatus: deleteStatus,
                discount: discount,
                quantityPerBox: item.quantityPerBox,
                discountType: discountType,
                customerId: customerId,
                status: item.status,
                paidStatus: paidStat,
                username: !isUpdate ? item.username : inputList[0].username,
                date: date,
                deliveredDate: deliveredDate,
                paidInvoiceNumber: item.paidInvoiceNumber,
                paidBy: item.paidBy,
                paidData: item.paidData,
                id: item.id,
              });

              //create sellData Array to submit user credit
              if (oldQuantityList[index] && item.status === "1") {
                if (
                  oldQuantityList[index].quantity > item.quantity ||
                  oldQuantityList[index].itemId !== item.itemId
                ) {
                  rejectedQuantity.push({
                    id: oldQuantityList[index].inventoryId,
                    quantity:
                      oldQuantityList[index].itemId !== item.itemId
                        ? oldQuantityList[index].quantity
                        : oldQuantityList[index].quantity - item.quantity,
                    status: oldQuantityList[index].status,
                  });
                  isRejecting = true;
                }
                if (
                  oldQuantityList[index].quantity < item.quantity ||
                  oldQuantityList[index].itemId !== item.itemId
                ) {
                  AddedQuantity.push({
                    id:
                      oldQuantityList[index].itemId !== item.itemId
                        ? item.inventoryId
                        : oldQuantityList[index].inventoryId,
                    quantity:
                      oldQuantityList[index].itemId !== item.itemId
                        ? item.quantity
                        : item.quantity - oldQuantityList[index].quantity,
                    status: oldQuantityList[index].status,
                  });
                  isAdding = true;
                }
              }
              if (item.status === "0") {
                AddedQuantity.push({
                  id: item.inventoryId,
                  quantity: item.quantity,
                  status: item.status,
                });
                isAdding = true;
              }
            });

            if (isCorrect && isCompleteData && !isMissLimitDebt) {
              axios
                .put(path + "/sell", sell, {
                  // headers: {
                  //   "auth-token": this.props.user.user.token,
                  // },
                })
                .then((result) => {
                  if (result.data.success === true) {
                    onBindSellData();
                    setshowAddModal(false);
                    setIsUpdate(false);
                    setItemPriceAllList([]);
                    if (isUpdate && isRejecting && rejectedQuantity.length) {
                      axios
                        .put(path + "/inventory/reject", rejectedQuantity, {
                          // headers: {
                          //   "auth-token": this.props.user.user.token,
                          // },
                        })
                        .then((result) => {
                          if (result.data.success === true) {
                          }
                          // if (result.data.token !== null)
                          //   this.props.user.user.token = result.data.token;
                        })
                        .catch((error) => {
                          Swal.fire({
                            title: "warning",
                            text: "there is some error while saving sell data please contact administrator",
                            icon: "warning",
                          });
                        });
                    }
                    if (
                      isUpdate &&
                      isDelivered &&
                      isAdding &&
                      AddedQuantity.length
                    ) {
                      axios
                        .put(path + "/inventory/sell", AddedQuantity, {
                          // headers: {
                          //   "auth-token": this.props.user.user.token,
                          // },
                        })
                        .then((result) => {
                          if (result.data.success === true) {
                            axios
                              .put(
                                path + "/sell/delivery/" + invoiceNo,
                                { deliveredDate: null },
                                {
                                  // headers: {
                                  //   "auth-token": this.props.user.user.token,
                                  // },
                                }
                              )
                              .then((result) => {
                                if (result.data.success === true) {
                                }
                              })
                              .catch((error) => {
                                Swal.fire({
                                  title: "warning",
                                  text: `there is some error while delivery with invoice No ${invoiceNo}`,
                                  icon: "warning",
                                });
                              });
                          }
                        })
                        .catch((error) => {
                          Swal.fire({
                            title: "warning",
                            text: `there is some error while delivery with invoice No ${invoiceNo}`,
                            icon: "warning",
                          });
                        });
                    }
                    onSubmitCredit("perinvoice", {
                      invoiceNumber: invNO,
                      totalIQD: totalInDinar,
                      creditType: userRepaymentType,
                      userId: username,
                      quantity: totalQuantityForCredit,
                    });
                    onBindSellData();
                    setshowAddModal(false);
                    setIsUpdate(false);
                    setItemPriceAllList([]);
                    setOldQuantityList([]);
                    Swal.fire({
                      title: "THANKS",
                      text: "the sell done",
                      icon: "success",
                    });
                  }
                  // if (result.data.token !== null)
                  //   this.props.user.user.token = result.data.token;
                })
                .catch((error) => {
                  Swal.fire({
                    title: "warning",
                    text: "there is some error while saving sell data please contact administrator",
                    icon: "warning",
                  });
                });
            } else {
              let message = "";
              if (!isCorrect)
                message = "بڕی مەواد زیاترە لە بڕی دیاری کراو لە مەخزەن";
              if (!isCompleteData)
                message =
                  "زانیاریەکانی (بەروار و بڕی مەواد و بەرواری بەسەر چون و ژمارەی وەسڵی دەستی) داواکراوە";
              if (isMissLimitDebt)
                message = "بڕی قەرز زیاترە لە ڕێژەی دیاری کراو بۆ ئەم کەستمەرە";
              Swal.fire({
                title: "warning",
                text: message,
                icon: "warning",
              });
            }
          }
        })
        .catch((error) => {
          Swal.fire({
            title: "warning",
            text: "there is some error while getting invoice number please contact administrator",
            icon: "warning",
          });
        });
    } else {
      Swal.fire({
        title: "warning",
        text: "تکایە ژمارەی وەسڵی دەستی پڕ بکەرەوە",
        icon: "warning",
      });
    }
  }

  async function OnCheckManualInvoiceNumberExsists(invoiceNumber) {
    try {
      const response = await axios.get(
        path + "/sell/CheckmanualInvoiceNumberExsists/" + invoiceNumber
      );
      return response;
    } catch {
      Swal.fire({
        title: "warning",
        text: "there is some error while checking manual invoice number please contact administrator",
        icon: "warning",
      });
    }
  }

  function onPaidSellItems(
    invoiceNo,
    manualInvoiceNumber,
    paidInvoiceNumber,
    paidBy,
    paidDate,
    sellData
  ) {
    if (manualInvoiceNumber) {
      Swal.fire({
        title: 'Do you want to Paid to invoiceNo "' + invoiceNo + '" ?',
        input: "number",
        inputValue: paidInvoiceNumber,
        inputLabel: "Paid Invoice Number",
        showCancelButton: true,
        confirmButtonText: "Paid",
        showLoaderOnConfirm: true,
        inputValidator: (value) => {
          if (!value) {
            return "Please enter paid invoice Number!";
          } else {
            let status;
            if (user.role === "accountant" || user.role === "admin")
              status = "2";
            else status = "1";
            let paidData = {
              status: status,
              invoiceNumber: invoiceNo,
              paidInvoiceNumber: value,
              paidBy: paidBy ? paidBy : user.name,
              paidDate: paidDate
                ? moment(paidDate).format("YYYY-MM-DD").toString()
                : moment(new Date()).format("YYYY-MM-DD").toString(),
            };

            // console.log(paidData);
            axios
              .put(path + "/sell/paid", paidData, {
                // headers: {
                //   "auth-token": this.props.user.user.token,
                // },
              })
              .then((result) => {
                if (result.data.success === true) {
                  if (user.role === "accountant" || user.role === "admin") {
                    onSubmitCredit("perpayment", sellData);
                  }
                  onBindSellData();
                  Swal.fire({
                    title: "THANKS",
                    text: "the Paid done",
                    icon: "success",
                  });
                }
              });
          }
        },
        // allowOutsideClick: () => !Swal.isLoading(),
      });
    } else {
      Swal.fire({
        title: "تکایە لە پێشدا ژمارەی وەصڵی دەستی دەخڵ بکە ",
        input: "number",
        inputValue: manualInvoiceNumber,
        inputLabel: "manual Ivoice Number",
        showCancelButton: true,
        confirmButtonText: "Update",
        showLoaderOnConfirm: true,
        inputValidator: (value) => {
          if (!value) {
            return "Please enter manual Ivoice Number!";
          } else {
            axios
              .get(path + "/sell/CheckmanualInvoiceNumberExsists/" + value, {
                // headers: {
                //   "auth-token": this.props.user.user.token,
                // },
              })
              .then((result) => {
                if (
                  (result.data.success === true &&
                    result.data.data === false) ||
                  isUpdate
                ) {
                  let updatedData = {
                    invoiceNumber: invoiceNo,
                    manualInvoiceNumber: value,
                  };

                  axios
                    .put(
                      path + "/sell/update/updateManualInvoiceNumber",
                      updatedData,
                      {
                        // headers: {
                        //   "auth-token": this.props.user.user.token,
                        // },
                      }
                    )
                    .then((result) => {
                      if (result.data.success === true) {
                        Swal.fire({
                          title:
                            'Do you want to Paid to invoiceNo "' +
                            invoiceNo +
                            '" ?',
                          input: "number",
                          inputValue: paidInvoiceNumber,
                          inputLabel: "Paid Invoice Number",
                          showCancelButton: true,
                          confirmButtonText: "Paid",
                          showLoaderOnConfirm: true,
                          inputValidator: (value) => {
                            if (!value) {
                              return "Please enter paid invoice Number!";
                            } else {
                              let status;
                              if (
                                user.role === "accountant" ||
                                user.role === "admin"
                              )
                                status = "2";
                              else status = "1";
                              let paidData = {
                                status: status,
                                invoiceNumber: invoiceNo,
                                paidInvoiceNumber: value,
                                paidBy: paidBy ? paidBy : user.name,
                                paidDate: paidDate
                                  ? moment(paidDate)
                                      .format("YYYY-MM-DD")
                                      .toString()
                                  : moment(new Date())
                                      .format("YYYY-MM-DD")
                                      .toString(),
                              };

                              axios
                                .put(path + "/sell/paid", paidData, {
                                  // headers: {
                                  //   "auth-token": this.props.user.user.token,
                                  // },
                                })
                                .then((result) => {
                                  if (result.data.success === true) {
                                    if (
                                      user.role === "accountant" ||
                                      user.role === "admin"
                                    ) {
                                      onSubmitCredit("perpayment", sellData);
                                    }
                                    onBindSellData();
                                    Swal.fire({
                                      title: "THANKS",
                                      text: "the Paid done",
                                      icon: "success",
                                    });
                                  }
                                });
                            }
                          },
                          // allowOutsideClick: () => !Swal.isLoading(),
                        });
                      }
                    });
                } else {
                  Swal.fire({
                    title: "warning",
                    text: "ژمارەی وەسڵی دەستی دوبارەیە",
                    icon: "warning",
                  });
                }
              })
              .catch((error) => {
                Swal.fire({
                  title: "warning",
                  text: "there is some error while getting invoice number please contact administrator",
                  icon: "warning",
                });
              });
          }
        },
        // allowOutsideClick: () => !Swal.isLoading(),
      });
    }
  }

  function ondeliverySellItems(invoiceNo, manualInvoiceNumber, sellData) {
    const today = new Date();
    const deliveredDate = today.toISOString().split("T")[0];
    if (manualInvoiceNumber) {
      Swal.fire({
        title:
          'Do you want to delivery items with invoiceNo "' + invoiceNo + '" ?',
        showCancelButton: true,
        confirmButtonText: "Save",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.value) {
          axios
            .get(path + "/sell/" + invoiceNo, {
              // headers: {
              //   "auth-token": this.props.user.user.token,
              // },
            })
            .then((result) => {
              // if (result.data.token !== null)
              //   this.props.user.user.token = result.data.token;
              if (result.data.success === true) {
                let deliveryData = [];
                let isAvailable = true;
                let notAvailableMessage = "";
                if (language === "Kurdish")
                  notAvailableMessage =
                    "بڕی پێویستی مەواد لە مەخزەن نیە تکایە چێکی مەخزەن بکە";
                else
                  notAvailableMessage =
                    "الكمية غير متوفرة في المخزون يرجى التحقق من المخزون من قبل";

                result.data.data.forEach((item) => {
                  deliveryData.push({
                    id: item.inventoryId,
                    quantity: item.quantity,
                  });
                  if (item.quantity > item.quantityInInventory)
                    isAvailable = false;
                });
                if (!isAvailable) {
                  Swal.fire({
                    title: "warning",
                    text: notAvailableMessage,
                    icon: "warning",
                  });
                } else {
                  axios
                    .put(path + "/inventory/sell", deliveryData, {
                      // headers: {
                      //   "auth-token": this.props.user.user.token,
                      // },
                    })
                    .then((result) => {
                      if (result.data.success === true) {
                        axios
                          .put(
                            path + "/sell/delivery/" + invoiceNo,
                            { deliveredDate: deliveredDate },
                            {
                              // headers: {
                              //   "auth-token": this.props.user.user.token,
                              // },
                            }
                          )
                          .then((result) => {
                            if (result.data.success === true) {
                              onSubmitCredit("perquantity", sellData);

                              onBindSellData();
                              Swal.fire({
                                title: "THANKS",
                                text: "the delivery done",
                                icon: "success",
                              });
                            }
                          })
                          .catch((error) => {
                            Swal.fire({
                              title: "warning",
                              text: `there is some error while delivery with invoice No ${invoiceNo}`,
                              icon: "warning",
                            });
                          });
                      }
                    })
                    .catch((error) => {
                      Swal.fire({
                        title: "warning",
                        text: `there is some error while delivery with invoice No ${invoiceNo}`,
                        icon: "warning",
                      });
                    });
                }
              }
            })
            .catch((error) => {
              Swal.fire({
                title: "warning",
                text: `there is some error while get invoice with invoice number ${invoiceNo} data please contact administrator`,
                icon: "warning",
              });
            });
        }
      });
    } else {
      Swal.fire({
        title: "تکایە لە پێشدا ژمارەی وەصڵی دەستی دەخڵ بکە ",
        input: "number",
        inputValue: manualInvoiceNumber,
        inputLabel: "manual Ivoice Number",
        showCancelButton: true,
        confirmButtonText: "Update",
        showLoaderOnConfirm: true,
        inputValidator: (value) => {
          if (!value) {
            return "Please enter manual Ivoice Number!";
          } else {
            axios
              .get(path + "/sell/CheckmanualInvoiceNumberExsists/" + value, {
                // headers: {
                //   "auth-token": this.props.user.user.token,
                // },
              })
              .then((result) => {
                if (
                  (result.data.success === true &&
                    result.data.data === false) ||
                  isUpdate
                ) {
                  let updatedData = {
                    invoiceNumber: invoiceNo,
                    manualInvoiceNumber: value,
                  };

                  axios
                    .put(
                      path + "/sell/update/updateManualInvoiceNumber",
                      updatedData,
                      {
                        // headers: {
                        //   "auth-token": this.props.user.user.token,
                        // },
                      }
                    )
                    .then((result) => {
                      if (result.data.success === true) {
                        Swal.fire({
                          title:
                            'Do you want to delivery items with invoiceNo "' +
                            invoiceNo +
                            '" ?',
                          showCancelButton: true,
                          confirmButtonText: "Save",
                        }).then((result) => {
                          /* Read more about isConfirmed, isDenied below */
                          if (result.value) {
                            axios
                              .get(path + "/sell/" + invoiceNo, {
                                // headers: {
                                //   "auth-token": this.props.user.user.token,
                                // },
                              })
                              .then((result) => {
                                // if (result.data.token !== null)
                                //   this.props.user.user.token = result.data.token;
                                if (result.data.success === true) {
                                  let deliveryData = [];
                                  let isAvailable = true;
                                  let notAvailableMessage = "";
                                  if (language === "Kurdish")
                                    notAvailableMessage =
                                      "بڕی پێویستی مەواد لە مەخزەن نیە تکایە چێکی مەخزەن بکە";
                                  else
                                    notAvailableMessage =
                                      "الكمية غير متوفرة في المخزون يرجى التحقق من المخزون من قبل";

                                  result.data.data.forEach((item) => {
                                    deliveryData.push({
                                      id: item.inventoryId,
                                      quantity: item.quantity,
                                    });
                                    if (
                                      item.quantity > item.quantityInInventory
                                    )
                                      isAvailable = false;
                                  });
                                  if (!isAvailable) {
                                    Swal.fire({
                                      title: "warning",
                                      text: notAvailableMessage,
                                      icon: "warning",
                                    });
                                  } else {
                                    axios
                                      .put(
                                        path + "/inventory/sell",
                                        deliveryData,
                                        {
                                          // headers: {
                                          //   "auth-token": this.props.user.user.token,
                                          // },
                                        }
                                      )
                                      .then((result) => {
                                        if (result.data.success === true) {
                                          axios
                                            .put(
                                              path +
                                                "/sell/delivery/" +
                                                invoiceNo,
                                              { deliveredDate: deliveredDate },
                                              {
                                                // headers: {
                                                //   "auth-token": this.props.user.user.token,
                                                // },
                                              }
                                            )
                                            .then((result) => {
                                              if (
                                                result.data.success === true
                                              ) {
                                                onSubmitCredit(
                                                  "perquantity",
                                                  sellData
                                                );

                                                onBindSellData();
                                                Swal.fire({
                                                  title: "THANKS",
                                                  text: "the delivery done",
                                                  icon: "success",
                                                });
                                              }
                                            })
                                            .catch((error) => {
                                              Swal.fire({
                                                title: "warning",
                                                text: `there is some error while delivery with invoice No ${invoiceNo}`,
                                                icon: "warning",
                                              });
                                            });
                                        }
                                      })
                                      .catch((error) => {
                                        Swal.fire({
                                          title: "warning",
                                          text: `there is some error while delivery with invoice No ${invoiceNo}`,
                                          icon: "warning",
                                        });
                                      });
                                  }
                                }
                              })
                              .catch((error) => {
                                Swal.fire({
                                  title: "warning",
                                  text: `there is some error while get invoice with invoice number ${invoiceNo} data please contact administrator`,
                                  icon: "warning",
                                });
                              });
                          }
                        });
                      }
                    });
                } else {
                  Swal.fire({
                    title: "warning",
                    text: "ژمارەی وەسڵی دەستی دوبارەیە",
                    icon: "warning",
                  });
                }
              })
              .catch((error) => {
                Swal.fire({
                  title: "warning",
                  text: "there is some error while getting invoice number please contact administrator",
                  icon: "warning",
                });
              });
          }
        },
        // allowOutsideClick: () => !Swal.isLoading(),
      });
    }
  }

  function onDeleteSellHandler(invoiceNo) {
    Swal.fire({
      title: 'Do you want to delete Invoice  "' + invoiceNo + '" ?',
      showCancelButton: true,
      confirmButtonText: "Save",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.value) {
        axios
          .get(path + "/sell/" + invoiceNo, {
            // headers: {
            //   "auth-token": this.props.user.user.token,
            // },
          })
          .then((result) => {
            // if (result.data.token !== null)
            //   this.props.user.user.token = result.data.token;
            if (result.data.success === true) {
              let rejectedQuantity = [];
              let isRejecting = false;

              result.data.data.forEach((item, index) => {
                if (item.status === "1") {
                  rejectedQuantity.push({
                    id: result.data.data[index].inventoryId,
                    quantity: item.quantity,
                    status: result.data.data[index].status,
                  });
                  isRejecting = true;
                }
              });
              axios
                .delete(path + "/sell/" + invoiceNo, {
                  // headers: {
                  //   "auth-token": this.props.user.user.token,
                  // },
                })
                .then((result) => {
                  if (result.data.statusCode === 200) {
                    if (isRejecting && rejectedQuantity.length) {
                      axios
                        .put(path + "/inventory/reject", rejectedQuantity, {
                          // headers: {
                          //   "auth-token": this.props.user.user.token,
                          // },
                        })
                        .then((result) => {
                          if (result.data.success === true) {
                          }
                          // if (result.data.token !== null)
                          //   this.props.user.user.token = result.data.token;
                        })
                        .catch((error) => {
                          Swal.fire({
                            title: "warning",
                            text: "there is some error while saving sell data please contact administrator",
                            icon: "warning",
                          });
                        });
                    }
                    onBindSellData();
                    Swal.fire("Invoice Deleted!", "", "success");
                  }
                  // if (result.data.token !== null)
                  //   this.props.user.user.token = result.data.token;
                })
                .catch((error) => {
                  Swal.fire({
                    title: "warning",
                    text: "the Invoice did not deleted please contact administrator",
                    icon: "warning",
                  });
                });
            }
          })
          .catch((error) => {
            Swal.fire({
              title: "warning",
              text: `there is some error while get invoice with invoice number ${invoiceNo} data please contact administrator`,
              icon: "warning",
            });
          });
      }
    });
  }

  return (
    <>
      <GeneralHeader />

      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col xs="6">
                    <h2 className="mb-0">
                      Sales{" "}
                      {status && status === "0" ? <span>(Pending)</span> : ""}
                    </h2>
                  </Col>

                  <Col className="text-right" xs="6">
                    <Button color="primary" size="md" onClick={onAddNewHandler}>
                      New Sale
                    </Button>
                    <Button color="success" size="md" onClick={SearchToggle}>
                      Search Invoice
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {sellList.map(function (item, index) {
                  return (
                    <div className="invoice-sell">
                      <table className="invoice-table-header">
                        <thead
                        // className="thead-dark"
                        >
                          <tr>
                            <th
                              scope="col"
                              style={{ fontSize: 17, fontWeight: "bold" }}
                            >
                              Invoice No# : {item.invoiceNumber}
                            </th>
                            <th
                              scope="col"
                              style={{ fontSize: 17, fontWeight: "bold" }}
                            >
                              Customer : {item.customerName}
                            </th>
                            <th
                              scope="col"
                              style={{ fontSize: 17, fontWeight: "bold" }}
                            >
                              user : {item.username}
                            </th>
                            <th
                              scope="col"
                              style={{ fontSize: 17, fontWeight: "bold" }}
                              colSpan="2"
                            >
                              Date :
                              <Moment format="YYYY/MM/DD">{item.date}</Moment>
                            </th>
                          </tr>
                          <tr
                            style={{
                              backgroundColor:
                                item.totalIQD - item.paid !== 0
                                  ? "#ffb3b3"
                                  : "#b3ffb3",
                            }}
                          >
                            <th
                              scope="col"
                              style={{ fontSize: 17, fontWeight: "bold" }}
                            >
                              subTotal :
                              <NumberFormat
                                value={item.subtotalIQD.toFixed(0)}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"IQD "}
                              />
                            </th>
                            <th
                              scope="col"
                              style={{ fontSize: 17, fontWeight: "bold" }}
                            >
                              subTotal :
                              <NumberFormat
                                value={item.subtotalDLR.toFixed(0)}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$ "}
                              />
                            </th>
                            <th
                              scope="col"
                              style={{ fontSize: 17, fontWeight: "bold" }}
                            >
                              discount:
                              <NumberFormat
                                value={item.discount ? item.discount : 0}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={
                                  item.discountType === "Cash" ? "% " : "IQD "
                                }
                              />
                            </th>
                            <th
                              scope="col"
                              style={{ fontSize: 17, fontWeight: "bold" }}
                            >
                              total :
                              <NumberFormat
                                value={item.totalIQD.toFixed(0)}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"IQD "}
                              />
                            </th>
                            <th
                              scope="col"
                              style={{ fontSize: 17, fontWeight: "bold" }}
                            >
                              total :
                              <NumberFormat
                                value={item.totalDLR.toFixed(0)}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$ "}
                              />
                            </th>
                          </tr>
                          <tr>
                            <th
                              scope="col"
                              style={{ fontSize: 17, fontWeight: "bold" }}
                            >
                              manual Invoice No : {item.manualInvoiceNumber}
                            </th>
                            <th
                              scope="col"
                              style={{ fontSize: 17, fontWeight: "bold" }}
                            >
                              Note : {item.note}
                            </th>
                            <th
                              scope="col"
                              style={{ fontSize: 17, fontWeight: "bold" }}
                            >
                              Discount Type : {item.discountType}
                            </th>
                            <th
                              scope="col"
                              style={{
                                backgroundColor:
                                  item.paidStatus === "0"
                                    ? "#ffb3b3"
                                    : item.paidStatus === "1"
                                    ? "#ffd966"
                                    : "#b3ffb3",
                                fontSize: 17,
                                fontWeight: "bold",
                              }}
                            >
                              Paid:
                              <NumberFormat
                                value={item.paid}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"IQD "}
                              />
                              {"  "}
                              <Button
                                className="btn-icon-only rounded-circle"
                                color="secondary"
                                type="button"
                                size="sm"
                                onClick={() =>
                                  onPaidSellItems(
                                    item.invoiceNumber,
                                    item.manualInvoiceNumber,
                                    item.paidInvoiceNumber,
                                    item.paidBy,
                                    item.paidDate,
                                    item
                                  )
                                }
                                disabled={
                                  item.paidStatus !== "2" ? false : true
                                }
                              >
                                <span className="btn-inner--icon">
                                  <svg
                                    className="text-black w-6 h-6"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="2"
                                      d="M5 13l4 4L19 7"
                                    ></path>
                                  </svg>
                                </span>
                              </Button>
                            </th>

                            <th
                              scope="col"
                              style={{
                                backgroundColor:
                                  item.status === "0" ? "#ffb3b3" : "#b3ffb3",
                                fontSize: 17,
                                fontWeight: "bold",
                              }}
                              colSpan={2}
                            >
                              Status :
                              {item.status === "0" ? "Pending" : "Deliveried"}
                            </th>
                          </tr>
                          <tr>
                            <th
                              scope="col"
                              style={{ fontSize: 17, fontWeight: "bold" }}
                            >
                              Paid Invoice No# : {item.paidInvoiceNumber}
                            </th>
                            <th
                              scope="col"
                              style={{ fontSize: 17, fontWeight: "bold" }}
                            >
                              Paid By : {item.paidBy ? item.paidBy : ""}
                            </th>

                            <th
                              scope="col"
                              style={{ fontSize: 17, fontWeight: "bold" }}
                              // colSpan="2"
                            >
                              T.Paid :{item.totalPaid ? item.totalPaid : 0}
                            </th>
                            <th
                              scope="col"
                              style={{ fontSize: 17, fontWeight: "bold" }}
                              // colSpan="2"
                            >
                              Paid Date :
                              <Moment format="YYYY/MM/DD">
                                {item.paidDate}
                              </Moment>
                            </th>
                            <th
                              scope="col"
                              style={{ fontSize: 17, fontWeight: "bold" }}
                              // colSpan="2"
                            >
                              D.Date :
                              <Moment format="YYYY/MM/DD">
                                {item.deliveredDate}
                              </Moment>
                            </th>
                          </tr>
                        </thead>
                        <tbody></tbody>
                      </table>

                      <table className="invoice-table-main">
                        <thead className="thead-light text-center">
                          <tr>
                            <th
                              scope="col"
                              style={{ fontSize: 17, fontWeight: "bold" }}
                            >
                              #
                            </th>
                            <th
                              scope="col"
                              style={{ fontSize: 17, fontWeight: "bold" }}
                            >
                              Items
                            </th>
                            <th
                              scope="col"
                              style={{ fontSize: 17, fontWeight: "bold" }}
                            >
                              Quantity
                            </th>
                            <th
                              scope="col"
                              style={{ fontSize: 17, fontWeight: "bold" }}
                            >
                              selling price
                            </th>
                            {/* <th
                              scope="col"
                              style={{ fontSize: 17, fontWeight: "bold" }}
                            >
                              offer
                            </th> */}
                            <th
                              scope="col"
                              style={{ fontSize: 17, fontWeight: "bold" }}
                            >
                              total
                            </th>
                            <th
                              scope="col"
                              style={{ fontSize: 17, fontWeight: "bold" }}
                              colSpan={2}
                            >
                              Expiry Date
                            </th>
                          </tr>
                        </thead>
                        <tbody className="text-center">
                          {Array.from(item.id.split(","), Number).map(function (
                            i,
                            ind
                          ) {
                            return (
                              <tr>
                                <th scope="row">{ind + 1}</th>
                                <td
                                  style={{ fontSize: 17, fontWeight: "bold" }}
                                >
                                  {
                                    Array.from(
                                      item.itemName.split(","),
                                      String
                                    )[ind]
                                  }
                                </td>
                                <td
                                  style={{ fontSize: 17, fontWeight: "bold" }}
                                >
                                  {
                                    Array.from(
                                      item.quantity.split(","),
                                      Number
                                    )[ind]
                                  }
                                </td>

                                <td
                                  style={{ fontSize: 17, fontWeight: "bold" }}
                                >
                                  <NumberFormat
                                    value={Array.from(
                                      item.price.split(","),
                                      Number
                                    )[ind].toFixed(0)}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"IQD "}
                                  />
                                </td>
                                {/* <td
                                  style={{ fontSize: 17, fontWeight: "bold" }}
                                >
                                  {
                                    Array.from(item.offer.split(","), Number)[
                                      ind
                                    ]
                                  }
                                </td> */}
                                <td
                                  style={{ fontSize: 17, fontWeight: "bold" }}
                                >
                                  <NumberFormat
                                    value={Array.from(
                                      item.total.split(","),
                                      Number
                                    )[ind].toFixed(0)}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"IQD "}
                                  />
                                </td>
                                <td
                                  style={{ fontSize: 17, fontWeight: "bold" }}
                                >
                                  <Moment format="DD-MM-YYYY">
                                    {Array.from(item.expiry.split(","))[ind]}
                                  </Moment>
                                </td>
                                <td className="text-right">
                                  <div> </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: 10,
                        }}
                      >
                        <Button
                          size="sm"
                          color="info"
                          onClick={() => onPrintHandler(item.invoiceNumber)}
                        >
                          Print
                        </Button>
                        <Button
                          size="sm"
                          color="success"
                          onClick={() =>
                            ondeliverySellItems(
                              item.invoiceNumber,
                              item.manualInvoiceNumber,
                              item
                            )
                          }
                          disabled={item.status === "0" ? false : true}
                        >
                          Delivery
                        </Button>
                        {user.role === "admin" || user.role === "accountant" ? (
                          <>
                            <Button
                              size="sm"
                              color="warning"
                              onClick={() =>
                                EditDataHandler(item.invoiceNumber)
                              }
                              // disabled={item.status === "0" ? false : true}
                            >
                              Edit
                            </Button>
                            <Button
                              size="sm"
                              color="danger"
                              onClick={() =>
                                onDeleteSellHandler(item.invoiceNumber)
                              }
                              // disabled={item.status === "0" ? false : true}
                            >
                              Delete
                            </Button>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  );
                })}
              </CardBody>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem>
                      <PaginationLink
                        onClick={() => {
                          if (PageNumber > 1) {
                            callPagination(PageNumber - 1);
                          }
                        }}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    {paginationItemsRender()}

                    <PaginationItem>
                      <PaginationLink
                        onClick={() => {
                          if (PageNumber < numofPages) {
                            callPagination(PageNumber + 1);
                          }
                        }}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        isOpen={showAddModal}
        toggle={toggle}
        size="lg"
        style={{ maxWidth: "1500px", width: "100%" }}
      >
        {/* <ModalHeader toggle={this.toggle}>New Purchase</ModalHeader> */}
        <ModalBody>
          <Col className="order-xl-1">
            <Card className="shadow" style={{ backgroundColor: "#F0F8FF" }}>
              <CardBody>
                <Form autoComplete="off" id="ff">
                  <h6 className="heading-small text-muted mb-2">Invoice</h6>
                  <div className="pl-lg-2 text-right" dir="rtl">
                    <div
                      style={{
                        backgroundColor: "white",
                        height: 210,
                        boxShadow: "1px 2px 3px #9E9E9E",
                        borderRadius: 5,
                        borderTop: "5px solid #99CCFF",
                        overflow: "auto",
                      }}
                    >
                      <div>
                        <FormGroup row style={{ margin: 3 }}>
                          <Label
                            for="exampleEmail"
                            lg={3}
                            style={{
                              fontSize: 17,
                              fontWeight: "bold",
                              marginTop: 5,
                            }}
                          >
                            {language === "Kurdish" ? (
                              <> کڕیار</>
                            ) : (
                              <> الزبون</>
                            )}
                          </Label>
                          <Col
                            lg={9}
                            style={{
                              marginTop: 10,
                            }}
                          >
                            <Select
                              value={customerOptionIndex}
                              onChange={(e) => onCustomerChangeHandler(e)}
                              options={customerOptions}
                              style={{
                                fontSize: 17,
                                fontWeight: "bold",
                              }}
                            />
                          </Col>
                        </FormGroup>
                      </div>
                      <div
                        style={{
                          // display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <FormGroup row style={{ margin: 3 }}>
                          <Label
                            for="exampleEmail"
                            lg={3}
                            style={{
                              fontSize: 17,
                              color: "blue",
                              marginTop: 5,
                            }}
                          >
                            {language === "Kurdish" ? (
                              <>ناوی کڕیار </>
                            ) : (
                              <> اسم الزبون</>
                            )}
                            : {customerName}
                          </Label>
                          <Label
                            for="exampleEmail"
                            lg={3}
                            style={{
                              fontSize: 17,
                              color: "green",
                              marginTop: 5,
                            }}
                          >
                            {language === "Kurdish" ? (
                              <>جۆری قەرز </>
                            ) : (
                              <> نوع قرض </>
                            )}
                            : {discountType}
                          </Label>

                          <Label
                            for="exampleEmail"
                            lg={3}
                            style={{
                              fontSize: 17,
                              color: "red",
                              marginTop: 5,
                            }}
                          >
                            {language === "Kurdish" ? <>قەرز </> : <> قرض</>}:{" "}
                            <NumberFormat
                              value={Math.abs(customerDebit.toFixed(0))}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"IQD"}
                            />
                          </Label>
                          <Label
                            for="exampleEmail"
                            lg={3}
                            style={{
                              fontSize: 17,
                              color: "purple",
                              marginTop: 5,
                            }}
                          >
                            {language === "Kurdish" ? (
                              <> بڕی دیاری کراوی قەرز </>
                            ) : (
                              <>مبلغ قرض </>
                            )}
                            :
                            <NumberFormat
                              value={customerLimit.toFixed(0)}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"IQD"}
                            />
                          </Label>
                        </FormGroup>
                      </div>
                      <div
                        style={{
                          // display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <FormGroup row style={{ margin: 3 }}>
                          <Label
                            for="exampleEmail"
                            lg={3}
                            style={{
                              fontSize: 17,
                              color: "blue",
                              marginTop: 5,
                            }}
                          >
                            {language === "Kurdish" ? <>جۆر </> : <> نوع</>} :{" "}
                            {customerType}
                          </Label>
                          <Label
                            for="exampleEmail"
                            lg={3}
                            style={{
                              fontSize: 17,
                              color: "green",
                              marginTop: 5,
                            }}
                          >
                            {language === "Kurdish" ? <>شار </> : <> مدينة</>} :{" "}
                            {customerCity}
                          </Label>
                          <Label
                            for="exampleEmail"
                            lg={3}
                            style={{
                              fontSize: 17,
                              color: "red",
                              marginTop: 5,
                            }}
                          >
                            {language === "Kurdish" ? <>زۆن </> : <> منطقة</>} :{" "}
                            {customerZone}
                          </Label>
                          <Label
                            for="exampleEmail"
                            lg={3}
                            style={{
                              fontSize: 17,
                              color: "purple",
                              marginTop: 5,
                            }}
                          >
                            {language === "Kurdish" ? (
                              <>ناونیشان </>
                            ) : (
                              <> عنوان</>
                            )}{" "}
                            : {customerAddress}
                          </Label>
                        </FormGroup>
                      </div>
                    </div>
                    <Row
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: 10,
                      }}
                    >
                      <Col lg="2">
                        <FormGroup>
                          <label
                            htmlFor="subTotalIQD"
                            style={{
                              fontSize: 17,
                              fontWeight: "bold",
                            }}
                          >
                            {language === "Kurdish" ? (
                              <> کۆی گشتی </>
                            ) : (
                              <> المجموع</>
                            )}{" "}
                            کۆی گشتی (دینار)
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={subTotalIQD}
                            id="subTotalIQD"
                            placeholder="subTotalIQD"
                            type="number"
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="2">
                        <FormGroup>
                          <label
                            htmlFor="subTotalDLR"
                            style={{
                              fontSize: 17,
                              fontWeight: "bold",
                            }}
                          >
                            {language === "Kurdish" ? (
                              <>کۆی گشتی (دۆلار)</>
                            ) : (
                              <>المجموع (دولار)</>
                            )}
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={subTotalDLR}
                            id="subTotalDLR"
                            placeholder="Subtotal"
                            type="number"
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="2">
                        <FormGroup>
                          <label
                            htmlFor="discount"
                            style={{
                              fontSize: 20,
                              fontWeight: "bold",
                            }}
                          >
                            {language === "Kurdish" ? (
                              <>
                                داشکاندن {discountType === "Cash" ? "%" : "IQD"}
                              </>
                            ) : (
                              <>
                                تخفيضات {discountType === "Cash" ? "%" : "IQD"}
                              </>
                            )}
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={discount}
                            id="discount"
                            placeholder="Discount %"
                            type="number"
                            onChange={(e) => {
                              onchangeDiscountHandler(e);
                            }}
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="3">
                        <FormGroup>
                          <label
                            htmlFor="totalInDinar"
                            style={{
                              fontSize: 20,
                              fontWeight: "bold",
                            }}
                          >
                            {language === "Kurdish" ? (
                              <>کۆی گشتی دوای داشکاندن (دینار) </>
                            ) : (
                              <> المجموع بعد تخفيضات(دینار)</>
                            )}
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={totalInDinar}
                            id="totalInDinar"
                            placeholder="total (IQD)"
                            type="number"
                            onChange={(e) => setTotalInDinar(e.target.value)}
                            disabled
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="3">
                        <FormGroup>
                          <label
                            htmlFor="totalInDolar"
                            style={{
                              fontSize: 20,
                              fontWeight: "bold",
                            }}
                          >
                            {language === "Kurdish" ? (
                              <>کۆی گشتی دوای داشکاندن (دۆلار) </>
                            ) : (
                              <> المجموع بعد تخفيضات(دولار)</>
                            )}
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={totalInDolar}
                            id="totalInDolar"
                            placeholder="Total (Dolar)"
                            type="number"
                            onChange={(e) => setTotalInDolar(e.target.value)}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: 10,
                      }}
                    >
                      <Col lg="3">
                        <FormGroup>
                          <label
                            htmlFor="manualInvoiceNumber"
                            style={{
                              fontSize: 20,
                              fontWeight: "bold",
                            }}
                          >
                            {language === "Kurdish" ? (
                              <>ژمارەی وەسڵی دەستی </>
                            ) : (
                              <> رقم الفاتورة اليدوية</>
                            )}
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={manualInvoiceNumber}
                            id="manualInvoiceNumber"
                            placeholder="manualInvoiceNumber"
                            type="number"
                            onChange={(e) =>
                              setManualInvoiceNumber(e.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="3">
                        <FormGroup>
                          <label
                            htmlFor="note"
                            style={{
                              fontSize: 20,
                              fontWeight: "bold",
                            }}
                          >
                            {language === "Kurdish" ? <>تێبینی</> : <>ملاحظة</>}
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={note}
                            id="note"
                            placeholder="note"
                            type="text"
                            onChange={(e) => setNote(e.target.value)}
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="2">
                        <FormGroup>
                          <label
                            htmlFor="totalPaid"
                            style={{
                              fontSize: 20,
                              fontWeight: "bold",
                            }}
                          >
                            {language === "Kurdish" ? (
                              <>بڕی پارەی دراو (دینار)</>
                            ) : (
                              <>مبلغ المدفوعة (دینار)</>
                            )}
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={totalPaid}
                            id="totalPaid"
                            placeholder="Paid (DLR))"
                            type="number"
                            onChange={(e) => setTotalPaid(e.target.value)}
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="2">
                        <FormGroup>
                          <label
                            htmlFor="created By"
                            style={{
                              fontSize: 20,
                              fontWeight: "bold",
                            }}
                          >
                            {language === "Kurdish" ? (
                              <>بەکارهێنەر</>
                            ) : (
                              <>االمستخدم</>
                            )}
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={username}
                            id="user"
                            placeholder="user"
                            type="select"
                            onChange={(e) => {
                              setUserName(e.target.value);
                              inputList.map((item) => {
                                item.username = e.target.value;
                              });

                              const index = usersList.findIndex(
                                (item) => item.id === parseInt(e.target.value)
                              );

                              setuserRepaymentType(
                                usersList[index].repaymentType
                              );
                            }}
                            disabled={
                              user.role === "admin" ||
                              user.role === "accountant"
                                ? false
                                : true
                            }
                          >
                            <option>---User---</option>
                            {usersItemsRender()}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="2">
                        <FormGroup>
                          <label
                            htmlFor="manualInvoiceNumber"
                            style={{
                              fontSize: 20,
                              fontWeight: "bold",
                            }}
                          >
                            {language === "Kurdish" ? <>بەروار</> : <>تاريخ</>}
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={date}
                            id="manualInvoiceNumber"
                            placeholder="manualInvoiceNumber"
                            type="date"
                            onChange={(e) => setDate(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <hr className="my-2" />
                    <br />
                    {inputList.map((x, i) => {
                      return (
                        <div className="Container-lg">
                          <div className="row ">
                            <div className="col-sm-1">
                              <Badge color="success" style={{ fontSize: 18 }}>
                                {i + 1}
                              </Badge>
                            </div>
                            <div className="col-sm-2">
                              <Input
                                className="form-control-alternative"
                                value={x.itemId}
                                id="itemId"
                                placeholder="itemId"
                                type="select"
                                onChange={(e) => onItemChangeHandler(e, i)}
                              >
                                <option value={0}>---item---</option>
                                {ItemsRender()}
                              </Input>
                            </div>
                            <div className="col-sm-2">
                              <Input
                                className="form-control-alternative"
                                value={x.actualPriceIQD}
                                id="actualPriceIQD"
                                placeholder="price IQD"
                                type="number"
                                onChange={(e) => handleInputChange(e, i)}
                              />
                            </div>

                            <div className="col-sm-2">
                              <Input
                                className="form-control-alternative"
                                value={x.quantity}
                                id="quantity"
                                placeholder="quantity"
                                type="number"
                                onChange={(e) => handleInputChange(e, i)}
                              />
                            </div>
                            {/* <div className="col-sm-2">
                              <Input
                                className="form-control-alternative"
                                value={x.offer}
                                id="offer"
                                placeholder="offer"
                                type="number"
                                onChange={(e) => handleInputChange(e, i)}
                              />
                            </div> */}

                            <div className="col-sm-2">
                              <Input
                                className="form-control-alternative"
                                value={x.inventoryId}
                                id="inventoryId"
                                placeholder="inventoryId"
                                type="select"
                                onChange={(e) => onStockChangeHandler(e, i)}
                              >
                                <option>---expiry---</option>
                                {StockRender(i)}
                              </Input>
                            </div>

                            <div className="col-sm-1">
                              {inputList.length - 1 === i && (
                                <Button
                                  style={{
                                    width: " 30px",
                                    height: "30px",
                                    padding: "3px",
                                    borderRadius: " 30px",
                                    textAlign: "center",
                                    fontSize: "15px",
                                    lineHeight: "1.42857",
                                  }}
                                  color="info"
                                  type="button"
                                  onClick={handleAddClick}
                                >
                                  <span className="btn-inner--icon">
                                    <i className="fas fa-plus"></i>
                                  </span>
                                </Button>
                              )}
                              {inputList.length !== 1 && !isUpdate && (
                                <Button
                                  style={{
                                    width: " 30px",
                                    height: "30px",
                                    padding: "3px",
                                    borderRadius: " 30px",
                                    textAlign: "center",
                                    fontSize: "15px",
                                    lineHeight: "1.42857",
                                  }}
                                  color="danger"
                                  type="button"
                                  onClick={() => handleRemoveClick(i)}
                                >
                                  <span className="btn-inner--icon">
                                    <i className="far fa-trash-alt"></i>
                                  </span>
                                </Button>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={onsaveDataHandler}>
            Save
          </Button>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={showInvoiceAddModal}
        toggle={invoiceToggle}
        size="lg"
        style={{ maxWidth: "1500px", width: "100%" }}
      >
        <ModalBody>
          <Row
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Col lg="4">
              <FormGroup>
                <label htmlFor="invoiceLanguage">Invoice Language</label>
                <Input
                  className="form-control-alternative"
                  value={invoiceLanguage}
                  id="invoiceLanguage"
                  placeholder="invoiceLanguage"
                  type="select"
                  onChange={(e) => setInvoiceLanguage(e.target.value)}
                >
                  <option>---Invoice Language---</option>
                  <option>kurdish</option>
                  <option>arabic</option>
                </Input>
              </FormGroup>
            </Col>
            <Col lg="4">
              <FormGroup>
                <label htmlFor="invoiceCurrency">Invoice Currency</label>
                <Input
                  className="form-control-alternative"
                  value={invoiceCurrency}
                  id="invoiceCurrency"
                  placeholder="invoiceCurrency"
                  type="select"
                  onChange={(e) => setInvoiceCurrency(e.target.value)}
                >
                  <option>---Invoice Currency---</option>
                  <option>dinar</option>
                  <option>dolar</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>

          <SellInvoice
            ref={(el) => (invoiceRef = el)}
            invoiceNo={invoiceNo}
            language={invoiceLanguage}
            currency={invoiceCurrency}
          />
        </ModalBody>
        <ModalFooter>
          <ReactToPrint
            trigger={() => <Button color="primary">print</Button>}
            content={() => invoiceRef}
          />

          <Button color="secondary" onClick={invoiceToggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      {/* Search Modal */}
      <Modal isOpen={showSearchModal} toggle={SearchToggle} size="lg">
        <ModalBody>
          <Form autoComplete="off">
            <FormGroup>
              <label htmlFor="invoiceNumber">Invoice Number</label>
              <Input
                className="form-control-alternative"
                value={sellBindData.invoiceNumber}
                id="invoiceNumber"
                placeholder="invoice Number"
                type="number"
                onChange={(e) => onChangeSearchInputHandler(e)}
              />
            </FormGroup>

            <FormGroup>
              <label htmlFor="manualInvoiceNumber">Manual Invoice Number</label>
              <Input
                className="form-control-alternative"
                value={sellBindData.manualInvoiceNumber}
                id="manualInvoiceNumber"
                placeholder="manual Invoice Number"
                type="number"
                onChange={(e) => onChangeSearchInputHandler(e)}
              />
            </FormGroup>

            <FormGroup>
              <label htmlFor="user">User</label>
              <Input
                className="form-control-alternative"
                value={sellBindData.user}
                id="user"
                placeholder="user"
                type="select"
                onChange={(e) => onChangeSearchInputHandler(e)}
              >
                <option>---User---</option>
                {usersItemsRender()}
              </Input>
            </FormGroup>

            <FormGroup>
              <label htmlFor="status">Status</label>
              <Input
                className="form-control-alternative"
                value={sellBindData.invoiceStatus}
                id="invoiceStatus"
                placeholder="invoiceStatus"
                type="select"
                onChange={(e) => onChangeSearchInputHandler(e)}
              >
                <option>---Status---</option>
                <option value={"0"}>Pending</option>
                <option value={"1"}>Delivery</option>
              </Input>
            </FormGroup>

            <FormGroup>
              <label htmlFor="from">From</label>
              <Input
                className="form-control-alternative"
                value={sellBindData.from}
                id="from"
                placeholder="from"
                type="date"
                onChange={(e) => onChangeSearchInputHandler(e)}
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="from">to</label>
              <Input
                className="form-control-alternative"
                value={sellBindData.to}
                id="to"
                placeholder="to"
                type="date"
                onChange={(e) => onChangeSearchInputHandler(e)}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => onBindSellData()}>
            Search
          </Button>

          <Button color="secondary" onClick={SearchToggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
