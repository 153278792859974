import React, { useState, useEffect } from "react";
import GeneralHeader from "components/Headers/GeneralHeader";
import Select from "react-select";
import axios from "axios";
import Swal from "sweetalert2";
// import NumberFormat from "react-number-format";
import Moment from "react-moment";
import {
  Button,
  Card,
  CardHeader,
  CardFooter,
  CardBody,
  Form,
  Table,
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  Badge,
} from "reactstrap";
import { useSelector } from "react-redux";
import moment from "moment";
const path = require("../../externals/path.json").path;

export default function Index() {
  const user = useSelector((state) => state.isLogged.user);

  const [itemList, setItemList] = useState([]);
  const [sampleOrLossList, setSampleOrLossList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [doctorList, setDoctorList] = useState([]);
  const [hospitalList, setHospitalList] = useState([]);
  const [showAddModal, setshowAddModal] = useState(false);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [customerOptionIndex, setCustomerOptionIndex] = useState();
  const [doctorOptions, setDoctorOptions] = useState([]);
  const [doctorOptionIndex, setdoctorOptionIndex] = useState();
  const [hospitalOptions, setHospitalOptions] = useState([]);
  const [hospitalOptionIndex, setHospitalOptionIndex] = useState();
  const [type, setType] = useState();
  const [sampleFor, setSampleFor] = useState();
  const [sampleOrLossId, setSampleOrLossId] = useState();
  const [customerId, setCustomerId] = useState();
  const [doctorId, setDoctorId] = useState();
  const [hosptialId, setHosptialId] = useState();
  const [quantity, setQuantity] = useState();
  const [quantityType, setQuantityType] = useState();
  const [inventoryId, setInventoryId] = useState();
  const [itemId, setitemId] = useState();
  const [stock, setStock] = useState();
  const [stockout, setStockout] = useState("0");
  const [username, setusername] = useState();
  const [deliveredBy, setDeliveredBy] = useState();
  const [expiry, setExpiry] = useState();
  const [zoneId, setZoneId] = useState();
  const [isUpdate, setIsUpdate] = useState();
  const [itemPriceList, setItemPriceList] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [quantityPerBox, setQuantityPerBox] = useState();
  const [searchcityId, setsearchcityId] = useState();
  const [searchzoneId, setSearchZoneId] = useState();
  const [cityList, setCityList] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [searchdeliveredBy, setSearchDeliveredBy] = useState();

  function toggle() {
    setshowAddModal(!showAddModal);
  }

  function onBindSampleOrLossData() {
    let index = usersList.findIndex(
      (element) => element.id === Number(searchdeliveredBy)
    );
    axios
      .post(
        path + "/sampleorlosslist",
        {
          userId: user.id,
          leaderId: user.leaderId,
          role: user.role,
          deliveredBy: index !== -1 ? usersList[index].id : null,
          cityId: searchcityId !== "0" ? searchcityId : null,
          zoneId: searchzoneId !== "0" ? searchzoneId : null,
        },
        {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        }
      )
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setSampleOrLossList(result.data.data);
        }
      });
  }

  useEffect(() => {
    onBindSampleOrLossData();
    onBindHospitalData();
    onBindDoctorData();
    onBindCustomerData();
    onBindItemsData();
    onBindUsersData();
    onBindcityData();
  }, []);
  useEffect(() => {
    onBindSampleOrLossData();
  }, [searchcityId, searchdeliveredBy, searchzoneId]);
  function onBindcityData() {
    axios
      .get(path + "/cities", {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setCityList(result.data.data);
        }
      });
  }

  function onBindZoneDataHandler(cityId) {
    axios
      .get(path + "/zone/" + Number(cityId), {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.data !== null) setZoneList(result.data.data);
      });
  }
  function onBindUsersData() {
    axios
      .get(path + "/users", {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setUsersList(result.data.data);
        }
      });
  }
  function usersItemsRender() {
    let items = [];
    usersList.map((item) =>
      items.push(
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      )
    );
    return items;
  }

  function onBindCustomerData() {
    axios
      .post(
        path + "/customers/sell",
        { role: user.role, zones: user.zones, userId: user.id },
        {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        }
      )
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setCustomerList(result.data.data);
          let options = [{ value: -1, label: "----Customer List ----" }];
          result.data.data.forEach((item, index) => {
            options.push({ value: index, label: item.name });
          });
          setCustomerOptions(options);
        }
      });
  }

  function onBindDoctorData() {
    axios
      .post(
        path + "/doctors/list",
        { role: user.role, zones: user.zones },
        {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        }
      )
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setDoctorList(result.data.data);
          let options = [{ value: -1, label: "----Doctor List ----" }];

          result.data.data.forEach((item, index) => {
            options.push({ value: index, label: item.name });
          });
          setDoctorOptions(options);
        }
      });
  }

  function onBindHospitalData() {
    axios
      .post(
        path + "/hospitals/list",
        { role: user.role, zones: user.zones },
        {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        }
      )
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setHospitalList(result.data.data);
          let options = [{ value: -1, label: "----Hospital List ----" }];

          result.data.data.forEach((item, index) => {
            options.push({ value: index, label: item.name });
          });
          setHospitalOptions(options);
        }
      });
  }

  function onBindItemsData() {
    axios
      .get(path + "/items", {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setItemList(result.data.data);
        }
      });
  }

  function renderSampleForRow(item) {
    if (item.doctorName) {
      return (
        <td
          style={{
            fontSize: 15,
          }}
        >
          {item.doctorName}
        </td>
      );
    } else if (item.customerName) {
      return (
        <td
          style={{
            fontSize: 15,
          }}
        >
          {item.customerName}
        </td>
      );
    } else if (item.hospitalName) {
      return (
        <td
          style={{
            fontSize: 15,
          }}
        >
          {item.hospitalName}
        </td>
      );
    } else {
      return (
        <td
          style={{
            fontSize: 15,
          }}
        ></td>
      );
    }
  }
  function onCustomerChangeHandler(e) {
    setCustomerOptionIndex({
      value: e.value,
      label: customerList[e.value].name,
    });
    setCustomerId(customerList[e.value].id);
    setZoneId(customerList[e.value].zone);
    setDoctorId();
    setHosptialId();
    setHospitalOptionIndex();
    setdoctorOptionIndex();
  }
  function onDoctorChangeHandler(e) {
    setdoctorOptionIndex({
      value: e.value,
      label: doctorList[e.value].name,
    });
    setDoctorId(doctorList[e.value].id);
    setZoneId(doctorList[e.value].zoneId);
    setHosptialId();
    setCustomerId();
    setHospitalOptionIndex();
    setCustomerOptionIndex();
  }
  function onHospitalChangeHandler(e) {
    setHospitalOptionIndex({
      value: e.value,
      label: hospitalList[e.value].name,
    });
    setHosptialId(hospitalList[e.value].id);
    setZoneId(hospitalList[e.value].zoneId);
    setDoctorId();
    setCustomerId();
    setCustomerOptionIndex();
    setdoctorOptionIndex();
  }

  function onchangeOfferForhandler(e) {
    setSampleFor(e.target.value);
    setCustomerOptionIndex(null);
    setHospitalOptionIndex(null);
    setdoctorOptionIndex(null);
    setCustomerId(null);
    setHosptialId(null);
    setDoctorId(null);
    if (e.target.value === "Doctor") {
      onBindDoctorData();
    } else if (e.target.value === "Hospital") {
      onBindHospitalData();
    } else if (e.target.value === "Customer") {
      onBindCustomerData();
    } else {
    }
  }

  function listItemsRender() {
    if (type === "Free Sample") {
      if (sampleFor === "Doctor") {
        return (
          <Col
            lg={4}
            style={{
              size: 10,
            }}
          >
            <FormGroup>
              <label
                style={{
                  fontSize: 16,
                  fontWeight: "bold",
                }}
              >
                Doctor
              </label>
              <Select
                value={doctorOptionIndex}
                onChange={(e) => onDoctorChangeHandler(e)}
                options={doctorOptions}
                styles={{
                  control: (base) => ({
                    ...base,
                    height: 44,
                    minHeight: 44,
                  }),
                }}
              />
            </FormGroup>
          </Col>
        );
      } else if (sampleFor === "Hospital") {
        return (
          <Col
            lg={4}
            style={{
              size: 10,
            }}
          >
            <FormGroup>
              <label
                style={{
                  fontSize: 16,
                  fontWeight: "bold",
                }}
              >
                Hospital
              </label>
              <Select
                value={hospitalOptionIndex}
                onChange={(e) => onHospitalChangeHandler(e)}
                options={hospitalOptions}
                styles={{
                  control: (base) => ({
                    ...base,
                    height: 44,
                    minHeight: 44,
                  }),
                }}
              />
            </FormGroup>
          </Col>
        );
      } else if (sampleFor === "Customer") {
        return (
          <Col
            lg={4}
            style={{
              size: 10,
            }}
          >
            <FormGroup>
              <label
                style={{
                  fontSize: 16,
                  fontWeight: "bold",
                }}
              >
                Customer
              </label>
              <Select
                value={customerOptionIndex}
                onChange={(e) => onCustomerChangeHandler(e)}
                options={customerOptions}
                styles={{
                  control: (base) => ({
                    ...base,
                    height: 44,
                    minHeight: 44,
                  }),
                }}
              />
            </FormGroup>
          </Col>
        );
      } else {
      }
    }
  }

  function onAddNewDataHandler() {
    setType();
    setSampleFor();
    setCustomerOptionIndex();
    setCustomerId();
    setDoctorId();
    setHosptialId();
    setQuantity();
    setQuantity();
    setusername(user.id);
    setIsUpdate(false);
    setStockout("0");
    toggle();
    setitemId();
    setStock();
    setZoneId();
    setItemPriceList([]);
    onBindUsersData();
    setQuantityType();
    setDeliveredBy();
    setQuantityPerBox();
  }

  function ItemsRender() {
    let items = [];
    itemList.map((item) =>
      items.push(
        <option key={item.id} value={item.id}>
          {item.nameKrd}
        </option>
      )
    );
    return items;
  }

  function onItemChangeHandler(id, isUpd) {
    setitemId(id);
    setItemPriceList([]);
    if (type === "Free Sample" && !zoneId) {
      Swal.fire({
        title: "warning",
        text: "تکایە لە پێشدا کەسمەر یان دکتۆر یان نەخۆشخانەیەک دیاری بکە",
        icon: "warning",
      });
    } else {
      axios
        .get(
          path + `/items/sampleorloss/${id}/${zoneId}/${isUpdate}/${type}`,

          {
            // headers: {
            //   "auth-token": this.props.user.user.token,
            // },
          }
        )
        .then((result) => {
          // if (result.data.token !== null)
          //   this.props.user.user.token = result.data.token;

          if (result.data.success === true) {
            setItemPriceList(result.data.data);
          }
        })
        .catch((err) => {
          setItemPriceList([]);
          // Swal.fire({
          //   title: "warning",
          //   text: "There is no price for this Item in Inventory",
          //   icon: "warning",
          // });
        });
    }
  }
  function StockRender() {
    let items = [];

    itemPriceList.map((item) =>
      items.push(
        <option
          key={item.inventoryId}
          value={item.inventoryId}
          style={{
            fontSize: 15,
            fontWeight: "bold",
            color: "black",
          }}
        >
          {moment(item.expiry).format("YYYY-MM-DD").toString()} ({" "}
          {item.stock.toFixed(2)} )( {item.inventoryName} )
        </option>
      )
    );
    return items;
  }
  function onStockChangeHandler(e, index) {
    let arrayIndex = itemPriceList.findIndex(
      (obj) => obj.inventoryId === Number(e.target.value)
    );
    console.log(itemPriceList[arrayIndex]);
    setExpiry(itemPriceList[arrayIndex].expiry);
    setInventoryId(e.target.value);
    setStock(itemPriceList[arrayIndex].stock);
    setQuantityPerBox(itemPriceList[arrayIndex].quantityPerBox);
  }

  function onsaveDataHandler() {
    const sampleOrLoss = {
      type: type,
      sampleFor: sampleFor,
      customerId: customerId,
      doctorId: doctorId,
      hospitalId: hosptialId,
      itemId: itemId,
      quantityType: quantityType,
      quantity: quantity,
      quantityPerBox: quantityPerBox,
      inventoryId: inventoryId,
      zoneId: zoneId,
      expiry: moment(expiry).format("YYYY-MM-DD").toString(),
      stockout: stockout,
      deliveredBy: deliveredBy,
      username: username,
    };

    if (isUpdate === true) {
      axios
        .put(path + "/sampleorloss/" + sampleOrLossId, sampleOrLoss, {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        })
        .then((result) => {
          if (result.data.success === true) {
            onBindSampleOrLossData();
            setshowAddModal(false);
            setIsUpdate(false);
            Swal.fire({
              title: "THANKS",
              text: "the Sample or loss updated",
              icon: "success",
            });
          }
          // if (result.data.token !== null)
          //   this.props.user.user.token = result.data.token;
        })
        .catch((error) => {
          Swal.fire({
            title: "warning",
            text: "Sample or loss did not updated please contact administrator",
            icon: "warning",
          });
        });
    } else {
      axios
        .post(path + "/sampleorloss", sampleOrLoss, {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        })
        .then((result) => {
          if (result.data.success === true) {
            onBindSampleOrLossData();
            setshowAddModal(false);
            Swal.fire({
              title: "THANKS",
              text: "new Sample or loss Added",
              icon: "success",
            });
          }
          // if (result.data.token !== null)
          //   this.props.user.user.token = result.data.token;
        })
        .catch((error) => {
          Swal.fire({
            title: "warning",
            text: "Sample or loss did not added please contact administrator",
            icon: "warning",
          });
        });
    }
  }

  function onChangeTypeHandler(e) {
    if (e.target.value !== "Free Sample") {
      setSampleFor();
      setItemPriceList([]);
      setitemId();
      setInventoryId();
      setZoneId();
    }
    setType(e.target.value);
  }

  function EditDataHandler(sampleOrLossId, Index) {
    // let item = itemList.find((o) => o.id === item.itemId);
    axios
      .get(path + "/sampleorloss/" + sampleOrLossId, {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          axios
            .get(
              path +
                `/items/sampleorloss/${result.data.data.itemId}/${result.data.data.zoneId}/true/${type}`,

              {
                // headers: {
                //   "auth-token": this.props.user.user.token,
                // },
              }
            )
            .then((result) => {
              // if (result.data.token !== null)
              //   this.props.user.user.token = result.data.token;

              if (result.data.success === true) {
                setItemPriceList(result.data.data);
              }
            })
            .catch((err) => {
              // console.log("--------------");
              // console.log(err);
              // Swal.fire({
              //   title: "warning",
              //   text: "There is no price for this Item in this zone please entter price first",
              //   icon: "warning",
              // });
            });
          setIsUpdate(true);
          setshowAddModal(true);
          setType(result.data.data.type);
          setSampleFor(result.data.data.sampleFor);
          setInventoryId(result.data.data.inventoryId);
          setExpiry(result.data.data.expiry);
          setusername(result.data.data.username);
          setStockout(result.data.data.stockout);
          setSampleOrLossId(sampleOrLossId);
          setQuantity(result.data.data.quantity);
          setQuantityType(result.data.data.quantityType);
          onItemChangeHandler(result.data.data.itemId.toString(), "true");
          setZoneId(result.data.data.zoneId);
          setDeliveredBy(result.data.data.deliveredBy);
          setQuantityPerBox(result.data.data.quantityPerBox);

          if (result.data.data.customerId) {
            const index = customerList.findIndex(
              (item) => item.id === result.data.data.customerId
            );
            onCustomerChangeHandler({
              value: index,
              label: customerList[index].name,
            });
          } else if (result.data.data.doctorId) {
            const index = doctorList.findIndex(
              (item) => item.id === result.data.data.doctorId
            );
            onDoctorChangeHandler({
              value: index,
              label: doctorList[index].name,
            });
          } else if (result.data.data.hosptialId) {
            const index = hospitalList.findIndex(
              (item) => item.id === result.data.data.hosptialId
            );
            onHospitalChangeHandler({
              value: index,
              label: hospitalList[index].name,
            });
          }
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "warning",
          text: `there is some error while get Sample or loss with  number ${Index} data please contact administrator`,
          icon: "warning",
        });
      });
  }

  function onDeleteDataHandler(id, index) {
    Swal.fire({
      title: 'Do you want to delete Sample or loss  "' + index + '" ?',
      showCancelButton: true,
      confirmButtonText: "Save",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.value) {
        axios
          .delete(path + "/sampleorloss/" + id, {
            // headers: {
            //   "auth-token": this.props.user.user.token,
            // },
          })
          .then((result) => {
            if (result.data.statusCode === 200) {
              onBindSampleOrLossData();
              Swal.fire("Sample or loss Deleted!", "", "success");
            }
            // if (result.data.token !== null)
            //   this.props.user.user.token = result.data.token;
          })
          .catch((error) => {
            Swal.fire({
              title: "warning",
              text: "the Sample or loss did not deleted please contact administrator",
              icon: "warning",
            });
          });
      }
    });
  }

  function ondeliverySellItems(id, index) {
    Swal.fire({
      title: 'Do you want to stockout items with id "' + index + '" ?',
      showCancelButton: true,
      confirmButtonText: "Save",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.value) {
        axios
          .get(path + "/sampleorloss/" + id, {
            // headers: {
            //   "auth-token": this.props.user.user.token,
            // },
          })
          .then((result) => {
            // if (result.data.token !== null)
            //   this.props.user.user.token = result.data.token;
            if (result.data.success === true) {
              let quantity;
              if (result.data.data.quantityType === "Box")
                quantity =
                  (1 / result.data.data.quantityPerBox) *
                  result.data.data.quantity;
              else {
                quantity = result.data.data.quantity;
              }
              let deliveryData = {
                id: result.data.data.inventoryId,
                quantity: quantity,
              };
              axios
                .put(path + "/inventory/sampleorLoss", deliveryData, {
                  // headers: {
                  //   "auth-token": this.props.user.user.token,
                  // },
                })
                .then((result) => {
                  if (result.data.success === true) {
                    axios
                      .put(path + "/sampleorloss/stockout/" + id, {
                        // headers: {
                        //   "auth-token": this.props.user.user.token,
                        // },
                      })
                      .then((result) => {
                        if (result.data.success === true) {
                          onBindSampleOrLossData();
                          Swal.fire({
                            title: "THANKS",
                            text: "the stockout done",
                            icon: "success",
                          });
                        }
                      })
                      .catch((error) => {
                        Swal.fire({
                          title: "warning",
                          text: `there is some error while stockout with id No ${index}`,
                          icon: "warning",
                        });
                      });
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    title: "warning",
                    text: `there is some error while stockout with id No ${index}`,
                    icon: "warning",
                  });
                });
            }
          })
          .catch((error) => {
            Swal.fire({
              title: "warning",
              text: `there is some error while get Sample or loss with  number ${Index} data please contact administrator`,
              icon: "warning",
            });
          });
      }
    });
  }

  function cityItemsRender() {
    let items = [];
    cityList.map((item) =>
      items.push(
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      )
    );
    return items;
  }
  function zoneItemsRender() {
    let items = [];
    zoneList.map((item) =>
      items.push(
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      )
    );
    return items;
  }
  function onChangeCityHandler(event) {
    setsearchcityId(event.target.value);
    setSearchZoneId("0");

    onBindZoneDataHandler(event.target.value);
  }

  return (
    <>
      <GeneralHeader />

      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h2 className="mb-0">Sample Or Loss List</h2>
                  </Col>{" "}
                  <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      onClick={(e) => onAddNewDataHandler()}
                      size="md"
                    >
                      New Sample
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input
                      className="form-control-alternative"
                      value={searchdeliveredBy}
                      id="searchdeliveredBy"
                      placeholder="searchdeliveredBy"
                      type="select"
                      onChange={(e) => setSearchDeliveredBy(e.target.value)}
                      style={{ fontSize: 16, color: "black", height: 45 }}
                    >
                      <option value={0}>---User---</option>
                      {usersItemsRender()}
                    </Input>
                  </Col>
                  <Col>
                    <Input
                      className="form-control-alternative"
                      value={searchcityId}
                      id="searchcityId"
                      placeholder="cityId"
                      type="select"
                      onChange={(e) => onChangeCityHandler(e)}
                      style={{ fontSize: 16, color: "black", height: 45 }}
                    >
                      <option key={0} value={0}>
                        ---city---
                      </option>
                      {cityItemsRender()}
                    </Input>
                  </Col>
                  <Col>
                    <Input
                      className="form-control-alternative"
                      value={searchzoneId}
                      id="searchzoneId"
                      placeholder="searchzoneId"
                      type="select"
                      onChange={(e) => {
                        setSearchZoneId(e.target.value);
                      }}
                      style={{ fontSize: 16, color: "black", height: 45 }}
                    >
                      <option key={0} value={0}>
                        ---zone---
                      </option>
                      {zoneItemsRender()}
                    </Input>
                  </Col>
                </Row>
              </CardHeader>
              <div style={{ height: "76vh", overflow: "scroll" }}>
                <Table
                  // className=" align-items-center table-flush"
                  bordered
                  style={{ fontWeight: "bold", color: "black" }}
                >
                  <thead className="thead-light">
                    <tr>
                      <th
                        scope="col"
                        style={{
                          fontSize: 15,
                        }}
                        className="text-center"
                      >
                        #
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontSize: 15,
                        }}
                        className="text-center"
                      >
                        TYPE
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontSize: 15,
                        }}
                        className="text-center"
                      >
                        S.FOR
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontSize: 15,
                        }}
                        className="text-center"
                      >
                        (Name)
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontSize: 15,
                        }}
                        className="text-center"
                      >
                        Item Name
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontSize: 15,
                        }}
                        className="text-center"
                      >
                        Q. Type
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontSize: 15,
                        }}
                        className="text-center"
                      >
                        Q.
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontSize: 15,
                        }}
                        className="text-center"
                      >
                        Location
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontSize: 15,
                        }}
                        className="text-center"
                      >
                        Delivered By
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontSize: 15,
                        }}
                        className="text-center"
                      >
                        Stockout
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontSize: 15,
                        }}
                        className="text-center"
                      >
                        Date
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontSize: 15,
                        }}
                        className="text-center"
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {sampleOrLossList.map(function (item, index) {
                      return (
                        <tr className="text-center">
                          <th scope="row" className="text-center">
                            {index + 1}
                          </th>
                          <td
                            style={{
                              fontSize: 15,
                            }}
                            className="text-center"
                          >
                            {item.type}
                          </td>
                          <td
                            style={{
                              fontSize: 15,
                            }}
                            className="text-center"
                          >
                            {item.sampleFor}
                          </td>
                          {renderSampleForRow(item)}
                          <td
                            style={{
                              fontSize: 15,
                            }}
                            className="text-center"
                          >
                            {item.itemName}
                          </td>
                          <td
                            style={{
                              fontSize: 15,
                            }}
                            className="text-center"
                          >
                            {item.quantityType}
                          </td>{" "}
                          <td
                            style={{
                              fontSize: 15,
                            }}
                            className="text-center"
                          >
                            {item.quantity}
                          </td>
                          <td
                            style={{
                              fontSize: 15,
                            }}
                            className="text-center"
                          >
                            {item.location}
                          </td>
                          <td
                            style={{
                              fontSize: 15,
                            }}
                            className="text-center"
                          >
                            {item.deliveredBy}
                          </td>
                          <td
                            style={{
                              fontSize: 15,
                            }}
                            className="text-center"
                          >
                            {item.stockout === "1" ? (
                              <Badge color="success">YES</Badge>
                            ) : (
                              <Badge color="danger">No</Badge>
                            )}
                          </td>
                          <td
                            style={{
                              fontSize: 15,
                            }}
                            className="text-center"
                          >
                            <Moment format="DD-MM-YYYY">{item.date}</Moment>
                          </td>
                          <td
                            style={{
                              fontSize: 15,
                            }}
                            className="text-center"
                          >
                            <div>
                              <Button
                                size="sm"
                                color="info"
                                onClick={() =>
                                  ondeliverySellItems(item.id, index + 1)
                                }
                                disabled={item.stockout === "1" ? true : false}
                              >
                                Stockout
                              </Button>{" "}
                              {user.role === "admin" ||
                              user.role === "accountant" ? (
                                <>
                                  <Button
                                    size="sm"
                                    color="warning"
                                    onClick={() =>
                                      EditDataHandler(item.id, index + 1)
                                    }
                                    disabled={
                                      item.stockout === "1" ? true : false
                                    }
                                  >
                                    Edit
                                  </Button>{" "}
                                  <Button
                                    size="sm"
                                    color="danger"
                                    onClick={() =>
                                      onDeleteDataHandler(item.id, index + 1)
                                    }
                                    disabled={
                                      item.stockout === "1" ? true : false
                                    }
                                  >
                                    Delete
                                  </Button>{" "}
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem className="disabled">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem className="active">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        1
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        isOpen={showAddModal}
        toggle={toggle}
        className="modal-lg"
        style={{ maxWidth: "1500px", width: "100%" }}
      >
        <ModalHeader toggle={toggle}>New Sample Or Loss</ModalHeader>
        <ModalBody>
          <Col className="order-xl-1">
            <Card className="shadow" style={{ backgroundColor: "#F0F8FF" }}>
              <CardBody>
                <Form autoComplete="off" id="ff">
                  <h6 className="heading-small text-muted mb-2">
                    Sample Or Loss information
                  </h6>
                  <div className="pl-lg-2">
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            htmlFor="type"
                            style={{
                              fontSize: 16,
                              fontWeight: "bold",
                            }}
                          >
                            Type
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={type}
                            id="type"
                            placeholder="type"
                            type="select"
                            onChange={(e) => onChangeTypeHandler(e)}
                          >
                            <option>---Type---</option>
                            <option>Free Sample</option>
                            <option>Loss</option>
                          </Input>
                        </FormGroup>
                      </Col>
                      {type === "Free Sample" ? (
                        <Col lg="4">
                          <label
                            htmlFor="sampleFor"
                            style={{
                              fontSize: 16,
                              fontWeight: "bold",
                            }}
                          >
                            Sample For
                          </label>
                          <FormGroup>
                            <Input
                              className="form-control-alternative"
                              value={sampleFor}
                              id="sampleFor"
                              placeholder="sampleFor"
                              type="select"
                              onChange={(e) => onchangeOfferForhandler(e)}
                            >
                              <option>---Sample For---</option>
                              <option>Doctor</option>
                              <option>Customer</option>
                              <option>Hospital</option>
                            </Input>
                          </FormGroup>
                        </Col>
                      ) : (
                        ""
                      )}
                      {listItemsRender()}
                    </Row>
                    <Row>
                      <Col lg="3">
                        <FormGroup>
                          <label
                            htmlFor="itemId"
                            style={{
                              fontSize: 16,
                              fontWeight: "bold",
                            }}
                          >
                            Item
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={itemId}
                            id="itemId"
                            placeholder="itemId"
                            type="select"
                            onChange={(e) =>
                              onItemChangeHandler(e.target.value)
                            }
                          >
                            <option>---item---</option>
                            {ItemsRender()}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label
                            htmlFor="quantity"
                            style={{
                              fontSize: 16,
                              fontWeight: "bold",
                            }}
                          >
                            Quantity Type
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={quantityType}
                            id="quantityType"
                            placeholder="quantityType"
                            type="select"
                            onChange={(e) => {
                              if (e.target.value !== "0")
                                setQuantityType(e.target.value);
                              else setQuantityType();
                            }}
                          >
                            <option value={0}>--- quantityType ---</option>
                            <option value={"Box"}>Box</option>
                            <option value={"Carton"}> Carton </option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label
                            htmlFor="quantity"
                            style={{
                              fontSize: 16,
                              fontWeight: "bold",
                            }}
                          >
                            Quantity
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={quantity}
                            id="quantity"
                            placeholder="Quantity"
                            type="number"
                            onChange={(e) => setQuantity(e.target.value)}
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="3">
                        <FormGroup>
                          <label
                            htmlFor="stock"
                            style={{
                              fontSize: 16,
                              fontWeight: "bold",
                            }}
                          >
                            Stock
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={inventoryId}
                            id="inventoryId"
                            placeholder="inventoryId"
                            type="select"
                            onChange={(e) => onStockChangeHandler(e)}
                          >
                            <option>---expiry---</option>
                            {StockRender()}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            htmlFor="deliveredBy"
                            style={{
                              fontSize: 16,
                              fontWeight: "bold",
                            }}
                          >
                            Delivered By
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={deliveredBy}
                            id="deliveredBy"
                            placeholder="deliveredBy"
                            type="select"
                            onChange={(e) => {
                              if (e.target.value !== "0")
                                setDeliveredBy(e.target.value);
                              else setDeliveredBy();
                            }}
                          >
                            <option value={0}>--- delivered By ---</option>
                            {usersItemsRender()}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => onsaveDataHandler()}>
            Save
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
