import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import Swal from "sweetalert2";
import GeneralHeader from "components/Headers/GeneralHeader";
import NumberFormat from "react-number-format";
import Moment from "react-moment";
import moment from "moment";
import axios from "axios";
import { useSelector } from "react-redux";

import ReactToPrint from "react-to-print";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

import {
  Card,
  CardHeader,
  CardBody,
  Form,
  // CardFooter,
  Table,
  Container,
  Row,
  Col,
  // DropdownMenu,
  // DropdownItem,
  // UncontrolledDropdown,
  // DropdownToggle,
  // Pagination,
  // PaginationItem,
  // PaginationLink,
  Button,
  // ButtonGroup,
  Modal,
  // ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  ModalHeader,
  FormGroup,
  Label,
} from "reactstrap";

const path = require("../../externals/path.json").path;

export default function Distribution() {
  const user = useSelector((state) => state.isLogged.user);
  const initiaDistributionlState = {
    item: null,
    itemId: null,
    availableQunatity: 0,
    quantity: 0,
    expiry: 0,
    username: user.id,
  };

  const [showDistributionModal, setShowDistributionModal] = useState(false);
  const [invoiceNumber, setinvoiceNumber] = useState();
  const [isUpdate, setisUpdate] = useState(false);
  const [itemList, setItemList] = useState([]);
  const [distributionList, setDistributionList] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [inputList, setInputList] = useState([initiaDistributionlState]);
  const [to, setTo] = useState();
  const [from, setFrom] = useState();
  const [receiver, setReceiver] = useState();

  const [availableQunatity, setAvailableQunatity] = useState();
  const [inventoryLocationList, setInventoryLocationList] = useState([]);
  const [availableItemsInLocation, setAvailableItemsInLocationList] = useState(
    []
  );

  const [Searchto, setSearchTo] = useState();
  const [Searchfrom, setSearchFrom] = useState();
  const [searchReceiver, setSearchReceiver] = useState();
  const [searchFromStock, setSearchFromStock] = useState();
  const [searchToStock, setSearchToStock] = useState();

  const distributionToggle = () => {
    setShowDistributionModal(!showDistributionModal);
  };

  useEffect(() => {
    onBindDistributionData();
  }, [Searchfrom, Searchto, searchReceiver, searchToStock, searchFromStock]);
  useEffect(() => {
    onBindInventoryLocation();
    onBindUsersData();
  }, []);

  const onBindDistributionData = () => {
    axios
      .post(
        path + "/distribution/list",
        {
          from: Searchfrom,
          to: Searchto,
          searchReceiver: searchReceiver,
          searchFromStock: searchFromStock,
          searchToStock: searchToStock,
        },
        {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        }
      )
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setDistributionList(result.data.data);
        }
      });
  };
  function onBindUsersData() {
    axios
      .get(path + "/users", {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setUsersList(result.data.data);
        }
      });
  }
  const onBindInventoryLocation = () => {
    axios
      .get(path + "/inventoryLocation", {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true)
          setInventoryLocationList(result.data.data);
      });
  };
  const onBindAvialableItemsInLocationDataHandler = (LocationId) => {
    axios
      .get(path + "/inventory/available/" + Number(LocationId), {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setAvailableItemsInLocationList(result.data.data);
        } else {
          setAvailableItemsInLocationList([]);
        }
      });
  };
  const onChangeinventoryLocationIdHandler = (event) => {
    setFrom(event.target.value);
    onBindAvialableItemsInLocationDataHandler(event.target.value);
  };

  function onAddDistributionHandler() {
    setFrom();
    setTo();
    setAvailableQunatity();
    setReceiver();
    setShowDistributionModal(true);
    onBindUsersData();
    setInputList([initiaDistributionlState]);
    setisUpdate(false);
    setinvoiceNumber();
  }

  const handleAddClick = () => {
    setInputList([...inputList, initiaDistributionlState]);
  };
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const inventoryLocationItemsRender = () => {
    let items = [];
    inventoryLocationList.map((item) =>
      items.push(<option value={item.id}>{item.name}</option>)
    );
    return items;
  };
  const handleInputChange = (e, index) => {
    const { id, value } = e.target;
    const list = [...inputList];
    list[index][id] = value;
    setInputList(list);
  };

  const ItemsRender = () => {
    let items = [];
    availableItemsInLocation.map((item, index) =>
      items.push(
        <option value={index}>
          {item.itemName} ({moment(item.expiry).format("YYYY-MM-DD").toString()}{" "}
          )
        </option>
      )
    );
    return items;
  };
  const onItemChangeHandler = (e, index) => {
    const { id, value } = e.target;
    const list = [...inputList];
    list[index][id] = value;

    list[index]["itemId"] = availableItemsInLocation[value].itemId;
    list[index]["expiry"] = availableItemsInLocation[value].expiry;
    list[index]["availableQunatity"] = availableItemsInLocation[value].quantity;
    list[index]["inventoryId"] = availableItemsInLocation[value].inventoryId;
    setInputList(list);
  };
  function usersItemsRender() {
    let items = [];
    usersList.map((item) =>
      items.push(<option value={item.id}>{item.name}</option>)
    );
    return items;
  }

  function onsaveDataHandler() {
    axios
      .get(path + "/distributionlastinvoice", {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        if (result.data.success === true) {
          let distribution = [];
          let invNO = result.data.data.invoiceNumber + 1;

          let isCorrect = true;
          inputList.forEach((item) => {
            if (Number(item.quantity) > Number(item.availableQunatity)) {
              isCorrect = false;
            }
            distribution.push({
              invoiceNumber: isUpdate ? invoiceNumber : invNO,
              itemId: item.itemId,
              quantity: Number(item.quantity),
              fromINV: Number(from),
              toINV: Number(to),
              inventoryId: item.inventoryId,
              receiver: Number(receiver),
              username: item.username,
              status: "0",
              expiry: moment(item.expiry).format("YYYY-MM-DD").toString(),
              id: isUpdate ? item.id : 0,
            });
          });

          if (isCorrect) {
            axios
              .put(path + "/distribution", distribution, {
                // headers: {
                //   "auth-token": this.props.user.user.token,
                // },
              })
              .then((result) => {
                if (result.data.success === true) {
                  onBindDistributionData();
                  setShowDistributionModal(false);
                  setisUpdate(false);
                  Swal.fire({
                    title: "THANKS",
                    text: "the Distribution done",
                    icon: "success",
                  });
                }
                // if (result.data.token !== null)
                //   this.props.user.user.token = result.data.token;
              })
              .catch((error) => {
                Swal.fire({
                  title: "warning",
                  text: "there is some error while saving Distribution data please contact administrator",
                  icon: "warning",
                });
              });
          } else {
            Swal.fire({
              title: "warning",
              text: "the quantity is greater than stock",
              icon: "warning",
            });
          }
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "warning",
          text: "there is some error while getting invoice number please contact administrator",
          icon: "warning",
        });
      });
  }

  function EditDataHandler(invoiceNo) {
    // let item = itemList.find((o) => o.id === item.itemId);
    axios
      .get(path + "/distribution/id/" + invoiceNo, {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          axios
            .get(
              path +
                "/inventory/available/" +
                Number(result.data.data[0].fromINV),
              {
                // headers: {
                //   "auth-token": this.props.user.user.token,
                // },
              }
            )
            .then((res) => {
              // if (result.data.token !== null)
              //   this.props.user.user.token = result.data.token;
              if (res.data.success === true) {
                setAvailableItemsInLocationList(res.data.data);
                let list = [];
                result.data.data.forEach((item) => {
                  const index = res.data.data.findIndex((obj, index) => {
                    return obj.inventoryId === item.inventoryId;
                  });
                  list.push({
                    id: item.id,
                    item: index,
                    itemId: res.data.data[index].itemId,
                    availableQunatity: res.data.data[index].quantity,
                    quantity: item.quantity,
                    expiry: res.data.data[index].expiry,
                    username: res.data.data[index].username,
                    inventoryId: res.data.data[index].inventoryId,
                  });
                });
                setInputList(list);
                setShowDistributionModal(true);
                setisUpdate(true);
              } else {
                setAvailableItemsInLocationList([]);
              }
            });

          onBindUsersData();
          setFrom(result.data.data[0].fromINV);
          setTo(result.data.data[0].toINV);
          setReceiver(result.data.data[0].receiver);
          setinvoiceNumber(invoiceNo);
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "warning",
          text: `there is some error while get invoice with invoice number ${invoiceNo} data please contact administrator`,
          icon: "warning",
        });
      });
  }

  function ondeliverySellItems(invoiceNo) {
    Swal.fire({
      title:
        'Do you want to delivery items with invoiceNo "' + invoiceNo + '" ?',
      showCancelButton: true,
      confirmButtonText: "Save",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.value) {
        axios
          .get(path + "/distribution/id/" + invoiceNo, {
            // headers: {
            //   "auth-token": this.props.user.user.token,
            // },
          })
          .then((result) => {
            // if (result.data.token !== null)
            //   this.props.user.user.token = result.data.token;
            if (result.data.success === true) {
              let deliveryData = [];
              result.data.data.forEach((item) => {
                deliveryData.push({
                  inventoryId: item.inventoryId,
                  expiry: moment(item.expiry).format("YYYY-MM-DD").toString(),
                  itemId: item.itemid,
                  inventoryLocationId: item.toINV,

                  quantity: item.quantity,
                  distributionId: item.id,
                });
              });

              axios
                .post(path + "/inventory/fromdistribution", deliveryData, {
                  // headers: {
                  //   "auth-token": this.props.user.user.token,
                  // },
                })
                .then((result) => {
                  if (result.data.success === true) {
                    onBindDistributionData();
                    Swal.fire({
                      title: "THANKS",
                      text: "the delivery done",
                      icon: "success",
                    });
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    title: "warning",
                    text: `there is some error while delivery with invoice No ${invoiceNo}`,
                    icon: "warning",
                  });
                });
            }
          })
          .catch((error) => {
            Swal.fire({
              title: "warning",
              text: `there is some error while get invoice with invoice number ${invoiceNo} data please contact administrator`,
              icon: "warning",
            });
          });
      }
    });
  }

  function onDeleteSellHandler(invoiceNo) {
    Swal.fire({
      title: 'Do you want to delete Invoice  "' + invoiceNo + '" ?',
      showCancelButton: true,
      confirmButtonText: "Save",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.value) {
        axios
          .delete(path + "/distribution/" + invoiceNo, {
            // headers: {
            //   "auth-token": this.props.user.user.token,
            // },
          })
          .then((result) => {
            if (result.data.statusCode === 200) {
              onBindDistributionData();
              Swal.fire("Invoice Deleted!", "", "success");
            }
            // if (result.data.token !== null)
            //   this.props.user.user.token = result.data.token;
          })
          .catch((error) => {
            Swal.fire({
              title: "warning",
              text: "the Invoice did not deleted please contact administrator",
              icon: "warning",
            });
          });
      }
    });
  }

  return (
    <>
      <GeneralHeader />
      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row
                  className="align-items-center"
                  style={{ marginBottom: 10 }}
                >
                  <Col>
                    <Input
                      className="form-control-alternative"
                      value={searchFromStock}
                      id="from"
                      placeholder="inventory Location"
                      type="select"
                      onChange={(e) => {
                        if (e.target.value !== "0")
                          setSearchFromStock(e.target.value);
                        else setSearchFromStock();
                      }}
                    >
                      <option value={0}>---inventory from ---</option>
                      {inventoryLocationItemsRender()}
                    </Input>
                  </Col>
                  <Col>
                    <Input
                      className="form-control-alternative"
                      value={searchToStock}
                      id="from"
                      placeholder="inventory Location"
                      type="select"
                      onChange={(e) => {
                        if (e.target.value !== "0")
                          setSearchToStock(e.target.value);
                        else setSearchToStock();
                      }}
                    >
                      <option value={0}>---inventory to ---</option>
                      {inventoryLocationItemsRender()}
                    </Input>
                  </Col>
                  <Col>
                    <Input
                      className="form-control-alternative"
                      value={searchReceiver}
                      id="searchReceiver"
                      placeholder="searchReceiver"
                      type="select"
                      onChange={(e) => {
                        if (e.target.value !== "0")
                          setSearchReceiver(e.target.value);
                        else setSearchReceiver();
                      }}
                    >
                      <option value={0}>---delivered By---</option>
                      {usersItemsRender()}
                    </Input>
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col>
                    <Input
                      className="form-control-alternative"
                      value={Searchfrom}
                      id="Searchfrom"
                      placeholder="Searchfrom"
                      type="date"
                      onChange={(e) => setSearchFrom(e.target.value)}
                      style={{ fontSize: 16, color: "black", height: 45 }}
                    />
                  </Col>
                  <Col>
                    <Input
                      className="form-control-alternative"
                      value={Searchto}
                      id="Searchto"
                      placeholder="Searchto"
                      type="date"
                      onChange={(e) => setSearchTo(e.target.value)}
                      style={{ fontSize: 16, color: "black", height: 45 }}
                    />
                  </Col>

                  <Col className="text-right">
                    <Button
                      color="primary"
                      size="md"
                      onClick={onAddDistributionHandler}
                    >
                      New Distribution
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {distributionList.map(function (item, index) {
                  return (
                    <div style={{ marginBottom: 50, border: "1px solid " }}>
                      <div>
                        <Table
                          responsive
                          bordered
                          style={{ fontWeight: "bold", color: "black" }}
                        >
                          <thead
                            // className="thead-dark"
                            style={{ backgroundColor: "#F0F8FF" }}
                            className="text-center"
                          >
                            <tr>
                              <th
                                scope="col"
                                style={{
                                  fontSize: 15,
                                }}
                              >
                                Invoice No# : {item.invoiceNumber}
                              </th>
                              <th
                                scope="col"
                                style={{
                                  fontSize: 15,
                                }}
                              >
                                from : {item.fromName}
                              </th>
                              <th
                                scope="col"
                                style={{
                                  fontSize: 15,
                                }}
                              >
                                to : {item.toName}
                              </th>
                              <th
                                scope="col"
                                style={{
                                  fontSize: 15,
                                }}
                              >
                                Receiver : {item.receiver}
                              </th>
                              <th scope="col" colSpan="2">
                                Date :
                                <Moment format="YYYY/MM/DD">{item.date}</Moment>
                              </th>
                            </tr>
                          </thead>
                          <tbody></tbody>
                        </Table>
                      </div>
                      <div>
                        <Table
                          responsive
                          bordered
                          style={{ fontWeight: "bold", color: "black" }}
                        >
                          <thead className="thead-light text-center">
                            <tr>
                              <th
                                scope="col"
                                style={{
                                  fontSize: 15,
                                }}
                              >
                                #
                              </th>
                              <th
                                scope="col"
                                style={{
                                  fontSize: 15,
                                }}
                              >
                                Items
                              </th>
                              <th
                                scope="col"
                                style={{
                                  fontSize: 15,
                                }}
                              >
                                Quantity
                              </th>
                              <th
                                scope="col"
                                style={{
                                  fontSize: 15,
                                }}
                                colSpan="2"
                              >
                                Expiry
                              </th>
                            </tr>
                          </thead>
                          <tbody className="text-center">
                            {Array.from(item.id.split(","), Number).map(
                              function (i, ind) {
                                return (
                                  <tr>
                                    <th scope="row">{ind + 1}</th>
                                    <td
                                      style={{
                                        fontSize: 15,
                                      }}
                                    >
                                      {
                                        Array.from(
                                          item.itemName.split(","),
                                          String
                                        )[ind]
                                      }
                                    </td>
                                    <td
                                      style={{
                                        fontSize: 15,
                                      }}
                                    >
                                      {Array.from(
                                        item.quantity.split(","),
                                        Number
                                      )[ind].toFixed(2)}
                                    </td>
                                    <td
                                      style={{
                                        fontSize: 15,
                                      }}
                                    >
                                      <Moment
                                        format="DD-MM-YYYY"
                                        style={{
                                          fontSize: 15,
                                        }}
                                      >
                                        {
                                          Array.from(item.expiry.split(","))[
                                            ind
                                          ]
                                        }
                                      </Moment>
                                    </td>
                                    <td className="text-right">
                                      <div> </div>
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </Table>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: 10,
                        }}
                      >
                        {/* <Button
                          size="md"
                          color="info"
                          //   onClick={() => onPrintHandler(item.invoiceNumber)}
                        >
                          Print
                        </Button> */}
                        <Button
                          size="md"
                          color="success"
                          onClick={() =>
                            ondeliverySellItems(item.invoiceNumber)
                          }
                          disabled={item.status === "0" ? false : true}
                        >
                          Delivery
                        </Button>
                        {user.role === "admin" || user.role === "accountant" ? (
                          <>
                            <Button
                              size="md"
                              color="warning"
                              onClick={() =>
                                EditDataHandler(item.invoiceNumber)
                              }
                              disabled={item.status === "0" ? false : true}
                            >
                              Edit
                            </Button>
                            <Button
                              size="md"
                              color="danger"
                              onClick={() =>
                                onDeleteSellHandler(item.invoiceNumber)
                              }
                              disabled={item.status === "0" ? false : true}
                            >
                              Delete
                            </Button>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  );
                })}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        isOpen={showDistributionModal}
        toggle={distributionToggle}
        className="modal-lg"
        size="lg"
        style={{ maxWidth: "1800px", width: "100%" }}
      >
        <ModalHeader toggle={distributionToggle}>Distribution</ModalHeader>
        <ModalBody>
          <Col className="order-xl-1">
            <Card className="shadow" style={{ backgroundColor: "#F0F8FF" }}>
              <CardBody>
                <Form autoComplete="off" id="ff">
                  <h6 className="heading-small text-muted mb-2">
                    Distribution
                  </h6>
                  <div className="pl-lg-2 text-right">
                    <div
                      style={{
                        // backgroundColor: "grey",

                        boxShadow: "1px 2px 3px #9E9E9E",
                        borderRadius: 5,
                        borderTop: "5px solid #99CCFF",
                      }}
                    >
                      <div>
                        <FormGroup row style={{ margin: 5, padding: 10 }}>
                          <Label
                            for="exampleEmail"
                            style={{ marginLeft: 20, fontSize: 18 }}
                          >
                            from :
                          </Label>
                          <Col lg={3}>
                            <Input
                              className="form-control-alternative"
                              value={from}
                              id="from"
                              placeholder="inventory Location"
                              type="select"
                              onChange={onChangeinventoryLocationIdHandler}
                            >
                              <option>---inventory---</option>
                              {inventoryLocationItemsRender()}
                            </Input>
                          </Col>
                          <Label
                            for="exampleEmail"
                            style={{ marginLeft: 20, fontSize: 18 }}
                          >
                            To :
                          </Label>
                          <Col lg={3}>
                            <Input
                              className="form-control-alternative"
                              value={to}
                              id="to"
                              placeholder="inventory Location"
                              type="select"
                              onChange={(e) => setTo(e.target.value)}
                            >
                              <option>---inventory---</option>
                              {inventoryLocationItemsRender()}
                            </Input>
                          </Col>
                          <Label
                            for="exampleEmail"
                            style={{ marginLeft: 20, fontSize: 18 }}
                          >
                            Receiver :
                          </Label>
                          <Col lg={3}>
                            <Input
                              className="form-control-alternative"
                              value={receiver}
                              id="receiver"
                              placeholder="receiver"
                              type="select"
                              onChange={(e) => {
                                setReceiver(e.target.value);
                              }}
                            >
                              <option>---delivered By---</option>
                              {usersItemsRender()}
                            </Input>
                          </Col>
                        </FormGroup>
                      </div>
                    </div>{" "}
                  </div>
                  <div className="pl-lg-2">
                    <hr className="my-2" />
                    <div
                      className="row pd-1"
                      style={{
                        fontSize: 14,
                        fontWeight: "bold",
                      }}
                    >
                      <div className="col-md-4">
                        <div className="row text-center">
                          <div className="col-md-12" dir="ltr">
                            <b> Item</b>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="row text-center">
                          <div className="col-md-6">
                            <b>Stock</b>
                          </div>
                          <div className="col-md-6">
                            <b>quantity</b>
                          </div>
                        </div>
                      </div>
                    </div>
                    {inputList.map((x, i) => {
                      return (
                        <div className="row">
                          <div className="col-md-4">
                            <div className="row text-center">
                              <div className="col-md-12">
                                <Input
                                  className="form-control-alternative"
                                  value={x.item}
                                  id="item"
                                  placeholder="inventory Location"
                                  type="select"
                                  onChange={(e) => {
                                    onItemChangeHandler(e, i);
                                  }}
                                >
                                  <option>---Item---</option>
                                  {ItemsRender()}
                                </Input>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="row text-center">
                              <div className="col-md-6">
                                <Input
                                  className="form-control-alternative"
                                  value={x.availableQunatity}
                                  id="availableQunatity"
                                  placeholder="Available Qunatity"
                                  type="text"
                                  onChange={(e) => handleInputChange(e, i)}
                                  disabled
                                />
                              </div>
                              <div className="col-md-6">
                                <Input
                                  className="form-control-alternative"
                                  value={x.quantity}
                                  id="quantity"
                                  placeholder="quantity"
                                  type="number"
                                  onChange={(e) => handleInputChange(e, i)}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-1">
                            {inputList.length - 1 === i && (
                              <Button
                                style={{
                                  width: " 45px",
                                  height: "45px",
                                  padding: "3px",
                                  borderRadius: " 30px",
                                  textAlign: "center",
                                  fontSize: "20px",
                                  lineHeight: "1.42857",
                                }}
                                color="info"
                                type="button"
                                onClick={handleAddClick}
                              >
                                <span className="btn-inner--icon">
                                  <i className="fas fa-plus"></i>
                                </span>
                              </Button>
                            )}
                            {inputList.length !== 1 && (
                              <Button
                                style={{
                                  width: " 45px",
                                  height: "45px",
                                  padding: "3px",
                                  borderRadius: " 30px",
                                  textAlign: "center",
                                  fontSize: "20px",
                                  lineHeight: "1.42857",
                                }}
                                color="danger"
                                type="button"
                                onClick={() => handleRemoveClick(i)}
                              >
                                <span className="btn-inner--icon">
                                  <i className="far fa-trash-alt"></i>
                                </span>
                              </Button>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={onsaveDataHandler}>
            Save
          </Button>{" "}
          <Button color="secondary" onClick={distributionToggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
