import React, { useState, useEffect } from "react";
import GeneralHeader from "components/Headers/GeneralHeader";
import axios from "axios";
import Swal from "sweetalert2";
import NumberFormat from "react-number-format";
import Moment from "react-moment";

import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  Input,
  Badge,
} from "reactstrap";
import { useSelector } from "react-redux";
const path = require("../../../externals/path.json").path;

export default function PartnerAccounts() {
  const user = useSelector((state) => state.isLogged.user);
  const [reportData, setReportData] = useState([]);
  const [partnerAccountHistory, setPartnerAccountHistory] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [userName, setUserName] = useState();
  const [userData, setUserData] = useState();
  const [salary, setSalary] = useState();

  function onBindUsersData() {
    axios
      .get(path + "/users", {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setUsersList(result.data.data);
          // onBindTableData();
          // onBindTableData([], result.data.data);
        }
      });
  }

  function onBindPartnerAccountHistory(id) {
    if (!id) id = user.id;

    axios
      .get(path + "/monthlyprofit/" + id, {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          // setUsersList(result.data.data);
          setPartnerAccountHistory(result.data.data);

          setReportData({
            partnerPersentage: result.data.data
              ? result.data.data[0].partnerPersentage
              : 0,
            partnerId: result.data.data[0].partnerId,
            partnerName: result.data.data[0].partnerName,
            totalQuantity: result.data.data.reduce((sum, item) => {
              return sum + item.totalQuantity;
            }, 0),
            totalprofitOrLossIQD: result.data.data.reduce((sum, item) => {
              return sum + item.partnerAmountIQD;
            }, 0),
            totalprofitOrLossDLR: result.data.data.reduce((sum, item) => {
              return sum + item.partnerAmountIQD;
            }, 0),
            totalPaidBackDLR: result.data.data.reduce((sum, item) => {
              if (item.paidToParnter === 1) {
                return sum + item.partnerAmountDLR;
              } else {
                return sum;
              }
            }, 0),
            totalPaidBackIQD: result.data.data.reduce((sum, item) => {
              if (item.paidToParnter === 1) {
                return sum + item.partnerAmountIQD;
              } else {
                return sum;
              }
            }, 0),
            totalNotPaidBackDLR: result.data.data.reduce((sum, item) => {
              if (item.paidToParnter === 0) {
                return sum + item.partnerAmountIQD;
              } else {
                return sum;
              }
            }, 0),
            totalNotPaidBackIQD: result.data.data.reduce((sum, item) => {
              if (item.paidToParnter === 0) {
                return sum + item.partnerAmountIQD;
              } else {
                return sum;
              }
            }, 0),
          });
        }
      });
  }

  useEffect(() => {
    onBindUsersData();
    onBindPartnerAccountHistory();
  }, []);

  function usersItemsRender() {
    let items = [];
    usersList.map((item) =>
      items.push(
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      )
    );
    return items;
  }
  function onChangeUserHandler(e) {
    let index = usersList.findIndex(
      (element) => element.id === Number(e.target.value)
    );

    setUserName(e.target.value);
    setUserData(usersList[index]);
    // onBindTableData([usersList[index]]);
    onBindPartnerAccountHistory(usersList[index].id);
    setPartnerAccountHistory([]);
  }
  return (
    <>
      <GeneralHeader />

      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col>
                    <h2 className="mb-0">
                      Partner Account ({" "}
                      {reportData ? reportData.partnerName : ""})
                    </h2>
                  </Col>
                  {/* <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                    //   onClick={(e) => onAddNewHandler()}
                      size="md"
                    >
                      New User
                    </Button>
                  </Col> */}
                  {user.role === "admin" || user.role === "accountant" ? (
                    <Col>
                      <Input
                        className="form-control-alternative"
                        value={userName}
                        id="userName"
                        placeholder="userName"
                        type="select"
                        onChange={(e) => onChangeUserHandler(e)}
                        style={{ fontSize: 16, color: "black", height: 45 }}
                      >
                        <option value={0}>---User---</option>
                        {usersItemsRender()}
                      </Input>
                    </Col>
                  ) : (
                    <></>
                  )}
                </Row>
              </CardHeader>
              <div>
                <Table
                  className=" align-items-center table-flush"
                  bordered
                  style={{ fontWeight: "bold", color: "black" }}
                  responsive
                >
                  <thead className="thead-light">
                    <tr>
                      <th
                        scope="col"
                        style={{
                          color: "black",
                          fontSize: 15,
                          textAlign: "center",
                        }}
                      ></th>
                      <th
                        scope="col"
                        style={{
                          color: "black",
                          fontSize: 15,
                          textAlign: "center",
                        }}
                      >
                        Result
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        style={{
                          color: "black",
                          fontSize: 13,
                          textAlign: "left",
                        }}
                      >
                        Total Quantity
                      </td>
                      <td
                        style={{
                          color: "black",
                          fontSize: 15,
                          textAlign: "center",
                        }}
                      >
                        {reportData &&
                        (reportData.totalQuantity || reportData.totalQuantity)
                          ? reportData.totalQuantity.toFixed(0)
                          : 0}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          color: "black",
                          fontSize: 13,
                          textAlign: "left",
                        }}
                      >
                        Partner Persentage
                      </td>
                      <td
                        style={{
                          color: "black",
                          fontSize: 15,
                          textAlign: "center",
                        }}
                      >
                        %
                        {reportData &&
                        (reportData.partnerPersentage ||
                          reportData.partnerPersentage === 0)
                          ? reportData.partnerPersentage
                          : 0}{" "}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          color: "black",
                          fontSize: 13,
                          textAlign: "left",
                        }}
                      >
                        Total Profit Or Loss
                      </td>
                      <td
                        style={{
                          color: "black",
                          fontSize: 15,
                          textAlign: "center",
                        }}
                      >
                        <NumberFormat
                          value={
                            reportData &&
                            (reportData.totalprofitOrLossIQD ||
                              reportData.totalprofitOrLossIQD === 0)
                              ? reportData.totalprofitOrLossIQD.toFixed(0)
                              : 0
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"IQD "}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          color: "black",
                          fontSize: 13,
                          textAlign: "left",
                        }}
                      >
                        Total Paid back
                      </td>
                      <td
                        style={{
                          color: "black",
                          fontSize: 15,
                          textAlign: "center",
                        }}
                      >
                        <NumberFormat
                          value={
                            reportData &&
                            (reportData.totalPaidBackIQD ||
                              reportData.totalPaidBackIQD === 0)
                              ? reportData.totalPaidBackIQD.toFixed(0)
                              : 0
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"IQD "}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          color: "black",
                          fontSize: 13,
                          textAlign: "left",
                        }}
                      >
                        total Not Paid Back
                      </td>
                      <td
                        style={{
                          color: "black",
                          fontSize: 15,
                          textAlign: "center",
                        }}
                      >
                        <NumberFormat
                          value={
                            reportData &&
                            (reportData.totalNotPaidBackIQD ||
                              reportData.totalNotPaidBackIQD === 0)
                              ? reportData.totalNotPaidBackIQD.toFixed(0)
                              : 0
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"IQD "}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <hr />
                <div>
                  <h2 className="mb-0" style={{ marginLeft: "20px" }}>
                    History
                  </h2>
                  <br />
                  <Table
                    className=" align-items-center table-flush"
                    bordered
                    style={{ fontWeight: "bold", color: "black" }}
                    responsive
                  >
                    <thead className="thead-light">
                      <tr>
                        <th
                          scope="col"
                          style={{
                            color: "black",
                            fontSize: 13,
                            textAlign: "center",
                          }}
                        >
                          Total Q.
                        </th>
                        <th
                          scope="col"
                          style={{
                            color: "black",
                            fontSize: 13,
                            textAlign: "center",
                          }}
                        >
                          Total Sell
                        </th>
                        <th
                          scope="col"
                          style={{
                            color: "black",
                            fontSize: 13,
                            textAlign: "center",
                          }}
                        >
                          total Cost
                        </th>
                        <th
                          scope="col"
                          style={{
                            color: "black",
                            fontSize: 13,
                            textAlign: "center",
                          }}
                        >
                          Expenses and Salary
                        </th>
                        <th
                          scope="col"
                          style={{
                            color: "black",
                            fontSize: 13,
                            textAlign: "center",
                          }}
                        >
                          Partner Per.
                        </th>
                        <th
                          scope="col"
                          style={{
                            color: "black",
                            fontSize: 13,
                            textAlign: "center",
                          }}
                        >
                          Date
                        </th>{" "}
                        <th
                          scope="col"
                          style={{
                            color: "black",
                            fontSize: 13,
                            textAlign: "center",
                          }}
                        >
                          Paid
                        </th>
                        <th
                          scope="col"
                          style={{
                            color: "black",
                            fontSize: 13,
                            textAlign: "center",
                          }}
                        >
                          Paid Date
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {partnerAccountHistory.map(function (item, index) {
                        return (
                          <tr>
                            <th
                              scope="row"
                              style={{
                                color: "black",
                                fontSize: 13,
                                textAlign: "center",
                              }}
                            >
                              {item.totalQuantity
                                ? item.totalQuantity.toFixed(0)
                                : 0}
                            </th>
                            <td
                              style={{
                                color: "black",
                                fontSize: 13,
                                textAlign: "center",
                              }}
                            >
                              <NumberFormat
                                value={
                                  item.totalSellIQD
                                    ? item.totalSellIQD.toFixed(0)
                                    : 0
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"IQD "}
                              />
                            </td>
                            <td
                              style={{
                                color: "black",
                                fontSize: 13,
                                textAlign: "center",
                              }}
                            >
                              <NumberFormat
                                value={
                                  item.totalCostIQD
                                    ? item.totalCostIQD.toFixed(0)
                                    : 0
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"IQD "}
                              />
                            </td>
                            <td
                              style={{
                                color: "black",
                                fontSize: 13,
                                textAlign: "center",
                              }}
                            >
                              <NumberFormat
                                value={
                                  item.totalExpensesIQD
                                    ? (
                                        item.totalExpensesIQD +
                                        item.totalSalaryIQD
                                      ).toFixed(0)
                                    : 0
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"IQD "}
                              />
                            </td>
                            <td
                              style={{
                                color: "black",
                                fontSize: 13,
                                textAlign: "center",
                              }}
                            >
                              <NumberFormat
                                value={
                                  item.partnerAmountIQD
                                    ? item.partnerAmountIQD.toFixed(0)
                                    : 0
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"IQD "}
                              />
                            </td>
                            <td
                              style={{
                                color: "black",
                                fontSize: 13,
                                textAlign: "center",
                              }}
                            >
                              {item.fromDate ? (
                                <>
                                  {" "}
                                  <Moment format="YYYY/MM/DD">
                                    {item.fromDate}
                                  </Moment>{" "}
                                  to{" "}
                                  <Moment format="YYYY/MM/DD">
                                    {item.toDate}
                                  </Moment>{" "}
                                </>
                              ) : (
                                ""
                              )}
                            </td>

                            <td
                              style={{
                                color: "black",
                                fontSize: 13,
                                textAlign: "center",
                              }}
                            >
                              {" "}
                              {item.paidToParnter === 1 ? (
                                <Badge color="success">YES</Badge>
                              ) : (
                                <Badge color="danger">No</Badge>
                              )}
                            </td>
                            <td
                              style={{
                                color: "black",
                                fontSize: 13,
                                textAlign: "center",
                              }}
                            >
                              {item.paidDate ? (
                                <>
                                  {" "}
                                  <Moment format="YYYY/MM/DD">
                                    {item.paidDate}
                                  </Moment>{" "}
                                </>
                              ) : (
                                ""
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
