import { combineReducers } from "redux";
// import loggedReducer from "./isLogged";
import currentUser from "./isLogged";
import languages from "./Languages";
import Rates from "./Rates";

const allReducers = combineReducers({
  isLogged: currentUser,
  rateData: Rates,
  Language: languages,
});

export default allReducers;
