/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  Badge,
} from "reactstrap";
import NumberFormat from "react-number-format";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "variables/charts.js";

import axios from "axios";
import MandwbHeader from "./../components/Headers/MandwbHeader";
const path = require("../externals/path.json").path;
const Index = (props) => {
  const [activeNav, setActiveNav] = useState(1);
  const [sellRoportToday, setSellRoportToday] = useState([]);
  const [chartExample1Data, setChartExample1Data] = useState("data1");
  const [ChartOverView, setchartOverView] = useState(chartExample1);
  const [chartCountSellData, setChartCountSellData] = useState({
    labels: ["Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: [
      {
        label: "Sales",
        data: [25, 20, 30, 22, 17, 29],
        maxBarThickness: 10,
      },
    ],
  });

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setChartExample1Data("data" + index);
  };

  var colors = {
    gray: {
      100: "#f6f9fc",
      200: "#e9ecef",
      300: "#dee2e6",
      400: "#ced4da",
      500: "#adb5bd",
      600: "#8898aa",
      700: "#525f7f",
      800: "#32325d",
      900: "#212529",
    },
    theme: {
      default: "#172b4d",
      primary: "#5e72e4",
      secondary: "#f4f5f7",
      info: "#11cdef",
      success: "#2dce89",
      danger: "#f5365c",
      warning: "#fb6340",
    },
    black: "#12263F",
    white: "#FFFFFF",
    transparent: "transparent",
  };

  useEffect(() => {
    onBindChartOverViewData();
    onBindChartCountSellData();
    onBindSellReportTodayData();
  }, []);

  function onBindChartOverViewData() {
    axios
      .get(path + "/sell/chartoverview", {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          let month = [];
          let total = [];
          result.data.data.forEach((element) => {
            month.push(element.month);
            total.push(element.total);
          });

          setchartOverView({
            options: {
              scales: {
                yAxes: [
                  {
                    gridLines: {
                      color: colors.gray[900],
                      zeroLineColor: colors.gray[900],
                    },
                    ticks: {
                      callback: function (value) {
                        if (!(value % 10)) {
                          return "$" + value + "k";
                        }
                      },
                    },
                  },
                ],
              },
              tooltips: {
                callbacks: {
                  label: function (item, data) {
                    var label = data.datasets[item.datasetIndex].label || "";
                    var yLabel = item.yLabel;
                    var content = "";

                    if (data.datasets.length > 1) {
                      content += label;
                    }

                    content += "$" + yLabel + "k";
                    return content;
                  },
                },
              },
            },
            data1: (canvas) => {
              return {
                labels: month,
                datasets: [
                  {
                    label: "Performance",
                    data: total,
                  },
                ],
              };
            },
            data2: (canvas) => {
              return {
                labels: [
                  "May",
                  "Jun",
                  "Jul",
                  "Aug",
                  "Sep",
                  "Oct",
                  "Nov",
                  "Dec",
                ],
                datasets: [
                  {
                    label: "Performance",
                    data: [0, 20, 5, 25, 10, 30, 15, 40, 40],
                  },
                ],
              };
            },
          });
        }
      });
  }
  function onBindChartCountSellData() {
    axios
      .get(path + "/sell/chartcountsell", {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          let month = [];
          let total = [];
          result.data.data.forEach((element) => {
            month.push(element.month);
            total.push(element.total);
          });
          setChartCountSellData({
            labels: month,
            datasets: [
              {
                label: "Sales",
                data: total,
                maxBarThickness: 10,
              },
            ],
          });
        }
      });
  }

  function onBindSellReportTodayData() {
    axios
      .get(path + "/sell/sellreporttoday", {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setSellRoportToday(result.data.data);
        }
      });
  }

  return (
    <>
      <MandwbHeader />
      {/* Page content */}
      <Container className="mt--4" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="8">
            <Card className="bg-gradient-default shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-light ls-1 mb-1">
                      Overview
                    </h6>
                    <h2 className="text-white mb-0">Sales value</h2>
                  </div>
                  <div className="col">
                    <Nav className="justify-content-end" pills>
                      <NavItem>
                        <NavLink
                          className={classnames("py-2 px-3", {
                            active: activeNav === 1,
                          })}
                          href="#pablo"
                          onClick={(e) => toggleNavs(e, 1)}
                        >
                          <span className="d-none d-md-block">Month</span>
                          <span className="d-md-none">M</span>
                        </NavLink>
                      </NavItem>
                      {/* <NavItem>
                        <NavLink
                          className={classnames("py-2 px-3", {
                            active: activeNav === 2,
                          })}
                          data-toggle="tab"
                          href="#pablo"
                          onClick={(e) => toggleNavs(e, 2)}
                        >
                          <span className="d-none d-md-block">Week</span>
                          <span className="d-md-none">W</span>
                        </NavLink>
                      </NavItem> */}
                    </Nav>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {/* Chart */}
                <div className="chart">
                  <Line
                    data={ChartOverView[chartExample1Data]}
                    options={chartExample1.options}
                    getDatasetAtEvent={(e) => console.log(e)}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl="4">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-muted ls-1 mb-1">
                      Performance
                    </h6>
                    <h2 className="mb-0">Total Sell Per Month</h2>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {/* Chart */}
                <div className="chart">
                  <Bar
                    data={chartCountSellData}
                    options={chartExample2.options}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0 text-uppercase h2">
                      Reports for today
                    </h3>
                  </div>
                  {/* <div className="col text-right">
                    <Button
                      color="primary"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      size="md"
                    >
                      See all
                    </Button>
                  </div> */}
                </Row>
              </CardHeader>
              <Table className=" align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col" className={"w-25"}>
                      #
                    </th>
                    <th scope="col" className={"w-25"}>
                      NAME
                    </th>
                    <th scope="col" className={"w-25"}>
                      SALES ($)
                    </th>
                    <th scope="col" className={"w-25"}>
                      SALES (IQD)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sellRoportToday.map(function (item, index) {
                    return (
                      <tr>
                        <th scope="row">{index + 1}</th>
                        <td>{item.name}</td>

                        <td>
                          <Badge
                            color={item.totalDLR > 0 ? "success" : "danger"}
                            style={{ fontSize: 12, color: "black" }}
                          >
                            <NumberFormat
                              value={item.totalDLR.toFixed(0)}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$ "}
                            />
                          </Badge>
                        </td>
                        <td>
                          <Badge
                            color={item.totalIQD > 0 ? "success" : "danger"}
                            style={{ fontSize: 12, color: "black" }}
                          >
                            <NumberFormat
                              value={item.totalIQD.toFixed(0)}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"IQD "}
                            />
                          </Badge>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Index;
