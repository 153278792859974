import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import NumberFormat from "react-number-format";
import Moment from "react-moment";
import ReactToPrint from "react-to-print";

import {
  Button,
  Table,
  Row,
  Col,
  Input,
  FormGroup,
  Label,
  Badge,
} from "reactstrap";

const path = require("../../../externals/path.json").path;

export default function ExpensesCompareByZones() {
  let invoiceRef = useRef();

  const [reportData, setReportData] = useState([]);
  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  const [cityId, setCityId] = useState();
  const [cityList, setCityList] = useState([]);

  useEffect(() => {
    onBindcityData();
  }, []);

  function onBindcityData() {
    axios
      .get(path + "/cities", {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setCityList(result.data.data);
        }
      });
  }

  function onBindtableData() {
    axios
      .post(
        path + "/expensesreports/getExpensesCompareByZones",
        {
          cityId: cityId !== "0" ? cityId : null,
          from: from,
          to: to,
        },
        {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        }
      )
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setReportData(result.data.data);
        }
      });
  }

  function cityItemsRender() {
    let items = [];
    cityList.map((item) =>
      items.push(
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      )
    );
    return items;
  }
  function onChangeCityHandler(event) {
    setCityId(event.target.value);
  }

  return (
    <>
      <Row>
        <Col>
          <Input
            className="form-control-alternative"
            value={cityId}
            id="cityId"
            placeholder="cityId"
            type="select"
            onChange={(e) => onChangeCityHandler(e)}
            style={{ fontSize: 16, color: "black", height: 45 }}
          >
            <option key={0} value={0}>
              ---city---
            </option>
            {cityItemsRender()}
          </Input>
        </Col>
        <Col>
          <Input
            className="form-control-alternative"
            value={from}
            id="from"
            placeholder="from"
            type="date"
            onChange={(e) => setFrom(e.target.value)}
            style={{ fontSize: 16, color: "black", height: 45 }}
          />
        </Col>
        <Col>
          <Input
            className="form-control-alternative"
            value={to}
            id="to"
            placeholder="to"
            type="date"
            onChange={(e) => setTo(e.target.value)}
            style={{ fontSize: 16, color: "black", height: 45 }}
          />
        </Col>
        <Col className="text-right">
          <Button color="primary" onClick={(e) => onBindtableData()} size="md">
            Get
          </Button>
          <ReactToPrint
            trigger={() => <Button color="primary">print</Button>}
            content={() => invoiceRef}
          />
        </Col>
      </Row>
      <br />

      <br />
      <div ref={(el) => (invoiceRef = el)}>
        <br />
        <br />

        <div>
          <Table
            className=" align-items-center table-flush"
            bordered
            style={{ fontWeight: "bold", color: "black" }}
            responsive
          >
            <thead className="thead-light">
              <tr>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  #
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  City Name
                </th>{" "}
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  Zone Name
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  {" "}
                  Amount ($)
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  Amount (IQD)
                </th>
              </tr>
            </thead>
            <tbody>
              {reportData.map(function (item, index) {
                return (
                  <tr>
                    <th
                      scope="row"
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      {index + 1}
                    </th>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      {item.cityName}
                    </td>{" "}
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      {item.zoneName}
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      <NumberFormat
                        value={item.amountDLR ? item.amountDLR.toFixed(0) : 0}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$ "}
                      />
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      <NumberFormat
                        value={item.amountIQD ? item.amountIQD.toFixed(0) : 0}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"IQD "}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
}
