import React, { useState, useEffect } from "react";
import GeneralHeader from "components/Headers/GeneralHeader";
// import Select from "react-select";
import axios from "axios";
import Swal from "sweetalert2";
import NumberFormat from "react-number-format";
// import Moment from "react-moment";
import RoutesList from "../../../routes/Routelist.js";
import {
  Button,
  Card,
  CardHeader,
  CardFooter,
  CardBody,
  Form,
  Table,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  Badge,
} from "reactstrap";
import { useSelector } from "react-redux";
const path = require("../../../externals/path.json").path;

export default function Users() {
  const user = useSelector((state) => state.isLogged.user);
  const [userslist, setUserslist] = useState([]);
  const [usersSearchlist, setUsersSearchlist] = useState([]);

  const [userPagesList, setUserPagesList] = useState([]);
  const [userZoneList, setUserZoneList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [userId, setUserId] = useState();
  const [name, setName] = useState();
  const [userName, setUserName] = useState();
  const [userSearchName, setUserSearchName] = useState();
  const [password, setPassword] = useState();
  const [role, setRole] = useState();
  const [leaderId, setLeaderId] = useState();
  const [phone, setPhone] = useState();
  const [salary, setSalary] = useState();
  const [isPartner, setIsPartner] = useState("0");
  const [partnerPersentage, setPartnerPersentage] = useState();
  const [repaymentType, setRepaymentType] = useState();
  const [creditType, setCreditType] = useState();
  const [persentage, setPersentage] = useState();
  const [bonus, setBonus] = useState();
  const [cityId, setCityId] = useState();
  const [zoneId, setZoneId] = useState();
  const [newpass, setnewpass] = useState();
  const [confirmPass, setconfirmPass] = useState();
  const [createdBy, setCreatedBy] = useState();
  const [status, setStatus] = useState();
  const [isUpdate, setIsUpdate] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const [showPageModal, setShowPageModal] = useState(false);
  const [showZoneModal, setShowZoneModal] = useState(false);
  const [showPasswordModal, setshowPasswordModal] = useState(false);
  const [page, setPage] = useState();

  const UserToggle = () => {
    setShowUserModal(!showUserModal);
  };
  const pageToggle = () => {
    setShowPageModal(!showPageModal);
  };
  const zoneToggle = () => {
    setShowZoneModal(!showZoneModal);
  };
  const changePasswordToggle = () => {
    setshowPasswordModal(!showPasswordModal);
  };
  useEffect(() => {
    onBindTableData();
  }, []);
  const onBindTableData = () => {
    axios
      .get(path + "/users", {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setUserslist(result.data.data);
          setUsersSearchlist(result.data.data);
        }
      });
  };

  const onBindCityList = () => {
    axios
      .get(path + "/cities", {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setCityList(result.data.data);
        }
      });
  };
  function onBindZoneDataHandler(cityId) {
    axios
      .get(path + "/zone/" + Number(cityId), {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) setZoneList(result.data.data);
        else setZoneList([]);
      });
  }
  function onChangeCityHandler(event) {
    if (event !== "---City---") {
      setCityId(event);
      onBindZoneDataHandler(event);
    }
  }
  async function onChangePassowrd() {
    if (newpass) {
      if (newpass === confirmPass) {
        axios
          .put(path + "/users/changepassword", {
            username: userName,
            password: newpass,
          })
          .then((result) => {
            if (result.data.success === true) {
              // dispatch(allActions.AuthAction.logIn(user));
              Swal.fire("success", "Password Changed for " + userName);
            } else Swal.fire("warning", "there is an error please try agian!");
          })
          .catch((err) => {
            Swal.fire("warning", "there is an error please try agian!");
          });
      } else {
        Swal.fire("warning", "password incorrect please try agian!");
      }
    }
  }
  function cityItemsRender() {
    let items = [];
    cityList.map((item) =>
      items.push(
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      )
    );
    return items;
  }
  function pagesListRender() {
    let items = [];
    RoutesList.map((item) =>
      items.push(
        <option key={item} value={item}>
          {item}
        </option>
      )
    );
    return items;
  }
  function zoneItemsRender() {
    let items = [];
    zoneList.map((item) =>
      items.push(
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      )
    );
    return items;
  }

  function onAddNewHandler() {
    UserToggle();
    setName();
    setUserName();
    setCityId();
    setZoneId();
    setCreatedBy(user.id);
    setPhone();
    setRole();
    setLeaderId();
    UserToggle();
    onBindCityList();
    setRepaymentType("0");
    setIsPartner("0");
    setPartnerPersentage();
    setRepaymentType("0");
    setCreditType("0");
    setIsUpdate(false);
    setUserId();
    setPassword("1234");
    setBonus();
    setPersentage();
    setSalary();
    setStatus("1");
  }

  function leaderItemsRender() {
    let items = [];
    userslist.map((item) =>
      items.push(
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      )
    );
    return items;
  }

  function onSaveDataHandler() {
    const userData = {
      name: name,
      username: userName,
      password: password,
      token: null,
      refreshToken: null,
      leaderId: leaderId,
      role: role,
      cityId: cityId,
      zoneId: zoneId,
      phone: phone,
      status: status,
      salary: salary,
      isPartner: isPartner,
      partnerPersentage: partnerPersentage ? partnerPersentage : 0,
      repaymentType:
        repaymentType && repaymentType !== "0" ? repaymentType : null,
      creditType: creditType && creditType !== "0" ? creditType : null,
      persentage: persentage,
      bonus: bonus,
      first_login: "Y",
      createdBy: createdBy,
    };
    if (isUpdate === true) {
      axios
        .put(path + "/users/" + userId, userData, {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        })
        .then((result) => {
          if (result.data.success === true) {
            onBindTableData();
            setShowUserModal(false);
            setIsUpdate(false);
            setName();
            setUserName();
            setCityId();
            setZoneId();
            setCreatedBy(user.id);
            setPhone();
            setRole();
            setLeaderId();
            UserToggle();
            onBindCityList();
            setRepaymentType("0");
            setCreditType("0");
            setIsUpdate(false);
            setUserId();
            setPassword("1234");
            setBonus();
            setPersentage();
            setSalary();
            setStatus("1");
            setIsPartner("0");
            setPartnerPersentage();
            Swal.fire({
              title: "THANKS",
              text: "the User updated",
              icon: "success",
            });
          }
          // if (result.data.token !== null)
          //   this.props.user.user.token = result.data.token;
        })
        .catch((error) => {
          Swal.fire({
            title: "warning",
            text: "User did not updated please contact administrator",
            icon: "warning",
          });
        });
    } else {
      axios
        .post(path + "/users", userData, {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        })
        .then((result) => {
          if (result.data.success === true) {
            onBindTableData();
            setShowUserModal(false);
            setName();
            setUserName();
            setCityId();
            setZoneId();
            setCreatedBy(user.id);
            setPhone();
            setRole();
            setLeaderId();
            UserToggle();
            onBindCityList();
            setRepaymentType("0");
            setIsPartner("0");
            setPartnerPersentage();
            setCreditType("0");
            setIsUpdate(false);
            setUserId();
            setPassword("1234");
            setBonus();
            setPersentage();
            setSalary();
            setStatus("1");
            Swal.fire({
              title: "THANKS",
              text: "new User Added",
              icon: "success",
            });
          }
          // if (result.data.token !== null)
          //   this.props.user.user.token = result.data.token;
        })
        .catch((error) => {
          Swal.fire({
            title: "warning",
            text: "User did not added please contact administrator",
            icon: "warning",
          });
        });
    }
  }
  function EditDataHandler(UserId, Index) {
    // let item = itemList.find((o) => o.id === item.itemId);
    axios
      .get(path + "/users/" + UserId, {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setIsUpdate(true);
          setShowUserModal(true);
          setName(result.data.data.name);
          setUserName(result.data.data.username);
          setPassword(result.data.data.password);
          setCityId(result.data.data.cityId);
          setZoneId(result.data.data.zoneId);
          setLeaderId(result.data.data.leaderId);
          setPhone(result.data.data.phone);
          setCreatedBy(result.data.data.createdBy);
          setStatus(result.data.data.status);
          setUserId(UserId);
          setRole(result.data.data.role);
          setSalary(result.data.data.salary);
          setIsPartner(result.data.data.isPartner);
          setPartnerPersentage(result.data.data.partnerPersentage);
          setRepaymentType(result.data.data.repaymentType);
          setCreditType(result.data.data.creditType);
          setPersentage(result.data.data.persentage);
          setBonus(result.data.data.bonus);

          onBindCityList();
          onBindZoneDataHandler(result.data.data.cityId);
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "warning",
          text: `there is some error while get user with  number ${Index} data please contact administrator`,
          icon: "warning",
        });
      });
  }
  function onDeleteUserHandler(id, index) {
    Swal.fire({
      title: 'Do you want to delete user  "' + index + '" ?',
      showCancelButton: true,
      confirmButtonText: "Save",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.value) {
        axios
          .delete(path + "/users/" + id, {
            // headers: {
            //   "auth-token": this.props.user.user.token,
            // },
          })
          .then((result) => {
            if (result.data.statusCode === 200) {
              onBindTableData();
              Swal.fire("user Deleted!", "", "success");
            }
            // if (result.data.token !== null)
            //   this.props.user.user.token = result.data.token;
          })
          .catch((error) => {
            Swal.fire({
              title: "warning",
              text: "the user did not deleted please contact administrator",
              icon: "warning",
            });
          });
      }
    });
  }
  function onBindUserPages(userId) {
    axios
      .get(path + "/userpages/list/" + userId, {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setUserPagesList(result.data.data);
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "warning",
          text: `there is some error while getting user page data please contact administrator`,
          icon: "warning",
        });
      });
  }
  function onBindUserZones(userId) {
    axios
      .get(path + "/userzones/list/" + userId, {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setUserZoneList(result.data.data);
        } else setUserZoneList([]);
      })
      .catch((error) => {
        Swal.fire({
          title: "warning",
          text: `there is some error while getting user Zone data please contact administrator`,
          icon: "warning",
        });
      });
  }

  function EditUserPages(userId) {
    onBindUserPages(userId);
    setShowPageModal(true);
    setCreatedBy(user.id);
    setUserId(userId);
  }

  function EditUserZones(userId) {
    onBindUserZones(userId);
    setShowZoneModal(!showZoneModal);
    setCreatedBy(user.id);
    setUserId(userId);
    setCityId();
    setZoneId();
    onBindCityList();
  }
  function onsavePageHandler() {
    if (userId && page) {
      let pages = {
        userId: userId,
        page: page,
        username: createdBy,
      };

      axios
        .post(path + "/userpages", pages, {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        })
        .then((result) => {
          if (result.data.success === true) {
            onBindUserPages(userId);

            Swal.fire({
              title: "THANKS",
              text: "new page  Added to user",
              icon: "success",
            });
          }
          // if (result.data.token !== null)
          //   this.props.user.user.token = result.data.token;
        })
        .catch((error) => {
          Swal.fire({
            title: "warning",
            text: "page did not added please contact administrator",
            icon: "warning",
          });
        });
    } else
      Swal.fire({
        title: "warning",
        text: "Please Select the page",
        icon: "warning",
      });
  }
  function onsaveZoneHandler() {
    let zones = {
      userId: userId,
      zoneId: zoneId,
      username: createdBy,
    };

    if (userId && zoneId) {
      axios
        .post(path + "/userzones", zones, {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        })
        .then((result) => {
          if (result.data.success === true) {
            onBindUserZones(userId);

            Swal.fire({
              title: "THANKS",
              text: "new zone  Added to user",
              icon: "success",
            });
          }
          // if (result.data.token !== null)
          //   this.props.user.user.token = result.data.token;
        })
        .catch((error) => {
          Swal.fire({
            title: "warning",
            text: "zone did not added please contact administrator",
            icon: "warning",
          });
        });
    } else
      Swal.fire({
        title: "warning",
        text: "Please Select zone",
        icon: "warning",
      });
  }

  function onDeleteUserPageHandler(id, index) {
    Swal.fire({
      title: 'Do you want to delete user page  "' + index + '" ?',
      showCancelButton: true,
      confirmButtonText: "Save",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.value) {
        axios
          .delete(path + "/userpages/" + id, {
            // headers: {
            //   "auth-token": this.props.user.user.token,
            // },
          })
          .then((result) => {
            if (result.data.statusCode === 200) {
              onBindUserPages(userId);
              Swal.fire("user page Deleted!", "", "success");
            }
            // if (result.data.token !== null)
            //   this.props.user.user.token = result.data.token;
          })
          .catch((error) => {
            Swal.fire({
              title: "warning",
              text: "the user page did not deleted please contact administrator",
              icon: "warning",
            });
          });
      }
    });
  }
  function onDeleteUserzoneHandler(id, index) {
    Swal.fire({
      title: 'Do you want to delete user zone  "' + index + '" ?',
      showCancelButton: true,
      confirmButtonText: "Save",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.value) {
        axios
          .delete(path + "/userzones/" + id, {
            // headers: {
            //   "auth-token": this.props.user.user.token,
            // },
          })
          .then((result) => {
            if (result.data.statusCode === 200) {
              onBindUserZones(userId);
              Swal.fire("user zone Deleted!", "", "success");
            }
            // if (result.data.token !== null)
            //   this.props.user.user.token = result.data.token;
          })
          .catch((error) => {
            Swal.fire({
              title: "warning",
              text: "the user zone did not deleted please contact administrator",
              icon: "warning",
            });
          });
      }
    });
  }

  return (
    <>
      <GeneralHeader />

      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col>
                    <h2 className="mb-0">users list</h2>
                  </Col>
                  <Col lg="5">
                    <FormGroup>
                      <Input
                        className="form-control-alternative"
                        defaultValue={userSearchName}
                        id="name"
                        placeholder="User Name"
                        type="text"
                        onChange={(e) => {
                          setUserSearchName(e.target.value);
                          let list = usersSearchlist.filter((user) =>
                            user.name.includes(e.target.value)
                          );
                          setUserslist(list);
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      onClick={(e) => onAddNewHandler()}
                      size="md"
                    >
                      New User
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <div style={{ height: "76vh", overflow: "scroll" }}>
                <Table
                  className=" align-items-center table-flush"
                  style={{ fontWeight: "bold", color: "black" }}
                >
                  <thead className="thead-light">
                    <tr>
                      <th scope="col" className="text-center">
                        #
                      </th>
                      <th scope="col" className="text-center">
                        name
                      </th>
                      <th scope="col" className="text-center">
                        username
                      </th>
                      <th scope="col" className="text-center">
                        phone
                      </th>
                      <th scope="col" className="text-center">
                        role
                      </th>
                      <th scope="col" className="text-center">
                        leader
                      </th>
                      {/* <th scope="col" className="text-center">
                      city
                    </th>
                    <th scope="col" className="text-center">
                      zone
                    </th> */}

                      <th scope="col" className="text-center">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {userslist.map(function (item, index) {
                      return (
                        <tr>
                          <th scope="row" className="text-center">
                            {index + 1}
                          </th>
                          <td className="text-center">{item.name}</td>
                          <td className="text-center">{item.username}</td>
                          <td className="text-center">{item.phone}</td>
                          <td className="text-center">{item.role}</td>
                          <td className="text-center">{item.leaderName}</td>
                          {/* <td className="text-center">{item.city}</td>
                        <td className="text-center">{item.zone}</td> */}

                          <td className="text-center">
                            <div>
                              <Button
                                size="sm"
                                color="primary"
                                onClick={() => EditUserZones(item.id)}
                              >
                                User Zones
                              </Button>
                              <Button
                                size="sm"
                                color="info"
                                onClick={() => EditUserPages(item.id)}
                              >
                                user Pages
                              </Button>
                              <Button
                                size="sm"
                                color="success"
                                onClick={() => {
                                  setnewpass();
                                  setconfirmPass();
                                  setUserName(item.username);
                                  changePasswordToggle();
                                }}
                              >
                                Change Paassword
                              </Button>
                              <Button
                                size="sm"
                                color="warning"
                                onClick={() =>
                                  EditDataHandler(item.id, index + 1)
                                }
                              >
                                Edit
                              </Button>
                              <Button
                                size="sm"
                                color="danger"
                                onClick={() =>
                                  onDeleteUserHandler(item.id, index + 1)
                                }
                              >
                                Delete
                              </Button>{" "}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        isOpen={showUserModal}
        toggle={UserToggle}
        className="modal-lg"
        style={{ maxWidth: "1500px", width: "100%" }}
      >
        <ModalHeader toggle={UserToggle}>New User</ModalHeader>
        <ModalBody>
          <Col className="order-xl-1">
            <Card className="shadow" style={{ backgroundColor: "#F0F8FF" }}>
              <CardBody>
                <Form autoComplete="off" id="ff">
                  <h6 className="heading-small text-muted mb-2">
                    User information
                  </h6>
                  <div className="pl-lg-2">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            value={name}
                            id="name"
                            placeholder="Name .."
                            type="text"
                            onChange={(e) => setName(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            value={userName}
                            id="userName"
                            placeholder="user Name .."
                            type="text"
                            onChange={(e) => setUserName(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            value={role}
                            id="role"
                            placeholder="role"
                            type="select"
                            onChange={(e) => setRole(e.target.value)}
                          >
                            <option>---Role---</option>
                            <option>admin</option>
                            <option>mandwb</option>
                            <option>mandwbi_zansti</option>
                            <option>leader</option>
                            <option>accountant</option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            value={leaderId}
                            id="leaderId"
                            placeholder="leaderId"
                            type="select"
                            onChange={(e) => setLeaderId(e.target.value)}
                          >
                            <option>---LeaderId---</option>
                            {leaderItemsRender()}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    {/* <Row>
                      <Col lg="6">
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            value={cityId}
                            id="city"
                            placeholder="city"
                            type="select"
                            onChange={(e) =>
                              onChangeCityHandler(e.target.value)
                            }
                          >
                            <option>---City---</option>
                            {cityItemsRender()}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            value={zoneId}
                            id="zoneId"
                            placeholder="zoneId"
                            type="select"
                            onChange={(e) => setZoneId(e.target.value)}
                          >
                            <option>---Zone---</option>
                            {zoneItemsRender()}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row> */}
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            value={phone}
                            id="phone"
                            placeholder="Phone Number"
                            type="number"
                            onChange={(e) => setPhone(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            value={salary}
                            id="salary"
                            placeholder="Salary."
                            type="number"
                            onChange={(e) => setSalary(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            value={repaymentType}
                            id="repaymentType"
                            placeholder="repaymentType"
                            type="select"
                            onChange={(e) => setRepaymentType(e.target.value)}
                          >
                            <option value={0}>---Persentage Type---</option>
                            <option key={"perinvoice"} value={"perinvoice"}>
                              Per Invoice
                            </option>
                            <option key={"perpayment"} value={"perpayment"}>
                              per Payment
                            </option>{" "}
                            <option key={"perquantity"} value={"perquantity"}>
                              Per Quantity
                            </option>
                            <option key={"notseller"} value={"notseller"}>
                              not seller
                            </option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            value={creditType}
                            id="creditType"
                            placeholder="creditType"
                            type="select"
                            onChange={(e) => setCreditType(e.target.value)}
                          >
                            <option value={0}>---Credit Type---</option>
                            <option key={"onDelivery"} value={"onDelivery"}>
                              On Delivery
                            </option>
                            <option key={"onPaid"} value={"onPaid"}>
                              On Paid
                            </option>
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            value={persentage}
                            id="persentage"
                            placeholder="Persentage"
                            type="number"
                            onChange={(e) => setPersentage(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            value={bonus}
                            id="bonus"
                            placeholder="Bonus"
                            type="number"
                            onChange={(e) => setBonus(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            value={isPartner}
                            id="isPartner"
                            placeholder="isPartner"
                            type="select"
                            onChange={(e) => setIsPartner(e.target.value)}
                          >
                            <option key={"0"} value={"0"}>
                              Not Partner
                            </option>
                            <option key={"1"} value={"1"}>
                              Partner
                            </option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            value={partnerPersentage}
                            id="partnerPersentage"
                            placeholder="partner Persentage"
                            type="number"
                            onChange={(e) =>
                              setPartnerPersentage(e.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => onSaveDataHandler()}>
            Save
          </Button>{" "}
          <Button color="secondary" onClick={UserToggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={showPageModal} toggle={pageToggle} className="modal-lg">
        <ModalHeader toggle={pageToggle}>user's page permission</ModalHeader>
        <ModalBody>
          <Col className="order-xl-1">
            <Card className="shadow" style={{ backgroundColor: "#F0F8FF" }}>
              <CardBody>
                <Form autoComplete="off" id="ff">
                  <div className="pl-lg-2">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            value={page}
                            id="page"
                            placeholder="page"
                            type="select"
                            onChange={(e) => setPage(e.target.value)}
                          >
                            <option>---Page---</option>
                            {pagesListRender()}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Table bordered responsive>
                        <thead className="thead-light">
                          <tr>
                            <th scope="col" className="text-center">
                              #
                            </th>
                            <th scope="col" className="text-center">
                              user
                            </th>
                            <th scope="col" className="text-center">
                              pages
                            </th>
                            <th scope="col" className="text-center">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {userPagesList.map(function (item, index) {
                            return (
                              <tr>
                                <th scope="row">{index + 1}</th>
                                <td className="text-center">{item.name}</td>
                                <td className="text-center">{item.page}</td>

                                <td className="text-center">
                                  <div>
                                    <Button
                                      size="sm"
                                      color="danger"
                                      onClick={() =>
                                        onDeleteUserPageHandler(
                                          item.id,
                                          index + 1
                                        )
                                      }
                                    >
                                      Delete
                                    </Button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={onsavePageHandler}>
            Save
          </Button>{" "}
          <Button color="secondary" onClick={pageToggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={showZoneModal} toggle={zoneToggle} className="modal-lg">
        <ModalHeader toggle={zoneToggle}>user's Zones</ModalHeader>
        <ModalBody>
          <Col className="order-xl-1">
            <Card className="shadow" style={{ backgroundColor: "#F0F8FF" }}>
              <CardBody>
                <Form autoComplete="off" id="ff">
                  <div className="pl-lg-2">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            value={cityId}
                            id="city"
                            placeholder="city"
                            type="select"
                            onChange={(e) =>
                              onChangeCityHandler(e.target.value)
                            }
                          >
                            <option>---City---</option>
                            {cityItemsRender()}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            value={zoneId}
                            id="zoneId"
                            placeholder="zoneId"
                            type="select"
                            onChange={(e) => setZoneId(e.target.value)}
                          >
                            <option>---Zone---</option>
                            {zoneItemsRender()}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Table bordered responsive>
                        <thead className="thead-light">
                          <tr>
                            <th scope="col" className="text-center">
                              #
                            </th>
                            <th scope="col" className="text-center">
                              user
                            </th>
                            <th scope="col" className="text-center">
                              Zone
                            </th>{" "}
                            <th scope="col" className="text-center">
                              City
                            </th>
                            <th scope="col" className="text-center">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {userZoneList.map(function (item, index) {
                            return (
                              <tr>
                                <th scope="row">{index + 1}</th>
                                <td className="text-center">{item.name}</td>
                                <td className="text-center">{item.cityName}</td>
                                <td className="text-center">{item.zoneName}</td>

                                <td className="text-center">
                                  <div>
                                    <Button
                                      size="sm"
                                      color="danger"
                                      onClick={() =>
                                        onDeleteUserzoneHandler(
                                          item.id,
                                          index + 1
                                        )
                                      }
                                    >
                                      Delete
                                    </Button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={onsaveZoneHandler}>
            Save
          </Button>{" "}
          <Button color="secondary" onClick={zoneToggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={showPasswordModal}
        toggle={changePasswordToggle}
        className="modal-lg"
      >
        <ModalHeader toggle={changePasswordToggle}>
          Change User Password
        </ModalHeader>
        <ModalBody>
          <Col className="order-xl-1">
            <Card className="shadow" style={{ backgroundColor: "#F0F8FF" }}>
              <CardBody>
                <Form autoComplete="off" id="ff">
                  <div className="pl-lg-2">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            value={newpass}
                            id="newpass"
                            placeholder="newpass"
                            type="password"
                            onChange={(e) => setnewpass(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            value={confirmPass}
                            id="confirmPass"
                            placeholder="confirmPass"
                            type="password"
                            onChange={(e) => setconfirmPass(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={onChangePassowrd}>
            Save
          </Button>{" "}
          <Button color="secondary" onClick={changePasswordToggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
