import React, { useState, useEffect } from "react";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import axios from "axios";
import GeneralHeader from "components/Headers/GeneralHeader";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

import {
  Button,
  Card,
  CardHeader,
  CardFooter,
  CardBody,
  Form,
  Table,
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
} from "reactstrap";

const path = require("../../externals/path.json").path;

export default function Visitation() {
  const { search } = useLocation();
  const { did } = queryString.parse(search);

  const isLoggedIn = useSelector((state) => state.isLogged);

  const [visitationList, setVisitationList] = useState([]);
  const [doctorId, setDoctorId] = useState();
  const [visitationId, setVisitationId] = useState(null);
  const [discription, setDiscription] = useState(null);
  const [username, setUsername] = useState(null);
  const [leaderId, setLeaderId] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  function onBindVisitationData() {
    axios
      .get(path + "/visitations/" + did, {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setVisitationList(result.data.data);
        }
      });
  }
  useEffect(() => {
    onBindVisitationData();
  }, []);

  function onAddNewDataHandler() {
    setDoctorId(did);
    setVisitationId(null);
    setDiscription();
    setUsername(isLoggedIn.user.id);
    setLeaderId(isLoggedIn.user.leaderId);
    setShowAddModal(true);
    setIsUpdate(false);
  }
  function toggle() {
    setShowAddModal(!showAddModal);
  }

  function onSaveDataHandler() {
    const visitation = {
      discription: discription,
      username: username,
      doctorId: doctorId,
      leaderId: leaderId,
    };
    if (isUpdate === true) {
      axios
        .put(path + "/visitations/" + visitationId, visitation, {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        })
        .then((result) => {
          if (result.data.success === true) {
            onBindVisitationData();
            setShowAddModal(false);
            setIsUpdate(false);
            Swal.fire({
              title: "THANKS",
              text: "the visitation updated",
              icon: "success",
            });
          }
          // if (result.data.token !== null)
          //   this.props.user.user.token = result.data.token;
        })
        .catch((error) => {
          Swal.fire({
            title: "warning",
            text: "visitation did not updated please contact administrator",
            icon: "warning",
          });
        });
    } else {
      axios
        .post(path + "/visitations", visitation, {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        })
        .then((result) => {
          if (result.data.success === true) {
            onBindVisitationData();
            setShowAddModal(false);
            Swal.fire({
              title: "THANKS",
              text: "new visitation Added",
              icon: "success",
            });
          }
          // if (result.data.token !== null)
          //   this.props.user.user.token = result.data.token;
        })
        .catch((error) => {
          Swal.fire({
            title: "warning",
            text: "Visitation did not added please contact administrator",
            icon: "warning",
          });
        });
    }
  }

  function EditDataHandler(visitationId, Index) {
    // let item = itemList.find((o) => o.id === item.itemId);
    axios
      .get(path + "/visitations/visitation/" + visitationId, {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setIsUpdate(true);
          setShowAddModal(true);
          setDiscription(result.data.data.discription);
          setDoctorId(result.data.data.doctorId);
          setUsername(result.data.data.username);
          setLeaderId(result.data.data.leaderId);
          setVisitationId(result.data.data.id);
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "warning",
          text: `there is some error while get vistation with  number ${Index} data please contact administrator`,
          icon: "warning",
        });
      });
  }

  function onDeleteVisitationHandler(id, index) {
    Swal.fire({
      title: 'Do you want to delete visitation  "' + index + '" ?',
      showCancelButton: true,
      confirmButtonText: "Save",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.value) {
        axios
          .delete(path + "/visitations/" + id, {
            // headers: {
            //   "auth-token": this.props.user.user.token,
            // },
          })
          .then((result) => {
            if (result.data.statusCode === 200) {
              onBindVisitationData();
              Swal.fire("visitation Deleted!", "", "success");
            }
            // if (result.data.token !== null)
            //   this.props.user.user.token = result.data.token;
          })
          .catch((error) => {
            Swal.fire({
              title: "warning",
              text: "the visitation did not deleted please contact administrator",
              icon: "warning",
            });
          });
      }
    });
  }

  return (
    <>
      <GeneralHeader />

      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h2 className="mb-0">Visitation List</h2>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      onClick={(e) => onAddNewDataHandler()}
                      size="md"
                    >
                      New Visitation
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <div style={{ height: "76vh", overflow: "scroll" }}>
                <Table
                  className=" align-items-center table-flush"
                  style={{ fontWeight: "bold", color: "black" }}
                >
                  <thead className="thead-light">
                    <tr>
                      <th
                        scope="col"
                        style={{
                          fontSize: 15,
                        }}
                      >
                        #
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontSize: 15,
                        }}
                      >
                        Dicription
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {visitationList.map(function (item, index) {
                      return (
                        <tr>
                          <th
                            scope="row"
                            style={{
                              fontSize: 15,
                            }}
                          >
                            {index + 1}
                          </th>
                          <td
                            style={{
                              fontSize: 15,
                            }}
                          >
                            {item.discription}
                          </td>

                          <td className="text-right">
                            <div>
                              <Button
                                size="sm"
                                color="warning"
                                onClick={() =>
                                  EditDataHandler(item.id, index + 1)
                                }
                              >
                                Edit
                              </Button>{" "}
                              <Button
                                size="sm"
                                color="danger"
                                onClick={() =>
                                  onDeleteVisitationHandler(item.id, index + 1)
                                }
                              >
                                Delete
                              </Button>{" "}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem className="disabled">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem className="active">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        1
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        isOpen={showAddModal}
        toggle={toggle}
        className="modal-lg"
        style={{ maxWidth: "1500px", width: "100%" }}
      >
        <ModalHeader toggle={toggle}>New Visitation</ModalHeader>
        <ModalBody>
          <Col className="order-xl-1">
            <Card className="shadow" style={{ backgroundColor: "#F0F8FF" }}>
              <CardBody>
                <Form autoComplete="off" id="ff">
                  <h6 className="heading-small text-muted mb-2">
                    Visitation information
                  </h6>
                  <div className="pl-lg-2">
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            value={discription}
                            id="discription"
                            placeholder="discription"
                            type="textarea"
                            onChange={(e) => setDiscription(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => onSaveDataHandler()}>
            Save
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
