import React, { useState, useEffect, useRef } from "react";
import ReactToPrint from "react-to-print";
import axios from "axios";
import NumberFormat from "react-number-format";
import { Bar } from "react-chartjs-2";

import {
  Button,
  Table,
  Row,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap";
import { chartExample2 } from "variables/charts.js";

const path = require("../../../externals/path.json").path;

export default function SellNeighbourCompareByDate() {
  let invoiceRef = useRef();
  let chartRef = useRef();

  const [cityList, setCityList] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [cityId, setCityId] = useState();
  const [zoneId, setZoneId] = useState();

  const [reportData, setReportData] = useState([]);
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [showChartAddModal, setshowChartAddModal] = useState(false);
  const [chartCountSellData, setChartCountSellData] = useState({
    labels: ["Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: [
      {
        label: "Sales",
        data: [25, 20, 30, 22, 17, 29],
        maxBarThickness: 10,
      },
    ],
  });

  useEffect(() => {
    onBindcityData();
  }, []);

  function onBindcityData() {
    axios
      .get(path + "/cities", {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setCityList(result.data.data);
        }
      });
  }
  function onBindZoneDataHandler(cityId) {
    axios
      .get(path + "/zone/" + Number(cityId), {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.data !== null) setZoneList(result.data.data);
      });
  }
  function cityItemsRender() {
    let items = [];
    cityList.map((item) =>
      items.push(
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      )
    );
    return items;
  }

  function zoneItemsRender() {
    let items = [];
    zoneList.map((item) =>
      items.push(
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      )
    );
    return items;
  }

  function chartToggle() {
    setshowChartAddModal(!showChartAddModal);
  }

  function onBindtableData() {
    axios
      .post(
        path + "/sellreports/getSellNeighbourCompareByDate",
        {
          zoneId: zoneId !== "0" ? zoneId : null,
          from: from,
          to: to,
        },
        {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        }
      )
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setReportData(result.data.data);
          let month = [];
          let total = [];
          result.data.data.forEach((element) => {
            month.push(element.name);
            total.push(element.totalIQD);
          });
          setChartCountSellData({
            labels: month,
            datasets: [
              {
                label: "Sales",
                data: total,
                maxBarThickness: 5,
              },
            ],
          });
        }
      });
  }
  function onChangeCityHandler(event) {
    setCityId(event.target.value);

    onBindZoneDataHandler(event.target.value);
  }

  return (
    <>
      <Row>
        <Col>
          <Input
            className="form-control-alternative"
            value={cityId}
            id="cityId"
            placeholder="cityId"
            type="select"
            onChange={(e) => onChangeCityHandler(e)}
            style={{ fontSize: 16, color: "black", height: 45 }}
          >
            <option key={0} value={0}>
              ---city---
            </option>
            {cityItemsRender()}
          </Input>
        </Col>
        <Col>
          <Input
            className="form-control-alternative"
            value={zoneId}
            id="zoneId"
            placeholder="zoneId"
            type="select"
            onChange={(e) => setZoneId(e.target.value)}
            style={{ fontSize: 16, color: "black", height: 45 }}
          >
            <option key={0} value={0}>
              ---zone---
            </option>
            {zoneItemsRender()}
          </Input>
        </Col>
        <Col>
          <Input
            className="form-control-alternative"
            value={from}
            id="from"
            placeholder="from"
            type="date"
            onChange={(e) => setFrom(e.target.value)}
            style={{ fontSize: 16, color: "black", height: 45 }}
          />
        </Col>
        <Col>
          <Input
            className="form-control-alternative"
            value={to}
            id="to"
            placeholder="to"
            type="date"
            onChange={(e) => setTo(e.target.value)}
            style={{ fontSize: 16, color: "black", height: 45 }}
          />
        </Col>
        <Col className="text-right">
          <Button color="primary" onClick={(e) => onBindtableData()} size="md">
            Get
          </Button>
          <ReactToPrint
            trigger={() => <Button color="success">print</Button>}
            content={() => invoiceRef}
          />
          <Button color="warning" onClick={chartToggle} size="md">
            Chart
          </Button>
        </Col>
      </Row>

      <br />
      <div ref={(el) => (invoiceRef = el)}>
        <div>
          <Table
            className=" align-items-center table-flush"
            bordered
            style={{ fontWeight: "bold", color: "black" }}
            responsive
          >
            <thead className="thead-light">
              <tr>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  User
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  Quantity
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  {" "}
                  total ($)
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  total (IQD)
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  PAID
                </th>
              </tr>
            </thead>
            <tbody>
              {reportData.map(function (item, index) {
                return (
                  <tr>
                    <th
                      scope="row"
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      {item.name}
                    </th>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      {item.quantity}
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      <NumberFormat
                        value={item.totalDLR ? item.totalDLR.toFixed(0) : 0}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$ "}
                      />
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      <NumberFormat
                        value={item.totalIQD ? item.totalIQD.toFixed(0) : 0}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"IQD "}
                      />
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      <NumberFormat
                        value={item.totalPaid ? item.totalPaid.toFixed(0) : 0}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"IQD "}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
      <Modal
        isOpen={showChartAddModal}
        toggle={chartToggle}
        size="lg"
        style={{ maxWidth: "1500px", width: "100%" }}
      >
        <ModalBody>
          <div ref={(el) => (chartRef = el)}>
            <br />
            <br />

            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-muted ls-1 mb-1">
                      Performance
                    </h6>
                    <h2 className="mb-0">User Compare by Date</h2>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {/* Chart */}
                <div className="chart">
                  <Bar
                    data={chartCountSellData}
                    options={chartExample2.options}
                  />
                </div>
              </CardBody>
            </Card>
          </div>
        </ModalBody>
        <ModalFooter>
          <ReactToPrint
            trigger={() => <Button color="primary">print</Button>}
            content={() => chartRef}
          />

          <Button color="secondary" onClick={chartToggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
