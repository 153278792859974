var RoutesList = [
  "Dashboard",
  "Customer",
  "Customer Report",
  "Doctors",
  "Hospitals",
  "Purchase",
  "Stock",
  "Items",
  "Sales",
  "Offers",
  "Expenses",
  "Visitation",
  "Sample Or Loss",
  "Users",
  "Debts",
  "distribution",
  "Cities",
  "Inventories",
  "ExpensesType",
  "My Account",
  "Partner Account",
  "Activities",
  "Money back to Company",
  "Reports",
  "Returning Or Change",
];

export default RoutesList;
