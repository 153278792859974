import React, { useState, useEffect } from "react";
import GeneralHeader from "components/Headers/GeneralHeader";
import Select from "react-select";
import axios from "axios";
import Swal from "sweetalert2";
import NumberFormat from "react-number-format";
import Moment from "react-moment";
import {
  Button,
  Card,
  CardHeader,
  CardFooter,
  CardBody,
  Form,
  Table,
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  Badge,
  Label,
} from "reactstrap";
import { useSelector } from "react-redux";
const path = require("../../externals/path.json").path;

export default function Index() {
  const user = useSelector((state) => state.isLogged.user);
  const rate = useSelector((state) => state.rateData.rate);
  const [offersList, setOffersList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [doctorList, setDoctorList] = useState([]);
  const [hospitalList, setHospitalList] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [customerOptionIndex, setCustomerOptionIndex] = useState();
  const [doctorOptions, setDoctorOptions] = useState([]);
  const [doctorOptionIndex, setdoctorOptionIndex] = useState();
  const [hospitalOptions, setHospitalOptions] = useState([]);
  const [hospitalOptionIndex, setHospitalOptionIndex] = useState();
  const [userOptions, setUserOptions] = useState([]);
  const [userOptionIndex, setUserOptionIndex] = useState();
  const [offerId, setOfferId] = useState();
  const [customerId, setCustomerId] = useState();
  const [doctorId, setDoctorId] = useState();
  const [hosptialId, setHosptialId] = useState();
  const [userId, setUserId] = useState();
  const [costDlR, setCostDlR] = useState();
  const [costIQD, setCostIQD] = useState();
  const [deliveredBy, setDeliveredBy] = useState();
  const [orderedBy, setOrderedBy] = useState();
  const [paidBack, setPaidBack] = useState();
  const [isDone, setIsDone] = useState();
  const [username, setusername] = useState();
  const [type, setType] = useState();
  const [listType, setListType] = useState("all");
  const [offerFor, setOfferFor] = useState();
  const [showAddModal, setshowAddModal] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [rateId, setRateId] = useState();
  const [rateValue, setRateValue] = useState();
  const [userNameSearch, setUserNameSearch] = useState();

  function onBindOffersData() {
    axios
      .post(
        path + "/offers/list",
        {
          userId: user.id,
          leaderId: user.leaderId,
          role: user.role,
          type: listType,
          userNameSearch: userNameSearch,
        },
        {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        }
      )
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setOffersList(result.data.data);
        }
      });
  }

  useEffect(() => {
    onBindOffersData();
    onBindHospitalData();
    onBindDoctorData();
    onBindCustomerData();
    onBindUsersData();
  }, [listType, userNameSearch]);
  useEffect(() => {
    onBindUsersData();
  }, []);

  function onBindCustomerData() {
    axios
      .post(
        path + "/customers/sell",
        { role: user.role, zones: user.zones },
        {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        }
      )
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setCustomerList(result.data.data);
          let options = [{ value: -1, label: "----Customer List ----" }];

          result.data.data.forEach((item, index) => {
            options.push({ value: index, label: item.name });
          });
          setCustomerOptions(options);
        }
      });
  }

  function onBindDoctorData() {
    axios
      .post(
        path + "/doctors/list",
        { role: user.role, zones: user.zones },
        {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        }
      )
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setDoctorList(result.data.data);
          let options = [{ value: -1, label: "----Doctor List ----" }];

          result.data.data.forEach((item, index) => {
            options.push({ value: index, label: item.name });
          });
          setDoctorOptions(options);
        }
      });
  }

  function onBindHospitalData() {
    axios
      .post(
        path + "/hospitals/list",
        { role: user.role, zones: user.zones },
        {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        }
      )
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setHospitalList(result.data.data);
          let options = [{ value: -1, label: "----Hospital List ----" }];

          result.data.data.forEach((item, index) => {
            options.push({ value: index, label: item.name });
          });
          setHospitalOptions(options);
        }
      });
  }

  function onBindUsersData() {
    axios
      .get(path + "/users", {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setUsersList(result.data.data);
          let options = [{ value: -1, label: "----Hospital List ----" }];

          result.data.data.forEach((item, index) => {
            options.push({ value: index, label: item.name });
          });
          setUserOptions(options);
        }
      });
  }
  function toggle() {
    setshowAddModal(!showAddModal);
  }

  function onchangeOfferForhandler(e) {
    setOfferFor(e.target.value);
    setCustomerOptionIndex(null);
    setHospitalOptionIndex(null);
    setdoctorOptionIndex(null);
    setUserOptionIndex(null);
    setCustomerId(null);
    setHosptialId(null);
    setDoctorId(null);
    setUserId(null);
    if (e.target.value === "Doctor") {
      onBindDoctorData();
    } else if (e.target.value === "Hospital") {
      onBindHospitalData();
    } else if (e.target.value === "Customer") {
      onBindCustomerData();
    } else {
    }
  }

  function onCustomerChangeHandler(e) {
    setCustomerOptionIndex({
      value: e.value,
      label: customerList[e.value].name,
    });
    setCustomerId(customerList[e.value].id);
    setDoctorId();
    setHosptialId();
    setUserId();
    setHospitalOptionIndex();
    setdoctorOptionIndex();
  }
  function onDoctorChangeHandler(e) {
    setdoctorOptionIndex({
      value: e.value,
      label: doctorList[e.value].name,
    });
    setDoctorId(doctorList[e.value].id);
    setHosptialId();
    setCustomerId();
    setUserId();
    setHospitalOptionIndex();
    setCustomerOptionIndex();
  }
  function onHospitalChangeHandler(e) {
    setHospitalOptionIndex({
      value: e.value,
      label: hospitalList[e.value].name,
    });
    setHosptialId(hospitalList[e.value].id);
    setDoctorId();
    setCustomerId();
    setUserId();
    setCustomerOptionIndex();
    setdoctorOptionIndex();
  }
  function onUserChangeHandler(e) {
    setUserOptionIndex({
      value: e.value,
      label: usersList[e.value].name,
    });
    setUserId(usersList[e.value].id);
    setDoctorId();
    setCustomerId();
    setHosptialId();
    setCustomerOptionIndex();
    setdoctorOptionIndex();
    setHospitalOptionIndex();
  }

  function usersItemsRender() {
    let items = [];
    usersList.map((item) =>
      items.push(
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      )
    );
    return items;
  }

  function listItemsRender() {
    if (offerFor === "Doctor") {
      return (
        <Col
          lg={4}
          style={{
            size: 10,
          }}
        >
          <Select
            value={doctorOptionIndex}
            onChange={(e) => onDoctorChangeHandler(e)}
            options={doctorOptions}
            styles={{
              control: (base) => ({
                ...base,
                height: 44,
                minHeight: 44,
              }),
            }}
          />
        </Col>
      );
    } else if (offerFor === "Hospital") {
      return (
        <Col
          lg={4}
          style={{
            size: 10,
          }}
        >
          <Select
            value={hospitalOptionIndex}
            onChange={(e) => onHospitalChangeHandler(e)}
            options={hospitalOptions}
            styles={{
              control: (base) => ({
                ...base,
                height: 44,
                minHeight: 44,
              }),
            }}
          />
        </Col>
      );
    } else if (offerFor === "Customer") {
      return (
        <Col
          lg={4}
          style={{
            size: 10,
          }}
        >
          <Select
            value={customerOptionIndex}
            onChange={(e) => onCustomerChangeHandler(e)}
            options={customerOptions}
            styles={{
              control: (base) => ({
                ...base,
                height: 44,
                minHeight: 44,
              }),
            }}
          />
        </Col>
      );
    } else if (offerFor === "User") {
      return (
        <Col
          lg={4}
          style={{
            size: 10,
          }}
        >
          <Select
            value={userOptionIndex}
            onChange={(e) => onUserChangeHandler(e)}
            options={userOptions}
            styles={{
              control: (base) => ({
                ...base,
                height: 44,
                minHeight: 44,
              }),
            }}
          />
        </Col>
      );
    } else {
    }
  }

  function renderOfferForRow(item) {
    if (item.doctorName) {
      return (
        <td
          style={{
            fontSize: 15,
          }}
        >
          {item.doctorName}
        </td>
      );
    } else if (item.customerName) {
      return (
        <td
          style={{
            fontSize: 15,
          }}
        >
          {item.customerName}
        </td>
      );
    } else if (item.hospitalName) {
      return (
        <td
          style={{
            fontSize: 15,
          }}
        >
          {item.hospitalName}
        </td>
      );
    } else if (item.uname) {
      return (
        <td
          style={{
            fontSize: 15,
          }}
        >
          {item.uname}
        </td>
      );
    } else {
      return (
        <td
          style={{
            fontSize: 15,
          }}
        ></td>
      );
    }
  }

  function onAddNewDataHandler() {
    setRateValue(rate.rate);
    setRateId(rate.id);
    setType();
    setOfferFor();
    setCustomerOptionIndex();
    setCustomerId();
    setDoctorId();
    setHosptialId();
    setUserId();
    setOrderedBy();
    setCostDlR();
    setCostIQD();
    setIsDone();
    setDeliveredBy();
    setPaidBack();
    setusername(user.id);
    setIsUpdate(false);
    toggle();
  }

  function onCHangeCostIQDHandler(value) {
    setCostIQD(value);
    setCostDlR(value / rateValue);
  }
  function onCHangeCostDLRHandler(value) {
    setCostIQD(value * rateValue);
    setCostDlR(value);
  }

  function onSaveDataHandler() {
    const offer = {
      type: type,
      offerFor: offerFor,
      doctorId: doctorId,
      customerId: customerId,
      hosptialId: hosptialId,
      userId: userId,
      cost: costDlR,
      rateId: rateId,
      deliveredBy: deliveredBy,
      orderedBy: orderedBy,
      paidBack: paidBack,
      isDone: isDone,
      username: username,
    };
    if (isUpdate === true) {
      axios
        .put(path + "/offers/" + offerId, offer, {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        })
        .then((result) => {
          if (result.data.success === true) {
            onBindOffersData();
            setshowAddModal(false);
            setIsUpdate(false);
            Swal.fire({
              title: "THANKS",
              text: "the Offer updated",
              icon: "success",
            });
          }
          // if (result.data.token !== null)
          //   this.props.user.user.token = result.data.token;
        })
        .catch((error) => {
          Swal.fire({
            title: "warning",
            text: "Offer did not updated please contact administrator",
            icon: "warning",
          });
        });
    } else {
      axios
        .post(path + "/offers", offer, {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        })
        .then((result) => {
          if (result.data.success === true) {
            onBindOffersData();
            setshowAddModal(false);
            Swal.fire({
              title: "THANKS",
              text: "new Offer Added",
              icon: "success",
            });
          }
          // if (result.data.token !== null)
          //   this.props.user.user.token = result.data.token;
        })
        .catch((error) => {
          Swal.fire({
            title: "warning",
            text: "Offer did not added please contact administrator",
            icon: "warning",
          });
        });
    }
  }

  function EditDataHandler(offerId, Index) {
    // let item = itemList.find((o) => o.id === item.itemId);
    axios
      .get(path + "/offers/" + offerId, {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          axios
            .get(path + "/rates/" + result.data.data.rateId, {
              // headers: {
              //   "auth-token": this.props.user.user.token,
              // },
            })
            .then((res) => {
              setIsUpdate(true);
              setshowAddModal(true);
              setRateValue(res.data.data.rate);
              setRateId(res.data.data.id);
              setType(result.data.data.type);
              setOfferFor(result.data.data.offerFor);
              onCHangeCostDLRHandler(result.data.data.cost);
              setDeliveredBy(result.data.data.deliveredBy);
              setPaidBack(result.data.data.paidBack);
              setOrderedBy(result.data.data.orderedBy);
              setIsDone(result.data.data.isDone);

              setOfferId(offerId);

              if (result.data.data.customerId) {
                const index = customerList.findIndex(
                  (item) => item.id === result.data.data.customerId
                );
                onCustomerChangeHandler({
                  value: index,
                  label: customerList[index].name,
                });
              } else if (result.data.data.doctorId) {
                const index = doctorList.findIndex(
                  (item) => item.id === result.data.data.doctorId
                );
                onDoctorChangeHandler({
                  value: index,
                  label: doctorList[index].name,
                });
              } else if (result.data.data.hosptialId) {
                const index = hospitalList.findIndex(
                  (item) => item.id === result.data.data.hosptialId
                );
                onHospitalChangeHandler({
                  value: index,
                  label: hospitalList[index].name,
                });
              } else if (result.data.data.userId) {
                const index = usersList.findIndex(
                  (item) => item.id === result.data.data.userId
                );
                onUserChangeHandler({
                  value: index,
                  label: usersList[index].name,
                });
              }
            })
            .catch((error) => {
              Swal.fire({
                title: "warning",
                text: `there is some error while get rate with rate id ${result.data.data.rateId} data please contact administrator`,
                icon: "warning",
              });
            });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "warning",
          text: `there is some error while get offer with  number ${Index} data please contact administrator`,
          icon: "warning",
        });
      });
  }

  function onDeleteOfferHandler(id, index) {
    Swal.fire({
      title: 'Do you want to delete offer  "' + index + '" ?',
      showCancelButton: true,
      confirmButtonText: "Save",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.value) {
        axios
          .delete(path + "/offers/" + id, {
            // headers: {
            //   "auth-token": this.props.user.user.token,
            // },
          })
          .then((result) => {
            if (result.data.statusCode === 200) {
              onBindOffersData();
              Swal.fire("offer Deleted!", "", "success");
            }
            // if (result.data.token !== null)
            //   this.props.user.user.token = result.data.token;
          })
          .catch((error) => {
            Swal.fire({
              title: "warning",
              text: "the offer did not deleted please contact administrator",
              icon: "warning",
            });
          });
      }
    });
  }
  return (
    <>
      <GeneralHeader />

      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col>
                    <h2 className="mb-0">Offers List</h2>
                  </Col>
                  <Col>
                    <Input
                      className="form-control-alternative"
                      value={userNameSearch}
                      id="userName"
                      placeholder="userName"
                      type="select"
                      onChange={(e) => {
                        if (e.target.value !== "0")
                          setUserNameSearch(e.target.value);
                        else setUserNameSearch();
                      }}
                      style={{ fontSize: 16, color: "black", height: 45 }}
                    >
                      <option value={0}>---Ordered By---</option>
                      {usersItemsRender()}
                    </Input>
                  </Col>

                  <Col>
                    <FormGroup row className="mb-0">
                      <Label
                        for="exampleEmail"
                        style={{
                          fontSize: 18,
                          fontWeight: "bold",
                        }}
                      >
                        List Type
                      </Label>
                      <Col style={{}}>
                        <Input
                          className="form-control-alternative"
                          value={listType}
                          id="offerFor"
                          placeholder="offerFor"
                          type="select"
                          onChange={(e) => {
                            setListType(e.target.value);
                          }}
                        >
                          <option value={"all"}>All</option>
                          <option value={"notDone"}>Ordered</option>
                          <option value={"done"}>Done</option>
                        </Input>
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      onClick={(e) => onAddNewDataHandler()}
                      size="md"
                    >
                      New Offer
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <div style={{ height: "76vh", overflow: "scroll" }}>
                <Table
                  className=" align-items-center table-flush"
                  style={{ fontWeight: "bold", color: "black" }}
                >
                  <thead className="thead-light">
                    <tr>
                      <th
                        scope="col"
                        style={{
                          fontSize: 15,
                        }}
                      >
                        #
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontSize: 15,
                        }}
                      >
                        TYPE
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontSize: 15,
                        }}
                      >
                        Offer FOR
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontSize: 15,
                        }}
                      >
                        Offer FOR (Name)
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontSize: 15,
                        }}
                      >
                        Cost IQD
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontSize: 15,
                        }}
                      >
                        Cost $
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontSize: 15,
                        }}
                      >
                        Deliveried By
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontSize: 15,
                        }}
                      >
                        Ordered By
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontSize: 15,
                        }}
                      >
                        Paid Back ?
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontSize: 15,
                        }}
                      >
                        isDone ?
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontSize: 15,
                        }}
                      >
                        Date
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontSize: 15,
                        }}
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {offersList.map(function (item, index) {
                      return (
                        <tr>
                          <th scope="row">{index + 1}</th>
                          <td
                            style={{
                              fontSize: 15,
                            }}
                          >
                            {item.type}
                          </td>
                          <td
                            style={{
                              fontSize: 15,
                            }}
                          >
                            {item.offerFor}
                          </td>
                          {renderOfferForRow(item)}

                          <td
                            style={{
                              fontSize: 15,
                            }}
                          >
                            {" "}
                            <NumberFormat
                              value={item.costIQD.toFixed(0)}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"IQD "}
                            />
                          </td>
                          <td
                            style={{
                              fontSize: 15,
                            }}
                          >
                            <NumberFormat
                              value={item.cost.toFixed(0)}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$ "}
                            />
                          </td>
                          <td
                            style={{
                              fontSize: 15,
                            }}
                          >
                            {item.deliveredByName}
                          </td>
                          <td
                            style={{
                              fontSize: 15,
                            }}
                          >
                            {item.orderedByName}
                          </td>
                          <td className="text-center">
                            {item.paidBack === "1" ? (
                              <Badge color="success">YES</Badge>
                            ) : (
                              <Badge color="danger">No</Badge>
                            )}
                          </td>
                          <td className="text-center">
                            {item.isDone === "1" ? (
                              <Badge color="success">YES</Badge>
                            ) : (
                              <Badge color="danger">No</Badge>
                            )}
                          </td>
                          <td
                            style={{
                              fontSize: 15,
                            }}
                          >
                            {" "}
                            <Moment format="DD-MM-YYYY">{item.date}</Moment>
                          </td>
                          <td className="text-right">
                            <div>
                              {user.role === "admin" ||
                              user.role === "accountant" ? (
                                <>
                                  <Button
                                    size="sm"
                                    color="warning"
                                    onClick={() =>
                                      EditDataHandler(item.id, index + 1)
                                    }
                                    disabled={
                                      item.paidBack === "1" ? true : false
                                    }
                                  >
                                    Edit
                                  </Button>{" "}
                                  <Button
                                    size="sm"
                                    color="danger"
                                    onClick={() =>
                                      onDeleteOfferHandler(item.id, index + 1)
                                    }
                                    disabled={
                                      item.paidBack === "1" ? true : false
                                    }
                                  >
                                    Delete
                                  </Button>{" "}
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem className="disabled">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem className="active">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        1
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        isOpen={showAddModal}
        toggle={toggle}
        className="modal-lg"
        style={{ maxWidth: "1500px", width: "100%" }}
      >
        <ModalHeader toggle={toggle}>New Offer</ModalHeader>
        <ModalBody>
          <Col className="order-xl-1">
            <Card className="shadow" style={{ backgroundColor: "#F0F8FF" }}>
              <CardBody>
                <Form autoComplete="off" id="ff">
                  <h6 className="heading-small text-muted mb-2">
                    Offer information
                  </h6>
                  <div className="pl-lg-2">
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            value={type}
                            id="type"
                            placeholder="type"
                            type="text"
                            onChange={(e) => setType(e.target.value)}
                          ></Input>
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            value={offerFor}
                            id="offerFor"
                            placeholder="offerFor"
                            type="select"
                            onChange={(e) => onchangeOfferForhandler(e)}
                          >
                            <option>---offer For---</option>
                            <option>Doctor</option>
                            <option>Customer</option>
                            <option>Hospital</option>
                            <option>User</option>
                          </Input>
                        </FormGroup>
                      </Col>
                      {listItemsRender()}
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            value={costIQD}
                            id="costIQD"
                            placeholder="cost (IQD)"
                            type="number"
                            onChange={(e) =>
                              onCHangeCostIQDHandler(e.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            value={costDlR}
                            id="costDlR"
                            placeholder="cost ($)"
                            type="number"
                            onChange={(e) =>
                              onCHangeCostDLRHandler(e.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            value={deliveredBy}
                            id="deliveredBy"
                            placeholder="deliveredBy"
                            type="select"
                            onChange={(e) => setDeliveredBy(e.target.value)}
                          >
                            <option>---delivered By---</option>
                            {usersItemsRender()}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            value={orderedBy}
                            id="orderedBy"
                            placeholder="Ordered By"
                            type="select"
                            onChange={(e) => setOrderedBy(e.target.value)}
                          >
                            <option>---Ordered By---</option>
                            {usersItemsRender()}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="2">
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            value={paidBack}
                            id="offerFor"
                            placeholder="offerFor"
                            type="select"
                            onChange={(e) => setPaidBack(e.target.value)}
                          >
                            <option>---Paid Back ?---</option>
                            <option key={"0"} value={"0"}>
                              No
                            </option>
                            <option key={"1"} value={"1"}>
                              Yes
                            </option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="2">
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            value={isDone}
                            id="isDone"
                            placeholder="isDone"
                            type="select"
                            onChange={(e) => setIsDone(e.target.value)}
                          >
                            <option>---isDone ?---</option>
                            <option key={"0"} value={"0"}>
                              No
                            </option>
                            <option key={"1"} value={"1"}>
                              Yes
                            </option>
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => onSaveDataHandler()}>
            Save
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
