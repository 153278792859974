import React, { useState, useEffect, useRef } from "react";
import ReactToPrint from "react-to-print";
import axios from "axios";
import Swal from "sweetalert2";
import NumberFormat from "react-number-format";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import Select from "react-select";
import * as XLSX from "xlsx";
import {
  Button,
  Table,
  Row,
  Col,
  Input,
  FormGroup,
  Label,
  Badge,
} from "reactstrap";

const path = require("../../../externals/path.json").path;

export default function EmployeesAccounting() {
  const rate = useSelector((state) => state.rateData.rate);
  const user = useSelector((state) => state.isLogged.user);
  let invoiceRef = useRef();

  const [reportData, setReportData] = useState([]);
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [userName, setUserName] = useState();

  const [userPersentage, setUserPersentage] = useState();
  const [userRepaymentType, setUserRepaymentType] = useState();

  const [usersList, setUsersList] = useState([]);

  const [ReadytoPay, setReadytoPay] = useState(true);

  const [usersOptions, setUsersOptions] = useState([]);
  const [usersOptionIndex, setUsersOptionIndex] = useState();

  useEffect(() => {
    onBindUsersData();
  }, []);

  function OnUsersChangeHandler(data) {
    setUsersOptionIndex(data);
    setUserName(usersList[data.value].name);
    setUserPersentage(usersList[data.value].persentage);
    setUserRepaymentType(usersList[data.value].repaymentType);
  }

  function onExportDataToExcel() {
    let list = [];
    reportData.forEach((item, index) => {
      list.push({
        "#": index + 1,
        "Employee Name": item.employeeName,
        Salary: item.salary,
        "Salary Date": item.salaryDate,
        "Persentage Price": item.persentagePrice,
        "Bonus Price": item.bonusPrice,
        Total:
          Number(item.bonusPrice.toFixed(0)) +
          Number(item.persentagePrice.toFixed(0)) +
          Number(item.salary.toFixed(0)),
        "Per. Date from": item.from,
        "Per. Date to": item.to,
        Signature: "                                ",
      });
    });

    var wb = XLSX.utils.book_new();
    var ws = XLSX.utils.json_to_sheet(list);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "Listi muchay karmandan.xlsx");
  }

  function onBindtableData() {
    axios
      .post(
        path + "/sellreports/getSellUserShare",
        {
          userName:
            usersList[usersOptionIndex.value].id !== "0"
              ? usersList[usersOptionIndex.value].id
              : null,
          from: from,
          to: to,
          role: usersList[usersOptionIndex.value].role,
          repaymentType: usersList[usersOptionIndex.value].repaymentType,
          persentage: usersList[usersOptionIndex.value].persentage,
        },
        {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        }
      )
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          let Tquantity = 0;
          let TpriceIQD = 0;
          let TpriceDLR = 0;
          let Tpaid = 0;
          let TPersentage = 0;
          let TBonus = 0;

          result.data.data.forEach((item) => {
            Tquantity = Tquantity + item.quantity;
            TpriceDLR = TpriceDLR + item.priceDLR;
            TpriceIQD = TpriceIQD + item.priceIQD;
            Tpaid = Tpaid + item.paid;
            TPersentage = TPersentage + item.persentagePrice;
            TBonus = TBonus + item.bonusPrice;
          });
          setReportData([
            ...reportData,
            {
              employeeName: usersList[usersOptionIndex.value].name,
              employeeId: usersList[usersOptionIndex.value].id,
              repaymentType: usersList[usersOptionIndex.value].repaymentType,
              salary: usersList[usersOptionIndex.value].salary
                ? usersList[usersOptionIndex.value].salary
                : 0,
              numberofInvoice: result.data.data.length,

              persentagePrice: TPersentage ? TPersentage : 0,
              bonusPrice: TBonus ? TBonus : 0,
              from: from,
              to: to,
              role: usersList[usersOptionIndex.value].role,
              username: user.id,
              rateId: rate.id,
            },
          ]);

          setUserRepaymentType();
          setReadytoPay(false);
          // setTotalPaid(result.data.data.paid);
          // setTotalIQD(result.data.data.priceIQD);
          // setTotalDLR(result.data.data.priceDLR);
          // setTotalQuantity(result.data.data.quantity);
          // setTotalBunus(result.data.data.bonusPrice);
          // setTotalPersentage(result.data.data.persentagePrice);
        }
      });
  }

  function onBindUsersData() {
    axios
      .get(path + "/users", {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setUsersList(result.data.data);

          let options = [];
          result.data.data.forEach((item, index) => {
            options.push({ value: index, label: item.name });
          });

          setUsersOptions(options);
        }
      });
  }
  function onClearTableData() {
    setReportData([]);
    setReadytoPay(true);
  }

  function onPayToUserHandler(id) {
    Swal.fire({
      title: 'Do you want to Pay persentage to users ? "',
      showCancelButton: true,
      confirmButtonText: "Save",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.value) {
        for (let i = 0; i < reportData.length; i++) {
          axios
            .post(
              path + "/userhare",
              {
                userId: reportData[i].employeeId,
                fullName: reportData[i].employeeName,
                numberofInvoice: reportData[i].numberofInvoice,
                amountIQD: reportData[i].persentagePrice,
                repaymentType: reportData[i].repaymentType,
                bonusAmount: reportData[i].bonusPrice,
                salary: reportData[i].salary,
                rateId: rate.id,
                from: reportData[i].from ? reportData[i].from : null,
                to: reportData[i].to ? reportData[i].to : null,
                username: user.id,
              },
              {
                // headers: {
                //   "auth-token": this.props.user.user.token,
                // },
              }
            )
            // eslint-disable-next-line no-loop-func
            .then((result) => {
              if (result.data.statusCode === 200) {
                axios
                  .put(
                    path + "/usercreditpaid",
                    {
                      userName: reportData[i].employeeId,
                      from: reportData[i].from,
                      to: reportData[i].to,
                      repaymentType: reportData[i].repaymentType,
                      role: reportData[i].role,
                      // paidType: paidType !== "0" ? paidType : null,
                    },
                    {
                      // headers: {
                      //   "auth-token": this.props.user.user.token,
                      // },
                    }
                  )
                  .then((result) => {
                    if (result.data.statusCode === 200) {
                      // onBindtableData();
                      setReadytoPay(true);
                      // setReportData([]);
                      setUserRepaymentType();
                      // setTotalDLR();
                      // setTotalIQD();
                      // setTotalQuantity();
                      // setTotalPaid();
                      // Swal.fire(
                      //   "Paid Successful to user !" + usersList[index].name,
                      //   "",
                      //   "success"
                      // );
                    }
                    // if (result.data.token !== null)
                    //   this.props.user.user.token = result.data.token;
                  })
                  .catch((error) => {
                    Swal.fire({
                      title: "warning",
                      text: " paying did not suscess please contact administrator",
                      icon: "warning",
                    });
                  });
              }
              // if (result.data.token !== null)
              //   this.props.user.user.token = result.data.token;
            })
            .catch((error) => {
              Swal.fire({
                title: "warning",
                text: " paying did not suscess please contact administrator",
                icon: "warning",
              });
            });
        }
        Swal.fire("Paid Successful to users !", "", "success");
      }
    });
  }
  return (
    <>
      <Row>
        <Col>
          <FormGroup>
            <Label
              for="exampleEmail"
              style={{
                fontSize: 12,
                fontWeight: "bold",
                marginTop: 10,
              }}
            >
              User
            </Label>
            <Select
              options={usersOptions}
              placeholder="Select Users"
              value={usersOptionIndex}
              onChange={OnUsersChangeHandler}
              isSearchable={true}
              // isMulti
            />
          </FormGroup>
        </Col>

        <Col className="text-right">
          <Button color="primary" onClick={(e) => onBindtableData()} size="md">
            Get
          </Button>
          <Button
            color="success"
            disabled={reportData.length ? false : true}
            onClick={(e) => onExportDataToExcel()}
            size="md"
          >
            Excel
          </Button>
          {/* <ReactToPrint
            trigger={() => <Button color="success">print</Button>}
            content={() => invoiceRef}
          /> */}
          <Button
            color="warning"
            onClick={(e) => onPayToUserHandler()}
            size="md"
            disabled={ReadytoPay}
          >
            Pay to user
          </Button>{" "}
          <Button color="warning" onClick={(e) => onClearTableData()} size="md">
            Clear
          </Button>
        </Col>
      </Row>

      <Row>
        {/* <Col lg="4">
          <FormGroup>
            <Label
              for="exampleEmail"
              style={{
                fontSize: 12,
                fontWeight: "bold",
                marginTop: 10,
              }}
            >
              Salary Date
            </Label>
            <Input
              className="form-control-alternative"
              value={salaryDate}
              id="salaryDate"
              placeholder="salaryDate"
              type="date"
              onChange={(e) => {
                setSalaryDate(e.target.value);
              }}
              style={{ fontSize: 16, color: "black", height: 45 }}
            />
          </FormGroup>
        </Col> */}
        <Col>
          <FormGroup>
            <Label
              for="exampleEmail"
              style={{
                fontSize: 14,
                fontWeight: "bold",
                marginTop: 10,
              }}
            >
              Per. From
            </Label>
            <Input
              className="form-control-alternative"
              value={from}
              id="from"
              placeholder="from"
              type="date"
              onChange={(e) => {
                setFrom(e.target.value);
                // setReadytoPay(true);
                // setReportData([]);
                // setTotalDLR();
                // setTotalIQD();
                // setTotalQuantity();
                // setTotalPaid();
              }}
              style={{ fontSize: 16, color: "black", height: 45 }}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label
              for="exampleEmail"
              style={{
                fontSize: 14,
                fontWeight: "bold",
                marginTop: 10,
              }}
            >
              Per. To
            </Label>
            <Input
              className="form-control-alternative"
              value={to}
              id="to"
              placeholder="to"
              type="date"
              onChange={(e) => {
                setTo(e.target.value);
                // setReadytoPay(true);
                // setReportData([]);
                // setTotalDLR();
                // setTotalIQD();
                // setTotalQuantity();
                // setTotalPaid();
              }}
              style={{ fontSize: 16, color: "black", height: 45 }}
            />
          </FormGroup>
        </Col>
      </Row>
      {/* <Row>
        <Col>
          <FormGroup check inline>
            <Label
              check
              style={{
                fontSize: 16,
                fontWeight: "bold",
                marginTop: 10,
              }}
            >
              <Input
                type="checkbox"
                id="salaryPay"
                onChange={(e) => setSalaryPay(!salaryPay)}
                checked={salaryPay}
              />{" "}
              Pay Salary
            </Label>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup check inline>
            <Label
              check
              style={{
                fontSize: 16,
                fontWeight: "bold",
                marginTop: 10,
              }}
            >
              <Input
                type="checkbox"
                id="persentagePay"
                onChange={(e) => setPersentagePay(!persentagePay)}
                checked={persentagePay}
              />{" "}
              Pay Persentage
            </Label>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup check inline>
            <Label
              check
              style={{
                fontSize: 16,
                fontWeight: "bold",
                marginTop: 10,
              }}
            >
              <Input
                type="checkbox"
                id="bonusPay"
                onChange={(e) => setBonusPay(!bonusPay)}
                checked={bonusPay}
              />{" "}
              Pay Bonus
            </Label>
          </FormGroup>
        </Col>
      </Row> */}

      <div ref={(el) => (invoiceRef = el)}>
        <br />
        <br />

        <div>
          <Table
            className=" align-items-center table-flush"
            bordered
            style={{ fontWeight: "bold", color: "black" }}
            responsive
          >
            <thead className="thead-light">
              <tr>
                <th scope="col" style={{ color: "black", fontSize: 15 }}>
                  Emp. Name
                </th>
                <th scope="col" style={{ color: "black", fontSize: 15 }}>
                  Salary
                </th>
                <th scope="col" style={{ color: "black", fontSize: 15 }}>
                  per. (IQD)
                </th>{" "}
                <th scope="col" style={{ color: "black", fontSize: 15 }}>
                  Bon. (IQD)
                </th>
                <th scope="col" style={{ color: "black", fontSize: 15 }}>
                  Total (IQD)
                </th>
                <th scope="col" style={{ color: "black", fontSize: 15 }}>
                  From
                </th>
                <th scope="col" style={{ color: "black", fontSize: 15 }}>
                  To
                </th>
                <th scope="col" style={{ color: "black", fontSize: 15 }}>
                  Signature
                </th>
              </tr>
            </thead>
            <tbody>
              {reportData.map(function (item, index) {
                return (
                  <tr>
                    <th
                      scope="row"
                      style={{
                        color: "black",
                        fontSize: 15,
                      }}
                    >
                      {item.employeeName}
                    </th>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                      }}
                    >
                      <NumberFormat
                        value={item.salary.toFixed(0)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"IQD "}
                      />
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                      }}
                    >
                      <NumberFormat
                        value={item.persentagePrice.toFixed(0)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"IQD "}
                      />
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                      }}
                    >
                      <NumberFormat
                        value={item.bonusPrice.toFixed(0)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"IQD "}
                      />
                    </td>{" "}
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                      }}
                    >
                      <NumberFormat
                        value={
                          Number(item.bonusPrice.toFixed(0)) +
                          Number(item.persentagePrice.toFixed(0)) +
                          Number(item.salary.toFixed(0))
                        }
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"IQD "}
                      />
                    </td>{" "}
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                      }}
                    >
                      {" "}
                      <Moment format="YYYY/MM/DD">{item.from}</Moment>
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                      }}
                    >
                      {" "}
                      <Moment format="YYYY/MM/DD">{item.to}</Moment>
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                      }}
                    >
                      {" "}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
}
