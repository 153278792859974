import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
// import { connect } from "react-redux";

//import NumberFormat from "react-number-format";
import Moment from "react-moment";

import axios from "axios";
import GeneralHeader from "components/Headers/GeneralHeader.js";
import {
  Card,
  CardHeader,
  // CardFooter,
  Table,
  Container,
  Row,
  Col,
  // DropdownMenu,
  // DropdownItem,
  // UncontrolledDropdown,
  // DropdownToggle,
  // Pagination,
  // PaginationItem,
  // PaginationLink,
  Badge,
  // ButtonGroup,
  Input,
  FormGroup,
} from "reactstrap";

const path = require("../../externals/path.json").path;

export default function Index() {
  const [inventoryList, setInventoryList] = useState([]);
  const [CityList, setCityList] = useState([]);
  const [cityId, setCityId] = useState();
  const [inventoryLocationId, setInventoryLocationId] = useState();
  const [inventoryLocationList, setInventoryLocationList] = useState([]);
  const [availableItemsInLocation, setAvailableItemsInLocationList] = useState(
    []
  );
  const [availablity, setAvailablity] = useState("available");

  //const [inventoryId, setInventoryID] = useState();

  const onBindInventoryData = () => {
    axios
      .get(path + "/inventory/list/" + availablity, {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setInventoryList(result.data.data);
        }
      });
  };

  const onBindInventoryDataByLocation = (locationId) => {
    axios
      .get(path + `/inventory/location/${availablity}/` + locationId, {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          if (availablity === "available") {
            let list = [];
            result.data.data.forEach((item) => {
              if (item.quantity > 0) list.push(item);
            });
            setInventoryList(list);
          } else setInventoryList(result.data.data);
        }
      });
  };

  const onBindInventoryLocation = (cityId) => {
    let pathName;
    if (cityId > 0) pathName = `/inventoryLocation/city/` + cityId;
    else pathName = "/inventoryLocation";

    axios
      .get(path + pathName, {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true)
          setInventoryLocationList(result.data.data);
        else setInventoryLocationList([]);
      });
  };
  const onBindCityHandler = () => {
    axios
      .get(path + "/cities", {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.data !== null) setCityList(result.data.data);
      });
  };

  const inventoryLocationItemsRender = () => {
    let items = [];
    inventoryLocationList.map((item) =>
      items.push(
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      )
    );
    return items;
  };

  const CityItemsRender = () => {
    let items = [];
    CityList.map((item) =>
      items.push(
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      )
    );
    return items;
  };

  useEffect(() => {
    onBindInventoryData();
    onBindCityHandler();
  }, []);

  useEffect(() => {
    if (inventoryLocationId && inventoryLocationId !== "0") {
      onBindInventoryDataByLocation(inventoryLocationId);
    } else {
      onBindInventoryData();
    }
  }, [availablity]);

  return (
    <>
      <GeneralHeader />
      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col>
                    <h2 className="mb-0">Stock List</h2>
                  </Col>
                  <Col>
                    <Input
                      className="form-control-alternative"
                      value={cityId}
                      id="cityId"
                      placeholder="cityId"
                      type="select"
                      onChange={(e) => {
                        setCityId(e.target.value);
                        onBindInventoryLocation(e.target.value);
                      }}
                    >
                      <option value={0}>---City---</option>
                      {CityItemsRender()}
                    </Input>
                  </Col>
                  <Col>
                    <Input
                      className="form-control-alternative"
                      value={inventoryLocationId}
                      id="inventoryLocationId"
                      placeholder="inventoryLocationId"
                      type="select"
                      onChange={(e) => {
                        setInventoryLocationId(e.target.value);
                        onBindInventoryDataByLocation(e.target.value);
                      }}
                    >
                      <option value={0}>---Inventory---</option>
                      {inventoryLocationItemsRender()}
                    </Input>
                  </Col>
                  <Col>
                    <Input
                      className="form-control-alternative"
                      value={availablity}
                      id="availablity"
                      placeholder="availablity"
                      type="select"
                      onChange={(e) => {
                        setAvailablity(e.target.value);
                      }}
                    >
                      <option value={0}>---Availablity---</option>
                      <option value={"available"}>Available</option>
                      <option value={"All"}>All</option>
                    </Input>
                  </Col>
                </Row>
              </CardHeader>
              <div style={{ height: "76vh", overflow: "scroll" }}>
                <Table bordered style={{ fontWeight: "bold", color: "black" }}>
                  <thead className="thead-light">
                    <tr>
                      <th
                        scope="col"
                        style={{
                          fontSize: 15,
                        }}
                        className="text-center"
                      >
                        #
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontSize: 15,
                        }}
                        className="text-center"
                      >
                        Location
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontSize: 15,
                        }}
                        className="text-center"
                      >
                        Item
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontSize: 15,
                        }}
                        className="text-center"
                      >
                        Quantity
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontSize: 15,
                        }}
                        className="text-center"
                      >
                        Expire Date
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontSize: 15,
                        }}
                        className="text-center"
                      >
                        Active
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {inventoryList.map(function (item, index) {
                      return (
                        <tr>
                          <th scope="row">{index + 1}</th>
                          <td
                            className="text-center"
                            style={{
                              fontSize: 15,
                            }}
                          >
                            {item.location}
                          </td>
                          <td
                            className="text-center"
                            style={{
                              fontSize: 15,
                            }}
                          >
                            {item.itemName}
                          </td>
                          <td
                            className="text-center"
                            style={{
                              fontSize: 15,
                            }}
                          >
                            {item.quantity.toFixed(2)}
                          </td>

                          <td
                            className="text-center"
                            style={{
                              fontSize: 15,
                            }}
                          >
                            <Moment format="YYYY/MM/DD">{item.expiry}</Moment>
                          </td>
                          <td
                            className="text-center"
                            style={{
                              fontSize: 15,
                            }}
                          >
                            {item.active === "Y" ? (
                              <Badge color="primary">YES</Badge>
                            ) : (
                              <Badge color="danger">No</Badge>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
              {/* <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem className="disabled">
                        <PaginationLink
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                          tabIndex="-1"
                        >
                          <i className="fas fa-angle-left" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem className="active">
                        <PaginationLink
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          1
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter> */}
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
