import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import { Route, Switch, Redirect } from "react-router-dom";
// import { useSelector } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import allActions from "actions/allActions";
import axios from "axios";
import { useEffect } from "react";
const path = require("./externals/path.json").path;

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    axios
      .get(path + "/rates", {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          let rate = {
            id: result.data.data.id,
            rate: result.data.data.rate,
          };
          dispatch(allActions.RatesAction.setRate(rate));
          dispatch(allActions.LanguageAction.Kurdish());
        }
      });
  });

  const isLoggedIn = useSelector((state) => state.isLogged);

  if (isLoggedIn.loggedIn) {
    return (
      <Switch>
        <Route path="/" render={(props) => <AdminLayout {...props} />} />
        {/* <Route path="/auth" render={(props) => <AuthLayout {...props} />} /> */}
        <Redirect from="/" to="/admin/index" />
      </Switch>
    );
  } else {
    return (
      <Switch>
        <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
        <Redirect from="*" to="/auth/login" />
      </Switch>
    );
  }
};
export default App;
