import React, { useState, useEffect, useRef } from "react";
import GeneralHeader from "components/Headers/GeneralHeader";

import axios from "axios";

import NumberFormat from "react-number-format";
import moment from "moment";
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  Input,
  FormGroup,
  Label,
  Button,
} from "reactstrap";
import ReactToPrint from "react-to-print";

import { useLocation, Link } from "react-router-dom";
import queryString from "query-string";
const path = require("../../externals/path.json").path;

export default function CustomerReport() {
  const { search } = useLocation();
  const { cid } = queryString.parse(search);
  let invoiceRef = useRef();

  const [CustomerDebitList, setCustomerDebitList] = useState([]);
  const [type, setType] = useState(0);
  const [TotalpriceDLR, setTotalpriceDLR] = useState();
  const [TotalpriceIQD, setTotalpriceIQD] = useState();
  const [Totalquantity, setTotalquantity] = useState();
  const [TotalPaid, setTotalPaid] = useState();
  function onBindTableData(status) {
    axios
      .get(path + `/customers/customerreport/${cid}/${status}`, {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setCustomerDebitList(result.data.data);
        }
      });
    axios
      .get(path + `/customers/customertotalreport/${cid}/${status}`, {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setTotalpriceDLR(result.data.data.priceDLR);
          setTotalpriceIQD(result.data.data.priceIQD);
          setTotalPaid(result.data.data.paid);
          setTotalquantity(result.data.data.quantity);
          // setCustomerDebitList(result.data.data);
        }
      });
  }
  useEffect(() => {
    onBindTableData(type);
  }, []);

  return (
    <>
      <GeneralHeader />

      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col xs="4">
                    <h2 className="mb-0">Customer Report</h2>
                  </Col>
                  <Col xs="3">
                    <Input
                      className="form-control-alternative"
                      value={type}
                      id="type"
                      placeholder="type"
                      type="select"
                      onChange={(e) => {
                        setType(e.target.value);
                        onBindTableData(e.target.value);
                      }}
                      style={{ fontSize: 16, color: "black", height: 45 }}
                    >
                      <option value={0}>---type---</option>
                      <option value={1}>Paid</option>
                      <option value={2}>Unpaid</option>
                    </Input>
                  </Col>
                  <Col className="text-right">
                    <ReactToPrint
                      trigger={() => <Button color="primary">print</Button>}
                      content={() => invoiceRef}
                    />
                  </Col>
                </Row>
              </CardHeader>{" "}
              <div ref={(el) => (invoiceRef = el)}>
                <Row>
                  <Col>
                    <FormGroup row>
                      <Label
                        for="exampleEmail"
                        style={{
                          fontSize: 14,
                          fontWeight: "bold",
                          marginTop: 10,
                        }}
                      >
                        Total Quantity
                      </Label>
                      <Col
                        style={{
                          marginTop: 10,
                        }}
                      >
                        {Totalquantity}
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup row>
                      <Label
                        for="exampleEmail"
                        style={{
                          fontSize: 14,
                          fontWeight: "bold",
                          marginTop: 10,
                        }}
                      >
                        Total Price (IQD)
                      </Label>
                      <Col
                        style={{
                          marginTop: 10,
                        }}
                      >
                        <NumberFormat
                          value={TotalpriceIQD ? TotalpriceIQD.toFixed(0) : 0}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"IQD "}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup row>
                      <Label
                        for="exampleEmail"
                        style={{
                          fontSize: 14,
                          fontWeight: "bold",
                          marginTop: 10,
                        }}
                      >
                        Total Price ($)
                      </Label>
                      <Col
                        style={{
                          marginTop: 10,
                        }}
                      >
                        <NumberFormat
                          value={TotalpriceDLR ? TotalpriceDLR.toFixed(0) : 0}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$ "}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup row>
                      <Label
                        for="exampleEmail"
                        style={{
                          fontSize: 14,
                          fontWeight: "bold",
                          marginTop: 10,
                        }}
                      >
                        Total Paid (IQD)
                      </Label>
                      <Col
                        style={{
                          marginTop: 10,
                        }}
                      >
                        <NumberFormat
                          value={TotalPaid ? TotalPaid.toFixed(0) : 0}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"IQD "}
                        />
                      </Col>
                    </FormGroup>
                  </Col>{" "}
                  <Col>
                    <FormGroup row>
                      <Label
                        for="exampleEmail"
                        style={{
                          fontSize: 14,
                          fontWeight: "bold",
                          marginTop: 10,
                        }}
                      >
                        current Debt (IQD)
                      </Label>
                      <Col
                        style={{
                          marginTop: 10,
                        }}
                      >
                        <NumberFormat
                          value={(TotalpriceIQD - TotalPaid).toFixed(0)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"IQD "}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>
                <br />
                <br />
                <div style={{ height: "76vh", overflow: "scroll" }}>
                  <Table
                    style={{ fontWeight: "bold", color: "black" }}
                    bordered
                  >
                    <thead className="thead-light text-center">
                      <tr>
                        <th
                          scope="col"
                          style={{
                            fontSize: 16,
                          }}
                        >
                          Invoice No
                        </th>
                        <th
                          scope="col"
                          style={{
                            fontSize: 16,
                          }}
                        >
                          Manual Invoice No
                        </th>
                        <th
                          scope="col"
                          style={{
                            fontSize: 16,
                          }}
                        >
                          Date
                        </th>
                        <th
                          scope="col"
                          style={{
                            fontSize: 16,
                          }}
                        >
                          Quantity
                        </th>
                        {/* <th
                        scope="col"
                        style={{
                          fontSize: 16,
                        }}
                      >
                        Dollar
                      </th>
                      <th
                        scope="col"
                        style={{
                          fontSize: 16,
                        }}
                      >
                        Discount
                      </th> */}
                        <th
                          scope="col"
                          style={{
                            fontSize: 16,
                          }}
                        >
                          Dinar
                        </th>
                        <th
                          scope="col"
                          style={{
                            fontSize: 16,
                          }}
                        >
                          Paid
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-center">
                      {CustomerDebitList.map(function (item, index) {
                        return (
                          <tr>
                            {" "}
                            <th
                              scope="row"
                              style={{
                                fontSize: 16,
                              }}
                            >
                              <Link
                                to={"/admin/sales?nid=" + item.invoiceNumber}
                              >
                                {item.invoiceNumber}
                              </Link>
                            </th>
                            <td
                              style={{
                                fontSize: 16,
                              }}
                            >
                              {item.manualInvoiceNumber}
                            </td>
                            <td
                              style={{
                                fontSize: 16,
                              }}
                            >
                              {moment(item.date)
                                .format("YYYY-MM-DD")
                                .toString()}
                            </td>
                            <td
                              style={{
                                fontSize: 16,
                              }}
                            >
                              {item.quantity}
                            </td>
                            {/* <td
                            style={{
                              fontSize: 16,
                            }}
                          >
                            <NumberFormat
                              value={item.priceDLR.toFixed(0)}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$ "}
                            />
                          </td>
                          <td
                            style={{
                              fontSize: 16,
                            }}
                          >
                            <NumberFormat
                              value={item.discount}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"% "}
                            />
                          </td> */}
                            <td
                              style={{
                                fontSize: 16,
                              }}
                            >
                              <NumberFormat
                                value={item.priceIQD.toFixed(0)}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"IQD "}
                              />
                            </td>
                            <td
                              style={{
                                fontSize: 16,
                              }}
                            >
                              <NumberFormat
                                value={item.paid.toFixed(0)}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"IQD "}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
