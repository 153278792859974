import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import NumberFormat from "react-number-format";
import Moment from "react-moment";

import {
  Button,
  Table,
  Row,
  Col,
  Input,
  FormGroup,
  Label,
  Badge,
} from "reactstrap";
import ReactToPrint from "react-to-print";

const path = require("../../../externals/path.json").path;

export default function ItemSummary() {
  const [reportData, setReportData] = useState([]);
  const [itemsList, setItemsList] = useState([]);

  const [itemId, setItemId] = useState();

  let invoiceRef = useRef();

  function onBindItemsData() {
    axios
      .get(path + "/items", {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setItemsList(result.data.data);
        }
      });
  }

  function ItemItemsRender() {
    let items = [];
    itemsList.map((item) =>
      items.push(
        <option key={item.id} value={item.id}>
          {item.nameKrd}
        </option>
      )
    );
    return items;
  }

  useEffect(() => {
    onBindtableData();
    onBindItemsData();
  }, []);

  useEffect(() => {
    onBindtableData();
  }, [itemId]);

  function onBindtableData() {
    axios
      .post(
        path + "/sellreports/getItemSummary",
        {
          itemId: itemId,
        },
        {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        }
      )
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setReportData(result.data.data);
        }
      });
  }

  return (
    <>
      <Row>
        <Col>
          <Input
            className="form-control-alternative"
            value={itemId}
            id="itemId"
            placeholder="itemId"
            type="select"
            onChange={(e) => {
              if (e.target.value !== "0") setItemId(e.target.value);
              else setItemId();
            }}
            style={{ fontSize: 16, color: "black", height: 45 }}
          >
            <option value={"0"}>---Item---</option>
            {ItemItemsRender()}
          </Input>
        </Col>
        <Col className="text-right">
          <ReactToPrint
            trigger={() => <Button color="primary">print</Button>}
            content={() => invoiceRef}
          />
        </Col>
      </Row>
      <div ref={(el) => (invoiceRef = el)}>
        <br />
        <br />
        <div>
          <Table
            className=" align-items-center table-flush"
            bordered
            style={{ fontWeight: "bold", color: "black" }}
            responsive
          >
            <thead className="thead-light">
              <tr>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  #
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  Item Name
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  Expiry
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  T.Purchase
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  T.Sell
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  T.Delivered
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  T.Paid
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  T.Sample Or Loss
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  Stock Remain
                </th>
              </tr>
            </thead>
            <tbody>
              {reportData.map(function (item, index) {
                return (
                  <tr>
                    <th
                      scope="row"
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      {index + 1}
                    </th>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      {item.nameKrd}
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      <Moment format="YYYY/MM/DD">{item.expiry}</Moment>
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      {item.totalPurchase ? item.totalPurchase.toFixed(2) : 0}
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      {item.totalQuantitySell
                        ? item.totalQuantitySell.toFixed(2)
                        : 0}
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      {item.totalQuantityDeliveredSell
                        ? item.totalQuantityDeliveredSell.toFixed(2)
                        : 0}
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      {item.totalQuantityPaidSell
                        ? item.totalQuantityPaidSell.toFixed(2)
                        : 0}
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      {item.totalQuantityFreeSampleOrLoss
                        ? item.totalQuantityFreeSampleOrLoss.toFixed(2)
                        : 0}
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      {item.totalQuantityStockRemain
                        ? item.totalQuantityStockRemain.toFixed(2)
                        : 0}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
}
