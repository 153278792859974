/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Index from "views/Index.js";
import Doctors from "views/Doctors/Index";
import Create from "views/Customers/Create";
import Purchase from "views/Purchase/Index";
import Inventory from "views/Inventory/Index";
import Items from "../views/Items/Index";
import Sell from "views/Sell/Index";
import Offers from "views/Offers/Index";
import Expenses from "../views/Expenses/Index";
import Activity from "../views/ActivitySection/Index";

import Hospitals from "views/Hospitals/Index";
import Login from "views/Admin/Users/Login";
import Visitation from "./../views/Doctors/Visitation";
import SampleOrLoss from "views/SampleOrLoss/Index";
import Users from "views/Admin/Users/Users";
import Debts from "views/Customers/Debts";
import CustomerReport from "views/Customers/CustomerReport";
import Distribution from "views/Inventory/Distribution";
import Cities from "views/Admin/Cities/Index";
import InventoryLocations from "views/Admin/Cities/InventoryLocations";
import ExpensesType from "views/Admin/ExpensesType/ExpensesType";
import MyAccounts from "views/Admin/Users/MyAccounts";
import PartnerAccount from "views/Admin/Users/PartnerAccount";
import Reports from "views/Admin/Reports/Index";
import ForCompany from "views/Forcompany/Index";
import Returning from "views/Sell/Returning";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "fas fa-home text-primary",
    component: Index,
    layout: "/admin",
  },
  {
    path: "/customer/create",
    name: "Customer",
    icon: "fas fa-user-tie text-primary",
    component: Create,
    layout: "/admin",
  },
  {
    path: "/Doctors",
    name: "Doctors",
    icon: "ni ni-planet text-blue",
    component: Doctors,
    layout: "/admin",
  },
  {
    path: "/Hospitals",
    name: "Hospitals",
    icon: "ni ni-planet text-blue",
    component: Hospitals,
    layout: "/admin",
  },
  {
    path: "/purchase",
    name: "Purchase",
    icon: "fas fa-store text-primary",
    component: Purchase,
    layout: "/admin",
  },
  {
    path: "/Inventory",
    name: "Stock",
    icon: "ni ni-tv-2 text-primary",
    component: Inventory,
    layout: "/admin",
  },
  {
    path: "/items",
    name: "Items",
    icon: "ni ni-planet text-blue",
    component: Items,
    layout: "/admin",
  },
  {
    path: "/sales",
    name: "Sales",
    icon: "fas fa-shopping-cart text-blue",
    component: Sell,
    layout: "/admin",
  },

  {
    path: "/offers",
    name: "Offers",
    icon: "ni ni-planet text-blue",
    component: Offers,
    layout: "/admin",
  },
  {
    path: "/expenses",
    name: "Expenses",
    icon: "ni ni-planet text-blue",
    component: Expenses,
    layout: "/admin",
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-planet text-blue",
    component: Login,
    layout: "/auth",
  },

  {
    path: "/visitation",
    name: "Visitation",
    icon: "ni ni-planet text-blue",
    component: Visitation,
    layout: "/admin",
  },
  {
    path: "/sampleorloss",
    name: "Sample Or Loss",
    icon: "ni ni-planet text-blue",
    component: SampleOrLoss,
    layout: "/admin",
  },
  {
    path: "/users",
    name: "Users",
    icon: "ni ni-planet text-blue",
    component: Users,
    layout: "/admin",
  },
  {
    path: "/debts",
    name: "Debts",
    icon: "ni ni-planet text-blue",
    component: Debts,
    layout: "/admin",
  },
  {
    path: "/customerreport",
    name: "Customer Report",
    icon: "ni ni-planet text-blue",
    component: CustomerReport,
    layout: "/admin",
  },
  {
    path: "/distribution",
    name: "distribution",
    icon: "ni ni-planet text-blue",
    component: Distribution,
    layout: "/admin",
  },
  {
    path: "/cities",
    name: "Cities",
    icon: "ni ni-planet text-blue",
    component: Cities,
    layout: "/admin",
  },
  {
    path: "/inventoryLocation",
    name: "Inventories",
    icon: "ni ni-planet text-blue",
    component: InventoryLocations,
    layout: "/admin",
  },
  {
    path: "/expensestype",
    name: "ExpensesType",
    icon: "ni ni-planet text-blue",
    component: ExpensesType,
    layout: "/admin",
  },
  {
    path: "/myaccount",
    name: "My Account",
    icon: "ni ni-planet text-blue",
    component: MyAccounts,
    layout: "/admin",
  },
  {
    path: "/partneraccount",
    name: "Partner Account",
    icon: "ni ni-planet text-blue",
    component: PartnerAccount,
    layout: "/admin",
  },
  {
    path: "/reports",
    name: "Reports",
    icon: "ni ni-planet text-blue",
    component: Reports,
    layout: "/admin",
  },
  {
    path: "/Activity",
    name: "Activities",
    icon: "ni ni-planet text-blue",
    component: Activity,
    layout: "/admin",
  },
  {
    path: "/forcompany",
    name: "Money back to Company",
    icon: "ni ni-planet text-blue",
    component: ForCompany,
    layout: "/admin",
  },
  {
    path: "/returning",
    name: "Returning Or Change",
    icon: "ni ni-planet text-blue",
    component: Returning,
    layout: "/admin",
  },
];
export default routes;
