import React, { Component } from "react";
import Swal from "sweetalert2";
// import { connect } from "react-redux";
import GeneralHeader from "components/Headers/GeneralHeader.js";
import axios from "axios";
import * as XLSX from "xlsx";

import {
  Card,
  CardHeader,
  CardBody,
  Form,
  // CardFooter,
  Table,
  Container,
  Row,
  Col,
  // DropdownMenu,
  // DropdownItem,
  // UncontrolledDropdown,
  // DropdownToggle,
  // Pagination,
  // PaginationItem,
  // PaginationLink,
  Button,
  // ButtonGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
} from "reactstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const path = require("../../externals/path.json").path;

class Create extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUpdate: false,
      showAddModal: false,
      showSearchModal: false,
      customId: null,
      name: null,
      phone: null,
      type: null,
      city: null,
      zone: null,
      neighbour: null,
      address: null,
      discountType: null,
      discount: null,
      offer: null,
      offerType: null,
      debitLimit: null,
      status: null,
      username: this.props.user.id,
      cityList: [],
      zoneList: [],
      neighbourList: [],
      customerList: [],
      searchName: null,
      searchCity: null,
      searchZone: null,
      searchNeighbour: null,
      searchStatus: "Active",
    };
    this.onBindDataHandler();

    axios
      .get(path + "/cities", {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.data !== null)
          this.setState({
            cityList: result.data.data,
          });
      });
  }

  CustomerReportHandler(cid) {
    this.props.history.push("/admin/customerreport?cid=" + cid);
  }

  onExportDataToExcel() {
    let list = [];
    this.state.customerList.forEach((item, index) => {
      list.push({
        "#": index + 1,
        name: item.name,
        Type: item.type,
        "Phone Number": item.phone,
        City: item.city,
        Zone: item.zone,
        Neighbour: item.neighbour,
        address: item.address,
      });
    });

    var wb = XLSX.utils.book_new();
    var ws = XLSX.utils.json_to_sheet(list);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "Customer List.xlsx");
  }

  onBindSearchData(e) {
    const { id, value } = e.target;
    if (
      value !== "---City---" ||
      value !== "---Zone---" ||
      value !== "---Neighbour---"
    )
      this.setState({
        [id]: value,
      });
    else {
      this.setState({
        [id]: null,
      });
    }

    let searchInputList;
    if (id === "searchName")
      searchInputList = {
        name: e.target.value,
        cityId: this.state.searchCity,
        zoneId: this.state.searchZone,
        neighbourId: this.state.searchNeighbour,
        searchStatus: this.state.searchStatus,
      };

    if (id === "searchStatus")
      searchInputList = {
        name: this.state.searchName,
        cityId: this.state.searchCity,
        zoneId: this.state.searchZone,
        neighbourId: this.state.searchNeighbour,
        searchStatus: e.target.value,
      };
    if (id === "searchCity") {
      let val = value;
      if (value === "---City---") val = null;
      searchInputList = {
        name: this.state.searchName,
        cityId: val,
        zoneId: null,
        neighbourId: null,
        searchStatus: this.state.searchStatus,
      };
      this.onBindZoneDataHandler(val);
    }
    if (id === "searchZone") {
      let val = value;
      if (value === "---Zone---") val = null;
      searchInputList = {
        name: this.state.searchName,
        cityId: null,
        zoneId: val,
        neighbourId: null,
        searchStatus: this.state.searchStatus,
      };
      this.onBindNeighboursDataHandler(val);
    }
    if (id === "searchNeighbour") {
      let val = value;
      if (value === "---Neighbour---") val = null;
      searchInputList = {
        name: this.state.searchName,
        cityId: null,
        zoneId: null,
        neighbourId: val,
        searchStatus: this.state.searchStatus,
      };
    }
    axios
      .post(path + "/customers/search", searchInputList, {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          this.setState({
            customerList: result.data.data,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "warning",
          text: `there is some error while searching data please contact administrator`,
          icon: "warning",
        });
      });
  }

  onBindZoneDataHandler(cityId) {
    axios
      .get(path + "/zone/" + Number(cityId), {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.data !== null)
          this.setState({
            zoneList: result.data.data,
          });
        else
          this.setState({
            zone: null,
            neighbour: null,
            zoneList: [],
            neighbourList: [],
          });
      });
  }
  onBindNeighboursDataHandler(zoneId) {
    axios
      .get(path + "/neighbours/" + Number(zoneId), {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.data !== null)
          this.setState({
            neighbourList: result.data.data,
          });
        else
          this.setState({
            neighbour: null,
            neighbourList: [],
          });
      });
  }
  onBindDataHandler() {
    axios
      .get(path + "/customers", {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.data !== null)
          this.setState({
            customerList: result.data.data,
          });
      });
  }

  toggle = () => {
    this.setState({
      showAddModal: !this.state.showAddModal,
    });
  };
  onAddNewCustomerHandler = () => {
    this.setState({
      isUpdate: false,
      showAddModal: true,
      name: null,
      phone: null,
      type: null,
      city: null,
      zone: null,
      neighbour: null,
      address: null,
      discountType: null,
      discount: null,
      offer: null,
      offerType: null,
      username: this.props.user.id,
      debitLimit: null,
      status: 1,
    });
  };

  cityItemsRender() {
    let items = [];
    this.state.cityList.map((item) =>
      items.push(
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      )
    );
    return items;
  }
  zoneItemsRender() {
    let items = [];
    this.state.zoneList.map((item) =>
      items.push(
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      )
    );
    return items;
  }
  neighbourItemsRender() {
    let items = [];
    this.state.neighbourList.map((item) =>
      items.push(
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      )
    );
    return items;
  }

  onChangeHandler = (event) => {
    if (event.target.id === "offerType") {
      if (event.target.value === "cash")
        this.setState({
          offer: null,
        });
      else
        this.setState({
          discount: null,
        });
    }
    const key = event.target.id;
    const value = event.target.value;
    this.setState({
      [key]: value,
    });
  };

  onChangeCityHandler = (event) => {
    const key = event.target.id;
    const value = event.target.value;
    this.setState({
      [key]: value,
    });

    this.onBindZoneDataHandler(event.target.value);
  };

  onChangeZoneHandler = (event) => {
    const key = event.target.id;
    const value = event.target.value;
    this.setState({
      [key]: value,
    });
    this.onBindNeighboursDataHandler(event.target.value);
  };

  saveDataHandler = (e) => {
    e.preventDefault();

    const customer = {
      id: this.state.customId,
      name: this.state.name,
      phone: this.state.phone,
      type: this.state.type,
      city: this.state.city,
      zone: this.state.zone,
      neighbour: this.state.neighbour,
      address: this.state.address,
      discountType: this.state.discountType,
      discount: this.state.discount,
      offer: this.state.offer,
      offerType: this.state.offerType,
      debitLimit: this.state.debitLimit,
      status: this.state.status,
      username: this.state.username,
    };

    if (this.state.isUpdate === true) {
      axios
        .put(path + "/customers/" + this.state.customId, customer, {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        })
        .then((result) => {
          if (result.data.success === true) {
            document.getElementById("ff").reset();
            this.onBindDataHandler();

            this.setState({
              showAddModal: false,
            });

            Swal.fire({
              title: "THANKS",
              text: "the customer updated",
              icon: "success",
            });
          }
          // if (result.data.token !== null)
          //   this.props.user.user.token = result.data.token;
        })
        .catch((error) => {
          Swal.fire({
            title: "warning",
            text: "customer did not updated please contact administrator",
            icon: "warning",
          });
        });
    } else {
      axios
        .post(path + "/customers", customer, {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        })
        .then((result) => {
          if (result.data.success === true) {
            this.setState({
              showAddModal: false,
            });
            document.getElementById("ff").reset();
            this.onBindDataHandler();
            Swal.fire({
              title: "THANKS",
              text: "new customer Added",
              icon: "success",
            });
          }
          // if (result.data.token !== null)
          //   this.props.user.user.token = result.data.token;
        })
        .catch((error) => {
          Swal.fire({
            title: "warning",
            text: "customer did not added please contact administrator",
            icon: "warning",
          });
        });
    }
  };
  EditDataHandler = async (item) => {
    let city = this.state.cityList.find((o) => o.name === item.city);
    let zone;
    let neighbour;
    if (city) {
      await axios
        .get(path + "/zone/" + Number(city.id), {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        })
        .then((result) => {
          // if (result.data.token !== null)
          //   this.props.user.user.token = result.data.token;
          if (result.data.data !== null) {
            this.setState({
              zoneList: result.data.data,
            });
            zone = this.state.zoneList.find((o) => o.name === item.zone);
          } else
            this.setState({
              zone: null,
              neighbour: null,
              zoneList: [],
              neighbourList: [],
            });
        });

      if (zone)
        await axios
          .get(path + "/neighbours/" + Number(zone.id), {
            // headers: {
            //   "auth-token": this.props.user.user.token,
            // },
          })
          .then((result) => {
            // if (result.data.token !== null)
            //   this.props.user.user.token = result.data.token;
            if (result.data.data !== null) {
              this.setState({
                neighbourList: result.data.data,
              });
              neighbour = this.state.neighbourList.find(
                (o) => o.name === item.neighbour
              );
            } else
              this.setState({
                neighbour: null,
                neighbourList: [],
              });
          });
    }
    // let neighbour = this.state.neighbourList.find(
    //   (o) => o.name === item.neighbour
    // );

    this.setState({
      isUpdate: true,
      customId: item.id,
      showAddModal: true,
      name: item.name,
      phone: item.phone,
      type: item.type,
      city: city ? city.id : null,
      zone: zone ? zone.id : null,
      neighbour: neighbour ? neighbour.id : null,
      address: item.address,
      discountType: item.discountType,
      discount: item.discount,
      offer: item.offer,
      offerType: item.offerType,
      debitLimit: item.debitLimit,
      status: item.status,
      username: item.username,
    });
  };

  onDeleteCustomerHandler = (id, customerName) => {
    Swal.fire({
      title: 'Do you want to delete customer  "' + customerName + '" ?',
      showCancelButton: true,
      confirmButtonText: "Save",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.value) {
        axios
          .delete(path + "/customers/" + id, {
            // headers: {
            //   "auth-token": this.props.user.user.token,
            // },
          })
          .then((result) => {
            if (result.data.statusCode === 200) {
              this.onBindDataHandler();
              Swal.fire("customer Deleted!", "", "success");
            }
            // if (result.data.token !== null)
            //   this.props.user.user.token = result.data.token;
          })
          .catch((error) => {
            Swal.fire({
              title: "warning",
              text: "the customer did not deleted please contact administrator",
              icon: "warning",
            });
          });
      }
    });
  };
  onChangeSearchCityHandler = (e) => {
    if (e.target.value !== "---City---") {
      this.setState({
        searchCity: e.target.value,
      });
      this.onBindZoneDataHandler(e.target.value);
      this.onBindSearchData();
    } else {
      this.setState({
        searchCity: e.target.value,
        searchZone: null,
        zoneList: [],
      });
      this.onBindSearchData();
    }
  };
  onChangeSearchZoneHandler = (e) => {
    if (e.target.value !== "---Zone---") {
      this.setState({
        searchZone: e.target.value,
      });

      this.onBindSearchData();
    } else {
      this.setState({
        searchZone: e.target.value,
      });
      this.onBindSearchData();
    }
  };

  onDeleteCustomerHandler = (id, customerName) => {
    Swal.fire({
      title: 'Do you want to delete customer  "' + customerName + '" ?',
      showCancelButton: true,
      confirmButtonText: "DELETE",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.value) {
        axios
          .delete(path + "/customers/" + id, {
            // headers: {
            //   "auth-token": this.props.user.user.token,
            // },
          })
          .then((result) => {
            if (result.data.statusCode === 200) {
              this.onBindDataHandler();
              Swal.fire("customer Deleted!", "", "success");
            }
            // if (result.data.token !== null)
            //   this.props.user.user.token = result.data.token;
          })
          .catch((error) => {
            Swal.fire({
              title: "warning",
              text: "the customer did not deleted please contact administrator",
              icon: "warning",
            });
          });
      }
    });
  };
  onActiveCustomerHandler = (id, customerName) => {
    Swal.fire({
      title: 'Do you want to Active customer  "' + customerName + '" ?',
      showCancelButton: true,
      confirmButtonText: "Active",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.value) {
        axios
          .put(path + "/activecustomers/" + id, {
            // headers: {
            //   "auth-token": this.props.user.user.token,
            // },
          })
          .then((result) => {
            if (result.data.statusCode === 200) {
              this.onBindDataHandler();
              Swal.fire("customer Actived!", "", "success");
            }
            // if (result.data.token !== null)
            //   this.props.user.user.token = result.data.token;
          })
          .catch((error) => {
            Swal.fire({
              title: "warning",
              text: "the customer did not deleted please contact administrator",
              icon: "warning",
            });
          });
      }
    });
  };

  render() {
    let _this = this;
    let customerList = this.state.customerList;
    return (
      <>
        <GeneralHeader />
        <Container className="mt--7" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <Col>
                      <Input
                        className="form-control-alternative"
                        value={this.state.searchName}
                        id="searchName"
                        placeholder="Customer Name Or Phone No"
                        type="text"
                        onChange={(e) => {
                          this.onBindSearchData(e);
                        }}
                      />
                    </Col>
                    <Col>
                      <Input
                        className="form-control-alternative"
                        value={this.state.searchStatus}
                        id="searchStatus"
                        placeholder="searchStatus"
                        type="select"
                        onChange={(e) => this.onBindSearchData(e)}
                      >
                        <option value="Active">Active</option>
                        <option value="Archive">Archive</option>
                      </Input>
                    </Col>

                    <Col className="text-right" xs="2">
                      <Button
                        color="success"
                        onClick={(e) => this.onExportDataToExcel()}
                        size="md"
                      >
                        Excel
                      </Button>
                      <Button
                        color="primary"
                        onClick={(e) => this.onAddNewCustomerHandler()}
                        size="md"
                      >
                        New
                      </Button>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <Input
                        className="form-control-alternative"
                        value={this.state.searchCity}
                        id="searchCity"
                        placeholder="searchCity"
                        type="select"
                        onChange={(e) => this.onBindSearchData(e)}
                      >
                        <option value="">---City---</option>
                        {this.cityItemsRender()}
                      </Input>
                    </Col>
                    <Col>
                      <Input
                        className="form-control-alternative"
                        value={this.state.searchZone}
                        id="searchZone"
                        placeholder="searchZone"
                        type="select"
                        onChange={(e) => this.onBindSearchData(e)}
                      >
                        <option value="">---Zone---</option>
                        {this.zoneItemsRender()}
                      </Input>
                    </Col>
                    <Col>
                      <Input
                        className="form-control-alternative"
                        value={this.state.searchNeighbour}
                        id="searchNeighbour"
                        placeholder="searchNeighbour"
                        type="select"
                        onChange={(e) => this.onBindSearchData(e)}
                      >
                        <option value="">---Neighbour---</option>
                        {this.neighbourItemsRender()}
                      </Input>
                    </Col>
                  </Row>
                </CardHeader>
                <div style={{ height: "76vh", overflow: "scroll" }}>
                  <Table
                    bordered
                    style={{ fontWeight: "bold", color: "black" }}
                  >
                    <thead className="thead-light">
                      <tr className="text-center">
                        <th
                          scope="col"
                          style={{
                            fontSize: 15,
                          }}
                        >
                          #
                        </th>
                        <th
                          scope="col"
                          style={{
                            fontSize: 15,
                          }}
                        >
                          Name
                        </th>
                        {/* <th scope="col" style={{
                              fontSize: 15,
                            }}>Type</th> */}
                        <th
                          scope="col"
                          style={{
                            fontSize: 15,
                          }}
                        >
                          Phone
                        </th>
                        <th
                          scope="col"
                          style={{
                            fontSize: 15,
                          }}
                        >
                          City
                        </th>
                        <th
                          scope="col"
                          style={{
                            fontSize: 15,
                          }}
                        >
                          Zone
                        </th>
                        <th
                          scope="col"
                          style={{
                            fontSize: 15,
                          }}
                        >
                          Neighbour
                        </th>
                        <th
                          scope="col"
                          style={{
                            fontSize: 15,
                          }}
                        >
                          Address
                        </th>
                        <th
                          scope="col"
                          style={{
                            fontSize: 15,
                          }}
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {customerList.map(function (item, index) {
                        return (
                          <tr className="text-center">
                            <th
                              scope="row"
                              style={{
                                fontSize: 15,
                              }}
                            >
                              {item.id}
                            </th>
                            <td
                              style={{
                                fontSize: 15,
                              }}
                            >
                              {" "}
                              <Link to={"/admin/sales?cid=" + item.id}>
                                {item.name}
                              </Link>
                            </td>
                            {/* <td  style={{
                            fontSize: 15,
                          }}>{item.type}</td> */}
                            <td
                              style={{
                                fontSize: 15,
                              }}
                            >
                              {item.phone}
                            </td>
                            <td
                              style={{
                                fontSize: 15,
                              }}
                            >
                              {item.city}
                            </td>
                            <td
                              style={{
                                fontSize: 15,
                              }}
                            >
                              {item.zone}
                            </td>
                            <td
                              style={{
                                fontSize: 15,
                              }}
                            >
                              {item.neighbour}
                            </td>
                            <td
                              style={{
                                fontSize: 15,
                              }}
                            >
                              {item.address}
                            </td>
                            <td className="text-right">
                              <div>
                                {/* <Button
                                  size="sm"
                                  color="info"
                                  onClick={() =>
                                    _this.CustomerReportHandler(item.id)
                                  }
                                >
                                  Report
                                </Button> */}
                                {_this.props.user.role === "admin" ||
                                _this.props.user.role === "accountant" ? (
                                  <>
                                    {item.status === 0 ? (
                                      <>
                                        <Button
                                          size="sm"
                                          color="success"
                                          onClick={() =>
                                            _this.onActiveCustomerHandler(
                                              item.id,
                                              item.name
                                            )
                                          }
                                        >
                                          Active
                                        </Button>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    <Button
                                      size="sm"
                                      color="warning"
                                      onClick={() =>
                                        _this.EditDataHandler(item)
                                      }
                                    >
                                      Edit
                                    </Button>{" "}
                                    <Button
                                      size="sm"
                                      color="danger"
                                      onClick={() =>
                                        _this.onDeleteCustomerHandler(
                                          item.id,
                                          item.name
                                        )
                                      }
                                    >
                                      Delete
                                    </Button>{" "}
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
                {/* <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem className="disabled">
                        <PaginationLink
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                          tabIndex="-1"
                        >
                          <i className="fas fa-angle-left" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem className="active">
                        <PaginationLink
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          1
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter> */}
              </Card>
            </Col>
          </Row>
        </Container>
        <Modal
          isOpen={this.state.showAddModal}
          toggle={this.toggle}
          className="modal-lg"
          style={{ maxWidth: "1500px", width: "100%" }}
        >
          <ModalHeader toggle={this.toggle}>New Customer</ModalHeader>
          <ModalBody>
            <Col className="order-xl-1">
              <Card className="shadow" style={{ backgroundColor: "#F0F8FF" }}>
                <CardBody>
                  <Form autoComplete="off" id="ff">
                    <h6 className="heading-small text-muted mb-2">
                      Customer information
                    </h6>
                    <div className="pl-lg-2">
                      <Row>
                        <Col lg="8">
                          <FormGroup>
                            <Input
                              className="form-control-alternative"
                              value={this.state.name}
                              id="name"
                              placeholder="Customer Name"
                              type="text"
                              onChange={this.onChangeHandler}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <Input
                              className="form-control-alternative"
                              value={this.state.phone}
                              id="phone"
                              placeholder="Phone Number"
                              type="number"
                              onChange={this.onChangeHandler}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <Input
                              className="form-control-alternative"
                              value={this.state.debitLimit}
                              id="debitLimit"
                              placeholder="debitLimit"
                              type="text"
                              onChange={this.onChangeHandler}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <Input
                              className="form-control-alternative"
                              value={this.state.type}
                              id="type"
                              placeholder="type"
                              type="select"
                              onChange={this.onChangeHandler}
                            >
                              <option>---Type---</option>
                              <option>Market</option>
                              <option>Pharmacy</option>
                              <option>Noringa</option>
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <Input
                              className="form-control-alternative"
                              value={this.state.city}
                              id="city"
                              placeholder="city"
                              type="select"
                              onChange={this.onChangeCityHandler}
                            >
                              <option>---City---</option>
                              {this.cityItemsRender()}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <Input
                              className="form-control-alternative"
                              value={this.state.zone}
                              id="zone"
                              placeholder="zone"
                              type="select"
                              onChange={this.onChangeZoneHandler}
                            >
                              <option>---Zone---</option>
                              {this.zoneItemsRender()}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <Input
                              className="form-control-alternative"
                              value={this.state.neighbour}
                              id="neighbour"
                              placeholder="neighbour"
                              type="select"
                              onChange={this.onChangeHandler}
                            >
                              <option>---neighbour---</option>
                              {this.neighbourItemsRender()}
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <Input
                              className="form-control-alternative"
                              value={this.state.address}
                              id="address"
                              placeholder="address"
                              type="text"
                              onChange={this.onChangeHandler}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <Input
                              className="form-control-alternative"
                              value={this.state.discountType}
                              id="discountType"
                              placeholder="discountType"
                              type="select"
                              onChange={this.onChangeHandler}
                            >
                              <option value={0}>--- discountType ---</option>
                              <option value={"Cash"}>Cash</option>
                              <option value={"Box"}>Box</option>
                              <option value={"Carton"}>Carton</option>
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <Input
                              className="form-control-alternative"
                              value={this.state.discount}
                              id="discount"
                              placeholder="discount"
                              type="text"
                              onChange={this.onChangeHandler}
                            />
                          </FormGroup>
                        </Col>
                        {/* ) : (
                          ""
                        )} */}
                        {/* {this.state.offerType &&
                        this.state.offerType === "item" ? (
                          <Col lg="4">
                            <FormGroup>
                              <Input
                                className="form-control-alternative"
                                value={this.state.offer}
                                id="offer"
                                placeholder="offer"
                                type="text"
                                onChange={this.onChangeHandler}
                              />
                            </FormGroup>
                          </Col>
                        ) : (
                          ""
                        )} */}
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={(e) => this.saveDataHandler(e)}>
              Save
            </Button>{" "}
            <Button color="secondary" onClick={this.toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

const userState = (state) => ({
  user: state.isLogged.user,
});

export default connect(userState, null)(Create);
