import React, { useState, useEffect } from "react";
import GeneralHeader from "components/Headers/GeneralHeader";
import Select from "react-select";
import axios from "axios";
import Swal from "sweetalert2";
import NumberFormat from "react-number-format";
import moment from "moment";
import {
  Button,
  Card,
  CardHeader,
  CardFooter,
  CardBody,
  Form,
  Table,
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  Badge,
  Label,
} from "reactstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
const path = require("../../externals/path.json").path;

export default function Debts() {
  let history = useHistory();
  const user = useSelector((state) => state.isLogged.user);
  const rate = useSelector((state) => state.rateData.rate);
  const [CustomerDebitList, setCustomerDebitList] = useState([]);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [customerDebitIvoices, setCustomerDebitIvoices] = useState([]);
  const [customerOptionIndex, setCustomerOptionIndex] = useState();
  const [customerShowIndex, setCustomerShowIndex] = useState();

  const [customerId, setCustomerId] = useState();

  function onBindTableData(id) {
    if (!id) id = "undefined";
    axios
      .post(
        path + "/customers/debtlist",
        { id: id, role: user.role, zones: user.zones, userId: user.id },
        {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        }
      )
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setCustomerDebitList(result.data.data);
          let options = [{ value: -1, label: "----Customer List ----" }];

          result.data.data.forEach((item, index) => {
            options.push({ value: index, label: item.name });
          });
          setCustomerOptions(options);
        }
      });
  }
  useEffect(() => {
    onBindTableData();
  }, []);

  function onCustomerChangeHandler(e) {
    if (e.value !== -1) {
      setCustomerOptionIndex({
        value: e.value,
        label: CustomerDebitList[e.value].name,
      });

      setCustomerId(CustomerDebitList[e.value].id);
      // onBindTableData(CustomerDebitList[e.value].id);
      setCustomerShowIndex(e.value);
    } else {
      onBindTableData();
      setCustomerOptionIndex();
    }
  }

  function GetCustomerDebitIvoices(CustomerName, customerId) {
    Swal.fire({
      title: 'Do you want to Total Pay to Customer  "' + CustomerName + '" ?',
      input: "number",
      // inputValue: paidInvoiceNumber,
      inputLabel: "Total IQD to Pay",
      showCancelButton: true,
      confirmButtonText: "Paid",
      showLoaderOnConfirm: true,
      inputValidator: (value) => {
        if (!value) {
          return "Please enter Total IQD to Pay!";
        } else {
          axios
            .get(path + "/sell/debit/" + customerId, {
              // headers: {
              //   "auth-token": this.props.user.user.token,
              // },
            })
            .then((result) => {
              if (result.data.success === true) {
                // onBindSellData();
                setCustomerDebitIvoices(result.data.data);
                onPaidSellItems(result.data.data, value);
              }
            });
        }
      },
    });
  }

  function onPaidSellItems(invoiceData, totalPay) {
    Swal.fire({
      title: "Please Enter Paid Invoice Number",
      input: "number",
      // inputValue: paidInvoiceNumber,
      inputLabel: "Paid Invoice Number",
      showCancelButton: true,
      confirmButtonText: "Paid",
      showLoaderOnConfirm: true,
      inputValidator: (value) => {
        if (!value) {
          return "Please enter paid invoice Number!";
        } else {
          let status;
          let paidInvoiceNO;
          let paidInvoiceDate;
          let paidByUser;
          let tpaid = totalPay;

          if (user.role === "accountant" || user.role === "admin") status = "2";
          else status = "1";
          totalPay = Number(totalPay);
          let i = 0;

          invoiceData.forEach((item) => {
            let payOnInvoie = 0;

            if (totalPay >= item.totalIQD - item.paid) {
              payOnInvoie = item.totalIQD - item.paid;
              totalPay = totalPay - payOnInvoie;
              //dway ledarkrdn la koy drawaka yeksany akainawa ba koy waslaka bo away rekbetawa
              payOnInvoie = item.totalIQD;
              paidByUser = user.name;
              paidInvoiceNO = value;
              paidInvoiceDate = moment(new Date())
                .format("YYYY-MM-DD")
                .toString();
            } else {
              if (totalPay > 0) {
                payOnInvoie = totalPay + item.paid;
                paidByUser = "";
                paidInvoiceNO = null;
                // paidInvoiceDate = "";
                totalPay = 0;
                tpaid = 0;
                status = "0";
              }
            }
            let paidData = {
              status: payOnInvoie / item.numberOfRecord !== 0 ? status : "0",
              invoiceNumber: item.invoiceNumber,
              paidInvoiceNumber:
                payOnInvoie / item.numberOfRecord !== 0 ? paidInvoiceNO : null,
              paidBy: payOnInvoie / item.numberOfRecord !== 0 ? paidByUser : "",
              paidDate: paidInvoiceDate,
              totalPaid: payOnInvoie / item.numberOfRecord !== 0 ? tpaid : 0,
              paid: payOnInvoie / item.numberOfRecord,
            };

            console.log(paidData);

            axios
              .put(path + "/sell/totalpaid", paidData, {
                // headers: {
                //   "auth-token": this.props.user.user.token,
                // },
              })
              .then((result) => {
                if (result.data.success === true) {
                  Swal.fire({
                    title: "THANKS",
                    text: "the Paid done",
                    icon: "success",
                  });
                }
              });
            i++;
          });
          onBindTableData();
        }
      },
    });
  }
  return (
    <>
      <GeneralHeader />

      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col xs="4">
                    <h4 className="mb-0">Debt List</h4>
                  </Col>
                  <Col xs="8">
                    <FormGroup row>
                      <Label
                        for="exampleEmail"
                        style={{
                          fontSize: 13,
                          fontWeight: "bold",
                          marginTop: 10,
                        }}
                      >
                        Customer
                      </Label>
                      <Col
                        style={{
                          marginTop: 10,
                        }}
                      >
                        <Select
                          value={customerOptionIndex}
                          onChange={(e) => onCustomerChangeHandler(e)}
                          options={customerOptions}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>
              </CardHeader>
              <div>
                <Table
                  className=" align-items-center table-flush"
                  bordered
                  style={{ fontWeight: "bold", color: "black" }}
                  responsive
                >
                  <thead className="thead-light">
                    <tr>
                      <th
                        scope="col"
                        style={{
                          color: "black",
                          fontSize: 15,
                          textAlign: "center",
                        }}
                      ></th>
                      <th
                        scope="col"
                        style={{
                          color: "black",
                          fontSize: 15,
                          textAlign: "center",
                        }}
                      >
                        Result
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        style={{
                          color: "black",
                          fontSize: 13,
                          textAlign: "left",
                        }}
                      >
                        Customer Name
                      </td>
                      <td
                        style={{
                          color: "black",
                          fontSize: 15,
                          textAlign: "center",
                        }}
                      >
                        {customerShowIndex || customerShowIndex === 0
                          ? CustomerDebitList[customerShowIndex].name
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          color: "black",
                          fontSize: 13,
                          textAlign: "left",
                        }}
                      >
                        Number of Invoices
                      </td>
                      <td
                        style={{
                          color: "black",
                          fontSize: 15,
                          textAlign: "center",
                        }}
                      >
                        {customerShowIndex || customerShowIndex === 0
                          ? CustomerDebitList[customerShowIndex].numberOfIvoices
                          : ""}
                      </td>
                    </tr>{" "}
                    <tr>
                      <td
                        style={{
                          color: "black",
                          fontSize: 13,
                          textAlign: "left",
                        }}
                      >
                        Total Invoices
                      </td>
                      <td
                        style={{
                          color: "black",
                          fontSize: 15,
                          textAlign: "center",
                        }}
                      >
                        <NumberFormat
                          value={
                            customerShowIndex || customerShowIndex === 0
                              ? CustomerDebitList[
                                  customerShowIndex
                                ].totalIvoices.toFixed(0)
                              : ""
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"IQD "}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          color: "black",
                          fontSize: 13,
                          textAlign: "left",
                        }}
                      >
                        Total quantity
                      </td>
                      <td
                        style={{
                          color: "black",
                          fontSize: 15,
                          textAlign: "center",
                        }}
                      >
                        {customerShowIndex || customerShowIndex === 0
                          ? CustomerDebitList[customerShowIndex].quantity
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          color: "black",
                          fontSize: 13,
                          textAlign: "left",
                        }}
                      >
                        Total Paid on Invoice
                      </td>
                      <td
                        style={{
                          color: "black",
                          fontSize: 15,
                          textAlign: "center",
                        }}
                      >
                        <NumberFormat
                          value={
                            customerShowIndex || customerShowIndex === 0
                              ? CustomerDebitList[
                                  customerShowIndex
                                ].totalPaid.toFixed(0)
                              : ""
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"IQD "}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          color: "black",
                          fontSize: 13,
                          textAlign: "left",
                        }}
                      >
                        Current Debt{" "}
                      </td>
                      <td
                        style={{
                          color: "black",
                          fontSize: 15,
                          textAlign: "center",
                        }}
                      >
                        <NumberFormat
                          value={
                            customerShowIndex || customerShowIndex === 0
                              ? CustomerDebitList[
                                  customerShowIndex
                                ].totalDebt.toFixed(0)
                              : ""
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"IQD "}
                        />
                      </td>
                    </tr>
                    <tr colSpan="2">
                      <td
                        style={{
                          color: "black",
                          fontSize: 13,
                          textAlign: "center",
                        }}
                      >
                        <Button
                          disabled={
                            customerShowIndex || customerShowIndex === 0
                              ? false
                              : true
                          }
                          size="lg"
                          color="success"
                          onClick={(e) =>
                            GetCustomerDebitIvoices(
                              CustomerDebitList[customerShowIndex].name,
                              CustomerDebitList[customerShowIndex].id
                            )
                          }
                        >
                          Pay Total
                        </Button>{" "}
                        <Button
                          disabled={
                            customerShowIndex || customerShowIndex === 0
                              ? false
                              : true
                          }
                          size="lg"
                          color="info"
                          onClick={(e) => {
                            e.preventDefault();
                            history.push(
                              "/admin/customerreport?cid=" +
                                CustomerDebitList[customerShowIndex].id
                            );
                          }}
                        >
                          Report
                        </Button>
                        <Button
                          disabled={
                            customerShowIndex || customerShowIndex === 0
                              ? false
                              : true
                          }
                          size="lg"
                          color="primary"
                          onClick={(e) => {
                            e.preventDefault();
                            history.push(
                              "/admin/sales?cid=" +
                                CustomerDebitList[customerShowIndex].id
                            );
                          }}
                        >
                          Invoices
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
