import AuthAction from "./AuthAction";
import RatesAction from "./RatesAction";
import LanguageAction from "./LanguageAction";
const allActions = {
  AuthAction,
  RatesAction,
  LanguageAction,
};

export default allActions;
