/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { Link } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";

import { useSelector, useDispatch } from "react-redux";
import allActions from "actions/allActions";
const AdminNavbar = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.isLogged.user);
  const rate = useSelector((state) => state.rateData.rate);
  const language = useSelector((state) => state.Language.language);

  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <Link
            className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            to="/"
          >
            {props.brandText}
          </Link>
          <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto"></Form>

          <Media className="align-items-center">
            <Media className="ml-2 d-none d-lg-block">
              <span
                className="mb-0 text-sm font-weight-bold primary"
                style={{ color: "white" }}
              >
                Today Dolar Rate : {rate ? rate.rate : ""} IQD
              </span>
            </Media>
          </Media>
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  {language === "Kurdish" ? (
                    <span className="avatar avatar-sm rounded-circle">
                      <img alt="..." src={"/assets/kurdistan.png"} />
                    </span>
                  ) : (
                    <span className="avatar avatar-sm rounded-circle">
                      <img alt="..." src={"/assets/iraq2.png"} />
                    </span>
                  )}
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem
                  onClick={(e) => {
                    dispatch(allActions.LanguageAction.Kurdish());
                  }}
                >
                  <i class="fas fa-sign-out-alt"></i>
                  <span>Kurdish</span>
                </DropdownItem>
                <DropdownItem
                  onClick={(e) => {
                    // dispatch(allActions.AuthAction.logOut())
                    dispatch(allActions.LanguageAction.Arabic());
                  }}
                >
                  <i class="fas fa-sign-out-alt"></i>
                  <span>Arabic</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>

          {user.role === "admin" ? (
            <Nav className="align-items-center d-none d-md-flex" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="avatar avatar-sm rounded-circle">
                        <i className="fa fa-cog"></i>
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem to="/admin/users" tag={Link}>
                    <i className="fas fa-users"></i>
                    <span>Users</span>
                  </DropdownItem>
                  <DropdownItem to="/admin/cities?status=city" tag={Link}>
                    <i className="fas fa-city"></i>
                    <span>Cities</span>
                  </DropdownItem>
                  <DropdownItem to="/admin/inventoryLocation" tag={Link}>
                    <i className="fas fa-city"></i>
                    <span>Inventories</span>
                  </DropdownItem>
                  <DropdownItem to="/admin/expensestype" tag={Link}>
                    <i className="fas fa-city"></i>
                    <span>Expenses Type</span>
                  </DropdownItem>
                  <DropdownItem to="/admin/reports" tag={Link}>
                    <i class="fas fa-chart-bar"></i>
                    <span>Reports</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          ) : (
            ""
          )}
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <img alt="..." src={"/assets/av.png"} />
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      {user.name}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                {/* {user.role === "admin" ? (
                  <>
                    <DropdownItem to="/admin/users" tag={Link}>
                      <i class="fas fa-users"></i>
                      <span>Users</span>
                    </DropdownItem>
                    <DropdownItem to="/admin/cities?status=city" tag={Link}>
                      <i class="fas fa-city"></i>
                      <span>Cities</span>
                    </DropdownItem>
                  </>
                ) : (
                  ""
                )} */}
                <DropdownItem to="/admin/myaccount" tag={Link}>
                  <i class="fas fa-users"></i>
                  <span>My account</span>
                </DropdownItem>
                {user.isPartner === "1" ? (
                  <>
                    {" "}
                    <DropdownItem to="/admin/partneraccount" tag={Link}>
                      <i class="fas fa-users"></i>
                      <span>Partner account</span>
                    </DropdownItem>
                  </>
                ) : (
                  ""
                )}
                <DropdownItem divider />
                <DropdownItem
                  onClick={(e) => dispatch(allActions.AuthAction.logOut())}
                >
                  <i class="fas fa-sign-out-alt"></i>
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
      <br />
    </>
  );
};

export default AdminNavbar;
