import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import NumberFormat from "react-number-format";
import Moment from "react-moment";
import ReactToPrint from "react-to-print";

import {
  Button,
  Table,
  Row,
  Col,
  Input,
  FormGroup,
  Label,
  Badge,
} from "reactstrap";

const path = require("../../../externals/path.json").path;

export default function ExpensesPerDateAndUsers() {
  let invoiceRef = useRef();

  const [reportData, setReportData] = useState([]);
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [userName, setUserName] = useState();
  const [usersList, setUsersList] = useState([]);
  const [totalPaidBackDLR, settotalPaidBackDLR] = useState(0);
  const [totalPaidBackIQD, settotalPaidBackIQD] = useState(0);
  const [totalExpensesDLR, settotalExpensesDLR] = useState(0);
  const [totalExpensesIQD, settotalExpensesIQD] = useState(0);
  const [cityId, setCityId] = useState();
  const [zoneId, setZoneId] = useState();
  const [cityList, setCityList] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [expensesTypeList, setExpensesTypeList] = useState([]);
  const [expenseType, setExpenseType] = useState();

  useEffect(() => {
    onBindUsersData();
    onBindcityData();
    onBindExpensesTypeData();
  }, []);

  function onBindcityData() {
    axios
      .get(path + "/cities", {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setCityList(result.data.data);
        }
      });
  }
  function onBindZoneDataHandler(cityId) {
    axios
      .get(path + "/zone/" + Number(cityId), {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.data !== null) setZoneList(result.data.data);
      });
  }

  function onBindExpensesTypeData() {
    axios
      .get(path + "/expensestype", {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setExpensesTypeList(result.data.data);
        }
      });
  }
  function cityItemsRender() {
    let items = [];
    cityList.map((item) =>
      items.push(
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      )
    );
    return items;
  }

  function zoneItemsRender() {
    let items = [];
    zoneList.map((item) =>
      items.push(
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      )
    );
    return items;
  }

  function onChangeCityHandler(event) {
    setCityId(event.target.value);

    onBindZoneDataHandler(event.target.value);
  }

  function onBindtableData() {
    axios
      .post(
        path + "/expensesreports/getExpensesByDateAndUser",
        {
          cityId: cityId !== "0" ? cityId : null,
          zoneId: zoneId !== "0" ? zoneId : null,
          expenseType: expenseType !== "0" ? expenseType : null,
          userName: userName !== "0" ? userName : null,
          from: from,
          to: to,
        },
        {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        }
      )
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setReportData(result.data.data);
        }
      });
    axios
      .post(
        path + "/expensesreports/getTotalExpensesByDateAndUser",
        {
          cityId: cityId !== "0" ? cityId : null,
          zoneId: zoneId !== "0" ? zoneId : null,
          expenseType: expenseType !== "0" ? expenseType : null,
          userName: userName !== "0" ? userName : null,
          from: from,
          to: to,
        },
        {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        }
      )
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          settotalPaidBackIQD(result.data.data.totalPaidBackIQD);
          settotalPaidBackDLR(result.data.data.totalPaidBackDLR);
          settotalExpensesDLR(result.data.data.totalExpensesDLR);
          settotalExpensesIQD(result.data.data.totalExpensesIQD);
        }
      });
  }

  function onBindUsersData() {
    axios
      .get(path + "/users", {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setUsersList(result.data.data);
        }
      });
  }

  function usersItemsRender() {
    let items = [];
    usersList.map((item) =>
      items.push(
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      )
    );
    return items;
  }
  function expensesTypeItemsRender() {
    let items = [];
    expensesTypeList.map((item) =>
      items.push(
        <option key={item.id} value={item.id}>
          {item.typeName}
        </option>
      )
    );
    return items;
  }
  return (
    <>
      <Row>
        <Col>
          <Input
            className="form-control-alternative"
            value={userName}
            id="userName"
            placeholder="userName"
            type="select"
            onChange={(e) => {
              setUserName(e.target.value);
            }}
            style={{ fontSize: 16, color: "black", height: 45 }}
          >
            <option value={0}>---User---</option>
            {usersItemsRender()}
          </Input>
        </Col>
        <Col>
          <Input
            className="form-control-alternative"
            value={from}
            id="from"
            placeholder="from"
            type="date"
            onChange={(e) => setFrom(e.target.value)}
            style={{ fontSize: 16, color: "black", height: 45 }}
          />
        </Col>
        <Col>
          <Input
            className="form-control-alternative"
            value={to}
            id="to"
            placeholder="to"
            type="date"
            onChange={(e) => setTo(e.target.value)}
            style={{ fontSize: 16, color: "black", height: 45 }}
          />
        </Col>
        <Col className="text-right">
          <Button color="primary" onClick={(e) => onBindtableData()} size="md">
            Get
          </Button>
          <ReactToPrint
            trigger={() => <Button color="primary">print</Button>}
            content={() => invoiceRef}
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <Input
            className="form-control-alternative"
            value={cityId}
            id="cityId"
            placeholder="cityId"
            type="select"
            onChange={(e) => onChangeCityHandler(e)}
            style={{ fontSize: 16, color: "black", height: 45 }}
          >
            <option key={0} value={0}>
              ---city---
            </option>
            {cityItemsRender()}
          </Input>
        </Col>
        <Col>
          <Input
            className="form-control-alternative"
            value={zoneId}
            id="zoneId"
            placeholder="zoneId"
            type="select"
            onChange={(e) => setZoneId(e.target.value)}
            style={{ fontSize: 16, color: "black", height: 45 }}
          >
            <option key={0} value={0}>
              ---zone---
            </option>
            {zoneItemsRender()}
          </Input>
        </Col>

        <Col>
          <Input
            className="form-control-alternative"
            value={expenseType}
            id="expenseType"
            placeholder="expenseType"
            type="select"
            onChange={(e) => setExpenseType(e.target.value)}
          >
            <option key={0} value={0}>
              ---Expense Type---
            </option>
            {expensesTypeItemsRender()}
          </Input>
        </Col>
      </Row>

      <br />
      <div ref={(el) => (invoiceRef = el)}>
        <br />
        <br />

        <Row>
          <Col>
            <FormGroup row>
              <Label
                for="exampleEmail"
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  marginTop: 10,
                }}
              >
                Total Expenses (IQD)
              </Label>
              <Col
                style={{
                  marginTop: 10,
                }}
              >
                <NumberFormat
                  value={totalExpensesIQD ? totalExpensesIQD.toFixed(0) : 0}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"IQD "}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup row>
              <Label
                for="exampleEmail"
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  marginTop: 10,
                }}
              >
                Total Expenses (DLR)
              </Label>
              <Col
                style={{
                  marginTop: 10,
                }}
              >
                <NumberFormat
                  value={totalExpensesDLR ? totalExpensesDLR.toFixed(0) : 0}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$ "}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup row>
              <Label
                for="exampleEmail"
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  marginTop: 10,
                }}
              >
                Total Paid back (IQD)
              </Label>
              <Col
                style={{
                  marginTop: 10,
                }}
              >
                <NumberFormat
                  value={totalPaidBackIQD ? totalPaidBackIQD.toFixed(0) : 0}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"IQD "}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup row>
              <Label
                for="exampleEmail"
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  marginTop: 10,
                }}
              >
                Total Paid back (DLR)
              </Label>
              <Col
                style={{
                  marginTop: 10,
                }}
              >
                <NumberFormat
                  value={totalPaidBackDLR ? totalPaidBackDLR.toFixed(0) : 0}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$ "}
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>

        <div>
          <Table
            className=" align-items-center table-flush"
            bordered
            style={{ fontWeight: "bold", color: "black" }}
            responsive
          >
            <thead className="thead-light">
              <tr>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  #
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  Reason
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  Type
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  City
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  Zone
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  {" "}
                  Amount ($)
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  Amount (IQD)
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  Expense For
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  PAID back
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  Date
                </th>
              </tr>
            </thead>
            <tbody>
              {reportData.map(function (item, index) {
                return (
                  <tr>
                    <th
                      scope="row"
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      {index + 1}
                    </th>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      {item.reason}
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      {item.expenseType}
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      {item.cityName}
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      {item.zoneName}
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      <NumberFormat
                        value={item.amountDLR.toFixed(0)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$ "}
                      />
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      <NumberFormat
                        value={item.amountIDQ.toFixed(0)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"IQD "}
                      />
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      {item.expenseFor}
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      {item.paidBack === "1" ? (
                        <Badge color="success">YES</Badge>
                      ) : (
                        <Badge color="danger">No</Badge>
                      )}
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      <Moment format="YYYY/MM/DD">{item.date}</Moment>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
}
