import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import GeneralHeader from "components/Headers/GeneralHeader.js";
import NumberFormat from "react-number-format";
import Moment from "react-moment";
// import moment from "moment";
import axios from "axios";
import { useSelector } from "react-redux";
import {
  Card,
  CardHeader,
  CardBody,
  Form,
  // CardFooter,
  Table,
  Container,
  Row,
  Col,
  // DropdownMenu,
  // DropdownItem,
  // UncontrolledDropdown,
  // DropdownToggle,
  // Pagination,
  // PaginationItem,
  // PaginationLink,
  Button,
  // ButtonGroup,
  Modal,
  // ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
} from "reactstrap";

const path = require("../../externals/path.json").path;
export default function Index() {
  const rate = useSelector((state) => state.rateData.rate);
  const user = useSelector((state) => state.isLogged.user);
  const [purchaseList, setPurchaseList] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [isUpdate, setisUpdate] = useState(false);
  const [showAddModal, setshowAddModal] = useState(false);
  const [manifestNumber, setManifestNumber] = useState(null);
  const [oldManifestNumber, setOldManifestNumber] = useState(null);
  const [totalInDinar, setTotalInDinar] = useState(0);
  const [totalInDolar, setTotalInDolar] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [totalExpenses, setTotalExpenses] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const initialState = {
    manifestNumber: null,
    itemId: null,
    quantity: 0,
    cost: 0,
    totalCostIQD: null,
    totalCostDolar: null,
    rateId: rate.id,
    expiry: null,
    expense: null,
    discount: null,
    status: 1,
    username: user.id,
  };
  const [inputList, setInputList] = useState([initialState]);
  // const [showAddModal, setshowAddModal] = useState(false);
  // const [showAddModal, setshowAddModal] = useState(false);
  // const [showAddModal, setshowAddModal] = useState(false);

  function toggle() {
    setshowAddModal(!showAddModal);
  }
  function onAddNewHandler() {
    setshowAddModal(true);
    setisUpdate(false);
    setInputList([initialState]);
    setTotalDiscount(null);
    setTotalExpenses(null);
    setManifestNumber(null);
    setTotalInDinar(null);
    setTotalInDolar(null);
  }

  function onBindItemsData() {
    axios
      .get(path + "/items", {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setItemList(result.data.data);
        }
      });
  }

  function onBindPurchaseData() {
    axios
      .get(path + "/purchase", {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;

        if (result.data.success === true) {
          setPurchaseList(result.data.data);
        }
      });
  }
  function ItemsRender() {
    let items = [];
    itemList.map((item) =>
      items.push(
        <option key={item.id} value={item.id}>
          {item.nameKrd}
        </option>
      )
    );
    return items;
  }

  useEffect(() => {
    onBindItemsData();
    onBindPurchaseData();
  }, []);

  const handleInputChange = (e, index) => {
    const { id, value } = e.target;
    const list = [...inputList];
    list[index][id] = value;

    if (id === "cost") {
      var newNum1 = +value;
      list[index][id] = newNum1;
      list[index]["totalCostIQD"] =
        list[index]["quantity"] * list[index][id] * rate.rate;
      list[index]["totalCostDolar"] = list[index]["quantity"] * list[index][id];
      sumDLR();
      sumIQD();
    }
    if (id === "quantity") {
      sumQuantitiy();
      list[index]["totalCostIQD"] =
        list[index]["cost"] * list[index][id] * rate.rate;
      list[index]["totalCostDolar"] = list[index]["cost"] * list[index][id];

      sumDLR();
      sumIQD();
    }

    setInputList(list);
  };
  function sumQuantitiy() {
    let sum = 0;
    inputList.forEach((item) => {
      sum = sum + Number(item.quantity);
    });
    setTotalQuantity(sum);
  }

  function sumIQD() {
    let expense = Number(totalExpenses * rate.rate);
    let discount = Number(totalDiscount * rate.rate);

    let sum = 0;
    inputList.forEach((item) => {
      sum = sum + Number(item.totalCostIQD);
    });
    setTotalInDinar(sum + expense - discount);
  }

  function sumDLR() {
    let sum = 0;
    inputList.forEach((item) => {
      sum = sum + Number(item.totalCostDolar);
    });
    setTotalInDolar(sum + Number(totalExpenses) - Number(totalDiscount));
  }

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, initialState]);
  };

  function EditDataHandler(invoiceNo) {
    // let item = itemList.find((o) => o.id === item.itemId);
    axios
      .get(path + "/purchase/" + invoiceNo, {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          let dis = 0,
            exp = 0;
          let sum = 0;

          result.data.data.forEach((item) => {
            dis = dis + item.discount * item.quantity;
            exp = exp + item.expense * item.quantity;
            sum = sum + Number(item.quantity);
          });
          sumDLR();
          sumIQD();
          sumQuantitiy(sum);
          setTotalDiscount(dis);
          setTotalExpenses(exp);
          setManifestNumber(result.data.data[0].manifestNumber);
          setOldManifestNumber(result.data.data[0].manifestNumber);
          setInputList(result.data.data);
          setshowAddModal(true);
          setisUpdate(true);
        }
      });
  }
  function onsaveDataHandler() {
    let expense = totalExpenses / totalQuantity;
    let discount = totalDiscount / totalQuantity;
    let purchase = [];
    let inventory = [];

    if (isUpdate) {
      inputList.forEach((item) => {
        item.manifestNumber = manifestNumber;
        item.discount = discount;
        item.expense = expense;
        purchase.push({
          manifestNumber: item.manifestNumber,
          itemId: item.itemId,
          quantity: item.quantity,
          cost: item.cost,
          rateId: item.rateId,
          expiry: item.expiry,
          expense: item.expense,
          discount: item.discount,
          status: item.status,
          username: item.username,
          id: item.id,
        });

        // inventory.push([
        //   1,
        //   item.itemId,
        //   item.quantity,
        //   item.expiry,
        //   item.cost,
        //   item.rateId,
        //   "Y",
        //   item.username,
        //   item.expense,
        //   item.discount,
        // ]);
      });

      axios
        .put(path + "/purchase/" + oldManifestNumber, purchase, {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        })
        .then((result) => {
          if (result.data.success === true) {
            onBindPurchaseData();
            setshowAddModal(false);
            setisUpdate(false);

            Swal.fire({
              title: "THANKS",
              text: "the purchase updated",
              icon: "success",
            });
          }
          // if (result.data.token !== null)
          //   this.props.user.user.token = result.data.token;
        })
        .catch((error) => {
          Swal.fire({
            title: "warning",
            text: "purchase did not updated please contact administrator",
            icon: "warning",
          });
        });
    } else {
      inputList.forEach((item) => {
        item.manifestNumber = manifestNumber;
        item.discount = discount;
        item.expense = expense;
        purchase.push([
          item.manifestNumber,
          item.itemId,
          item.quantity,
          item.cost,
          item.rateId,
          item.expiry,
          item.expense,
          item.discount,
          item.status,
          item.username,
        ]);

        inventory.push({
          inventoryLocationId: 1,
          itemId: item.itemId,
          quantity: item.quantity,
          expiry: item.expiry,
          cost: item.cost,
          rateId: item.rateId,
          active: "Y",
          username: item.username,
          expense: item.expense,
          discount: item.discount,
        });
      });
      axios
        .post(path + "/purchase", purchase, {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        })
        .then((result) => {
          if (result.data.success === true) {
            axios
              .post(path + "/inventory/frompurchase", inventory, {
                // headers: {
                //   "auth-token": this.props.user.user.token,
                // },
              })
              .then((result) => {
                if (result.data.success === true) {
                  setshowAddModal(false);
                  setisUpdate(false);
                  onBindPurchaseData();

                  Swal.fire({
                    title: "THANKS",
                    text: "new purchase updated",
                    icon: "success",
                  });
                }
                // if (result.data.token !== null)
                //   this.props.user.user.token = result.data.token;
              })
              .catch((error) => {
                Swal.fire({
                  title: "warning",
                  text: "purchase did not update please contact administrator",
                  icon: "warning",
                });
              });
            setshowAddModal(false);
            onBindPurchaseData();
          }
          // if (result.data.token !== null)
          //   this.props.user.user.token = result.data.token;
        })
        .catch((error) => {
          Swal.fire({
            title: "warning",
            text: "purchase did not added please contact administrator",
            icon: "warning",
          });
        });
    }
  }

  function onDeletePurchaseHandler(invoiceNo) {
    Swal.fire({
      title:
        'Do you want to delete purchase with invoiceNo "' + invoiceNo + '" ?',
      showCancelButton: true,
      confirmButtonText: "Save",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.value) {
        axios
          .delete(path + "/purchase/" + invoiceNo, {
            // headers: {
            //   "auth-token": this.props.user.user.token,
            // },
          })
          .then((result) => {
            if (result.data.statusCode === 200) {
              onBindPurchaseData();
              Swal.fire("purchase Deleted!", "", "success");
            }
            // if (result.data.token !== null)
            //   this.props.user.user.token = result.data.token;
          })
          .catch((error) => {
            Swal.fire({
              title: "warning",
              text: "the purchase did not deleted please contact administrator",
              icon: "warning",
            });
          });
      }
    });
  }

  return (
    <>
      <GeneralHeader />
      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h2 className="mb-0">Purchase List</h2>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button color="primary" onClick={onAddNewHandler} size="md">
                      New Purchase
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {purchaseList.map(function (item, index) {
                  return (
                    <div className="invoice-sell">
                      <table className="invoice-table-header">
                        <thead
                          // className="thead-dark"
                          style={{ backgroundColor: "#F0F8FF" }}
                          className="text-center"
                        >
                          <tr>
                            <th
                              scope="col"
                              style={{ fontSize: 16, fontWeight: "bold" }}
                            >
                              Invoice No# : {item.manifestNumber}
                            </th>
                            <th
                              scope="col"
                              style={{ fontSize: 16, fontWeight: "bold" }}
                            >
                              user : {item.username}
                            </th>
                            <th
                              scope="col"
                              colSpan="2"
                              style={{ fontSize: 16, fontWeight: "bold" }}
                            >
                              Date :
                              <Moment format="YYYY/MM/DD">{item.date}</Moment>
                            </th>
                          </tr>
                          <tr style={{ fontSize: 16, fontWeight: "bold" }}>
                            <th
                              scope="col"
                              style={{ fontSize: 16, fontWeight: "bold" }}
                            >
                              Total IQD :
                              <NumberFormat
                                value={
                                  item.totalIQD -
                                  item.totalDiscount * item.rate +
                                  item.totalExpense * item.rate
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"IQD "}
                              />
                            </th>
                            <th
                              scope="col"
                              style={{ fontSize: 16, fontWeight: "bold" }}
                            >
                              Total DLR :
                              <NumberFormat
                                value={
                                  item.totalDLR -
                                  item.totalDiscount +
                                  item.totalExpense
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$ "}
                              />
                            </th>
                            <th
                              scope="col"
                              style={{ fontSize: 16, fontWeight: "bold" }}
                            >
                              discount:
                              <NumberFormat
                                value={item.totalDiscount.toFixed(0)}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$ "}
                              />
                            </th>
                            <th
                              scope="col"
                              style={{ fontSize: 16, fontWeight: "bold" }}
                            >
                              Expenses :
                              <NumberFormat
                                value={item.totalExpense.toFixed(0)}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$ "}
                              />
                            </th>
                          </tr>
                        </thead>
                        <tbody></tbody>
                      </table>

                      <table className="invoice-table-main">
                        <thead className="thead-light text-center">
                          <tr>
                            <th
                              scope="col"
                              style={{ fontSize: 16, fontWeight: "bold" }}
                            >
                              #
                            </th>
                            <th
                              scope="col"
                              style={{ fontSize: 16, fontWeight: "bold" }}
                            >
                              Items
                            </th>
                            <th
                              scope="col"
                              style={{ fontSize: 16, fontWeight: "bold" }}
                            >
                              Quantity
                            </th>
                            <th
                              scope="col"
                              style={{ fontSize: 16, fontWeight: "bold" }}
                            >
                              Cost
                            </th>

                            <th
                              scope="col"
                              style={{ fontSize: 16, fontWeight: "bold" }}
                            >
                              Total cost (DLR)
                            </th>
                            <th
                              scope="col"
                              style={{ fontSize: 16, fontWeight: "bold" }}
                            >
                              Total cost (IQD)
                            </th>
                            <th
                              scope="col"
                              style={{ fontSize: 16, fontWeight: "bold" }}
                            >
                              Final cost (DLR)
                            </th>
                            <th
                              scope="col"
                              style={{ fontSize: 16, fontWeight: "bold" }}
                            >
                              Final cost (IQD)
                            </th>
                            <th
                              scope="col"
                              style={{ fontSize: 16, fontWeight: "bold" }}
                              colSpan="2"
                            >
                              Expiry
                            </th>
                          </tr>
                        </thead>
                        <tbody className="text-center">
                          {Array.from(item.id.split(","), Number).map(function (
                            i,
                            ind
                          ) {
                            return (
                              <tr>
                                <th scope="row">{ind + 1}</th>
                                <td
                                  style={{ fontSize: 16, fontWeight: "bold" }}
                                >
                                  {
                                    Array.from(item.item.split(","), String)[
                                      ind
                                    ]
                                  }
                                </td>
                                <td
                                  style={{ fontSize: 16, fontWeight: "bold" }}
                                >
                                  {item.quantity
                                    ? Array.from(
                                        item.quantity.split(","),
                                        Number
                                      )[ind]
                                    : 0}
                                </td>

                                <td
                                  style={{ fontSize: 16, fontWeight: "bold" }}
                                >
                                  <NumberFormat
                                    value={
                                      item.cost
                                        ? Array.from(
                                            item.cost.split(","),
                                            Number
                                          )[ind].toFixed(0)
                                        : 0
                                    }
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                  />
                                </td>

                                <td
                                  style={{ fontSize: 16, fontWeight: "bold" }}
                                >
                                  <NumberFormat
                                    value={(item.quantity
                                      ? Array.from(
                                          item.quantity.split(","),
                                          Number
                                        )[ind]
                                      : 0 * item.cost
                                      ? Array.from(
                                          item.cost.split(","),
                                          Number
                                        )[ind]
                                      : 0
                                    ).toFixed(0)}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"$ "}
                                  />
                                </td>
                                <td
                                  style={{ fontSize: 16, fontWeight: "bold" }}
                                >
                                  <NumberFormat
                                    value={(item.quantity
                                      ? Array.from(
                                          item.quantity.split(","),
                                          Number
                                        )[ind]
                                      : 0 * item.cost
                                      ? Array.from(
                                          item.cost.split(","),
                                          Number
                                        )[ind]
                                      : 0 * item.rate
                                    ).toFixed(0)}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"IQD "}
                                  />
                                </td>
                                <td
                                  style={{ fontSize: 16, fontWeight: "bold" }}
                                >
                                  <NumberFormat
                                    value={(item.quantity
                                      ? Array.from(
                                          item.quantity.split(","),
                                          Number
                                        )[ind]
                                      : 0 *
                                        (Array.from(
                                          item.cost.split(","),
                                          Number
                                        )[ind] + item.expense
                                          ? Array.from(
                                              item.expense.split(","),
                                              Number
                                            )[ind]
                                          : 0 - item.discount
                                          ? Array.from(
                                              item.discount.split(","),
                                              Number
                                            )[ind]
                                          : 0)
                                    ).toFixed(0)}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"$ "}
                                  />
                                </td>
                                <td
                                  style={{ fontSize: 16, fontWeight: "bold" }}
                                >
                                  <NumberFormat
                                    value={(
                                      Array.from(
                                        item.quantity.split(","),
                                        Number
                                      )[ind] *
                                      (Array.from(item.cost.split(","), Number)[
                                        ind
                                      ] +
                                        Array.from(
                                          item.expense.split(","),
                                          Number
                                        )[ind] -
                                        Array.from(
                                          item.discount.split(","),
                                          Number
                                        )[ind]) *
                                      item.rate
                                    ).toFixed(0)}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"IQD "}
                                  />
                                </td>
                                <td
                                  style={{ fontSize: 16, fontWeight: "bold" }}
                                >
                                  <Moment format="DD-MM-YYYY">
                                    {item.expiry
                                      ? Array.from(item.expiry.split(","))[ind]
                                      : ""}
                                  </Moment>
                                </td>
                                <td className="text-right">
                                  <div> </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: 10,
                        }}
                      >
                        {user.role === "admin" || user.role === "accountant" ? (
                          <>
                            {" "}
                            <Button
                              size="sm"
                              color="warning"
                              onClick={() =>
                                EditDataHandler(item.manifestNumber)
                              }
                            >
                              Edit
                            </Button>
                            <Button
                              size="sm"
                              color="danger"
                              onClick={() =>
                                onDeletePurchaseHandler(item.manifestNumber)
                              }
                            >
                              Delete
                            </Button>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  );
                })}
              </CardBody>
              {/* <CardFooter className="py-4">
              <nav aria-label="...">
                <Pagination
                  className="pagination justify-content-end mb-0"
                  listClassName="justify-content-end mb-0"
                >
                  <PaginationItem className="disabled">
                    <PaginationLink
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      tabIndex="-1"
                    >
                      <i className="fas fa-angle-left" />
                      <span className="sr-only">Previous</span>
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem className="active">
                    <PaginationLink
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      1
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fas fa-angle-right" />
                      <span className="sr-only">Next</span>
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </nav>
            </CardFooter> */}
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        isOpen={showAddModal}
        toggle={toggle}
        size="lg"
        style={{ maxWidth: "1500px", width: "100%" }}
      >
        {/* <ModalHeader toggle={this.toggle}>New Purchase</ModalHeader> */}
        <ModalBody>
          <Col className="order-xl-1">
            <Card className="shadow" style={{ backgroundColor: "#F0F8FF" }}>
              <CardBody>
                <Form autoComplete="off" id="ff">
                  <h6 className="heading-small text-muted mb-2">Purchase</h6>
                  <div className="pl-lg-2">
                    <Row
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: 16,
                      }}
                    >
                      <Col lg="2">
                        <FormGroup>
                          <label htmlFor="manifestNumber">Invoice Number</label>
                          <Input
                            className="form-control-alternative"
                            value={manifestNumber}
                            id="manifestNumber"
                            placeholder="Invoice Number"
                            type="number"
                            onChange={(e) => setManifestNumber(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="2">
                        <FormGroup>
                          <label htmlFor="totalDiscount">Discount (DLR)</label>
                          <Input
                            className="form-control-alternative"
                            value={totalDiscount}
                            id="totalDiscount"
                            placeholder="Discount (DLR)"
                            type="number"
                            onChange={(e) => setTotalDiscount(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="2">
                        <FormGroup>
                          <label htmlFor="totalExpenses">Expenses (DLR)</label>
                          <Input
                            className="form-control-alternative"
                            value={totalExpenses}
                            id="totalExpenses"
                            placeholder="Expenses (DLR))"
                            type="number"
                            onChange={(e) => setTotalExpenses(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label htmlFor="totalInDinar">Total (IQD)</label>
                          <Input
                            className="form-control-alternative"
                            value={totalInDinar}
                            id="totalInDinar"
                            placeholder="total (IQD)"
                            type="number"
                            onChange={(e) => setTotalInDinar(e.target.value)}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label htmlFor="totalInDolar">Total (DLR)</label>
                          <Input
                            className="form-control-alternative"
                            value={totalInDolar}
                            id="totalInDolar"
                            placeholder="Total (Dolar)"
                            type="number"
                            onChange={(e) => setTotalInDolar(e.target.value)}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <hr className="my-2" />
                    <div className="row pd-1">
                      <div className="col-md-3">
                        <div className="row text-center">
                          <div className="col-md-6">
                            <b>Item</b>
                          </div>
                          <div className="col-md-6">
                            <b>Quantity</b>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="row text-center">
                          <div className="col-md-6">
                            <b>Cost (Dolar)</b>
                          </div>
                          <div className="col-md-6">
                            <b>Total Cost (Dolar)</b>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="row text-center">
                          <div className="col-md-6">
                            <b>Total Cost (IQD)</b>
                          </div>
                          <div className="col-md-6">
                            <b>Expire Date</b>
                          </div>
                        </div>
                      </div>
                    </div>
                    {inputList.map((x, i) => {
                      return (
                        <div className="row">
                          <div className="col-md-3">
                            <div className="row text-center">
                              <div className="col-md-6">
                                <Input
                                  className="form-control-alternative"
                                  value={x.itemId}
                                  id="itemId"
                                  placeholder="itemId"
                                  type="select"
                                  onChange={(e) => handleInputChange(e, i)}
                                >
                                  <option>---item---</option>
                                  {ItemsRender()}
                                </Input>
                              </div>
                              <div className="col-md-6">
                                <Input
                                  className="form-control-alternative"
                                  value={x.quantity}
                                  id="quantity"
                                  placeholder="Quantity"
                                  type="number"
                                  onChange={(e) => handleInputChange(e, i)}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="row text-center">
                              <div className="col-md-6">
                                <Input
                                  className="form-control-alternative"
                                  value={x.cost}
                                  id="cost"
                                  placeholder="cost"
                                  type="number"
                                  onChange={(e) => handleInputChange(e, i)}
                                />
                              </div>
                              <div className="col-md-6">
                                <Input
                                  className="form-control-alternative"
                                  value={x.totalCostDolar}
                                  id="totalCostDolar"
                                  placeholder="totalCostDolar"
                                  type="number"
                                  onChange={(e) => handleInputChange(e, i)}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="row text-center">
                              <div className="col-md-6">
                                <Input
                                  className="form-control-alternative"
                                  value={x.totalCostIQD}
                                  id="totalCostIQD"
                                  placeholder="totalCostIQD"
                                  type="number"
                                  onChange={(e) => handleInputChange(e, i)}
                                  disabled
                                />
                              </div>
                              <div className="col-md-6">
                                <Input
                                  className="form-control-alternative"
                                  value={x.expiry}
                                  id="expiry"
                                  placeholder="expiry"
                                  type="date"
                                  onChange={(e) => handleInputChange(e, i)}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2">
                            {inputList.length - 1 === i && (
                              <Button
                                style={{
                                  width: " 45px",
                                  height: "45px",
                                  padding: "3px",
                                  "border-radius": " 30px",
                                  "text-align": "center",
                                  "font-size": "20px",
                                  "line-height": "1.42857",
                                }}
                                color="info"
                                type="button"
                                onClick={handleAddClick}
                              >
                                <span className="btn-inner--icon">
                                  <i className="fas fa-plus"></i>
                                </span>
                              </Button>
                            )}
                            {inputList.length !== 1 && (
                              <Button
                                style={{
                                  width: " 45px",
                                  height: "45px",
                                  padding: "3px",
                                  "border-radius": " 30px",
                                  "text-align": "center",
                                  "font-size": "20px",
                                  "line-height": "1.42857",
                                }}
                                color="danger"
                                type="button"
                                onClick={() => handleRemoveClick(i)}
                              >
                                <span className="btn-inner--icon">
                                  <i className="far fa-trash-alt"></i>
                                </span>
                              </Button>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={onsaveDataHandler}>
            Save
          </Button>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
