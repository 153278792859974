import React, { useState, useRef, useEffect } from "react";
import ReactToPrint from "react-to-print";
import axios from "axios";
import Swal from "sweetalert2";
import NumberFormat from "react-number-format";
import Moment from "react-moment";
import { useSelector } from "react-redux";

import { Button, Table, Row, Col, Input, FormGroup, Label } from "reactstrap";

const path = require("../../../externals/path.json").path;

export default function MonthlyProfitOrLoss() {
  let invoiceRef = useRef();
  let reportHistoryRef = useRef();
  let reportPartnerRef = useRef();
  const user = useSelector((state) => state.isLogged.user);

  const [reportData, setReportData] = useState({
    totalExpensesDLR: 0,
    totalExpensesIQD: 0,
    totalSellDLR: 0,
    totalSellIQD: 0,
    totalUserShareDLR: 0,
    totalUserShareIQD: 0,
    totalCostDLR: 0,
    totalCostIQD: 0,
    totalQuantity: 0,
    profitOrLoss: 0,
  });
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [isActiveSave, setIsActiveSave] = useState(true);
  const [reportHistory, setReportHistory] = useState([]);
  const [partnerUsersReportData, setPartnerUsersReportData] = useState([]);
  const [cityId, setCityId] = useState();
  const [zoneId, setZoneId] = useState();
  const [cityList, setCityList] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [userName, setUserName] = useState();
  const [usersList, setUsersList] = useState([]);

  useEffect(() => {
    onBindReportHistory();
    onBindcityData();
    onBindUsersData();
  }, []);

  function onBindPartnerUsers(ReportResult) {
    axios
      .get(
        path + "/partnerusers",

        {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        }
      )
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          let partnerResult = [];
          result.data.data.map((item) => {
            partnerResult.push({
              partnerId: item.id,
              PartnerName: item.name,
              role: item.role,
              partnerPersentage: item.partnerPersentage,
              fromDate: from,
              toDate: to,
              partnerAmountIQD:
                (ReportResult.profitOrLossIQD * item.partnerPersentage) / 100,
              partnerAmountDLR:
                (ReportResult.profitOrLossDLR * item.partnerPersentage) / 100,
              totalCostDLR: ReportResult.totalCostDLR,
              totalCostIQD: ReportResult.totalCostIQD,
              profitOrLossDLR: ReportResult.profitOrLossDLR,
              profitOrLossIQD: ReportResult.profitOrLossIQD,
              totalExpensesDLR: ReportResult.totalExpensesDLR,
              totalExpensesIQD: ReportResult.totalExpensesIQD,
              totalQuantity: ReportResult.totalQuantity,
              totalSellDLR: ReportResult.totalSellDLR,
              totalSellIQD: ReportResult.totalCostDLR,
              // totalSalaryDLR: ReportResult.totalUserShareDLR,
              // totalSalaryIQD: ReportResult.totalUserShareIQD,
              paidToParnter: 0,
              username: user.id,
            });
          });
          setPartnerUsersReportData(partnerResult);
          setIsActiveSave(false);
        }
      });
  }
  function onBindtableData() {
    if (from && to)
      axios
        .post(
          path + "/sellreports/getProfitandLoss",
          {
            cityId: cityId !== "0" ? cityId : null,
            zoneId: zoneId !== "0" ? zoneId : null,
            userName: userName !== "0" ? userName : null,
            from: from,
            to: to,
          },
          {
            // headers: {
            //   "auth-token": this.props.user.user.token,
            // },
          }
        )
        .then((result) => {
          // if (result.data.token !== null)
          //   this.props.user.user.token = result.data.token;
          if (result.data.success === true) {
            let reportResult = {
              totalExpensesDLR: result.data.data.totalExpensesDLR
                ? result.data.data.totalExpensesDLR
                : 0,
              totalExpensesIQD: result.data.data.totalExpensesIQD
                ? result.data.data.totalExpensesIQD
                : 0,
              totalSellDLR: result.data.data.totalSellDLR
                ? result.data.data.totalSellDLR
                : 0,
              totalSellIQD: result.data.data.totalSellIQD
                ? result.data.data.totalSellIQD
                : 0,
              // totalUserShareDLR: result.data.data.totalUserShareDLR
              //   ? result.data.data.totalUserShareDLR
              //   : 0,
              // totalUserShareIQD: result.data.data.totalUserShareIQD
              //   ? result.data.data.totalUserShareIQD
              //   : 0,
              totalCostIQD: result.data.data.costIQD
                ? result.data.data.costIQD
                : 0,
              totalCostDLR: result.data.data.costDLR
                ? result.data.data.costDLR
                : 0,
              totalQuantity: result.data.data.totalQuantity
                ? result.data.data.totalQuantity
                : 0,
              profitOrLossIQD:
                result.data.data.totalSellIQD -
                (result.data.data.totalExpensesIQD +
                  // result.data.data.totalUserShareIQD +
                  result.data.data.costIQD),
              profitOrLossDLR:
                result.data.data.totalSellDLR -
                (result.data.data.totalExpensesDLR +
                  // result.data.data.totalUserShareDLR +
                  result.data.data.costDLR),
            };
            setReportData(reportResult);
            onBindPartnerUsers(reportResult);
          } else {
            Swal.fire({
              title: "warning",
              text: "there is no result for this search",
              icon: "warning",
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            title: "warning",
            text: "there is some error please contact administrator",
            icon: "warning",
          });
        });
    else
      Swal.fire({
        title: "warning",
        text: "Please select date",
        icon: "warning",
      });
  }

  function onBindReportHistory() {
    axios
      .get(
        path + "/monthlyprofit",

        {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        }
      )
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setReportHistory(result.data.data);
          setIsActiveSave(true);
        }
      });
  }

  function onSaveReportData() {
    Swal.fire({
      title: "Do you want save report ?",
      showCancelButton: true,
      confirmButtonText: "Save",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.value) {
        axios
          .post(path + "/monthlyprofit", partnerUsersReportData, {
            // headers: {
            //   "auth-token": this.props.user.user.token,
            // },
          })
          .then((result) => {
            if (result.data.statusCode === 200) {
              Swal.fire("Report saved successfuly", "success");
              setPartnerUsersReportData([]);
              setReportData({
                totalExpensesDLR: 0,
                totalExpensesIQD: 0,
                totalSellDLR: 0,
                totalSellIQD: 0,
                totalUserShareDLR: 0,
                totalUserShareIQD: 0,
                totalCostDLR: 0,
                totalCostIQD: 0,
                totalQuantity: 0,
                profitOrLoss: 0,
              });
              onBindReportHistory();
              setIsActiveSave(false);
            }
            // if (result.data.token !== null)
            //   this.props.user.user.token = result.data.token;
          })
          .catch((error) => {
            Swal.fire({
              title: "warning",
              text: " Report can not save  please contact administrator",
              icon: "warning",
            });
          });
      }
    });
  }

  function userPartnersRender() {
    let items = [];
    partnerUsersReportData.map((item) =>
      items.push(
        <tr>
          <td
            style={{
              color: "black",
              fontSize: 15,
              textAlign: "center",
            }}
          >
            {item.PartnerName}
          </td>
          <td
            style={{
              color: "black",
              fontSize: 15,
              textAlign: "center",
            }}
          >
            <NumberFormat
              value={
                item.partnerAmountDLR ? item.partnerAmountDLR.toFixed(2) : 0
              }
              displayType={"text"}
              thousandSeparator={true}
              prefix={"$ "}
            />
          </td>
          <td
            style={{
              color: "black",
              fontSize: 15,
              textAlign: "center",
            }}
          >
            <NumberFormat
              value={
                item.partnerAmountIQD ? item.partnerAmountIQD.toFixed(2) : 0
              }
              displayType={"text"}
              thousandSeparator={true}
              prefix={"IQD "}
            />
          </td>
        </tr>
      )
    );
    return items;
  }

  function onBindcityData() {
    axios
      .get(path + "/cities", {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setCityList(result.data.data);
        }
      });
  }

  function onBindZoneDataHandler(cityId) {
    axios
      .get(path + "/zone/" + Number(cityId), {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.data !== null) setZoneList(result.data.data);
      });
  }

  function cityItemsRender() {
    let items = [];
    cityList.map((item) =>
      items.push(
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      )
    );
    return items;
  }
  function zoneItemsRender() {
    let items = [];
    zoneList.map((item) =>
      items.push(
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      )
    );
    return items;
  }
  function onChangeCityHandler(event) {
    setCityId(event.target.value);

    onBindZoneDataHandler(event.target.value);
  }

  function onBindUsersData() {
    axios
      .get(path + "/users", {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setUsersList(result.data.data);
        }
      });
  }

  function usersItemsRender() {
    let items = [];
    usersList.map((item) =>
      items.push(
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      )
    );
    return items;
  }

  return (
    <>
      <Row>
        <Col>
          <FormGroup row>
            <Col>
              <Input
                className="form-control-alternative"
                value={from}
                id="from"
                placeholder="from"
                type="date"
                onChange={(e) => {
                  setFrom(e.target.value);
                  setIsActiveSave(true);
                }}
                style={{ fontSize: 16, color: "black", height: 45 }}
              />
            </Col>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup row>
            <Col>
              <Input
                className="form-control-alternative"
                value={to}
                id="to"
                placeholder="to"
                type="date"
                onChange={(e) => {
                  setTo(e.target.value);
                  setIsActiveSave(true);
                }}
                style={{ fontSize: 16, color: "black", height: 45 }}
              />
            </Col>
          </FormGroup>
        </Col>
        {/* <Col lg="3">
          <FormGroup row>
            <Label
              for="exampleEmail"
              style={{
                fontSize: 16,
                fontWeight: "bold",
                marginTop: 10,
              }}
            >
              Keep in Bank(%):
            </Label>
            <Col>
              <Input
                className="form-control-alternative"
                value={keepInBankPer}
                id="keepInBankPer"
                placeholder="keepInBankPer"
                type="number"
                onChange={(e) => setKeepInBankPer(e.target.value)}
                style={{ fontSize: 16, color: "black", height: 45 }}
              />
            </Col>
          </FormGroup>
        </Col> */}

        <Col className="text-right">
          <Button color="primary" onClick={(e) => onBindtableData()} size="md">
            Get
          </Button>
          <Button
            color="success"
            disabled={isActiveSave}
            onClick={(e) => onSaveReportData()}
            size="md"
          >
            Save
          </Button>
          <ReactToPrint
            trigger={() => <Button color="warning">print</Button>}
            content={() => invoiceRef}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            className="form-control-alternative"
            value={cityId}
            id="cityId"
            placeholder="cityId"
            type="select"
            onChange={(e) => onChangeCityHandler(e)}
            style={{ fontSize: 16, color: "black", height: 45 }}
          >
            <option key={0} value={0}>
              ---city---
            </option>
            {cityItemsRender()}
          </Input>
        </Col>
        <Col>
          <Input
            className="form-control-alternative"
            value={zoneId}
            id="zoneId"
            placeholder="zoneId"
            type="select"
            onChange={(e) => setZoneId(e.target.value)}
            style={{ fontSize: 16, color: "black", height: 45 }}
          >
            <option key={0} value={0}>
              ---zone---
            </option>
            {zoneItemsRender()}
          </Input>
        </Col>
        <Col>
          <Input
            className="form-control-alternative"
            value={userName}
            id="userName"
            placeholder="userName"
            type="select"
            onChange={(e) => setUserName(e.target.value)}
            style={{ fontSize: 16, color: "black", height: 45 }}
          >
            <option value={0}>---User---</option>
            {usersItemsRender()}
          </Input>
        </Col>
      </Row>

      <br />
      <div ref={(el) => (invoiceRef = el)}>
        <br />
        <br />
        <h2 style={{ textAlign: "center" }}>
          ڕاپۆرتی مانگانەی قازانج و زەرەر بۆ بەرواری{" "}
          {<Moment format="YYYY/MM/DD">{from}</Moment>} بۆ {"    "}
          {<Moment format="YYYY/MM/DD">{to}</Moment>}
        </h2>
        <br />
        <br />{" "}
        <div>
          <Table
            className=" align-items-center table-flush"
            bordered
            style={{ fontWeight: "bold", color: "black", direction: "rtl" }}
            responsive
          >
            <thead className="thead-light">
              <tr>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                ></th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  بڕی پارە ($)
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  بڕی پارە (IQD)
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  style={{
                    color: "black",
                    fontSize: 15,
                    textAlign: "center",
                  }}
                >
                  کۆی گشتى فرشراوە (کارتۆن)
                </td>
                <td
                  style={{
                    color: "black",
                    fontSize: 15,
                    textAlign: "center",
                  }}
                >
                  {reportData.totalQuantity
                    ? reportData.totalQuantity.toFixed(2)
                    : 0}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    color: "black",
                    fontSize: 15,
                    textAlign: "center",
                  }}
                >
                  کۆی پارەی وەرگیراوی فرۆشتن
                </td>
                <td
                  style={{
                    color: "black",
                    fontSize: 15,
                    textAlign: "center",
                  }}
                >
                  <NumberFormat
                    value={
                      reportData.totalSellDLR
                        ? reportData.totalSellDLR.toFixed(0)
                        : 0
                    }
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$ "}
                  />
                </td>
                <td
                  style={{
                    color: "black",
                    fontSize: 15,
                    textAlign: "center",
                  }}
                >
                  <NumberFormat
                    value={
                      reportData.totalSellIQD
                        ? reportData.totalSellIQD.toFixed(0)
                        : 0
                    }
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"IQD "}
                  />
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    color: "black",
                    fontSize: 15,
                    textAlign: "center",
                  }}
                >
                  کۆی پارەی کڕین
                </td>
                <td
                  style={{
                    color: "black",
                    fontSize: 15,
                    textAlign: "center",
                  }}
                >
                  <NumberFormat
                    value={
                      reportData.totalCostDLR
                        ? reportData.totalCostDLR.toFixed(0)
                        : 0
                    }
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$ "}
                  />
                </td>
                <td
                  style={{
                    color: "black",
                    fontSize: 15,
                    textAlign: "center",
                  }}
                >
                  <NumberFormat
                    value={
                      reportData.totalCostIQD
                        ? reportData.totalCostIQD.toFixed(0)
                        : 0
                    }
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"IQD "}
                  />
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    color: "black",
                    fontSize: 15,
                    textAlign: "center",
                  }}
                >
                  کۆی گشتی مەصروفات
                </td>
                <td
                  style={{
                    color: "black",
                    fontSize: 15,
                    textAlign: "center",
                  }}
                >
                  <NumberFormat
                    value={
                      reportData.totalExpensesDLR
                        ? reportData.totalExpensesDLR.toFixed(0)
                        : 0
                    }
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$ "}
                  />
                </td>
                <td
                  style={{
                    color: "black",
                    fontSize: 15,
                    textAlign: "center",
                  }}
                >
                  <NumberFormat
                    value={
                      reportData.totalExpensesIQD
                        ? reportData.totalExpensesIQD.toFixed(0)
                        : 0
                    }
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"IQD "}
                  />
                </td>
              </tr>
              {/* <tr>
                <td
                  style={{
                    color: "black",
                    fontSize: 15,
                    textAlign: "center",
                  }}
                >
                  کۆی گشتی ڕۆشتوو بۆ موچە و نسبە
                </td>
                <td
                  style={{
                    color: "black",
                    fontSize: 15,
                    textAlign: "center",
                  }}
                >
                  <NumberFormat
                    value={
                      reportData.totalUserShareDLR
                        ? reportData.totalUserShareDLR.toFixed(0)
                        : 0
                    }
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$ "}
                  />
                </td>
                <td
                  style={{
                    color: "black",
                    fontSize: 15,
                    textAlign: "center",
                  }}
                >
                  <NumberFormat
                    value={
                      reportData.totalUserShareIQD
                        ? reportData.totalUserShareIQD.toFixed(0)
                        : 0
                    }
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"IQD "}
                  />
                </td>
              </tr> */}
              <tr>
                <td
                  style={{
                    color: "black",
                    fontSize: 15,
                    textAlign: "center",
                  }}
                >
                  كۆى گشتى پارەى قازانج یان زەرەر{" "}
                </td>
                <td
                  style={{
                    color: "black",
                    fontSize: 15,
                    textAlign: "center",
                  }}
                >
                  <NumberFormat
                    value={
                      reportData.profitOrLossDLR
                        ? reportData.profitOrLossDLR.toFixed(0)
                        : 0
                    }
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$ "}
                  />
                </td>
                <td
                  style={{
                    color: "black",
                    fontSize: 15,
                    textAlign: "center",
                  }}
                >
                  <NumberFormat
                    value={
                      reportData.profitOrLossIQD
                        ? reportData.profitOrLossIQD.toFixed(0)
                        : 0
                    }
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"IQD "}
                  />
                </td>
              </tr>

              {/* <tr style={{ background: resultDLR < 0 ? "red" : "green" }}>
                <td
                  style={{
                    color: "black",
                    fontSize: 15,
                    textAlign: "center",
                  }}
                >
                  کۆی گشتی
                </td>
                <td
                  style={{
                    color: "black",
                    fontSize: 15,
                    textAlign: "center",
                  }}
                >
                  <NumberFormat
                    value={resultDLR ? resultDLR.toFixed(0) : 0}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$ "}
                  />
                </td>
                <td
                  style={{
                    color: "black",
                    fontSize: 15,
                    textAlign: "center",
                  }}
                >
                  <NumberFormat
                    value={resultIQD ? resultIQD.toFixed(0) : 0}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"IQD "}
                  />
                </td>
              </tr> */}
            </tbody>
          </Table>
        </div>
      </div>
      <br />
      <br />
      <hr />
      <br />
      <Row>
        <Col className="text-right">
          <ReactToPrint
            trigger={() => <Button color="warning">print</Button>}
            content={() => reportPartnerRef}
          />
        </Col>
      </Row>
      <br />
      <div ref={(el) => (reportPartnerRef = el)}>
        <br />
        <br />
        <h2 style={{ textAlign: "center" }}>
          پشکی هاوبەشەکان{"   "}
          {<Moment format="YYYY/MM/DD">{from}</Moment>} بۆ {"    "}
          {<Moment format="YYYY/MM/DD">{to}</Moment>}
        </h2>
        <br />
        <br />{" "}
        <div>
          <Table
            className=" align-items-center table-flush"
            bordered
            style={{ fontWeight: "bold", color: "black", direction: "rtl" }}
            responsive
          >
            <thead className="thead-light">
              <tr>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  {" "}
                  ناوی هاوبەش
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  بڕی پارە ($)
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  بڕی پارە (IQD)
                </th>
              </tr>
            </thead>
            <tbody>{userPartnersRender()}</tbody>
          </Table>
        </div>
      </div>
      <br />
      <br />
      <hr />
      <Row>
        <Col className="text-right">
          <ReactToPrint
            trigger={() => <Button color="warning">print</Button>}
            content={() => reportHistoryRef}
          />
        </Col>
      </Row>

      <br />
      <div ref={(el) => (reportHistoryRef = el)}>
        <h2 style={{ textAlign: "center" }}>ڕاپۆرتی گشتی قازانج و زەرەر</h2>
        <br />
        <br />{" "}
        <div dir="rtl">
          <Table
            className=" align-items-center table-flush"
            bordered
            style={{ fontWeight: "bold", color: "black" }}
            responsive
          >
            <thead className="thead-light">
              <tr>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  #
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  پارەی وەرگیراوی فرۆشتن
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  پارەى کڕین
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  مەصروفات
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  بڕی ڕۆشتوو بۆ موچە و نسبە
                </th>

                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  بڕی قازانج یان زەرەر
                </th>
                <th
                  scope="col"
                  style={{ color: "black", fontSize: 15, textAlign: "center" }}
                >
                  بەروار
                </th>
              </tr>
            </thead>
            <tbody>
              {reportHistory.map(function (item, index) {
                return (
                  <tr>
                    <th
                      scope="row"
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      {index + 1}
                    </th>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      <NumberFormat
                        value={item.totalSellIQD.toFixed(0)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"IQD "}
                      />
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      <NumberFormat
                        value={item.totalCostIQD.toFixed(0)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"IQD "}
                      />
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      <NumberFormat
                        value={item.totalExpensesIQD.toFixed(0)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"IQD "}
                      />
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      <NumberFormat
                        value={item.totalSalaryIQD.toFixed(0)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"IQD "}
                      />
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      <NumberFormat
                        value={item.profitOrLossIQD.toFixed(0)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"IQD "}
                      />
                    </td>{" "}
                    <td
                      style={{
                        color: "black",
                        fontSize: 15,
                        textAlign: "center",
                      }}
                    >
                      {<Moment format="YYYY/MM/DD">{item.fromDate}</Moment>} بۆ{" "}
                      {"    "}
                      {<Moment format="YYYY/MM/DD">{item.toDate}</Moment>}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
}
