import React, { useState, useEffect } from "react";
import GeneralHeader from "components/Headers/GeneralHeader";
import axios from "axios";
import Swal from "sweetalert2";
import NumberFormat from "react-number-format";
import Moment from "react-moment";

import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  Input,
} from "reactstrap";
import { useSelector } from "react-redux";
const path = require("../../../externals/path.json").path;

export default function MyAccounts() {
  const user = useSelector((state) => state.isLogged.user);
  const [reportData, setReportData] = useState([]);
  const [userShareHistory, setUserShareHistory] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [userName, setUserName] = useState();
  const [userData, setUserData] = useState();
  const [salary, setSalary] = useState();

  function onBindUsersData() {
    axios
      .get(path + "/users", {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setUsersList(result.data.data);
          // onBindTableData();
          onBindTableData([], result.data.data);
        }
      });
  }

  function onBindUserShareHistory(fullName) {
    if (!fullName) fullName = user.name;

    axios
      .get(path + "/userhare/" + fullName, {
        // headers: {
        //   "auth-token": this.props.user.user.token,
        // },
      })
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          // setUsersList(result.data.data);
          setUserShareHistory(result.data.data);
        }
      });
  }

  const onBindTableData = (userData, users) => {
    if (userData.length) {
      setSalary(userData.salary);
    } else {
      if (users) {
        var index = users.findIndex(
          (element) => element.id === Number(user.id)
        );
      }
      setSalary(users[index].salary);
    }

    axios
      .post(
        path + "/sellreports/usersaccounts",
        {
          userId: userData.length ? userData[0].id : user.id,
          role: userData.length ? userData[0].role : user.role,
          repaymentType: userData.length
            ? userData[0].repaymentType
            : user.repaymentType,
        },
        {
          // headers: {
          //   "auth-token": this.props.user.user.token,
          // },
        }
      )
      .then((result) => {
        // if (result.data.token !== null)
        //   this.props.user.user.token = result.data.token;
        if (result.data.success === true) {
          setReportData(result.data.data);
          // if (!result.data.data.salary) {
          //   if (!userData) {
          //     let index = usersList.findIndex(
          //       (element) => element.id === Number(user.id)
          //     );

          //     setSalary(usersList[index].salary);
          //   } else setSalary(userData.salary);
          // }
        }
      });
  };
  useEffect(() => {
    // if (usersList) onBindTableData();
    onBindUsersData();
    onBindUserShareHistory();
  }, []);

  function usersItemsRender() {
    let items = [];
    usersList.map((item) =>
      items.push(
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      )
    );
    return items;
  }
  function onChangeUserHandler(e) {
    let index = usersList.findIndex(
      (element) => element.id === Number(e.target.value)
    );

    setUserName(e.target.value);
    setUserData(usersList[index]);
    onBindTableData([usersList[index]]);
    onBindUserShareHistory(usersList[index].name);
    setUserShareHistory([]);
  }
  return (
    <>
      <GeneralHeader />

      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col>
                    <h2 className="mb-0">
                      My Account ( {reportData ? reportData.name : ""})
                    </h2>
                  </Col>
                  {/* <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                    //   onClick={(e) => onAddNewHandler()}
                      size="md"
                    >
                      New User
                    </Button>
                  </Col> */}
                  {user.role === "admin" || user.role === "accountant" ? (
                    <Col>
                      <Input
                        className="form-control-alternative"
                        value={userName}
                        id="userName"
                        placeholder="userName"
                        type="select"
                        onChange={(e) => onChangeUserHandler(e)}
                        style={{ fontSize: 16, color: "black", height: 45 }}
                      >
                        <option value={0}>---User---</option>
                        {usersItemsRender()}
                      </Input>
                    </Col>
                  ) : (
                    <></>
                  )}
                </Row>
              </CardHeader>
              <div>
                <Table
                  className=" align-items-center table-flush"
                  bordered
                  style={{ fontWeight: "bold", color: "black" }}
                  responsive
                >
                  <thead className="thead-light">
                    <tr>
                      <th
                        scope="col"
                        style={{
                          color: "black",
                          fontSize: 15,
                          textAlign: "center",
                        }}
                      ></th>
                      <th
                        scope="col"
                        style={{
                          color: "black",
                          fontSize: 15,
                          textAlign: "center",
                        }}
                      >
                        Result
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        style={{
                          color: "black",
                          fontSize: 13,
                          textAlign: "left",
                        }}
                      >
                        Total Quantity
                      </td>
                      <td
                        style={{
                          color: "black",
                          fontSize: 15,
                          textAlign: "center",
                        }}
                      >
                        {reportData &&
                        (reportData.totalQuantity || reportData.totalQuantity)
                          ? reportData.totalQuantity
                          : 0}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          color: "black",
                          fontSize: 13,
                          textAlign: "left",
                        }}
                      >
                        Total Invoices
                      </td>
                      <td
                        style={{
                          color: "black",
                          fontSize: 15,
                          textAlign: "center",
                        }}
                      >
                        {reportData &&
                        (reportData.totalInvoices ||
                          reportData.totalInvoices === 0)
                          ? reportData.totalInvoices.toFixed(0)
                          : 0}
                      </td>
                    </tr>

                    <tr>
                      <td
                        style={{
                          color: "black",
                          fontSize: 13,
                          textAlign: "left",
                        }}
                      >
                        Total Invoices Price
                      </td>
                      <td
                        style={{
                          color: "black",
                          fontSize: 15,
                          textAlign: "center",
                        }}
                      >
                        <NumberFormat
                          value={
                            reportData &&
                            (reportData.totalpriceIQD ||
                              reportData.totalpriceIQD === 0)
                              ? reportData.totalpriceIQD.toFixed(0)
                              : 0
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"IQD "}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          color: "black",
                          fontSize: 13,
                          textAlign: "left",
                        }}
                      >
                        Your Salary
                      </td>
                      <td
                        style={{
                          color: "black",
                          fontSize: 15,
                          textAlign: "center",
                        }}
                      >
                        <NumberFormat
                          value={
                            reportData &&
                            (reportData.salary || reportData.salary === 0)
                              ? reportData.salary.toFixed(0)
                              : 0
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"IQD "}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          color: "black",
                          fontSize: 13,
                          textAlign: "left",
                        }}
                      >
                        Your Share
                      </td>
                      <td
                        style={{
                          color: "black",
                          fontSize: 15,
                          textAlign: "center",
                        }}
                      >
                        <NumberFormat
                          value={
                            reportData &&
                            (reportData.totalpersentage ||
                              reportData.totalpersentage === 0)
                              ? reportData.totalpersentage.toFixed(0)
                              : 0
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"IQD "}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          color: "black",
                          fontSize: 13,
                          textAlign: "left",
                        }}
                      >
                        Your Bonus
                      </td>
                      <td
                        style={{
                          color: "black",
                          fontSize: 15,
                          textAlign: "center",
                        }}
                      >
                        <NumberFormat
                          value={
                            reportData &&
                            (reportData.totalBonus ||
                              reportData.totalBonus === 0)
                              ? reportData.totalBonus.toFixed(0)
                              : 0
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"IQD "}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          color: "black",
                          fontSize: 13,
                          textAlign: "left",
                          background: "red",
                        }}
                      >
                        Total
                      </td>
                      <td
                        style={{
                          color: "black",
                          fontSize: 15,
                          textAlign: "center",
                          background: "red",
                        }}
                      >
                        <NumberFormat
                          value={
                            reportData
                              ? (
                                  reportData.totalBonus +
                                  reportData.salary +
                                  reportData.totalpersentage
                                ).toFixed(0)
                              : 0
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"IQD "}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <hr />
                <div>
                  <h2 className="mb-0" style={{ marginLeft: "20px" }}>
                    History
                  </h2>
                  <br />
                  <Table
                    className=" align-items-center table-flush"
                    bordered
                    style={{ fontWeight: "bold", color: "black" }}
                    responsive
                  >
                    <thead className="thead-light">
                      <tr>
                        <th
                          scope="col"
                          style={{
                            color: "black",
                            fontSize: 15,
                            textAlign: "center",
                          }}
                        >
                          Total Invoices
                        </th>
                        <th
                          scope="col"
                          style={{
                            color: "black",
                            fontSize: 15,
                            textAlign: "center",
                          }}
                        >
                          Repayment type
                        </th>
                        <th
                          scope="col"
                          style={{
                            color: "black",
                            fontSize: 15,
                            textAlign: "center",
                          }}
                        >
                          Persentage (IQD)
                        </th>
                        <th
                          scope="col"
                          style={{
                            color: "black",
                            fontSize: 15,
                            textAlign: "center",
                          }}
                        >
                          Bonus (IQD)
                        </th>
                        <th
                          scope="col"
                          style={{
                            color: "black",
                            fontSize: 15,
                            textAlign: "center",
                          }}
                        >
                          From
                        </th>{" "}
                        <th
                          scope="col"
                          style={{
                            color: "black",
                            fontSize: 15,
                            textAlign: "center",
                          }}
                        >
                          To
                        </th>
                        <th
                          scope="col"
                          style={{
                            color: "black",
                            fontSize: 15,
                            textAlign: "center",
                          }}
                        >
                          Paid Date
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {userShareHistory.map(function (item, index) {
                        return (
                          <tr>
                            <th
                              scope="row"
                              style={{
                                color: "black",
                                fontSize: 15,
                                textAlign: "center",
                              }}
                            >
                              {item.numberofInvoice}
                            </th>
                            <td
                              style={{
                                color: "black",
                                fontSize: 15,
                                textAlign: "center",
                              }}
                            >
                              {item.repaymentType}
                            </td>
                            <td
                              style={{
                                color: "black",
                                fontSize: 15,
                                textAlign: "center",
                              }}
                            >
                              <NumberFormat
                                value={
                                  item.amountIQD ? item.amountIQD.toFixed(0) : 0
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"IQD "}
                              />
                            </td>
                            <td
                              style={{
                                color: "black",
                                fontSize: 15,
                                textAlign: "center",
                              }}
                            >
                              <NumberFormat
                                value={
                                  item.bonusAmount
                                    ? item.bonusAmount.toFixed(0)
                                    : 0
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"IQD "}
                              />
                            </td>
                            <td
                              style={{
                                color: "black",
                                fontSize: 15,
                                textAlign: "center",
                              }}
                            >
                              {item.from ? (
                                <Moment format="YYYY/MM/DD">{item.from}</Moment>
                              ) : (
                                ""
                              )}
                            </td>
                            <td
                              style={{
                                color: "black",
                                fontSize: 15,
                                textAlign: "center",
                              }}
                            >
                              {item.to ? (
                                <Moment format="YYYY/MM/DD">{item.to}</Moment>
                              ) : (
                                ""
                              )}
                            </td>{" "}
                            <td
                              style={{
                                color: "black",
                                fontSize: 15,
                                textAlign: "center",
                              }}
                            >
                              {" "}
                              <Moment format="YYYY/MM/DD">
                                {item.timestamp}
                              </Moment>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
