import { Card, CardBody, CardTitle, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

export default function CitiesCard() {
  return (
    <>
      <Row>
        <Col lg="6" xl="3">
          <Card className="card-stats mb-4 mb-xl-2">
            <Link to="/admin/cities?status=city">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      tag="h4"
                      className="text-uppercase text-muted mb-0"
                    >
                      City
                    </CardTitle>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                      <i class="fas fa-city"></i>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Link>
          </Card>
        </Col>

        <Col lg="6" xl="3">
          <Card className="card-stats mb-4 mb-xl-2">
            <Link to="/admin/cities?status=zone">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      tag="h4"
                      className="text-uppercase text-muted mb-0"
                    >
                      Zone
                    </CardTitle>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-green  text-white rounded-circle shadow">
                      <i class="fas fa-city"></i>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Link>
          </Card>
        </Col>

        <Col lg="6" xl="3">
          <Card className="card-stats mb-4 mb-xl-2">
            <Link to="/admin/cities?status=neighbour">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      tag="h4"
                      className="text-uppercase text-muted mb-0"
                    >
                      Neighbour
                    </CardTitle>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-purple text-white rounded-circle shadow">
                      <i class="fas fa-city"></i>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Link>
          </Card>
        </Col>
      </Row>
    </>
  );
}
